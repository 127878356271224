import React, {Component} from 'react';

import LoaderComponent from '../../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../../acl.js';
import * as requests from '../../../requests.js';
import * as helperFunctions from '../../../../../../Utils/HelperFunctions.js';

export default
class DeleteComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      status: '',
      msg: null,
      isLoading: false,
    }

    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount(){

  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.galleryID,
    }

    let request = requests.deleteGallery(array);
    request.then(function(response){
      this.setState(
        {
          status: 'success',
          msg: i18n.t(response.data.msg),
        }, () => {this.props.changeView('list', 0)}
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }





  renderFormView(){
    return(
      <form onSubmit={(event) => this.submitForm(event)}>

        <p>{i18n.t('confirm_delete_action')}</p>
        <hr/>
        <button type="submit" className="btn btn-danger">{i18n.t('button_delete')}</button>

      </form>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderFormView()
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
