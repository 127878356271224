import React, {Component} from 'react';
import i18n from "i18next";
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';

class InfoComponent extends Component{

  constructor(props){
    super(props);
    this.state = {
      phone: '',
      address: '',
      home_no: '',
      flat_no: '',
      postal_code: '',
      city: '',
      isLoading: false,
      status: '',
      msg: '',
      validationErrors: [],
    }

    this.onChangeValue = this.onChangeValue.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount(){
    this.getMyInfo();
  }

  onChangeValue(event, type){
    if(type == 'phone'){
      this.setState({phone: event.target.value});
    }
    if(type == 'address'){
      this.setState({address: event.target.value});
    }
    if(type == 'home_no'){
      this.setState({home_no: event.target.value});
    }
    if(type == 'flat_no'){
      this.setState({flat_no: event.target.value});
    }
    if(type == 'postal_code'){
      this.setState({postal_code: event.target.value});
    }
    if(type == 'city'){
      this.setState({city: event.target.value});
    }
  }

  getMyInfo(){
    this.setState({isLoading: true});
    let request = requests.getMyInfo();
    request.then(function(response){
      this.setState(
        {
          phone: response.data.phone,
          address: response.data.address,
          home_no: response.data.home_no,
          flat_no: response.data.flat_no,
          postal_code: response.data.postal_code,
          city: response.data.city,
        }
      );
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true, status: '', msg: '', validationErrors: []});

    var array = {
      'phone': this.state.phone,
      'address': this.state.address,
      'home_no': this.state.home_no,
      'flat_no': this.state.flat_no,
      'postal_code': this.state.postal_code,
      'city': this.state.city,
    };

    let request = requests.postMyInfo(array);
    request.then(function(response){
      this.setState(
        {
          name: response.data.name,
          surname: response.data.surname,
          status: 'success',
          msg: i18n.t(response.data.msg),
        }
      )
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'validation_error'){
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));

  }

  renderForm(){
    return(
      <form onSubmit={this.submitForm}>
          {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
          <div className={'address' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('label_set_address')}</label>
            <input type="text" name="address" className="form-control" value={this.state.address} onChange={(event) => this.onChangeValue(event, 'address')}/>
            <span class="help-block">{'address' in this.state.validationErrors ? i18n.t(this.state.validationErrors['address']) : null}</span>
          </div>

          <div className={'home_no' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('label_set_home_no')}</label>
            <input type="text" name="home_no" className="form-control" value={this.state.home_no} onChange={(event) => this.onChangeValue(event, 'home_no')}/>
            <span class="help-block">{'home_no' in this.state.validationErrors ? i18n.t(this.state.validationErrors['home_no']) : null}</span>
          </div>

          <div className={'flat_no' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('label_set_flat_no')}</label>
            <input type="text" name="flat_no" className="form-control" value={this.state.flat_no} onChange={(event) => this.onChangeValue(event, 'flat_no')}/>
            <span class="help-block">{'flat_no' in this.state.validationErrors ? i18n.t(this.state.validationErrors['flat_no']) : null}</span>
          </div>

          <div className={'postal_code' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('label_set_postal_code')}</label>
            <input type="text" name="zip" className="form-control" value={this.state.postal_code} onChange={(event) => this.onChangeValue(event, 'postal_code')}/>
            <span class="help-block">{'postal_code' in this.state.validationErrors ? i18n.t(this.state.validationErrors['postal_code']) : null}</span>
          </div>

          <div className={'city' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('label_set_city')}</label>
            <input type="text" name="city" className="form-control" value={this.state.city} onChange={(event) => this.onChangeValue(event, 'city')}/>
            <span class="help-block">{'city' in this.state.validationErrors ? i18n.t(this.state.validationErrors['city']) : null}</span>
          </div>

          <hr/>

            <div className={'phone' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_phone')}</label>
              <div class="input-group">
                <span class="input-group-addon" id="basic-addon1">(+48)</span>
                <input type="text" name="phone" className="form-control" value={this.state.phone} onChange={(event) => this.onChangeValue(event, 'phone')}/>
              </div>
              <span class="help-block">{'phone' in this.state.validationErrors ? i18n.t(this.state.validationErrors['phone']) : null}</span>
            </div>

          <hr/>
          <input type="submit" className="btn btn-success" value={i18n.t('button_submit_update')} />
      </form>
    );
  }

  render(){
    var view = this.renderForm();
    if(this.state.isLoading === true){
      view = <LoaderComponent />;
    }
    return(
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12">

          {view}

        </div>
      </div>
    );
  }

}

export default InfoComponent;
