import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';

export default class ObjectInfoComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      file: [],
      view: 'form',
      isLoading: false,
      status: '',
      msg: null,
      url: '',
      validationErrors: [],
    }

    this.submitFile = this.submitFile.bind(this);
    this.changeState = this.changeState.bind(this);

  }

  componentDidMount(){
    this.getInfo();
  }

  getInfo(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getIOSchoolInfoDetails(array);
    request.then(function(response){
      this.setState(
        {
          url: response.data.template_url,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  changeState(event, type){
    if(type === 'file'){
      this.setState({file: event.target.files})
    }
  }

  submitFile(event){
    event.preventDefault();
    this.setState({isLoading: true, status: '', msg: null, validationErrors: []});

    const data = new FormData();
    for (var i = 0; i < this.state.file.length; i++) {
      data.append('file', this.state.file[i]);
    }

    data.append('school_id', localStorage.getItem('objectID'));
    let request = requests.postImportObjectInfo(data);
    request.then(function(response){
      this.setState({status: 'success', msg: i18n.t(response.data.msg)});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        if(error.response.data.error === 'validation_error'){
          this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
        }
        else{
          this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
        }
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderFormView(){
    return(
      <div>

        <div className="info-box">
          <span className="info-box-icon bg-red">
            <i className="fas fa-cloud-upload-alt"></i>
          </span>
          <div className="info-box-content">
            <span className="info-box-number">
              <small>
                {i18n.t('school_data_import_title_1')}.<br/>{i18n.t('school_data_import_title_2')}.
              </small>
            </span>
          </div>
        </div>

        <br/>

        <form onSubmit={(event) => this.submitFile(event)}>

          <div className={'file' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('upload_import_xls_file')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <input type="file" className="form-control" name="file" onChange={(event) => this.changeState(event, 'file')} required />
            <span className="help-block">{'file' in this.state.validationErrors ? i18n.t(this.state.validationErrors['file']) : null}</span>
          </div>
          <button className="btn btn-danger" type="submit">{i18n.t('button_save')}</button>

        </form>

        <hr/><br/>

        <div>
          <a href="https://net-kid.com/download/import_danych_przedszkolnych.xls" download role="button" className="btn btn-default"><i className="fas fa-download"></i> {i18n.t('download_import_xls_template')}</a>
        </div>

        <div>
          <h4>{i18n.t('upload_import_template_headings_info')}</h4>
          <ul className="list-unstyled">
            <li><strong>group_name</strong>: {i18n.t('group_name')}</li>
            <li><strong>group_age</strong>: {i18n.t('age_group')}</li>
            <li><strong>group_year_start</strong>: {i18n.t('group_year_start')}</li>
            <li><strong>group_year_end</strong>: {i18n.t('group_year_start')}</li>
            <li><strong>kid_name</strong>: {i18n.t('kid_name')}</li>
            <li><strong>kid_surname</strong>: {i18n.t('kid_surname')}</li>
            <li><strong>kid_pesel</strong>: {i18n.t('kid_pesel')}</li>
            <li><strong>parent_1_name</strong>: {i18n.t('first_parent_name')}</li>
            <li><strong>parent_1_surname</strong>: {i18n.t('first_parent_surname')}</li>
            <li><strong>parent_1_email</strong>: {i18n.t('first_parent_email')}</li>
            <li><strong>parent_2_name</strong>: {i18n.t('sec_parent_name')}</li>
            <li><strong>parent_2_surname</strong>: {i18n.t('sec_parent_surname')}</li>
            <li><strong>parent_2_email</strong>: {i18n.t('sec_parent_email')}</li>
          </ul>
        </div>

        <hr/>

      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'form'){
        view = this.renderFormView()
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
