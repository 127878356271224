import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';


export default
class TasksComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      objectSelectLoading: false,
      reloadList: false,
      view: 'list',
      currentListType: 'active',
      items: [],
      isLoading: false,
      status: '',
      msg: null,
      title: '',
      content: '',
      itemStatus: 0,
      itemID: 0,
    }

    this.getItemDetails = this.getItemDetails.bind(this);
    this.changeState = this.changeState.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);
  }

  componentWillMount(){
    this.getToDoList();
  }

  changeState(event, type){
    if(type === 'title'){
      this.setState({title: event.target.value});
    }
    if(type === 'content'){
      this.setState({content: event.target.value});
    }
    if(type === 'itemStatus'){
      this.setState({itemStatus: event.target.value});
    }
  }

  submitUpdate(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var request;
    if(this.state.view === 'details'){
      var array = {
        'id' : this.state.itemID,
        'title' : this.state.title,
        'content' : this.state.content,
        'status' : this.state.itemStatus,
      }

      request = requests.postMyToDoUpdate(array);
    }
    if(this.state.view === 'create'){
      var array = {
        'title' : this.state.title,
        'content' : this.state.content,
        'status' : this.state.itemStatus,
      }

      request = requests.postMyToDoCreate(array);
    }

    request.then(function(response){
      this.setState({msg: response.data.msg, status: 'success', view: 'list'}, () => this.getToDoList());
    }.bind(this));

    request.catch(function(error){
      this.setState({items: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getItemDetails(id){
    this.setState({isLoading: true});
    var array = {
      'id' : id,
    }

    let request = requests.getMyToDoDetails(array);
    request.then(function(response){
      this.setState(
        {
          title: response.data.title,
          content: response.data.content,
          itemStatus: response.data.status,
          itemID: response.data.id,
        }
      );
    }.bind(this));

    request.catch(function(error){
      this.setState({items: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getToDoList(){
    this.setState({isLoading: true});
    var array = {
      'type' : this.state.currentListType,
    }

    let request = requests.getMyToDo(array);
    request.then(function(response){
      this.setState({items: response.data.items});
    }.bind(this));

    request.catch(function(error){
      this.setState({items: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderListView(){
    return(
      <div>
        <ul className="list-inline">
          <li className="cursor" onClick={(event) => this.setState({currentListType: 'active', status: '', msg: null }, () => {this.getToDoList()})}>{i18n.t('active_list')} <span className="label label-default">{this.state.items.active}</span></li>
          <li className="cursor" onClick={(event) => this.setState({currentListType: 'inactive', status: '', msg: null}, () => {this.getToDoList()})}>{i18n.t('inactive_list')} <span className="label label-default">{this.state.items.inactive}</span></li>
          <li className="cursor" onClick={(event) => this.setState({currentListType: 'all', status: '', msg: null}, () => {this.getToDoList()})}>{i18n.t('all_list')} <span className="label label-default">{this.state.items.all}</span></li>
        </ul>

        <hr/>
        {
          this.state.items.map(function(item){
            return(
              <div key={item.id}>

                <div className="media cursor" onClick={(event) => this.setState({view: 'details', status: '', msg: null}, () => {this.getItemDetails(item.id)})}>
                  <div className="media-left">
                    <span className={"info-box-icon " + item.css}><i className={item.icon}></i></span>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">
                      <small>{i18n.t('cdate')}: <b>{item.cdate}</b></small><br/>
                      {item.title}
                    </h4>
                    <p>{item.preface}</p>
                  </div>
                  <hr/>
                </div>

              </div>
            );
          }.bind(this))
        }

      </div>
    );
  }

  renderCreateView(){
    return(<div>Dodaj</div>);
  }

  renderDetailsView(){
    return(
      <form onSubmit={(event) => this.submitUpdate(event)}>

        <div className="form-group">
          <label>{i18n.t('set_item_title')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <input type="text" name="title" className="form-control" value={this.state.title} onChange={(event) => this.changeState(event, 'title')} required />
        </div>

        <div className="form-group">
          <label>{i18n.t('set_item_content')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <textarea name="content" rows="10" className="form-control" value={this.state.content} onChange={(event) => this.changeState(event, 'content')} required />
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className="form-group">
              <label>{i18n.t('status')}</label>
              <select className="form-control" name="status" value={this.state.itemStatus} onChange={(event) => this.changeState(event, 'itemStatus')}>
                <option value="1">{i18n.t('active')}</option>
                <option value="0">{i18n.t('inactive')}</option>
              </select>
            </div>
          </div>
        </div>

        <hr/>
        <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
        <button type="button" onClick={(event) => this.setState({view: 'list'}, () => this.getToDoList())} className="btn btn-default">{i18n.t('button_cancel')}</button>

      </form>
    )
  }

  renderMainView(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView()
      }
      else if(this.state.view === 'details'){
        view = this.renderDetailsView()
      }
      else{
        view = this.renderDetailsView();
      }
    }
    else{
      view = <LoaderComponent />
    }

    return(
      <div className="nav-tabs-custom">
          <ul className="nav nav-tabs">
            <li className="active"><a href="#tab1" onClick={(event) => this.setState({view: 'list', status: '', msg: null}, () => {this.getToDoList()})} data-toggle="tab" aria-expanded="false"><i className="fas fa-ellipsis-v"></i> {i18n.t('list_of_my_tasks')}</a></li>
            <li className=""><a href="#tab1" onClick={(event) => this.setState({view: 'create', status: '', msg: null, title: '', content: '', itemStatus: 1, itemID: 0})} data-toggle="tab" aria-expanded="false"><i className="fas fa-plus-circle"></i> {i18n.t('add_new_item')}</a></li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active" id="tab1">
              {view}
            </div>
          </div>

        </div>
    );
  }

  render(){
    var view = this.renderMainView();

    return(
      <div>

        <section className="content-header">
          <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-red"><i className="fas fa-tasks"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-number">
                      <h3>{i18n.t('tasks_module')}<br/><small>{i18n.t('tasks_module_subtitle')}</small></h3>
                    </span>
                  </div>
                </div>
              </div>

          </div>
        </section>

        <section className="content container-fluid">
          {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
          {view}
        </section>

      </div>
    );
  }

}
