import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';


export default
class InvitationsFromParentsComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      reloadList: false,
      view: 'list',
      currentListType: 'active',
      items: [],
      isLoading: false,
      status: '',
      msg: null,
      objectID: 0,
      currentItem: [],
    }

      this.submitApplication = this.submitApplication.bind(this);
  }

  componentDidMount(){
    this.getObjectApplicationsList(localStorage.getItem('objectID'));
  }

  componentWillUpdate(nextProps){
    if(nextProps.reloadList !== this.props.reloadList){
      this.getObjectApplicationsList(localStorage.getItem('objectID'));
    }
  }

  submitApplication(id, action){
    this.setState({isLoading: true});

    var array = {
      'objectID' : localStorage.getItem('objectID'),
      'id' : id,
      'action' : action
    }

    let request = requests.postInvitaionParentExecute(array);
    request.then(function(response){
      this.setState({view: 'list'}, () => {this.getObjectApplicationsList(localStorage.getItem('objectID'))});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  getObjectApplicationsList(objectID){
    this.setState({isLoading: true});

    var array = {
      'objectID' : objectID,
    }

    let request = requests.getInvitatonsFromParentList(array);
    request.then(function(response){
      this.setState({items: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      this.props.reloadList = false;
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  renderListView(){
    return(
      <div>
        {
          this.state.items.map(function(item){
            return(
              <div key={item.id}>
                <div className="media cursor" onClick={(event) => this.setState({view: 'details', currentItem: item})}>
                  <div className="media-left">
                    <span className="info-box-icon bg-yellow"><i className="fas fa-user"></i></span>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">{item.name}</h4>
                    <p>{i18n.t('cdate')}: <strong>{item.cdate}</strong>. {i18n.t('who_reports')}: <strong>{item.author}</strong></p>
                    <p>{i18n.t('status')}: <span className={item.exists.className}><strong>{i18n.t(item.exists.info)}</strong></span></p>
                  </div>
                </div>
                <hr/>
              </div>
            );
          }.bind(this))
        }

      </div>

    );
  }





  renderDetailsView(){
    return(
      <div>
        <div className="media">
          <div className="media-left">
            <span className="info-box-icon bg-yellow"><i className="fas fa-user"></i></span>
          </div>
          <div className="media-body">
            <h4 className="media-heading">{this.state.currentItem.name}</h4>
            <p>{i18n.t('who_reports')}: <strong>{this.state.currentItem.cdate}</strong>. {i18n.t('who_reports')}: <strong>{this.state.currentItem.author}</strong></p>
            <p>{i18n.t('status')}: <span className={this.state.currentItem.exists.className}><strong>{i18n.t(this.state.currentItem.exists.info)}</strong></span></p>
            <p>{i18n.t('table_th_email_address')}: <strong>{this.state.currentItem.email}</strong></p>
            <p>{i18n.t('group')}: <strong>{i18n.t(this.state.currentItem.role)}</strong></p>
            <p>{i18n.t('can_recieve_single')}: <strong>{this.state.currentItem.canRecieve ? i18n.t('yes') : i18n.t('no')}</strong></p>
            <p>{i18n.t('create_without_email')}: <strong>{this.state.currentItem.withoutEmail ? i18n.t('yes') : i18n.t('no')}</strong></p>
            <p>{i18n.t('name_and_surname_of_kid_with_assigned')}: <strong>{this.state.currentItem.kid.name} {this.state.currentItem.kid.surname} ({this.state.currentItem.kid.groupName})</strong></p>

              <hr/>
              <button onClick={(event) => this.submitApplication(this.state.currentItem.id, 'accept')} type="button" className="btn btn-success">{i18n.t('button_accept')}</button>
              <button onClick={(event) => this.submitApplication(this.state.currentItem.id, 'reject')} type="button" className="btn btn-danger">{i18n.t('button_reject')}</button>

          </div>
        </div>
        <hr/>
      </div>
    )
  }

  renderMainView(){

    var view;
    if(this.state.isLoading === false){
      view = helperFunctions.renderNoContentInfo();
      if(this.state.items !== undefined && this.state.items.length > 0){
        if(this.state.view === 'list'){
          view = this.renderListView()
        }
        if(this.state.view === 'details'){
          view = this.renderDetailsView()
        }
      }
    }
    else{
      view = <LoaderComponent />
    }

    return(<div>{view}</div>);
  }

  render(){
    var view;
    view = this.renderMainView();
    return(
      <div>{view}</div>
    );
  }

}
