import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';

export default
class ObjectBranchesComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      isLoading: false,
      status: '',
      msg: null,
      view: 'main',
      object: {id: null},
      items: [],
      validationFields: []
    }

    this.submitForm = this.submitForm.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  componentDidMount(){
    this.getObjectBranches();
  }

  getObjectBranches(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getMainSettingsObjectBranches(array);
    request.then(function(response){
      this.setState({items: response.data});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  deleteItem(event){
    event.preventDefault();
    this.setState(
      {
        isLoading: true,
      }
    );
    var array = {
      school_id: localStorage.getItem('objectID'),
      id: this.state.object.id,
    }
    let request = requests.deleteMainSettingsObjectBranch(array);
    request.then(function(response){
      this.setState({status: 'success', msg: i18n.t(response.data.msg), view: 'main', object: []}, () => {this.getObjectBranches()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else if (error.response.data.error === 'validation_error') {
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationFields: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));

  }

  submitForm(event){
    event.preventDefault();
    this.setState(
      {
        isLoading: true,
        object: {
          objectID: localStorage.getItem('objectID'),
          street: event.target.street.value,
          postal: event.target.postal.value,
          city: event.target.city.value,
          phone: event.target.phone.value,
          fax: event.target.fax.value,
          nip: event.target.nip.value,
          regon: event.target.regon.value,
          krs: event.target.krs.value,
          contact_email: event.target.contact_email.value,
          website: event.target.website.value,
          fb_profile: event.target.fb_profile.value,
          bank_acc: event.target.bank_acc.value,
        }
      }
    );

    var array = {
      school_id: localStorage.getItem('objectID'),
      id: this.state.object.id,
      street: event.target.street.value,
      postal: event.target.postal.value,
      city: event.target.city.value,
      phone: event.target.phone.value,
      fax: event.target.fax.value,
      nip: event.target.nip.value,
      regon: event.target.regon.value,
      krs: event.target.krs.value,
      contact_email: event.target.contact_email.value,
      website: event.target.website.value,
      fb_profile: event.target.fb_profile.value,
      bank_acc: event.target.bank_acc.value,
    }
    let request = requests.postMainSettingsObjectBranchManage(array);
    request.then(function(response){
      this.setState({status: 'success', msg: i18n.t(response.data.msg), view: 'main', object: []}, () => {this.getObjectBranches()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else if (error.response.data.error === 'validation_error') {
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationFields: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  loadListView(){
    return(
      <div>
        {
          this.state.items.map(function(item){
            return(
              <div>
                <div onClick={(event) => this.setState({view: 'edit', status: '', msg: null, object: item})} className="media cursor">
                  <div className="media-left">
                    <span className="info-box-icon bg-red"><i className="fas fa-school"></i></span>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">
                      <small>Data utworzenia: {item.cdate}</small><br/>
                      {item.street}, {item.postal}, {item.city}
                    </h4>
                  </div>
                </div>
                <hr/>
              </div>
            );
          }.bind(this))
        }
      </div>
    );
  }

  renderFormView(){
    var view;
    if(this.state.view === 'edit'){
      view = <button onClick={(event) => this.deleteItem(event)} type="button" class="text-right btn btn-danger"><i className="fas fa-trash-alt"></i> Usuń pozycję</button>
    }
    return(
      <div>
        <form onSubmit={(event) => this.submitForm(event)}>
          {helperFunctions.getTextInput(
              i18n.t('label_set_address'),
              "street",
              this.state.object.street,
              this.state.validationFields.indexOf('street') === -1 ? false : true,
              i18n.t('error_data_input')
          )}
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              {helperFunctions.getTextInput(
                  i18n.t('label_set_postal_code'),
                  "postal",
                  this.state.object.postal,
                  this.state.validationFields.indexOf('postal') === -1 ? false : true,
                  i18n.t('error_data_input')
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              {helperFunctions.getTextInput(
                  i18n.t('label_set_city'),
                  "city",
                  this.state.object.city,
                  this.state.validationFields.indexOf('city') === -1 ? false : true,
                  i18n.t('error_data_input')
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              {helperFunctions.getTextInput(
                  i18n.t('label_set_phone'),
                  "phone",
                  this.state.object.phone,
                  this.state.validationFields.indexOf('phone') === -1 ? false : true,
                  i18n.t('error_data_input')
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              {helperFunctions.getTextInput(
                  i18n.t('label_set_fax'),
                  "fax",
                  this.state.object.fax,
                  this.state.validationFields.indexOf('fax') === -1 ? false : true,
                  i18n.t('error_data_input'),
                  false
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              {helperFunctions.getTextInput(
                  i18n.t('label_set_nip'),
                  "nip",
                  this.state.object.nip,
                  this.state.validationFields.indexOf('nip') === -1 ? false : true,
                  i18n.t('error_data_input')
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              {helperFunctions.getTextInput(
                  i18n.t('label_set_regon'),
                  "regon",
                  this.state.object.regon,
                  this.state.validationFields.indexOf('regon') === -1 ? false : true,
                  i18n.t('error_data_input'),
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              {helperFunctions.getTextInput(
                  i18n.t('label_set_krs'),
                  "krs",
                  this.state.object.krs,
                  this.state.validationFields.indexOf('krs') === -1 ? false : true,
                  i18n.t('error_data_input')
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              {helperFunctions.getTextInput(
                  i18n.t('label_set_bank_acc'),
                  "bank_acc",
                  this.state.object.bank_acc,
                  this.state.validationFields.indexOf('bank_acc') === -1 ? false : true,
                  i18n.t('error_data_input')
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              {helperFunctions.getTextInput(
                  i18n.t('label_set_contact_email'),
                  "contact_email",
                  this.state.object.contact_email,
                  this.state.validationFields.indexOf('contact_email') === -1 ? false : true,
                  i18n.t('error_data_input')
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"></div>
          </div>
          <hr/>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              {helperFunctions.getTextInput(
                  i18n.t('label_set_website'),
                  "website",
                  this.state.object.website,
                  this.state.validationFields.indexOf('website') === -1 ? false : true,
                  i18n.t('error_data_input'),
                  false
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              {helperFunctions.getTextInput(
                  i18n.t('label_set_facebook'),
                  "fb_profile",
                  this.state.object.fb_profile,
                  this.state.validationFields.indexOf('fb_profile') === -1 ? false : true,
                  i18n.t('error_data_input'),
                  false
              )}
            </div>
          </div>
          <hr/>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
              <button onClick={(event) => this.setState({view: 'main'}, () => {this.getObjectBranches()})} type="button" className="btn btn-danger">{i18n.t('button_cancel')}</button>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">{view}</div>
          </div>
        </form>
      </div>
    )
  }

  renderMainView(){
    var view;
    if(this.state.items.length){
      view = this.loadListView();
    }
    else{
      view = helperFunctions.renderNoContentInfo();
    }
    return(
      <div>
        <button onClick={(event) => this.setState({view: 'create'})} className="btn btn-default"><i className="fas fa-plus-circle"></i> {i18n.t('add_new_item')}</button>
        <hr/>
        {view}
      </div>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'main'){
        view = this.renderMainView();
      }
      else if(this.state.view === 'create' || this.state.view === 'edit'){
        view = this.renderFormView();
      }
    }
    else{
      view = <LoaderComponent />;
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
