import React, {Component} from 'react';

import * as requests from '../requests.js';
import i18n from "i18next";
import LoaderComponent from '../LoaderComponent';

export default
class AttachmentsComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'list',
      addFile: false,
      currentFileTitle: '',
      currentFileDesc: '',
      currentFileID: 0,
      status: false,
      isLoading: false,
      attachments: [],
    }

    this.assignFilesHandler = this.assignFilesHandler.bind(this);
    this.setFileTitle = this.setFileTitle.bind(this);
    this.setFileDesc = this.setFileDesc.bind(this);
  }

  assignFilesHandler(event){
    this.props.assignFilesHandler(event)
  }

  setFileTitle(event){
    this.setState({currentFileTitle: event.target.value});
  }

  setFileDesc(event){
    this.setState({currentFileDesc: event.target.value});
  }

  componentDidMount(){
    this.setState({attachments: this.props.items});
  }

  reloadFilesList(files){
    this.setState({attachments: files});
  }

  deleteItem(event, id){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'attachmentID': id,
    }

    let request = requests.postAttachmentDelete(array);
    request.then(function(response){
      this.setState({view: 'list'}, () => this.reloadFilesList(response.data.files));
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  updateFormSubmit(event, id){
    event.preventDefault();

    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'attachmentID': id,
      'title' : this.state.currentFileTitle,
      'description' : this.state.currentFileDesc
    }

    let request = requests.postAttachmentUpdateInfo(array);
    request.then(function(response){
      this.setState({view: 'list'}, () => this.reloadFilesList(response.data.files));
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));

  }

  loadAdminEditView(){
    return(
      <div className="jumbotron">
        <form onSubmit={(event) => this.updateFormSubmit(event, this.state.currentFileID)}>
          <div className="form-group">
            <label>{i18n.t('edit_file_name')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <input type="text" onChange={(event) => this.setFileTitle(event)} className="form-control" name="title" value={this.state.currentFileTitle} required />

          </div>
          <div className="form-group">
            <label>{i18n.t('edit_file_description')}</label>
            <textarea name="description" onChange={(event) => this.setFileDesc(event)} className="form-control" rows="5" value={this.state.currentFileDesc} />
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
              <button type="button" onClick={(event) => {this.setState({view: 'list', currentFileTitle: '', currentFileDesc: '', currentFileID: 0})}} className="btn btn-danger">{i18n.t('button_cancel')}</button>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"></div>
          </div>

        </form>

        <hr/>
        <button type="button" onClick={(event) => this.deleteItem(event, this.state.currentFileID)} className="btn btn-danger">{i18n.t('button_delete')}</button>
      </div>
    );
  }

  loadFrontView(){
    return(
      <div>
        <br/><p><strong>{this.props.title}</strong></p>
        <ul className="list-unstyled">
          {
            this.props.items.map(function(item){
              return(
                <li key={item.id}>
                  <div className="media">
                    <div className="media-left">
                      <a href={item.download + "&schoolID=" + localStorage.getItem('objectID') + "&token=" + localStorage.getItem('token')} download="download" role="button" className="btn btn-default" ><i className="fas fa-download"></i></a>
                    </div>
                    <div className="media-body">
                      <h4 className="media-heading">{item.title} ({item.weightSize})</h4>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </li>
              );
            }.bind(this))
          }
        </ul>
      </div>
    );
  }

  loadForm(){
    var status = null;
    if(this.props.filesStatus === true){
      status = <p className="text-success">{i18n.t('files_was_add_to_collection')}</p>
    }
    return(
      <div class="jumbotron">
        <label>{i18n.t('choose_files_to_add_as_attachement')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
        <form onSubmit={(event) => this.assignFilesHandler(event)}>
          <div class="input-group">
            <input name="files" type="file" className="form-control" multiple required/>
            <span class="input-group-btn">
              <button class="btn btn-success" type="submit">{i18n.t('button_save')}</button>
            </span>
          </div>
          {status}
        </form>
      </div>
    );
  }

  loadButton(){
    return(
      <div>
        <button type="button" onClick={(event) => this.setState({addFile: true})} className="btn btn-default">{i18n.t('add_files')}</button>
        <br/><br/>
      </div>
    )
  }

  loadAdminView(){

    var view;
    if(this.state.addFile === true){
      if(this.state.isLoading === false){
        view = this.loadForm();
      }
      else{
        view = <LoaderComponent />
      }
    }
    else{
      view = this.loadButton();
    }

    var list;
    if(this.state.attachments !== undefined && this.state.attachments.length){
      list = this.state.attachments.map(function(item){
        return(
          <li>
            <div className="media cursor" onClick={(event) => this.setState({view: 'edit', currentFileTitle: item.title, currentFileDesc: item.description, currentFileID: item.id})}>
              <div className="media-left">
                <i className="fas fa-paperclip"></i>
              </div>
              <div className="media-body">
                <h4 className="media-heading">{item.title} ({item.weightSize})</h4>
                <p>{item.description}</p>
              </div>
            </div>
          </li>
        );
      }.bind(this))
    }
    else {
      list = null;
    }

    return(
      <div>
        <br/><p><strong>{this.props.title}</strong></p>
        {view}

        <ul className="list-unstyled">
          {list}
        </ul>
      </div>
    );
  }

  render(){
    var view;
    if(this.props.view === 'admin'){
      if(this.state.view === 'list'){
        view = this.loadAdminView();
      }
      else if (this.state.view === 'edit') {
        view = this.loadAdminEditView();
      }
    }
    else{
      view = this.loadFrontView();
    }

    return(
      <div>{view}</div>
    );
  }

}
