import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';

export default
class ObjectInfoComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      isLoading: false,
      status: '',
      msg: null,
      view: 'main',
      object: [],
      validationFields: []
    }

    this.submitForm =  this.submitForm.bind(this);
  }

  componentDidMount(){
    this.getObjectInfo();
  }

  submitForm(event){
    event.preventDefault();
    this.setState(
      {
        isLoading: true,
        object: {
          name: event.target.name.value,
          short: event.target.short.value
        }
      }
    );

    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'name' : event.target.name.value,
      'short' : event.target.short.value
    }

    let request = requests.postMainSettingsObjectInfo(array);
    request.then(function(response){
      this.setState({status: 'success', msg: i18n.t(response.data.msg), object: []}, () => {this.getObjectInfo()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else if (error.response.data.error === 'validation_error') {
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationFields: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));

  }

  getObjectInfo(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getMainSettingsObjectInfo(array);
    request.then(function(response){
      this.setState({object: response.data});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }



  renderMainView(){
    return(
      <form onSubmit={(event) => this.submitForm(event)}>
        {helperFunctions.getTextInput(
          i18n.t('object_full_name'),
          "name",
          this.state.object.name,
          this.state.validationFields.indexOf('name') === -1 ? false : true,
          i18n.t('error_data_input')
        )}
        {helperFunctions.getTextInput(
          i18n.t('object_short_name'),
          "short",
          this.state.object.short,
          this.state.validationFields.indexOf('short') === -1 ? false : true,
          i18n.t('error_data_input')
        )}
        <hr/>
        <button className="btn btn-success">{i18n.t('button_save')}</button>

      </form>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'main'){
        view = this.renderMainView();
      }
    }
    else{
      view = <LoaderComponent />;
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
