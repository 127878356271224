import React, {Component} from 'react';

import i18n from "i18next";
import * as acl from '../../acl.js';
import LoaderComponent from '../../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import * as requests from '../../requests.js';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import CkEditorComponent from '../../../../../Utils/CkEditorComponent';
import AttachmentsComponent from '../../../../../Utils/AttachmentsComponent';

export default
class EventsListComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'list',
      isLoading: false,
      status: '',
      msg: null,
      group: [],
      fromDate: null,
      toDate: null,
      events: [],
      canCreate: false,
      objectAttachments: [],
      filesStatus: false,
      attachments: [],
      cancelled: false,
      cancel_reason: '',
      title: '',
      content: '',
      place_name: '',
      place_address: '',
      price: '',
      from_date: '',
      to_date: '',
      id: null,
    }

    this.setDate = this.setDate.bind(this);
    this.setPlaceName = this.setPlaceName.bind(this);
    this.setPlaceAddress = this.setPlaceAddress.bind(this);
    this.setCancelled = this.setCancelled.bind(this);
    this.setCancelReason = this.setCancelReason.bind(this);
    this.assignFilesHandler = this.assignFilesHandler.bind(this);
    this.onChangeContentHandler = this.onChangeContentHandler.bind(this);
    this.onChangeTitleHandler = this.onChangeTitleHandler.bind(this);
    this.setItemDetails = this.setItemDetails.bind(this);
    this.deleteForm = this.deleteForm.bind(this);

  }

  componentDidMount(){
    this.loadFilteredEvents();
  }

  componentWillMount(){
    this.setState(
      {
        group: this.props.currentGroup,
        events: this.props.currentGroup.events,
        fromDate: new Date(moment(this.props.currentGroup.events.from, 'YYYY-MM-DD')),
        toDate: new Date(moment(this.props.currentGroup.events.to, 'YYYY-MM-DD')),
        canCreate: this.props.currentGroup.canCreate,
      }
    );
  }

  assignFilesHandler(event){
    event.preventDefault();

    const { objectAttachments } = this.state;
     let tempArr = [...objectAttachments];
     tempArr.push(event.target.files.files);
     this.setState({
       objectAttachments: tempArr,
       filesStatus: true
    });

  }

  setCancelled(event){
    this.setState({cancelled: event.target.value});
  }

  setCancelReason(event){
    this.setState({cancel_reason: event.target.value});
  }

  setPlaceName(event){
    this.setState({place_name: event.target.value});
  }

  setPlaceAddress(event){
    this.setState({place_address: event.target.value});
  }

  onChangeContentHandler(event, editor, data){
    this.setState({content: data });
  }

  onChangeTitleHandler(event){
    this.setState({title: event.target.value});
  }

  setItemDetails(item, view){
    this.setState({
      view: view,
      status: '',
      msg: null,
      id: item.id,
      cancelled: item.status === 'cancelled' ? "true" : false,
      cancel_reason: item.cancelled_reason,
      title: item.title,
      content: item.content,
      place_name: item.place.name,
      place_address: item.place.address,
      price: item.price,
      from_date: new Date(moment(item.dates.start, 'YYYY-MM-DD')),
      to_date: new Date(moment(item.dates.finish, 'YYYY-MM-DD')),
      attachments: item.attachments,
      filesStatus: false,
      objectAttachments: [],
    });
  }


  setDate(date, type){
    if(type === 'fromDate'){
      this.setState({fromDate : date}, () => {this.loadFilteredEvents()});
    }
    else if (type === 'from_date') {
      this.setState({from_date : date});
    }
    else if (type === 'to_date') {
      this.setState({to_date : date});
    }
    else{
      this.setState({toDate : date}, () => {this.loadFilteredEvents()});
    }
  }

  loadFilteredEvents(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : this.props.currentGroup.schoolID,
      'groupID' : this.props.currentGroup.id,
      'fromDate' : this.state.fromDate,
      'toDate' : this.state.toDate,
    }

    let request = requests.getGroupEvents(array);
    request.then(function(response){
      this.setState({events: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getGroupDetails(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : this.props.currentGroup.schoolID,
      'groupID' : this.props.currentGroup.id
    }

    let request = requests.getGroupDetails(array);
    request.then(function(response){
      this.setState({group: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  deleteForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : this.props.currentGroup.schoolID,
      'groupID' : this.props.currentGroup.id,
      'id' : this.state.id,
    }

    let request = requests.deleteSchoolGroupEvent(array);
    request.then(function(response){
      this.setState({view: 'list', status: 'success', msg: i18n.t(response.data.msg)}, () => {  this.loadFilteredEvents()});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true});

    const data = new FormData();
    for (var i = 0; i < this.state.objectAttachments.length; i++) {
      data.append('file[]', this.state.objectAttachments[i][0]);
    }
    data.append('action', this.state.view);
    data.append('school_id', localStorage.getItem('objectID'));
    data.append('title', this.state.title);
    data.append('content', this.state.content);
    data.append('all_groups', 0);
    data.append('groupID', this.props.currentGroup.id);
    data.append('id', this.state.id);
    data.append('place_name', this.state.place_name);
    data.append('place_address', this.state.place_address);
    data.append('event_start', moment(this.state.from_date).format('YYYY-MM-DD'));
    data.append('event_end', moment(this.state.to_date).format('YYYY-MM-DD'));
    data.append('cancelled', this.state.cancelled === "true" ? 1 : 0);
    data.append('cancel_reason', this.state.cancel_reason);

    let request = requests.postSchoolGroupEvent(data);
    request.then(function(response){
      this.setState({view: 'list', status: 'success', msg: i18n.t(response.data.msg)}, () => {  this.loadFilteredEvents()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderAddButton(){
    return(
      <div className="form-group">
        <label>{i18n.t('add_new_anouncement_in_this_group')}</label><br/>
        <button className="btn btn-groups" onClick={(event) => this.setState(
          {
            view: 'create',
            status: '',
            msg: null,
            title: '',
            content: '',
            from_date: new Date(),
            to_date: new Date(),
            place_name: '',
            place_address: '',
            id: null,
            cancelled: false,
            cancel_reason: '',
            objectAttachments: [],
            filesStatus: false,
            attachments: [],
          }
          )}>{i18n.t('add_new_item')}</button>
      </div>
    );
  }

  renderReasonTextarea(){
    return(
      <div className="form-group">
        <label>{i18n.t('set_event_cancel_reason')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
        <textarea name="reason" cols="" rows="5" required className="form-control" value={this.state.cancel_reason} onChange={(event) => this.setCancelReason(event)} />
      </div>
    );
  }

  loadTempAttachments(){
    return(
      <div className="callout callout-info">
        <h4>{i18n.t('new_attachments_added_to_the_collection')}</h4>
        <p>{i18n.t('files_count')}: <b>{this.state.objectAttachments.length}</b></p>
      </div>
    );
  }

  renderCreateView(){
    var cancelForm = null;
    if(this.state.cancelled === "true"){
      cancelForm = this.renderReasonTextarea();
    }

    var tempAttachments = null;
    if(this.state.objectAttachments.length){
      tempAttachments = this.loadTempAttachments()
    }

    return(
      <div>
        <form onSubmit={(event) => this.submitForm(event)}>
          <div className="form-group">
            <label>{i18n.t('set_item_title')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <input type="text" name="title" className="form-control" value={this.state.title} onChange={(event) => this.onChangeTitleHandler(event)} required/>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="form-group">
                <label>{i18n.t('select_start_date')}</label>
                <DatePicker
                    selected={this.state.from_date}
                    onSelect={(value) => this.setDate(value, 'from_date')}
                    filterDate={this.isWeekday}
                    todayButton={i18n.t('set_current_date')}
                    shouldCloseOnSelect={false}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="form-group">
                <label>{i18n.t('select_finish_date')}</label>
                <DatePicker
                    selected={this.state.to_date}
                    onSelect={(value) => this.setDate(value, 'to_date')}
                    filterDate={this.isWeekday}
                    todayButton={i18n.t('set_current_date')}
                    shouldCloseOnSelect={false}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="form-group">
                <label>{i18n.t('set_event_place')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
                <input type="text" className="form-control" name="place_name" value={this.state.place_name} onChange={(event) => this.setPlaceName(event)} required/>

              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="form-group">
                <label>{i18n.t('label_set_address')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
                <input type="text" className="form-control" name="place_address" value={this.state.place_address} onChange={(event) => this.setPlaceAddress(event)} required/>


              </div>
            </div>
          </div>


          {<CkEditorComponent label={i18n.t('set_item_content')} content={this.state.content} onChangeContentHandler={this.onChangeContentHandler}/>}

          <div className="form-group">
            <label>{i18n.t('set_event_status')}</label>
            <select className="form-control" name="cancel" onChange={(event) => this.setCancelled(event)} value={this.state.cancelled}>
              <option value={false}>{i18n.t('event_active')}</option>
              <option value={true}>{i18n.t('event_cancelled')}</option>
            </select>
          </div>

          {cancelForm}

          {tempAttachments}

          <hr/>
          <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
          <button type="button" onClick={(event) => this.setState({view: 'list'}, () => {this.loadFilteredEvents()})} className="btn btn-danger">{i18n.t('button_cancel')}</button>
        </form>

        <hr/>
          {
            <AttachmentsComponent
              items={this.state.attachments}
              title={i18n.t('files')}
              view={"admin"}
              itemID={this.state.id}
              contentType={"attachment"}
              assignFilesHandler={this.assignFilesHandler}
              filesStatus={this.state.filesStatus}
              reloadFilesList={this.reloadFilesList}
            />
          }
      </div>
    );
  }

  renderEditButtons(item){
    return (
      <div>
        <hr/>
          <div className="btn-group" role="group">
            <button type="button" onClick={(event) => this.setItemDetails(item, 'edit')} className="btn btn-default">{i18n.t('button_edit')}</button>
            <button type="button" onClick={(event) => this.setItemDetails(item, 'delete')} className="btn btn-danger">{i18n.t('button_delete')}</button>
          </div>
      </div>

    );
  }

  renderDeleteView(){
    return(
      <form onSubmit={(event) => this.deleteForm(event)}>
        <p>{i18n.t('confirm_delete_action')}: <strong>{this.state.title}</strong></p>
        <hr/>
          <button type="submit" className="btn btn-danger">{i18n.t('button_delete')}</button>
          <button type="button" onClick={(event) => this.setState({view: 'list'}, () => {this.loadFilteredEvents()})} className="btn btn-default">{i18n.t('button_cancel')}</button>
      </form>
    );
  }


  renderListView(){
    var add = null;
    if(this.state.canCreate === true){
      add = this.renderAddButton()
    }
    return(
      <div>
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-12">

            <div className="form-group">
              <label>{i18n.t('select_start_date')}</label>
              <DatePicker
                  locale="lang"
                  selected={this.state.fromDate}
                  onSelect={(value) => this.setDate(value, 'fromDate')}
                  todayButton={i18n.t('set_current_date')}
                  filterDate={this.isWeekday}
                  shouldCloseOnSelect={false}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
              />
            </div>

          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">

            <div className="form-group">
              <label>{i18n.t('select_finish_date')}</label>
              <DatePicker
                  locale="lang"
                  selected={this.state.toDate}
                  onSelect={(value) => this.setDate(value, 'toDate')}
                  todayButton={i18n.t('set_current_date')}
                  filterDate={this.isWeekday}
                  shouldCloseOnSelect={false}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
              />
            </div>

          </div>

          <div className="col-md-3 col-sm-6 col-xs-12">

              {add}

          </div>
        </div>
        <hr/>
        <ul className="list-inline">
          <li>{i18n.t('legend')}</li>
          <li><i className="fas fa-frown"></i> - {i18n.t('event_cancelled')}</li>
          <li><i className="fas fa-layer-group"></i> - {i18n.t('event_only_for_this_group')}</li>
          <li><i className="fas fa-school"></i> - {i18n.t('event_for_all_groups')}</li>
        </ul>
        <hr/>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">

            <ul className="list-unstyled">
              {
                this.state.events.items.map(function(elem){
                  var attachments;
                  if(elem.attachments.length > 0){
                    attachments = <AttachmentsComponent view={"front"} items={elem.attachments} title={i18n.t('attachments_list')} />
                  }
                  var manage = null;
                  if(elem.can_manage === true){
                    manage = this.renderEditButtons(elem);
                  }
                  return(
                    <li key={elem.id}>
                      <div className="media">
                        <div className="media-left">
                          <span className={"info-box-icon " + elem.icon.class}><i className={elem.icon.fontawesome}></i></span>
                        </div>
                        <div className="media-body">
                          <h4 className="media-heading">{elem.title}
                            <br/><small>{i18n.t('status')}: <b>{i18n.t('event_'+elem.status)}</b></small>
                            <br/><small>{i18n.t('start_date')}: <b>{elem.dates.start}</b>. {i18n.t('finish_date')}: <b>{elem.dates.finish}</b></small>
                          </h4>
                          <br/>
                          <p><strong>{i18n.t('event_place')}</strong><br/>{elem.place.name}<br/>{elem.place.address}</p><br/>
                          <p><strong>{i18n.t('content')}</strong><br/><div dangerouslySetInnerHTML={{__html: elem.content}} /></p>

                          {attachments}

                          {elem.status === 'cancelled' ? <span><br/><p className="text-danger"><strong>{i18n.t('event_cancel_reason')}</strong><br/>{elem.cancelled_reason}</p></span> : null}

                          {manage}

                        </div>
                      </div>
                      <hr/>
                    </li>
                  )
                }.bind(this))
              }
            </ul>

          </div>
        </div>
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView();
      }
      if(this.state.view === 'create'){
        view = this.renderCreateView();
      }
      if(this.state.view === 'edit'){
        view = this.renderCreateView();
      }
      if(this.state.view === 'delete'){
        view = this.renderDeleteView();
      }
    }
    else{
      view = <LoaderComponent />;
    }
    return(
      <div>
        {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
        {view}
      </div>
    );
  }

}
