import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';

export default
class KidsWaitingComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      isLoading: false,
      status: '',
      msg: null,
      view: 'list',
      items: [],
    }
  }

  componentDidMount(){
    this.getKidsList()
  }

  getKidsList(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getWaitingKidsList(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderTableView(){
    return(
      <table className="table table-border table-hover">

        <thead>
          <tr>
            <th>{i18n.t('avatar')}</th>
            <th>{i18n.t('name_and_surname')}</th>
            <th>{i18n.t('pesel')}</th>
          </tr>
        </thead>

        <tbody>
          {
            this.state.items.map(function(item){
              return(
                <tr key={item.kid.id} className="cursor" onClick={(event) => this.props.setKidProfileView(item.kid.id)}>
                  <td><img className="img-circle img-md smallAvatar" src={item.kid.avatar} /></td>
                  <td>{item.kid.name} {item.kid.surname}</td>
                  <td>{item.kid.pesel}</td>
                </tr>
              )
            }.bind(this))
          }
        </tbody>

      </table>
    )
  }

  renderListView(){
    var view = helperFunctions.renderNoContentInfo();
    if(this.state.items !== undefined && this.state.items.length > 0){
      view = this.renderTableView()
    }
    return(<div>{view}</div>)
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView()
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
