import React, {Component} from 'react';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import * as requests from '../../requests.js';
import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';

class AdditionalActivityComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'list',
      currentActivityID: 0,
      kidActivityList: [],
      isLoading: false,
      activityList: [],
      status: '',
      msg: null,
    }

    this.assignKidToActivityApplication = this.assignKidToActivityApplication.bind(this);
    this.deleteActivityRelationApplication = this.deleteActivityRelationApplication.bind(this);
    this.assignKidToActivity = this.assignKidToActivity.bind(this);
    this.submitDeleteApplication = this.submitDeleteApplication.bind(this);
    this.changeView = this.changeView.bind(this);
  }

  componentDidMount(){
    this.setState({kidActivityList: this.props.moduleData.kidActivities})
  }

  changeView(event, view, activityID = null){
    if(activityID !== null){
      this.setState({currentActivityID: activityID});
    }

    this.setState({view: view, status: '', msg: null}, () => {
      if(view === 'assign'){
        this.getSchoolActivityList();
      }
      if(view === 'list'){
        this.getKidActivityList();
      }
    });
  }

  submitDeleteApplication(event, id){
    event.preventDefault();
    this.setState({isLoading: true});

    let array = {
      'objectID' : this.props.currentKid.schoolID,
      'kidID' : this.props.currentKid.kidID,
      'activityID' : this.state.currentActivityID,
      'action' : 'delete'
    }

    let request = requests.postKidActivityAssignApplication(array);
    request.then(function(response){
      this.setState({view: 'list', status: 'success', msg: i18n.t(response.data.msg)}, () => {this.getKidActivityList()});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getKidActivityList(){
    this.setState({isLoading: true});

    let array = {
      'kidID' : this.props.currentKid.kidID
    }

    let request = requests.getKidActivityList(array);
    request.then(function(response){
      this.setState({kidActivityList: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getSchoolActivityList(){
    this.setState({isLoading: true});

    let array = {
      'objectID' : this.props.currentKid.schoolID
    }

    let request = requests.getSchoolAdditionalActivityList(array);
    request.then(function(response){
      this.setState({activityList: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  assignKidToActivity(event, id){
    this.setState({isLoading: true});

    let array = {
      'objectID' : this.props.currentKid.schoolID,
      'kidID' : this.props.currentKid.kidID,
      'activityID' : id,
      'action' : 'create'
    }

    let request = requests.postKidActivityAssignApplication(array);
    request.then(function(response){
      this.setState({status: 'success', msg: i18n.t(response.data.msg)}, () => {this.getSchoolActivityList()});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  assignKidToActivityApplication(){
    return(
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <button onClick={(event) => this.changeView(event, 'list')} className="btn btn-default">{i18n.t('go_back_to_list')}</button><hr/>
          {
            this.state.activityList.map(function(item){

              var icon = <span className="info-box-icon bg-light-blue"><i className="fas fa-calendar-plus"></i></span>
              if(item.assigned === true){
                icon = <span className="info-box-icon bg-green"><i className="fas fa-calendar-check"></i></span>
              }
              else if(item.assignApp === true){
                icon = <span className="info-box-icon bg-aqua"><i className="fas fa-hourglass-half"></i></span>
              }

              var button = null;
              if(item.assigned === false && item.assignApp === false){
                button = <button onClick={(event) => this.assignKidToActivity(event, item.id)} className="btn btn-success">{i18n.t('assign_kid_to_this_activity')}</button>
              }
              else if(item.assigned === false && item.assignApp === true){
                button = <div className="callout callout-info"><p>{i18n.t('your_application_waiting_for_action')}</p></div>
              }

              return(
                <div key={item.id}>
                  <div className="media">
                    <div className="media-left">
                      {icon}
                    </div>
                    <div className="media-body">
                      <h4 className="media-heading">{i18n.t(item.trans)}</h4>
                      <ul className="list-unstyled">
                        <li>{i18n.t('price')}: <b>{item.price}</b></li>
                        <li><div dangerouslySetInnerHTML={{__html: item.content}} /></li>
                        <li><b>{i18n.t('schedule')}</b>
                          <ul className="list-unstyled">
                            {
                              item.calendar.map(function(calendar){
                                return(<li key={calendar.id}><b>{i18n.t(calendar.day)}</b>, {i18n.t('from')}: <b>{calendar.time_start}</b> {i18n.t('to')}: <b>{calendar.time_end}</b>, {i18n.t('lead_teacher')}: <b>{calendar.teacher}</b></li>)
                              }.bind(this))
                            }
                          </ul>
                        </li>
                        <li><br/>
                          {button}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr/>
                </div>
              );
            }.bind(this))
          }

        </div>
      </div>
    )
  }
  deleteActivityRelationApplication(){
    return(
      <div className="row">
        <div className="col-md-6 col-sm-9 col-xs-12">

          <form>
            <p>{i18n.t('confirm_activity_kid_relation_delete_application')}</p>
            <hr/>
            <button type="submit" onClick={(event) => this.submitDeleteApplication(event)} className="btn btn-danger">{i18n.t('post_application')}</button>
            <button type="button" onClick={(event) => this.changeView(event, 'list')} className="btn btn-default">{i18n.t('button_cancel')}</button>

          </form>

        </div>
      </div>
    )
  }

  renderDetailsView(){
    var canCreateItem = acl.check('create_kid_additional_activity_application', ['role_principal'], this.props.currentUser.userRoles);
    if(canCreateItem === false && this.props.currentKid.isMyKid === true){
      canCreateItem = true;
    }
    return(
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">

          {canCreateItem ? <div><button onClick={(event) => this.changeView(event, 'assign', null)} className="btn btn-default">{i18n.t('assign_kid_to_additional_activity')}</button><hr/></div> : null}
          {
            this.state.kidActivityList.map(function(item){
              return(
                <div key={item.id}>
                  <div className="media">
                    <div className="media-left">
                      {item.application === false ? <span className="info-box-icon bg-green"><i className="fas fa-calendar-check"></i></span> : <span className="info-box-icon bg-red"><i className="fas fa-hourglass-half"></i></span>}
                    </div>
                    <div className="media-body">
                      <h4 className="media-heading">{i18n.t(item.trans)}</h4>
                      <ul className="list-unstyled">
                        <li>{i18n.t('price')}: <b>{item.price}</b></li>
                        <li><div dangerouslySetInnerHTML={{__html: item.content}} /></li>
                        <li><b>{i18n.t('schedule')}</b>
                          <ul className="list-unstyled">
                            {
                              item.calendar.map(function(calendar){
                                return(<li key={calendar.id}><b>{i18n.t(calendar.day)}</b>, {i18n.t('from')}: <b>{calendar.time_start}</b> {i18n.t('to')}: <b>{calendar.time_end}</b>, {i18n.t('lead_teacher')}: <b>{calendar.teacher}</b></li>)
                              }.bind(this))
                            }
                          </ul>
                        </li>
                        <li><br/>
                          {item.application === false ? <button onClick={(event) => this.changeView(event, 'delete', item.id)} className="btn btn-danger">{i18n.t('delete_kid_from_activity')}</button> : <div className="callout callout-info"><p>{i18n.t('your_application_waiting_for_action')}</p></div>}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr/>
                </div>
              );
            }.bind(this))
          }

        </div>
      </div>
    )
  }

  render(){
    var view = helperFunctions.showBlockadeBlock(i18n.t('access_denied'), i18n.t('access_denied_info'));
    if(this.props.currentKid.canISeeDetails === true && this.props.moduleData.access === true){
      if(this.state.isLoading === false){
        if(this.state.view === 'list'){
          view = this.renderDetailsView();
        }
        else if(this.state.view === 'assign'){
          view = this.assignKidToActivityApplication();
        }
        else if(this.state.view === 'delete'){
          view = this.deleteActivityRelationApplication();
        }
      }
      else{
        view = <LoaderComponent />;
      }
    }

    return(
      <div>
        {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
        {view}
      </div>
    );
  }

}

export default AdditionalActivityComponent;
