import React, {Component} from 'react';
import i18n from "i18next";

import * as requests from '../../Requests/DashboardRequests.js';
import loader from '../../../../Img/small_loader.gif';

class ObjectSelectComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      list: [],
    }

    this.changeObject = this.changeObject.bind(this);
  }

  changeObject(event){
    this.props.objectSelectChangeHandler(event);
  }

  componentDidMount(){
    this.getMyObjectsList();
  }

  getMyObjectsList(){
    let request = requests.getMyObjectsList();
    request.then(function(response){
      this.setState({list: response.data});
    }.bind(this));
  }

  renderLoader(){
    return(<img src={loader} />);
  }

  renderSelectInput(){
    return(
      <select onChange={(event) => this.changeObject(event)} value={this.props.currentlyChoosen} className="form-control">
        {
          this.state.list.map(function(item){
            return(<option key={item.id} value={item.objectID}>{item.objectName}</option>)
          }.bind(this))
        }
      </select>
    );
  }

  render(){
    var view;
    if(this.props.loading === true){
      view = this.renderLoader();
    }
    else{
      view = this.renderSelectInput();
    }
    return(

        <div className="info-box">
          <span className="info-box-icon bg-aqua"><i className="fas fa-school"></i></span>

          <div className="info-box-content">
            <span className="info-box-text">{i18n.t('title_currently_choosen_school')}</span><br/>
            <span className="info-box-number">
              {view}
            </span>
          </div>
        </div>

    );
  }

}

export default ObjectSelectComponent;
