import React, {Component} from 'react';
import i18n from "i18next";
import * as acl from '../../acl.js';
import LoaderComponent from '../../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import * as requests from '../../requests.js';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';


export default
class GalleryComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'list',
      isLoading: false,
      status: '',
      msg: null,
      items: [],
      photos: [],
      fullSize: [],
      id: 0,
      title: '',
      description: '',
      currentImage: [],
      currentImageID: 0,
      reportReason: '',
      canCreate: false,
    }

    this.getGalleryDetails = this.getGalleryDetails.bind(this);
    this.openImageDetails = this.openImageDetails.bind(this);
    this.changeState = this.changeState.bind(this);
    this.submitImageReport = this.submitImageReport.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.deleteForm = this.deleteForm.bind(this);
    this.assignFilesHandler = this.assignFilesHandler.bind(this);
    this.deletePhotoForm = this.deletePhotoForm.bind(this);
  }

  componentDidMount(){
    this.getGalleryList();
  }

  componentWillMount(){
    this.setState({canCreate: this.props.currentGroup.canCreate});
  }

  openImageDetails(event, obj){
    this.setState({view: 'imageDetails', currentImage: obj.photo});
  }

  changeState(event, type){
    if(type === 'reportReason'){
      this.setState({reportReason: event.target.value});
    }
    if(type === 'title'){
      this.setState({title: event.target.value});
    }
    if(type === 'description'){
      this.setState({description: event.target.value});
    }
  }

  submitImageReport(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'objectID' : this.props.currentGroup.schoolID,
      'groupID' : this.props.currentGroup.id,
      'imageID' : this.state.currentImage.id,
      'reason' : this.state.reportReason,
    }

    let request = requests.postGroupImageReport(array);
    request.then(function(response){
      this.setState({view: 'imageDetails', status: 'success', msg: response.data.msg});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'action' : this.state.view,
      'school_id' : localStorage.getItem('objectID'),
      'groupID' : this.props.currentGroup.id,
      'title'  : this.state.title,
      'description' : this.state.description,
      'id' : this.state.id,
    }

    let request = requests.postGalleryStore(array);
    request.then(function(response){
      if(this.state.view === 'edit'){
        this.setState({status: 'success',msg: i18n.t(response.data.msg),view: 'details'}, () => {this.getGalleryDetails()});
      }
      else{
        this.setState({status: 'success',msg: i18n.t(response.data.msg),view: 'list'}, () => {this.getGalleryList()});
      }
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  deleteForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'groupID' : this.props.currentGroup.id,
      'id' : this.state.id,
    }

    let request = requests.deleteGallery(array);
    request.then(function(response){
      if(this.state.view === 'edit'){
        this.setState({status: 'success',msg: i18n.t(response.data.msg),view: 'details'}, () => {this.getGalleryDetails()});
      }
      else{
        this.setState({status: 'success',msg: i18n.t(response.data.msg),view: 'list'}, () => {this.getGalleryList()});
      }
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  deletePhotoForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.state.id,
      'photo_id' : this.state.currentImage.id,
    }

    let request = requests.deleteImage(array);
    request.then(function(response){
      this.setState({status: 'success',msg: i18n.t(response.data.msg),view: 'details'}, () => {this.getGalleryDetails()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  assignFilesHandler(event){
    event.preventDefault();
    this.setState({isLoading: true});

    const data = new FormData();
    for (var i = 0; i < event.target.files.files.length; i++) {
      data.append('photos[]', event.target.files.files[i]);
    }
    data.append('school_id', localStorage.getItem('objectID'));
    data.append('id', this.state.id);
    data.append('groupID', this.props.currentGroup.id);

    let request = requests.postGalleryPhotosStore(data);
    request.then(function(response){
      this.setState(
        {
          status: 'success',
          msg: i18n.t(response.data.msg),
          view: 'details',
        },
        () => {this.getGalleryDetails()}
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));

  }

  getGalleryDetails(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : this.props.currentGroup.schoolID,
      'groupID' : this.props.currentGroup.id,
      'galleryID' : this.state.id
    }

    let request = requests.getGroupGalleryDetails(array);
    request.then(function(response){
      this.setState({photos: response.data.thumbs, fullSize: response.data.fullSize, title: response.data.title, description: response.data.description});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getGalleryList(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : this.props.currentGroup.schoolID,
      'groupID' : this.props.currentGroup.id,
    }

    let request = requests.getGroupGallery(array);
    request.then(function(response){
      this.setState({items: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderAddButton(){
    return(
      <div className="form-group">
        <button className="btn btn-groups" onClick={(event) => this.setState(
          {
            view: 'create',
            status: '',
            msg: null,
            title: '',
            description: '',
          }
          )}>Dodaj nową galerię w tej grupie</button>
      </div>
    );
  }

  renderDeletePhotoButton(){
    return(
      <div className="form-group">
        <button className="btn btn-groups" onClick={(event) => this.setState(
          {
            view: 'deletePhoto',
            status: '',
            msg: null,
          }
        )}>Usuń zdjęcie</button>
      </div>
    );
  }

  renderListView(){
    return(
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <ul className="list-unstyled">
            {
              this.state.items.map(function(elem){

                return(
                  <li key={elem.id} className="cursor" onClick={(event) => this.setState({view: 'details', status: '', msg: null, id: elem.id}, () => this.getGalleryDetails())}>
                    <div className="media">
                      <div className="media-left">
                        <span className="info-box-icon bg-groups"><i className="fas fa-images"></i></span>
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">{elem.title}<br/><small>{i18n.t('photos_count')}: <strong>{elem.photos}</strong></small></h4>
                        <p>{elem.description}</p>
                      </div>
                    </div>
                    <hr/>
                  </li>
                )
              }.bind(this))
            }
          </ul>

        </div>
      </div>
    )
  }

  renderAddPhotoButton(){
    return(
      <div className="btn-group" role="group">
          <button type="button" class="btn btn-groups" onClick={(event) => this.setState({view: 'addPhoto',status: '',msg: null,})}>{i18n.t('add_new_photos')}</button>
          <button type="button" class="btn btn-default" onClick={(event) => this.setState({view: 'edit',status: '',msg: null, id: this.state.id})}>{i18n.t('button_edit')}</button>
          <button type="button" class="btn btn-danger" onClick={(event) => this.setState({view: 'delete',status: '',msg: null, id: this.state.id})}>{i18n.t('button_delete')}</button>
        </div>
    )
  }

  renderDetailsView(){
    var button;
    if(this.state.canCreate == true){
      button = this.renderAddPhotoButton();
    }
    return(
      <div>
          <div className="btn-group" role="group">
            <button className="btn btn-default" onClick={(event) => {this.setState({view: 'list', status: '', msg: null}, () => {this.getGalleryList()})}}>{i18n.t('go_back_to_list')}</button>
            {button}
          </div>
          <hr/>

        <h3>{this.state.title}</h3>
        <p>{this.state.description}</p>
        <hr/>
        <Gallery photos={this.state.photos} onClick={this.openImageDetails} />

      </div>
    )
  }

  renderImageDetailsView(){
    var button = <button className="btn btn-danger" onClick={(event) => {this.setState({view: 'imageReportForm', reportReason: ''})}}>{i18n.t('ask_for_photo_delete')}</button>
      if(this.state.canCreate == true){
        button = this.renderDeletePhotoButton();
      }
    return(
      <div>
        <button className="btn btn-default" onClick={(event) => {this.setState({view: 'details', status: '', msg: null}, () => {this.getGalleryDetails()})}}>{i18n.t('go_back_to_list')}</button><hr/>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div>
              <img src={this.state.currentImage.resized} className="img-thumbnail" />
              <div className="caption">
                <h3>{this.state.currentImage.title}</h3>
                <p>{this.state.currentImage.description}</p>
                <hr/>
                <p>{button}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderImageReportFormView(){
    return(
      <form onSubmit={(event) => this.submitImageReport(event)}>
        <div className="form-group">
          <label>{i18n.t('set_delete_photo_reason')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <textarea name="reason" className="form-control" rows="10" value={this.state.reportReason} onChange={(event) => this.changeState(event, 'reportReason')} required />
        </div>

        <hr/>
        <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
        <button type="button" className="btn btn-default" onClick={(event) => this.setState({view: 'imageDetails'})}>{i18n.t('button_cancel')}</button>

      </form>
    )
  }

  renderCreateView(){
    var cancel;
    if(this.state.view === 'create'){
      cancel = <button type="button" onClick={(event) => this.setState({view: 'list'}, () => {this.getGalleryList()})} className="btn btn-danger">{i18n.t('button_cancel')}</button>
    }
    else{
      cancel = <button type="button" onClick={(event) => this.setState({view: 'details'}, () => {this.getGalleryDetails()})} className="btn btn-danger">{i18n.t('button_cancel')}</button>
    }
    return(
      <div>
        <form onSubmit={(event) => this.submitForm(event)}>

          <div className="form-group">
            <label>{i18n.t('set_gallery_title')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <input type="text" name="title" value={this.state.title} onChange={(event) => this.changeState(event, 'title')} className="form-control" required/>
          </div>

          <div className="form-group">
            <label>{i18n.t('set_gallery_description')}</label>
            <textarea name="description" value={this.state.description} onChange={(event) => this.changeState(event, 'description')} className="form-control" rows="10"/>
          </div>

          <hr/>
          <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
          {cancel}
        </form>
      </div>
    );
  }

  renderDeleteView(){
    return(
      <form onSubmit={(event) => this.deleteForm(event)}>
        <p>{i18n.t('confirm_delete_action')}: <strong>{this.state.title}</strong></p>
        <hr/>
          <button type="submit" className="btn btn-danger">{i18n.t('button_delete')}</button>
          <button type="button" onClick={(event) => this.setState({view: 'details'}, () => {this.getGalleryDetails()})} className="btn btn-default">{i18n.t('button_cancel')}</button>
      </form>
    );
  }

  renderDeletePhotoView(){
    return(
      <form onSubmit={(event) => this.deletePhotoForm(event)}>
        <p>{i18n.t('confirm_delete_action')}</p>
        <hr/>
          <button type="submit" className="btn btn-danger">{i18n.t('button_delete')}</button>
          <button type="button" onClick={(event) => this.setState({view: 'imageDetails'})} className="btn btn-default">{i18n.t('button_cancel')}</button>
      </form>
    );
  }

  renderAddPhotoView(){
    return(
      <div>
        <form onSubmit={(event) => this.assignFilesHandler(event)}>
          <label>{i18n.t('choose_photos')}</label>
          <div className="input-group">
            <input name="files" type="file" className="form-control" multiple required/>
            <span className="input-group-btn">
              <button className="btn btn-success" type="submit">{i18n.t('upload_files')}</button>
            </span>
          </div>
        </form><hr/>
      <button type="button" onClick={(event) => this.setState({view: 'details'}, () => {this.getGalleryDetails()})} className="btn btn-danger">{i18n.t('button_cancel')}</button>
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = helperFunctions.renderNoContentInfo();
        if(this.state.items !== undefined && this.state.items.length > 0){
          view = this.renderListView()
        }
      }
      if(this.state.view === 'details'){
        view = this.renderDetailsView()
      }
      if(this.state.view === 'imageDetails'){
        view = this.renderImageDetailsView()
      }
      if(this.state.view === 'imageReportForm'){
        view = this.renderImageReportFormView()
      }
      if(this.state.view === 'create' || this.state.view === 'edit'){
        view = this.renderCreateView()
      }
      if(this.state.view === 'delete'){
        view = this.renderDeleteView()
      }
      if(this.state.view === 'addPhoto'){
        view = this.renderAddPhotoView()
      }
      if(this.state.view === 'deletePhoto'){
        view = this.renderDeletePhotoView()
      }
    }
    else{
      view = <LoaderComponent />;
    }

    var add = null;
    if(this.state.canCreate === true && this.state.view === 'list'){
      add = this.renderAddButton()
    }

    return(
      <div>
        {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
        {add}
        {view}
      </div>
    );
  }

}
