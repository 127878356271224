import React, {Component} from 'react';

import i18n from "i18next";
import * as acl from '../../acl.js';
import LoaderComponent from '../../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import * as requests from '../../requests.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import CkEditorComponent from '../../../../../Utils/CkEditorComponent';

export default
class DashboardComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'list',
      isLoading: false,
      status: '',
      msg: null,
      group: [],
      fromDate: null,
      toDate: null,
      events: [],
      canCreate: false,
      title: '',
      content: '',
      id: null,
    }

    this.setDate = this.setDate.bind(this);
    this.onChangeContentHandler = this.onChangeContentHandler.bind(this);
    this.onChangeTitleHandler = this.onChangeTitleHandler.bind(this);
    this.setItemDetails = this.setItemDetails.bind(this);
    this.deleteForm = this.deleteForm.bind(this);
  }

  componentDidMount(){
    this.loadFilteredEvents();
  }

  componentWillMount(){
    this.setState(
      {
        group: this.props.currentGroup,
        events: this.props.currentGroup.dashboard,
        fromDate: new Date(moment(this.props.currentGroup.dashboard.from, 'YYYY-MM-DD')),
        toDate: new Date(moment(this.props.currentGroup.dashboard.to, 'YYYY-MM-DD')),
        canCreate: this.props.currentGroup.canCreate,
      }
    );
  }

  onChangeContentHandler(event, editor, data){
    this.setState({content: data });
  }

  onChangeTitleHandler(event){
    this.setState({title: event.target.value});
  }

  setItemDetails(item, view){
    this.setState({
      view: view,
      status: '',
      msg: null,
      id: item.id,
      title: item.title,
      content: item.content,
    });
  }

  setDate(date, type){
    if(type === 'fromDate'){
      this.setState({fromDate : date}, () => {this.loadFilteredEvents()});
    }
    else{
      this.setState({toDate : date}, () => {this.loadFilteredEvents()});
    }
  }

  loadFilteredEvents(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : this.props.currentGroup.schoolID,
      'groupID' : this.props.currentGroup.id,
      'fromDate' : this.state.fromDate,
      'toDate' : this.state.toDate,
    }

    let request = requests.getGroupDashboard(array);
    request.then(function(response){
      this.setState({events: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getGroupDetails(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : this.props.currentGroup.schoolID,
      'groupID' : this.props.currentGroup.id
    }

    let request = requests.getGroupDetails(array);
    request.then(function(response){
      this.setState({group: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'action' : this.state.view,
      'school_id' : localStorage.getItem('objectID'),
      'title' : this.state.title,
      'content' : this.state.content,
      'for_all_groups' : 0,
      'groups': [{value: this.props.currentGroup.id}],
      'id'  : this.state.id,
    }

    let request = requests.postSchoolGroupDashboard(array);
    request.then(function(response){
      this.setState({view: 'list', status: 'success', msg: i18n.t(response.data.msg)}, () => {  this.loadFilteredEvents()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  deleteForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : this.props.currentGroup.schoolID,
      'id' : this.state.id,
    }

    let request = requests.deleteSchoolGroupDashboard(array);
    request.then(function(response){
      this.setState({view: 'list', status: 'success', msg: i18n.t(response.data.msg)}, () => {  this.loadFilteredEvents()});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderAddButton(){
    return(
      <div className="form-group">
        <label>Dodaj nowe ogłoszenie w tej grupie</label><br/>
        <button className="btn btn-groups" onClick={(event) => this.setState(
          {
            view: 'create',
            status: '',
            msg: null,
            title: '',
            content: '',
            id: null
          }
          )}>Dodaj nową pozycję</button>
      </div>
    );
  }

  renderEditButtons(item){
    return (
      <div>
        <hr/>
          <div className="btn-group" role="group">
            <button type="button" onClick={(event) => this.setItemDetails(item, 'edit')} className="btn btn-default">{i18n.t('button_edit')}</button>
            <button type="button" onClick={(event) => this.setItemDetails(item, 'delete')} className="btn btn-danger">{i18n.t('button_delete')}</button>
          </div>
      </div>

    );
  }

  renderListView(){
    var add = null;
    if(this.state.canCreate === true){
      add = this.renderAddButton()
    }
    return(
      <div>
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-12">

            <div className="form-group">
              <label>{i18n.t('select_start_date')}</label>
              <DatePicker
                  locale="lang"
                  selected={this.state.fromDate}
                  onSelect={(value) => this.setDate(value, 'fromDate')}
                  filterDate={this.isWeekday}
                  todayButton={i18n.t('set_current_date')}
                  shouldCloseOnSelect={false}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
              />
            </div>

          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">

            <div className="form-group">
              <label>{i18n.t('select_finish_date')}</label>
              <DatePicker
                  locale="lang"
                  selected={this.state.toDate}
                  onSelect={(value) => this.setDate(value, 'toDate')}
                  todayButton={i18n.t('set_current_date')}
                  filterDate={this.isWeekday}
                  shouldCloseOnSelect={false}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
              />
            </div>

          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">

              {add}

          </div>
        </div>
        <hr/>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <ul className="list-unstyled">
              {
                this.state.events.items.map(function(elem){
                  var attachments;
                  if(elem.attachments.length > 0){
                    attachments = helperFunctions.renderAttachmentsView(elem.attachments, i18n.t('attachments_list'));
                  }
                  var manage = null;
                  if(elem.can_manage === true){
                    manage = this.renderEditButtons(elem);
                  }
                  return(
                    <li key={elem.id}>
                      <div className="media">
                        <div className="media-left">
                          <span className={"info-box-icon " + elem.icon.class}><i className={elem.icon.fontawesome}></i></span>
                        </div>
                        <div className="media-body">
                          <h4 className="media-heading">{elem.title}
                            <br/><small>{i18n.t('for_all_groups')}: <b>{elem.for_all_groups ? i18n.t('yes') : i18n.t('no')}</b></small>
                            <br/><small>{i18n.t('cdate')}: <b>{elem.cdate}</b></small>
                          </h4>
                          <br/>

                          <p><strong>{i18n.t('content')}</strong><br/><div dangerouslySetInnerHTML={{__html: elem.content}} /></p>

                          {attachments}

                          {manage}


                        </div>
                      </div>
                      <hr/>
                    </li>
                  )
                }.bind(this))
              }
            </ul>

          </div>
        </div>
      </div>
    );
  }

  renderCreateView(){
    return(
      <form onSubmit={(event) => this.submitForm(event)}>
        <div className="form-group">
          <label>{i18n.t('set_item_title')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <input type="text" name="title" className="form-control" value={this.state.title} onChange={(event) => this.onChangeTitleHandler(event)} required/>
        </div>


        {<CkEditorComponent label={i18n.t('set_item_content')} content={this.state.content} onChangeContentHandler={this.onChangeContentHandler}/>}



        <hr/>
        <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
        <button type="button" onClick={(event) => this.setState({view: 'list'}, () => {this.loadFilteredEvents()})} className="btn btn-danger">{i18n.t('button_cancel')}</button>
      </form>
    );
  }

  renderDeleteView(){
    return(
      <form onSubmit={(event) => this.deleteForm(event)}>
        <p>{i18n.t('confirm_delete_action')}: <strong>{this.state.title}</strong></p>
        <hr/>
          <button type="submit" className="btn btn-danger">{i18n.t('button_delete')}</button>
          <button type="button" onClick={(event) => this.setState({view: 'list'}, () => {this.loadFilteredEvents()})} className="btn btn-default">{i18n.t('button_cancel')}</button>
      </form>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView();
      }
      if(this.state.view === 'create' || this.state.view === 'edit'){
        view = this.renderCreateView();
      }
      if(this.state.view === 'delete'){
        view = this.renderDeleteView();
      }
    }
    else{
      view = <LoaderComponent />;
    }
    return(
      <div>
        {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
        {view}
      </div>
    );
  }

}
