import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import GroupsListComponent from '../GroupsListComponent';
import FullProfileCard from '../FullProfileCard/InitialComponent';

class GroupsComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      objectSelectLoading: false,
      reloadGroupsList: false,
      view: 'main',
      group: [],
    }

    this.objectSelectChangeHandler = this.objectSelectChangeHandler.bind(this);
    this.finishListReloadHandler = this.finishListReloadHandler.bind(this);
    this.changeMainView = this.changeMainView.bind(this);
  }

  changeMainView(event, view, group){
    this.setState({view: view, group: group});
  }

  finishListReloadHandler(status){
    this.setState({reloadGroupsList: status});
  }

  objectSelectChangeHandler(event){
    this.setState({objectSelectLoading: true});
    localStorage.setItem('objectID', event.target.value);
    this.setState({objectSelectLoading: false, reloadGroupsList: true});
  }

  renderMainView(){
    return(
      <div className="nav-tabs-custom">
          <ul className="nav nav-tabs">
            <li className="active"><a href="#tab_1-1" data-toggle="tab" aria-expanded="false"><i className="fas fa-ellipsis-v"></i> {i18n.t('groups_list')}</a></li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active" id="tab_1-1">

              <GroupsListComponent
                objectID={localStorage.getItem('objectID')}
                reload={this.state.reloadGroupsList}
                afterReloadHandler={this.finishListReloadHandler}
                changeMainView={this.changeMainView}
              />

            </div>
          </div>

        </div>
    );
  }

  render(){
    var view;
    if(this.state.view === 'main'){
      view = this.renderMainView();
    }
    else if (this.state.view === 'full_group_profile') {
      view = <FullProfileCard
        currentUser={this.props.currentUser}
        currentGroup={this.state.group}
        changeMainView={this.changeMainView}
        />
    }

    return(
      <div>

        <section className="content-header">
          <div className="row">
              <div className="col-md-8 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-groups"><i className="fas fa-layer-group"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-number">
                      <h3>{i18n.t('groups_module')}<br/><small>{i18n.t('groups_module_subtitle')}</small></h3>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                  <ObjectSelectComponent
                    objectSelectChangeHandler={this.objectSelectChangeHandler}
                    currentlyChoosen={localStorage.getItem('objectID')}
                    loading={this.state.objectSelectLoading}
                    />
              </div>

          </div>
        </section>

        <section className="content container-fluid">
          {view}
        </section>

      </div>
    );
  }

}
export default GroupsComponent;
