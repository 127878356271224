import React, {Component} from 'react';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import * as requests from '../../requests.js';
import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";

class PersonalInfoComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      kid: [],
      isLoading: true,
      view: 'list',
      birth: new Date(),
      status: '',
      msg: null,
      validationErrors: [],
    }

    this.setView = this.setView.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.changeState = this.changeState.bind(this);
  }

  componentDidMount(){
    if(this.props.currentKid.canISeeDetails === true){
      this.getKidPersonalData();
    }
  }

  changeState(event, type){
    if(type === 'birth'){
      this.setState({birth: event});
    }
  }

  getKidPersonalData(){
    this.setState({isLoading: true});
    let array = {
      'kidID': this.props.currentKid.kidID,
      'schoolID' : this.props.currentKid.schoolID
    }

    let request = requests.getKidsPersonalInfo(array);
    request.then(function(response){
      this.setState({kid: response.data, birth: response.data.info.birth, pesel: response.data.info.pesel});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  setView(event, view){
    this.setState({view: view});
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true, validationErrors: [], status: '', msg: null});

    let array = {
      'name' : event.target.name.value,
      'surname' : event.target.surname.value,
      'nick' : event.target.nick.value,
      'birth' : this.state.birth,
      'kidID' : this.props.currentKid.kidID,
      'schoolID' : this.props.currentKid.schoolID
    }
    var request;
    if(this.state.view === 'edit'){
      request = requests.postKidsPersonalInfoUpdate(array);
    }
    if(this.state.view === 'application'){
      request = requests.postKidsPersonalInfoApplication(array);
    }

    request.then(function(response){
      this.setState({view: 'list', status: 'success', msg: i18n.t(response.data.msg)}, () => { return this.getKidPersonalData() })
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'validation_error'){
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderEditButton(){
    return(
      <button onClick={(event) => this.setView(event, 'edit')} className="btn btn-warning">{i18n.t('button_edit')}</button>
    );
  }

  renderApplicationButton(){
    if(this.state.kid.info.application.status === false){
      return(
        <button onClick={(event) => this.setView(event, 'application')} className="btn btn-default">{i18n.t('post_application_to_change_data')}</button>
      );
    }
    else{
      return(
        <div className="callout callout-info">
          <h4>{i18n.t('your_application_waiting_for_action')}</h4>
          <p>{i18n.t('application_day')}: <b>{this.state.kid.info.application.cdate}</b>, {i18n.t('application_action')}: <b>{i18n.t(this.state.kid.info.application.action)}</b></p>
        </div>
      );
    }
  }

  renderEditView(){
    return(
      <div className="row">
        <div className="col-md-6 col-sm-9 col-xs-12">

          <form onSubmit={(event) => this.submitForm(event)}>

            {helperFunctions.getTextInput(i18n.t('name'), "name", this.state.kid.info.name)}
            {helperFunctions.getTextInput(i18n.t('surname'), "surname", this.state.kid.info.surname)}
            {helperFunctions.getTextInput(i18n.t('nick'), "nick", this.state.kid.info.nick, false, null, false)}

            <hr/>
            <button className="btn btn-success">{i18n.t('button_save')}</button>
            <button onClick={(event) => this.setState({view: 'list'})} className="btn btn-default">{i18n.t('button_cancel')}</button>

          </form>

        </div>
      </div>
    );
  }

  renderDetailsView(){
    var edit = null;
    if(this.state.kid.accountRole === 'role_principal'){
      edit = this.renderEditButton();
    }
    if((this.state.kid.accountRole === 'role_parent' && this.props.currentKid.isMyKid === true) || this.state.kid.accessType === 'full'){
      edit = this.renderApplicationButton();
    }
    return(
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">

          <table className="table">
            <tbody>

              <tr>
                <td>{i18n.t('name_and_surname')}</td>
                <td><b>{this.state.kid.info.name} {this.state.kid.info.surname}</b></td>
              </tr>
              <tr>
                <td>{i18n.t('nick')}</td>
                <td><b>{this.state.kid.info.nick}</b></td>
              </tr>
              <tr>
                <td>{i18n.t('birth_date')}</td>
                <td><b>{this.state.kid.info.birth !== null ? this.state.kid.info.birth : "-"}</b></td>
              </tr>

            </tbody>
          </table>

          <hr/>
          {edit}

        </div>
      </div>
    )
  }



  render(){
    var view = helperFunctions.showBlockadeBlock(i18n.t('access_denied'), i18n.t('access_denied_info'));
    if(this.props.currentKid.canISeeDetails === true){
      if(this.state.isLoading === false && this.state.kid.info !== undefined){
        if(this.state.view === 'list'){
          view = this.renderDetailsView();
        }
        if(this.state.view === 'edit' || this.state.view === 'application'){
          view = this.renderEditView();
        }
      }
      else{
        view = <LoaderComponent />;
      }
    }

    return(
      <div>
        {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
        {view}
      </div>
    );
  }

}

export default PersonalInfoComponent;
