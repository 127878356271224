import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";


export default
class FoodMenuComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      objectSelectLoading: false,
      reloadList: false,
      view: 'list',
      items: [],
      isLoading: false,
      status: '',
      msg: null,
      startDate: new Date(),
      endDate: new Date(),
      selectedDayID: 0,
      selectedDayDetails: {meal: []},
    }

    this.objectSelectChangeHandler = this.objectSelectChangeHandler.bind(this);
    this.getFoodList = this.getFoodList.bind(this);
    this.getItemDetails = this.getItemDetails.bind(this);
  }

  componentDidMount(){
    this.getFoodList();
  }

  objectSelectChangeHandler(event){
    this.setState({objectSelectLoading: true});
    localStorage.setItem('objectID', event.target.value);
    this.setState({objectSelectLoading: false, reloadList: true});
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.reloadList == true && this.state.reloadList == false) {
      this.getFoodList();
    }
  }

  getFoodList(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
      'date_from': moment(this.state.startDate).format("YYYY-MM-DD"),
      'date_to' : moment(this.state.endDate).format("YYYY-MM-DD"),
    }

    let request = requests.getFoodList(array);
    request.then(function(response){
      this.setState({items: response.data, status: '', msg: null});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  getItemDetails(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
      'id' : this.state.selectedDayID,
    }

    let request = requests.getFoodDetails(array);
    request.then(function(response){
      this.setState({selectedDayDetails: response.data, status: '', msg: null});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  renderListView(){
    return(
      <div>
        {
          this.state.items.map(function(elem){
            return(

                <div key={elem.id} onClick={(event) => this.setState({selectedDayID: elem.id, view: 'mealTimes'}, () => {this.getItemDetails()})} className="media cursor">
                  <div className="media-left">
                    <span className="info-box-icon bg-navy"><i class="fas fa-utensils"></i></span>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">{elem.day}
                      <br/><small>{i18n.t('meal_count')}: <b>{elem.mealsCount}</b></small>
                      <br/><small>{i18n.t('cdate')}: <b>{elem.cdate}</b></small>
                    </h4>
                  </div>
                </div>

            )
          }.bind(this))
        }
      </div>
    )
  }

  renderMainView(){
    var view = helperFunctions.renderNoContentInfo();
    if(this.state.items !== undefined && this.state.items.length > 0){
      view = this.renderListView()
    }

    return(
      <div>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-xs-12">

            <DatePicker
                locale="lang"
                selected={this.state.startDate}
                onSelect={(value) => this.setState({startDate: value}, () => {this.getFoodList()})}
                filterDate={this.isWeekday}
                selectsStart
                startDate={this.state.startDate}
                shouldCloseOnSelect={false}
                dateFormat="yyyy-MM-dd"
                className="form-control"
            />

          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">

            <DatePicker
                locale="lang"
                selected={this.state.endDate}
                onSelect={(value) => this.setState({endDate: value}, () => {this.getFoodList()})}
                filterDate={this.isWeekday}
                selectsStart
                startDate={this.state.endDate}
                shouldCloseOnSelect={false}
                dateFormat="yyyy-MM-dd"
                className="form-control"
            />

          </div>
        </div>

        <hr/>
        {view}

      </div>
    );
  }

  renderMealTimesListView(){
    var view = helperFunctions.renderNoContentInfo();
    if(this.state.selectedDayDetails.meal.length){
      view =
        this.state.selectedDayDetails.meal.map(function(elem){
          return(
            <div key={elem.id}>
              <div className="media">
                <div className="media-left">
                  <span className="info-box-icon bg-navy"><i className="fas fa-clock"></i></span>
                </div>
                <div className="media-body">
                  <h4 className="media-heading">{elem.name}
                    <br/><small>{i18n.t('scheduled_meal_time')}: <b>{elem.time}</b></small>
                  </h4>
                  <div dangerouslySetInnerHTML={{__html: elem.description}} />
                </div>
              </div><hr/>
            </div>
          )
        }.bind(this))
      }


    return (
      <div>
      <button className="btn btn-default" onClick={(event) => this.setState({view: 'list'}, () => {this.getFoodList()})}>{i18n.t('go_back_to_list')}</button>
        <div className="page-header">
          <h3>{this.state.selectedDayDetails.day}</h3>
        </div>
        {view}
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = helperFunctions.renderNoContentInfo();
      if(this.state.view === 'list'){
        view = this.renderMainView();
      }
      if(this.state.view === 'mealTimes'){
        view = this.renderMealTimesListView();
      }
    }
    else{
      view = <LoaderComponent />;
    }


    return(
      <div>

        <section className="content-header">
          <div className="row">
              <div className="col-md-8 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-navy"><i className="fas fa-utensils"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-number">
                      <h3>{i18n.t('food_module')}<br/><small>{i18n.t('food_module_subtitle')}</small></h3>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                  <ObjectSelectComponent
                    objectSelectChangeHandler={this.objectSelectChangeHandler}
                    currentlyChoosen={localStorage.getItem('objectID')}
                    loading={this.state.objectSelectLoading}
                    />
              </div>

          </div>
        </section>

        <section className="content container-fluid">
          {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
          <div className="nav-tabs-custom"><div className="box-body">{view}</div></div>

        </section>

      </div>
    );
  }

}
