import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';

import MainSettings from '../MainSettings/InitialComponent';
import KidsSettings from '../KidsSettings/InitialComponent';
import GroupsSettings from '../GroupsSettings/InitialComponent';
import GallerSettings from '../GallerySettings/InitialComponent';
import TeachersSettings from '../TeachersSettings/InitialComponent';
import IOComponent from '../IOComponent/InitialComponent';
import KnowledgeBaseComponent from '../KnowledgeComponent/InitialComponent';
import SystemSettingsComponent from '../SystemSettings/InitialComponent';

export default
class AdministrationComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      objectSelectLoading: false,
      reloadList: false,
      view: 'mainSettings',
      isLoading: false,
      status: '',
      msg: null,
      remoteItemID: 0,
    }

    this.setAccessDenied = this.setAccessDenied.bind(this);
    this.objectSelectChangeHandler = this.objectSelectChangeHandler.bind(this);
  }

  objectSelectChangeHandler(event){
    this.setState({objectSelectLoading: true});
    localStorage.setItem('objectID', event.target.value);
    this.setState({objectSelectLoading: false, reloadList: true});
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.reloadList == true && this.state.reloadList == false) {
      this.checkAdminAccess();
      this.setState({view: 'mainSettings'})
    }

    if(nextProps.moduleParams.submodule !== undefined && nextProps.moduleParams.id !== undefined){
      this.setState({view: nextProps.moduleParams.submodule, remoteItemID: nextProps.moduleParams.id});
      nextProps.moduleParams.submodule = undefined;
      nextProps.moduleParams.id = undefined
    }
  }

  changeRemoteID(value){
    this.setState({remoteItemID: value});
  }

  componentDidMount(){
    this.checkAdminAccess()
  }

  checkAdminAccess(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.checkAdminAccess(array);
    request.then(function(response){

    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  setAccessDenied(){
    this.setState({view: 'access_denied'});
  }

  getComponentView(){
    var view = null;
    switch (this.state.view) {
      case 'mainSettings':
        view = <MainSettings setAccessDenied={this.setAccessDenied}/>
        break;

      case 'kidsSettings':
        view = <KidsSettings setAccessDenied={this.setAccessDenied} remoteItemID={this.state.remoteItemID} changeRemoteID={this.changeRemoteID.bind(this)}/>
        break;

      case 'groupsSettings':
        view = <GroupsSettings setAccessDenied={this.setAccessDenied}/>
        break;

      case 'gallerySettings':
        view = <GallerSettings setAccessDenied={this.setAccessDenied}/>
        break;

      case 'teachersSettings':
        view = <TeachersSettings setAccessDenied={this.setAccessDenied} remoteItemID={this.state.remoteItemID} changeRemoteID={this.changeRemoteID.bind(this)}/>
        break;

      case 'importExport':
        view = <IOComponent setAccessDenied={this.setAccessDenied}/>
        break;

      case 'knowledgeSettings':
        view = <KnowledgeBaseComponent setAccessDenied={this.setAccessDenied}/>
        break;

      case 'systemSettings':
        view = <SystemSettingsComponent setAccessDenied={this.setAccessDenied}/>
        break;
    }
    return (<div>{view}</div>);
  }

  renderMainView(){
    var view = this.getComponentView()
    return(
      <div className="nav-tabs-custom">
        <ul className="nav nav-tabs">
          <li className={this.state.view === 'mainSettings' ? "active" : null}><a onClick={(event) => this.setState({view: 'mainSettings'})} href="#tab" data-toggle="tab">{i18n.t('main')}</a></li>
          <li className={this.state.view === 'kidsSettings' ? "active" : null}><a onClick={(event) => this.setState({view: 'kidsSettings'})} href="#tab" data-toggle="tab">{i18n.t('kids_and_parents')}</a></li>
          <li className={this.state.view === 'groupsSettings' ? "active" : null}><a onClick={(event) => this.setState({view: 'groupsSettings'})} href="#tab" data-toggle="tab">{i18n.t('groups_module')}</a></li>
          <li className={this.state.view === 'gallerySettings' ? "active" : null}><a onClick={(event) => this.setState({view: 'gallerySettings'})} href="#tab" data-toggle="tab">{i18n.t('group_gallery')}</a></li>
          <li className={this.state.view === 'teachersSettings' ? "active" : null}><a onClick={(event) => this.setState({view: 'teachersSettings'})} href="#tab" data-toggle="tab">{i18n.t('cadre')}</a></li>
          <li className={this.state.view === 'knowledgeSettings' ? "active" : null}><a onClick={(event) => this.setState({view: 'knowledgeSettings'})} href="#tab" data-toggle="tab">{i18n.t('knowledgebase_module')}</a></li>
          <li className={this.state.view === 'importExport' ? "active" : null}><a onClick={(event) => this.setState({view: 'importExport'})} href="#tab" data-toggle="tab">{i18n.t('import_export')}</a></li>
          <li className={this.state.view === 'systemSettings' ? "active" : null}><a onClick={(event) => this.setState({view: 'systemSettings'})} href="#tab" data-toggle="tab">{i18n.t('system_settings')}</a></li>
      </ul>
        <div className="tab-content">
          <div className="tab-pane active" id="tab">{view}</div>
        </div>
      </div>
    );
  }

  renderAccessDeniedView(){
    return(<div>{i18n.t('access_denied_info')}</div>);
  }

  render(){
    var view;
    if(this.state.view === 'access_denied'){
      view = this.renderAccessDeniedView()
    }
    else{
      view = this.renderMainView()
    }
    return(
      <div>

        <section className="content-header">
          <div className="row">
              <div className="col-md-8 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-red"><i className="fas fa-cogs"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-number">
                      <h3>{i18n.t('admin_module')}<br/><small>{i18n.t('admin_module_subtitle')}</small></h3>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                  <ObjectSelectComponent
                    objectSelectChangeHandler={this.objectSelectChangeHandler}
                    currentlyChoosen={localStorage.getItem('objectID')}
                    loading={this.state.objectSelectLoading}
                    />
              </div>

          </div>
        </section>

        <section className="content container-fluid">
          {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
          {view}
        </section>

      </div>
    );
  }

}
