import React, { Component }  from 'react';
import i18n from "i18next";


export function getTextInput(label, name, value = null, error = false, errorMsg = null, required = true, type = 'text'){
  return(
    <div className={error ? 'form-group has-error' : 'form-group'}>
      <label>{label} {required ? <span className="text-danger">({i18n.t('input_is_required')})</span> : null}</label>
      <input type={type} name={name} defaultValue={value} className="form-control" placeholder={label} required={required} />
      {error ? <span className="help-block">{errorMsg}</span> : null}
    </div>
  );
}

export function getResponseAlert(type, msg){
  if(type == 'success'){
    return(
      <div className="alert alert-success alert-dismissible">
          <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
          <h4><i className="icon fa fa-check"></i> {i18n.t('request_alert_success_title')}</h4>
          {i18n.t(msg)}
      </div>
    );
  }
  else{
    return(
      <div className="alert alert-danger alert-dismissible">
          <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
          <h4><i className="fas fa-exclamation-triangle"></i> {i18n.t('request_alert_error_title')}</h4>
          {i18n.t(msg)}
      </div>
    );
  }
}

export function showBlockadeBlock(title, info){
  return(
    <div className="info-box">
      <span className="info-box-icon bg-red"><i className="fas fa-user-lock"></i></span>
      <div className="info-box-content">
        <span className="info-box-text">{title}</span>
        <span className="info-box-number">{info}</span>
      </div>
    </div>
  );
}

export function showErrorBlock(title, info){
  return(
    <div className="info-box">
      <span className="info-box-icon bg-red"><i className="fas fa-exclamation-triangle"></i></span>
      <div className="info-box-content">
        <span className="info-box-text">{title}</span>
        <span className="info-box-number">{info}</span>
      </div>
    </div>
  );
}

export function renderNoContentInfo(){
  return(
    <div className="noContent">
      <div className="big_logo_centered"><img src="https://net-kid.com/images/logo_big_bw.png" /></div>
      <div className="content">{i18n.t('no_content_to_display')}</div>
    </div>
  );
}

export function renderAttachmentsView(elems, translation){
  return(
    <div>
      <br/><p><strong>{translation}</strong></p>
      <ul className="list-unstyled">
        {
          elems.map(function(item){
            return(
              <li>
                <div className="media">
                  <div className="media-left">
                    <i className="fas fa-paperclip"></i>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">{item.title}</h4>
                    <p>{item.description}</p>
                  </div>
                </div>
              </li>
            );
          }.bind(this))
        }
      </ul>
    </div>
  );
}

export function renderAttachmentsAdminView(elems, translation){
  return(
    <div>
      <br/><p><strong>{translation}</strong></p>
      <ul className="list-unstyled">
        {
          elems.map(function(item){
            return(
              <li>
                <div className="media">
                  <div className="media-left">
                    <i className="fas fa-paperclip"></i>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">{item.title} ({item.weightSize})</h4>
                    <p>{item.description}</p>
                  </div>
                </div>
              </li>
            );
          }.bind(this))
        }
      </ul>
    </div>
  );
}
