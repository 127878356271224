import React, {Component} from 'react';

import logoImage from '../../../Img/logo.png';

export default class ContactComponent extends Component{

  render(){
    return(
      <div className="mp_contactWrapper">
        <div className="container">

          <div className="row visible-xs-block">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <h2>NETkid także w wersji mobilnej!</h2>
              <p>
                NETkid jest dostępny również w postaci aplikacji mobilnej. Umożliwia to praktycznie natychmiastowy kontakt z rodzicami / opiekunami poprzez wiadomości wyskakujące na wyświetlaczu komórki. Pozwala również rodzicom na łatwy dostęp do potrzebnych informacji o dziecku. Aplikacja przygotowana jest z myślą o użytkownikach urządzeń mobilnych, korzystających z systemu Android oraz IOS.
              </p>
            </div>
          </div>

          <div className="row visible-md-block visible-md-block visible-lg-block">
            <div className="col-lg-2 col-sm-3 col-xs-4 hidden-xs"><img src={logoImage} className="img-responsive"/></div>
            <div className="col-lg-10 col-sm-9 col-xs-8">
              <h2>NETkid także w wersji mobilnej!</h2>
              <p>
                NETkid jest dostępny również w postaci aplikacji mobilnej. Umożliwia to praktycznie natychmiastowy kontakt z rodzicami / opiekunami poprzez wiadomości wyskakujące na wyświetlaczu komórki. Pozwala również rodzicom na łatwy dostęp do potrzebnych informacji o dziecku. Aplikacja przygotowana jest z myślą o użytkownikach urządzeń mobilnych, korzystających z systemu Android oraz IOS.
              </p>
            </div>
          </div>

        </div>
      </div>
    );
  }

}
