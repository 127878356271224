import React, {Component} from 'react';

import i18n from "i18next";

import KidsListComponent from '../KidsListComponent';
import TeachersListComponent from '../TeachersListComponent';
import EventsListComponent from '../EventsListComponent';
import DashboardComponent from '../DashboardComponent';
import GalleryComponent from '../GalleryComponent';
import ActivityListComponent from '../ActivityListComponent';
import SurveyComponent from '../SurveyComponent';

export default
class InitialComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      currentModule: 'KidsListComponent',
      currentModuleParams: [],
    }

    this.changeModule = this.changeModule.bind(this);
  }

  chooseModuleHandler(){
    var module = null;
    switch (this.state.currentModule) {
      case 'KidsListComponent':
        module = KidsListComponent;
        break;

      case 'TeachersListComponent':
        module = TeachersListComponent;
        break;

      case 'EventsComponent':
        module = EventsListComponent;
        break;

      case 'DashboardComponent':
        module = DashboardComponent;
        break;

      case 'GalleryComponent':
        module = GalleryComponent;
        break;

      case 'ActivityListComponent':
        module = ActivityListComponent;
        break;

      case 'SurveyComponent':
        module = SurveyComponent;
        break;

    }

    return module;
  }

  changeModule(event, module){
    this.setState({currentModule: module});
  }

  render(){
    const Module = this.chooseModuleHandler();
    return(
      <div className="box box-groups box-solid">
        <div className="box-header with-border">

          <div className="media">
            <div className="media-left">
              <img className="media-object img-circle img-md" src={this.props.currentGroup.avatar} alt="" />
              </div>
              <div className="media-body">
                  <h4 className="media-heading">{i18n.t('full_group_profile')}: <b>{this.props.currentGroup.name}</b></h4>
                  <small>{i18n.t('object')}: <b>{this.props.currentGroup.school}</b><br/>{i18n.t('age_group')}: <b>{this.props.currentGroup.age}{i18n.t('age_group_suffix')}</b>, {i18n.t('yearbook')}: <b>{this.props.currentGroup.year_start} - {this.props.currentGroup.year_end}</b></small>
              </div>
            </div>

        </div>

        <div className="box-body">

          <div className="nav-tabs-custom">
              <ul className="nav nav-tabs">
                <li className=""><a onClick={(event) => this.props.changeMainView(event, 'main', this.props.currentKidID)} href="#tab" data-toggle="tab" aria-expanded="false"><i className="fas fa-arrow-alt-circle-left"></i> {i18n.t('go_back_to_list')}</a></li>
                <li className="active"><a onClick={(event) => this.changeModule(event, 'KidsListComponent')} href="#tab" data-toggle="tab" aria-expanded="false"><i className="far fa-folder"></i> {i18n.t('kids_list')}</a></li>
                <li className=""><a onClick={(event) => this.changeModule(event, 'TeachersListComponent')} href="#tab" data-toggle="tab" aria-expanded="false"><i className="far fa-folder"></i> {i18n.t('teachers_list')}</a></li>
                <li className=""><a onClick={(event) => this.changeModule(event, 'ActivityListComponent')} href="#tab" data-toggle="tab" aria-expanded="false"><i className="far fa-folder"></i> {i18n.t('activity_list')}</a></li>
                <li className=""><a onClick={(event) => this.changeModule(event, 'EventsComponent')} href="#tab" data-toggle="tab" aria-expanded="false"><i className="far fa-folder"></i> {i18n.t('upcoming_events')}</a></li>
                <li className=""><a onClick={(event) => this.changeModule(event, 'DashboardComponent')} href="#tab" data-toggle="tab" aria-expanded="false"><i className="far fa-folder"></i> {i18n.t('group_dashboard')}</a></li>
                <li className=""><a onClick={(event) => this.changeModule(event, 'GalleryComponent')} href="#tab" data-toggle="tab" aria-expanded="false"><i className="far fa-folder"></i> {i18n.t('group_gallery')}</a></li>
                <li className=""><a onClick={(event) => this.changeModule(event, 'SurveyComponent')} href="#tab" data-toggle="tab" aria-expanded="false"><i className="far fa-folder"></i> {i18n.t('survey_module')}</a></li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane active" id="tab">

                  <Module
                    currentGroup={this.props.currentGroup}
                    currentUser={this.props.currentUser}
                  />

                </div>

              </div>

            </div>

        </div>
      </div>
    );
  }

}
