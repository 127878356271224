import React, {Component} from 'react';

export default
class KidsTransferComponent extends Component{

  constructor(props){
    super(props)
  }

  render(){
    return(<div>Transfer</div>)
  }

}
