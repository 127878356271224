import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import AttachmentsComponent from '../../../../Utils/AttachmentsComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';


export default
class KnowledgeBaseComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      objectSelectLoading: false,
      reloadList: false,
      view: 'list',
      isLoading: false,
      status: '',
      msg: null,
      local: [],
      global: [],
      itemID: 0,
      item: [],
      files: [],
    }

    this.objectSelectChangeHandler = this.objectSelectChangeHandler.bind(this);
    this.finishListReloadHandler = this.finishListReloadHandler.bind(this);
  }

  objectSelectChangeHandler(event){
    this.setState({objectSelectLoading: true});
    localStorage.setItem('objectID', event.target.value);
    this.setState({objectSelectLoading: false, reloadItemsList: true}, () => {this.getItems()});

  }

  finishListReloadHandler(status){
    this.setState({reloadItemsList: status});
  }

  componentDidMount(){
    this.getItems();
  }

  getItems(){
    this.setState({isLoading: true});

    var array = {
      'objectID' : localStorage.getItem('objectID'),
    }

    let request = requests.getAllItems(array);
    request.then(function(response){
      this.setState({local: response.data.local, global: response.data.global});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  getItemDetails(){
    this.setState({isLoading: true});

    var array = {
      'objectID' : localStorage.getItem('objectID'),
      'itemID' : this.state.itemID
    }

    let request = requests.getItemDetails(array);
    request.then(function(response){
      this.setState({item: response.data, files: response.data.filesArray});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  renderListView(){
    return(
      <div>
        {
          this.state.global.map(function(item){
            return(
              <div key={item.key}>
                <div className="media cursor" onClick={(event) => this.setState({view: 'details', itemID: item.id}, () => {this.getItemDetails()})}>
                  <div className="media-left">
                    <span className="info-box-icon bg-aqua"><i className="fas fa-school"></i></span>
                  </div>
                  <div className="media-body">
                    <h4 class="media-heading">{item.title}</h4>
                    <p>{i18n.t('files_count')}: <strong>{item.files}</strong></p>
                    <p>{i18n.t('cdate')}: <strong>{i18n.t(item.cdate)}</strong></p>
                  </div>
                </div>
                <hr/>
              </div>
            );
          }.bind(this))
        }
          {
            this.state.local.map(function(item){
              return(
                <div key={item.key}>
                  <div className="media cursor" onClick={(event) => this.setState({view: 'details', itemID: item.id}, () => {this.getItemDetails()})}>
                    <div className="media-left">
                      <span className="info-box-icon bg-purple"><i className="fas fa-info-circle"></i></span>
                    </div>
                    <div className="media-body">
                      <h4 class="media-heading">{item.title}</h4>
                      <p>{i18n.t('files_count')}: <strong>{item.files}</strong></p>
                      <p>{i18n.t('cdate')}: <strong>{item.cdate}</strong></p>
                    </div>
                  </div>
                  <hr/>
                </div>
              );
            }.bind(this))
          }
      </div>
    );
  }

  renderDetailsView(){
    var attachments;
    if(this.state.files.length > 0){
      attachments = <AttachmentsComponent view={"front"} items={this.state.files} title={i18n.t('attachments_list')} />
    }
    return(
      <div key={this.state.item.key}>
        <button className="btn btn-default" onClick={(event) => this.setState({view: 'list'}, () => {this.getItems()})}>Wróć do listy </button>
        <div className="media cursor">
          <div className="media-body">
            <h4 class="media-heading">{this.state.item.title}</h4>
            <p>{i18n.t('files_count')}: <strong>{this.state.item.files}</strong></p>
            <p>{i18n.t('cdate')}: <strong>{this.state.item.cdate}</strong></p>

            <hr/>

            <div dangerouslySetInnerHTML={{__html: this.state.item.content}} />

            {attachments}

          </div>
        </div>
        <hr/>
      </div>
    );
  }

  renderMainView(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView()
      }
      if(this.state.view === 'details'){
        view = this.renderDetailsView()
      }
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div className="nav-tabs-custom">

          <div className="tab-content">
            <div className="tab-pane active" id="tab1">
              {view}
            </div>
          </div>

        </div>
    );
  }

  render(){
    var view = this.renderMainView();
    return(
      <div>

        <section className="content-header">
          <div className="row">
              <div className="col-md-8 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-purple"><i className="fas fa-info-circle"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-number">
                      <h3>{i18n.t('knowledgebase_module')}<br/><small>{i18n.t('knowledgebase_module_subtitle')}</small></h3>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                  <ObjectSelectComponent
                    objectSelectChangeHandler={this.objectSelectChangeHandler}
                    currentlyChoosen={localStorage.getItem('objectID')}
                    loading={this.state.objectSelectLoading}
                    />
              </div>

          </div>
        </section>

        <section className="content container-fluid">
          {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
          {view}
        </section>

      </div>
    );
  }

}
