import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { useTranslation, initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";

import translationPL from './Locales/pl/translations.json';
import translationEN from './Locales/en/translations.json';

i18n
  .use(initReactI18next, XHR) // passes i18n down to react-i18next
  .init({
    debug: true,
    resources: {
      pl: {
        translation: translationPL
      },
      en: {
        translation: translationEN
      }
    },
    lng: "pl",
    fallbackLng: "pl",

    interpolation: {
      escapeValue: false
    }
  });


export default i18n;
