import React, {Component} from 'react';

import logo from '../../../Img/logo_wbsite.png';

export default class NavigationComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      currentLink: this.props.link,
    }

    this.navLinkClickHandler = this.navLinkClickHandler.bind(this);
  }

  componentWillUpdate(nextProps, nextState){
    if(nextProps.link !== this.props.link){
      this.setState({currentLink: nextProps.link});
    }
  }

  navLinkClickHandler(event, type){
    this.setState({currentLink: type});
    this.props.changeContent(type);
  }

  render(){
    return(
      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <a className="navbar-brand" href="#"><img src={logo} className="img-responsive"/></a>
          </div>
          <div id="navbar" className="collapse navbar-collapse">
            <ul className="nav navbar-nav navbar-right">
              <li className={this.state.currentLink === 'about' ? "active" : null}><a href="#" onClick={(event) => this.navLinkClickHandler(event, 'about')}>O NETkid</a></li>
              <li className={this.state.currentLink === 'for_teacher' ? "active" : null}><a href="#" onClick={(event) => this.navLinkClickHandler(event, 'for_teacher')}>Dla nauczyciela</a></li>
              <li className={this.state.currentLink === 'for_principal' ? "active" : null}><a href="#" onClick={(event) => this.navLinkClickHandler(event, 'for_principal')}>Dla dyrekcji</a></li>
              <li className={this.state.currentLink === 'for_parent' ? "active" : null}><a href="#" onClick={(event) => this.navLinkClickHandler(event, 'for_parent')}>Dla rodzica</a></li>
              <li className={this.state.currentLink === 'what_to_do' ? "active" : null}><a href="#" onClick={(event) => this.navLinkClickHandler(event, 'what_to_do')}>Co zrobić aby zacząć pracę z NETkid</a></li>
              <li><a href="https://demo.net-kid.com" target="_blank" className="navbar_demo_link">DEMO</a></li>
              <li className={this.state.currentLink === 'login' ? "active" : null}><a href="#" onClick={(event) => this.navLinkClickHandler(event, 'login')} className="navbar_login_link">Zaloguj się</a></li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }

}
