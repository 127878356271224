import React, {Component} from 'react';

import LoaderComponent from '../../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../../acl.js';
import * as requests from '../../../requests.js';
import * as helperFunctions from '../../../../../../Utils/HelperFunctions.js';
import * as moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Moment from 'moment';
import plLocale from '@fullcalendar/core/locales/pl';

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

export default
class DailyLogComponent extends Component{

  calendarRef = React.createRef()

  constructor(props){
    super(props);

    this.state = {
      items: [],
      currentItemID: 0,
      currentDate: new Date(Moment().format("YYYY-MM-DD")),
      status: '',
      msg: null,
      view: 'calendar',
      months: [],
      proposal: false,
      create: false,
      details: [],
      exists: false,
      editItem: {
        hash: null,
        title_string: '',
        morning_activity: '',
        activities_main_1: '',
        activities_main_2: '',
        afternoon_classes: '',
        outdoor_activities: '',
        card_content: '',
        full_date: null,
      },
      choosenDate: null,
      cards: [],
      choosenCard: null,
    }

    this.changeState = this.changeState.bind(this);
    this.acceptCard = this.acceptCard.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getUnusedCards = this.getUnusedCards.bind(this);
    this.submitUnunsedCard = this.submitUnunsedCard.bind(this);
  }

  componentDidMount(){
    this.getGroupActvityLog();
  }

  changeState(event, type){
    if(type === 'month_select'){
      this.setState({currentDate: event.target.value}, () => {this.getGroupActvityLog()})
    }
    if(type === 'card'){
      this.setState({choosenCard: event.target.value})
    }
    if(type === 'title_string'){
      const { editItem } = this.state;
      editItem.title_string =  event.target.value;
      this.setState({editItem})
    }
    if(type === 'morning_activity'){
      const { editItem } = this.state;
      editItem.morning_activity =  event.target.value;
      this.setState({editItem})
    }
    if(type === 'activities_main_1'){
      const { editItem } = this.state;
      editItem.activities_main_1 =  event.target.value;
      this.setState({editItem})
    }
    if(type === 'activities_main_2'){
      const { editItem } = this.state;
      editItem.activities_main_2 =  event.target.value;
      this.setState({editItem})
    }
    if(type === 'afternoon_classes'){
      const { editItem } = this.state;
      editItem.afternoon_classes =  event.target.value;
      this.setState({editItem})
    }
    if(type === 'outdoor_activities'){
      const { editItem } = this.state;
      editItem.outdoor_activities =  event.target.value;
      this.setState({editItem})
    }
    if(type === 'card_content'){
      const { editItem } = this.state;
      editItem.card_content =  event.target.value;
      this.setState({editItem})
    }
  }

  getGroupActvityLog(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.groupID,
      'month': this.state.currentDate,
    }

    let request = requests.getGroupDailyLog(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data.items,
          currentDate: response.data.currentNormal,
          months: response.data.months
        }
      );
      let calendarApi = this.calendarRef.current.getApi();
      calendarApi.gotoDate(new Date(response.data.current));
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getActivityDetails(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.groupID,
      'log_id': this.state.currentItemID,
    }

    let request = requests.getGroupDailyLogDetails(array);
    request.then(function(response){
      this.setState(
        {
          details: response.data.details,
          proposal: response.data.proposal,
          create: response.data.create,
          exists: false,
          editItem: {
            hash: response.data.details.hash,
            title_string: response.data.details.title_string,
            morning_activity: response.data.details.morning_activity,
            activities_main_1: response.data.details.activities_main_1,
            activities_main_2: response.data.details.activities_main_2,
            afternoon_classes: response.data.details.afternoon_classes,
            outdoor_activities: response.data.details.outdoor_activities,
            card_content: response.data.details.card_content,
            card_no: response.data.details.card_no,
            full_date: this.state.choosenDate,
          },
        }, () => {this.getUnusedCards(response.data.create)}
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.groupID,
      'values' : this.state.editItem,
      'create' : this.state.create,
    }

    var request;
    request = requests.postGroupDailyLogStore(array);
    request.then(function(response){
      this.setState(
        {
          status: 'success',
          msg: i18n.t(response.data.msg),
          view: 'calendar',
          proposal: false,
          create: false,
          details: [],
        }, () => {this.getGroupActvityLog()}
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  acceptCard(hash){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.groupID,
      'log_id': hash,
    }
    let request = requests.postGroupDailyLogAccept(array);
    request.then(function(response){
      this.setState(
        {
          view: 'calendar',
          proposal: false,
          create: false,
        }, () => {this.getGroupActvityLog()}
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getUnusedCards(status){
    if(this.state.exists === false){
      this.setState({isLoading: true});
      var array = {
        'school_id' : localStorage.getItem('objectID'),
        'id' : this.props.groupID,
        'date': this.state.choosenDate,
      }
      let request = requests.getGroupActvityLogUnusedCards(array);
      request.then(function(response){
        this.setState(
          {
            cards: response.data,
            choosenCard: null,
          }
        );
      }.bind(this));

      request.catch(function(error){
        if(error.response.data.error === 'access_denied'){
          this.props.setAccessDenied()
        }
        else{
          this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
        }
      }.bind(this));

      request.finally(function(){
        this.setState({isLoading: false});
      }.bind(this));
    }
  }

  submitUnunsedCard(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.groupID,
      'log_id': this.state.choosenCard,
      'date': this.state.choosenDate,
    }
    let request = requests.postGroupDailyLogUnusedCard(array);
    request.then(function(response){
      this.setState(
        {
          view: 'calendar',
          proposal: false,
          create: false,
        }, () => {this.getGroupActvityLog()}
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderCalendar(){
    return(
      <div>
        <div className="form-group">
          <label>{i18n.t('choose_month')}</label>
          <select className="form-control" value={this.state.currentDate} onChange={(event) => this.changeState(event, 'month_select')}>
            {
              this.state.months.map(function(item){
                return(
                  <option key={item.key} value={item.full_date}>{item.month}</option>
                );
              }.bind(this))
            }
          </select>
        </div>
        <FullCalendar
          defaultView="dayGridMonth"
          locale={plLocale}
          ref={this.calendarRef}
          header={{ left: null, center: null, right: null }}
          showNonCurrentDates={false}
          plugins={[dayGridPlugin]}
          weekends={false}
          events={this.state.items}
          eventClick={function(calEvent){
            this.setState({currentItemID: calEvent.event.id, choosenDate: calEvent.event.start, view: 'details'}, () => {this.getActivityDetails()})
          }.bind(this)}
          />
      </div>
    )
  }

  renderProposalForm(){
    return(
      <div>

        <button className="btn btn-default" onClick={(event) => this.setState({view: 'calendar'}, () => {this.getGroupActvityLog()})}>Wróć do kalendarza</button>
        <br/><br/>
        <div className="info-box">
          <span className="info-box-icon bg-aqua"><i className="fas fa-info-circle"></i></span>
          <div className="info-box-content">
            <span className="info-box-text">{i18n.t('daily_log_create_title_1')}<br/>{i18n.t('daily_log_create_title_2')}</span><br/>
            <span className="info-box-number">
              <button onClick={(event) => this.acceptCard(this.state.details.hash)} className="btn btn-success">{i18n.t('accept_proposal')}</button>&nbsp;
              <button onClick={(event) => this.setState({proposal: false, exists: true, create: true, editItem: this.state.details})} className="btn btn-warning">{i18n.t('edit_proposal')}</button>&nbsp;
              <button onClick={(event) => this.setState({proposal: false, exists: false, create: true, editItem: {title_string: '', hash: null, full_date: this.state.details.full_date, card_no: null, morning_activity: '', activities_main_1: '', activities_main_2: '', afternoon_classes: '', outdoor_activities: '', card_content: ''}})} className="btn btn-primary">{i18n.t('create_new_daily_log')}</button>
            </span>
          </div>
        </div>

        <h4><small>{i18n.t('daily_log_title')}: </small><br/>{this.state.details.title_string}</h4>
        <br/>
        <h5><b>{i18n.t('daly_log_card_no')}: </b>{this.state.details.card_no}</h5>
        <h5><b>{i18n.t('daily_log_morning_ac')}:</b><br/>{this.state.details.morning_activity}</h5>
        <h5><b>{i18n.t('daily_log_activities_1')}:</b><br/>{this.state.details.activities_main_1}</h5>
        <h5><b>{i18n.t('daily_log_activities_2')}:</b><br/>{this.state.details.activities_main_2}</h5>
        <h5><b>{i18n.t('daily_log_activities_indoor')}:</b><br/>{this.state.details.afternoon_classes}</h5>
        <h5><b>{i18n.t('daily_log_activities_outdoor')}:</b><br/>{this.state.details.outdoor_activities}</h5>
        <h5><b>{i18n.t('daily_log_card_content')}:</b><br/>{this.state.details.card_content}</h5>

      </div>
    )
  }

  renderEditForm(){
    return(
      <div>
        <button className="btn btn-default" onClick={(event) => this.setState({view: 'calendar'}, () => {this.getGroupActvityLog()})}>Wróć do kalendarza</button>
        <br/><br/>
        Tutaj będzie formularz edycji karty
      </div>
    )
  }

  renderCreateForm(){
    return(
      <div>


        <form onSubmit={(event) => this.submitForm(event)}>
          <div className="form-group">
            <label>{i18n.t('daily_log_title')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <input type="text" name="title_string" className="form-control" onChange={(event) => this.changeState(event, 'title_string')} value={this.state.editItem.title_string} required />
          </div>
          <div className="form-group">
            <label>{i18n.t('daily_log_morning_ac')}</label>
            <textarea name="morning_activity" class="form-control" rows="10" onChange={(event) => this.changeState(event, 'morning_activity')} value={this.state.editItem.morning_activity} />
          </div>
          <div className="form-group">
            <label>{i18n.t('daily_log_activities_1')}</label>
            <textarea name="activities_main_1" class="form-control" rows="10" onChange={(event) => this.changeState(event, 'activities_main_1')} value={this.state.editItem.activities_main_1} />
          </div>
          <div className="form-group">
            <label>{i18n.t('daily_log_activities_2')}</label>
            <textarea name="activities_main_2" class="form-control" rows="10" onChange={(event) => this.changeState(event, 'activities_main_2')} value={this.state.editItem.activities_main_2} />
          </div>
          <div className="form-group">
            <label>{i18n.t('daily_log_activities_indoor')}</label>
            <textarea name="afternoon_classes" class="form-control" rows="10" onChange={(event) => this.changeState(event, 'afternoon_classes')} value={this.state.editItem.afternoon_classes} />
          </div>
          <div className="form-group">
            <label>{i18n.t('daily_log_activities_outdoor')}</label>
            <textarea name="outdoor_activities" class="form-control" rows="10" onChange={(event) => this.changeState(event, 'outdoor_activities')} value={this.state.editItem.outdoor_activities} />
          </div>
          <div className="form-group">
            <label>{i18n.t('daily_log_card_content')}</label>
            <textarea name="card_content" class="form-control" rows="10" onChange={(event) => this.changeState(event, 'card_content')} value={this.state.editItem.card_content} />
          </div>

          <hr/>
          <button className="btn btn-success" type="submit">{i18n.t('button_save')}</button>
          <button className="btn btn-default" type="button" onClick={(event) => this.setState({view: 'calendar'}, () => {this.getGroupActvityLog()})}>{i18n.t('button_cancel')}</button>


        </form>

      </div>
    )
  }

  renderSelectCardForm(){
    return(
      <div>
        <form onSubmit={(event) => this.submitUnunsedCard(event)}>

          <div class="input-group">
            <select name="card" className="form-control" onChange={(event) => this.changeState(event, 'card')}>
              <option>{i18n.t('daily_log_choose_card')}</option>
              {
                this.state.cards.map(function(item){
                  return(<option value={item.code}>{i18n.t('card') + " :" + item.card_no}, {item.title_string}</option>)
                }.bind(this))
              }
            </select>
          </div>
          <hr/>
          <button class="btn btn-success" type="submit">{i18n.t('button_save')}</button>
          <button className="btn btn-default" type="button" onClick={(event) => this.setState({view: 'calendar'}, () => {this.getGroupActvityLog()})}>{i18n.t('button_cancel')}</button>


        </form>
      </div>
    )
  }

  renderCreateOrSelectForm(){
    return(
      <div>

        <div className="nav-tabs-custom">
          <ul className="nav nav-tabs">
            <li className="active"><a href="#tab_1" data-toggle="tab" aria-expanded="true">{i18n.t('daily_log_create_card')}</a></li>
            <li className=""><a href="#tab_2" data-toggle="tab" aria-expanded="false">{i18n.t('daily_log_choose_other_card')}</a></li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active" id="tab_1">
              {this.renderCreateForm()}
            </div>
            <div className="tab-pane" id="tab_2">
              {this.renderSelectCardForm()}
            </div>

          </div>
        </div>

      </div>
    );
  }

  renderDetails(){
    var view = this.renderProposalForm()
    if(this.state.proposal === false){
      if(this.state.create === true){
        if(this.state.exists === false){
          view = this.renderCreateOrSelectForm()
        }
        else{
          view = this.renderCreateForm()
        }
      }
      else{
        view = this.renderCreateForm()
      }
    }
    return(
      <div>{view}</div>
    );
  }


  render(){
    var view = this.renderCalendar();
    if(this.state.view === 'details'){
      view = this.renderDetails();
    }
    return(
      <div>
        {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
        {view}
      </div>
    )
  }

}
