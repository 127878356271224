import React, {Component} from 'react';

import loader from '../../../Img/loader.gif';

export default class LoadingComponent extends Component{

  constructor(props){
    super(props)
  }

  render(){
    return(
      <div className="mp_loading_wrapper">
        <div className="mp_loading_image"><img src={loader} /></div>
      </div>
    );
  }

}
