export function check(action, rolesRequired, userRoles){

  var actions = {
    'role_principal'              : [
      'create_kid_profile', 'edit_kid_profile', 'create_kid_food_preference', 'create_kid_additional_activity_application'
    ],

    'role_teacher'                : [],

    'role_parent'                 : [],

    'role_administration_worker'  : [
      'create_kid_profile', 'edit_kid_profile'
    ]
  }

  if(rolesRequired.length > 0){
    for (var i = 0; i < rolesRequired.length; i++) {
      if(userRoles !== undefined && userRoles.indexOf(rolesRequired[i]) > -1){
        if(actions[rolesRequired[i]].indexOf(action) > -1){
          return true;
        }
      }
    }
  }

  return false;

}
