import API from '../../../../Utils/api';

export function getObjectApplicationsList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/applications', {params: array, headers: { Authorization: AuthStr }});
}

export function postObjectApplicationsExecute(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('school/post/applications/execute', array, {headers: { Authorization: AuthStr }});
}

export function getInvitatonsFromParentList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/invitations-from-parents', {params: array, headers: { Authorization: AuthStr }});
}

export function postInvitaionParentExecute(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('school/post/invitations-from-parents', array, {headers: { Authorization: AuthStr }});
}

export function checkAdminAccess(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/access', {params: array, headers: { Authorization: AuthStr }});
}
