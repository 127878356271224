import API from '../../../../Utils/api';

export function getFoodList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/food', {params: array, headers: { Authorization: AuthStr }});
}

export function getFoodDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/food-details', {params: array, headers: { Authorization: AuthStr }});
}
