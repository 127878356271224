import React, {Component} from 'react';

import LoaderComponent from '../../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../../acl.js';
import * as requests from '../../../requests.js';
import * as helperFunctions from '../../../../../../Utils/HelperFunctions.js';

export default
class InfoComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      status: '',
      type: 0,
      msg: null,
      isLoading: false,
      employee_bio: '',
      employee_spec: '',
      employee_func: '',
      validationErrors: [],
    }

    this.changeState = this.changeState.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount(){
    this.getTeacherDetails();
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true, status: '', msg: null, validationErrors: []});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.teacherID,
      'employee_bio' : this.state.employee_bio,
      'employee_spec' : this.state.employee_spec,
      'employee_func' : this.state.employee_func,
    }
    let request;
    request = requests.postTeacherInfoUpdate(array);

    request.then(function(response){
      this.setState(
        {
          status: 'success',
          msg: i18n.t(response.data.msg),
          view: response.data.view,
        }
      );
      if(response.data.view === 'principal' || response.data.view === 'administration' || response.data.view === 'teachers'){
        this.props.changeView(response.data.view, 0);
      }
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        if(error.response.data.error === 'validation_error'){
          this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
        }
        else{
          this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
        }
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  changeState(event, type){
    if(type === 'bio'){
      this.setState({employee_bio: event.target.value});
    }
    if(type === 'spec'){
      this.setState({employee_spec: event.target.value});
    }
    if(type === 'func'){
      this.setState({employee_func: event.target.value});
    }
  }

  getTeacherDetails(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.teacherID,
    }

    let request = requests.getTeacherInfo(array);
    request.then(function(response){
      this.setState(
        {
          employee_bio: response.data.employee_bio,
          employee_spec: response.data.employee_spec,
          employee_func: response.data.employee_func,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderFormView(){
    return(
      <form onSubmit={(event) => this.submitForm(event)}>

        <div className={'func' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
          <label>{i18n.t('set_account_function')}</label>
          <input type="text" name="func" onChange={(event) => this.changeState(event, 'func')} className="form-control" value={this.state.employee_func} />
          <span className="help-block">{'func' in this.state.validationErrors ? i18n.t(this.state.validationErrors['func']) : null}</span>
        </div>

        <div className={'spec' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
          <label>{i18n.t('set_account_spec')}</label>
          <input type="text" name="spec" onChange={(event) => this.changeState(event, 'spec')} className="form-control" value={this.state.employee_spec} />
          <span className="help-block">{'spec' in this.state.validationErrors ? i18n.t(this.state.validationErrors['spec']) : null}</span>
        </div>

        <div className={'bio' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
          <label>{i18n.t('set_account_bio')}</label>
          <textarea name="bio" rows="10" value={this.state.employee_bio}  onChange={(event) => this.changeState(event, 'bio')} className="form-control"/>
          <span className="help-block">{'bio' in this.state.validationErrors ? i18n.t(this.state.validationErrors['bio']) : null}</span>
        </div>

        <hr/><button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
      </form>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderFormView()
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
