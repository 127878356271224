import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';

import Calendar from '../CalendarComponent';

export default
class DailyLogComponent extends Component{

  constructor(props){
    super(props)

    this.objectSelectChangeHandler = this.objectSelectChangeHandler.bind(this);
    this.finishListReloadHandler = this.finishListReloadHandler.bind(this);

    this.state = {
      objectSelectLoading: false,
      reloadItemsList: false,
      view: 'main',
      status: '',
      error: null,
      isLoading: false,
    }
  }

  objectSelectChangeHandler(event){
    this.setState({objectSelectLoading: true});
    localStorage.setItem('objectID', event.target.value);
    this.setState({objectSelectLoading: false, reloadItemsList: true});
  }

  finishListReloadHandler(status){
    this.setState({reloadItemsList: status});
  }

  render(){
    var view;
    if(this.state.isLoading === false){

      if(this.state.view === 'main'){
        view = <Calendar
          currentUser={this.props.currentUser}
          objectID={localStorage.getItem('objectID')}
          reload={this.state.reloadItemsList}
          afterReloadHandler={this.finishListReloadHandler}
          />
      }
    }
    else{
      view = <LoaderComponent />;
    }


    return(
      <div>
        <section className="content-header">
          <div className="row">
              <div className="col-md-8 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-violet"><i className="fas fa-clipboard-check"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-number">
                      <h3>{i18n.t('daily_log_module')}<br/><small>{i18n.t('daily_log_module_subtitle')}</small></h3>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                  <ObjectSelectComponent
                    objectSelectChangeHandler={this.objectSelectChangeHandler}
                    currentlyChoosen={localStorage.getItem('objectID')}
                    loading={this.state.objectSelectLoading}
                    />
              </div>

          </div>
        </section>

        <section className="content container-fluid">
          {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
          {view}
        </section>

      </div>
    );
  }

}
