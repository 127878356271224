import API from '../../Utils/api';

export function auth(username, password){
  return API.post('auth/post/login', {email: username, password: password});
}

export function register_step1(array){
  return API.post('auth/post/register-step-1', array);
}

export function register_step2(array){
  return API.post('auth/post/register-step-2', array);
}

export function register_step3(array){
  return API.post('auth/post/register-step-3', array);
}

export function register_step4(array){
  return API.post('auth/post/register-step-4', array);
}

export function remindPassword(username){
  return API.post('auth/post/remind-password', {email: username});
}

export function checkHash(array){
  return API.post('auth/post/check-hash', array);
}

export function restorePassword(array){
  return API.post('auth/post/restore-password', array);
}

export function checkAssingObjectRelationHash(array){
  return API.post('auth/post/check-assign-object-relation-hash', array);
}

export function checkInvitationFormParentHash(array){
  return API.post('auth/post/check-invitation-from-parent-hash', array);
}

export function acceptAggr(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('auth/post/privacy', array, {headers: { Authorization: AuthStr }});
}
