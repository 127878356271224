import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';


export default
class MsgComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      objectSelectLoading: false,
      reloadList: false,
      view: 'list',
      isLoading: false,
      status: '',
      msg: null,
      box: 'inbox',
      items: [],
      selectedChatID: 0,
      selectedChat: {items: []},
      responseContent: null,
      newMsgForm: false,
      people: [],
      user: [],
      title: '',
      content: '',
    }

    this.objectSelectChangeHandler = this.objectSelectChangeHandler.bind(this);
    this.getItemDetails = this.getItemDetails.bind(this);
    this.changeState = this.changeState.bind(this);
    this.submitResponse = this.submitResponse.bind(this);
    this.getChatPeople = this.getChatPeople.bind(this);
    this.showSendMsgForm = this.showSendMsgForm.bind(this);
    this.submitNewMsg = this.submitNewMsg.bind(this);
  }

  componentDidMount(){
    this.getChatList('inbox');
  }

  changeState(event, type){
    if(type === 'responseContent'){
      this.setState({responseContent: event.target.value});
    }
    if(type === 'title'){
      this.setState({title: event.target.value});
    }
    if(type === 'content'){
      this.setState({content: event.target.value});
    }
  }

  getChatPeople(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
    }

    let request = requests.getChatPeople(array);
    request.then(function(response){
      this.setState({people: response.data, status: '', msg: null});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  submitResponse(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
      'id' : this.state.selectedChatID,
      'content' : this.state.responseContent,
    }

    let request = requests.postChatResponse(array);
    request.then(function(response){
      this.setState({status: 'success', msg: response.data.msg}, () => {this.getItemDetails(this.props.lang)});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  submitNewMsg(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
      'title' : this.state.title,
      'content' : this.state.content,
      'user' : this.state.user.user.id,
    }

    let request = requests.postChatNewMsg(array);
    request.then(function(response){
      this.setState({status: 'success', msg: response.data.msg, box: 'sent'}, () => {this.getChatList('sent')});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  objectSelectChangeHandler(event){
    this.setState({objectSelectLoading: true});
    localStorage.setItem('objectID', event.target.value);
    this.setState({objectSelectLoading: false, reloadList: true});
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.reloadList == true && this.state.reloadList == false) {
      this.setState({box: 'inbox'}, () => {this.getChatList('inbox')})
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lang !== this.props.lang) {
        if(this.state.selectedChatID > 0){
          this.getItemDetails(nextProps.lang);
        }
    }
  }


  getItemDetails(lang){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
      'id' : this.state.selectedChatID,
      'lang' : lang,
    }

    let request = requests.getChatDetails(array);
    request.then(function(response){
      this.setState({selectedChat: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  getChatList(type){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
      'type' : type,
    }

    let request = requests.getChatList(array);
    request.then(function(response){
      this.setState({items: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  showSendMsgForm(user){
    this.setState({newMsgForm: true, user: user})
  }

  renderBoxView(){
    return(
      <div>
        {
          this.state.items.map(function(elem) {
            return(
              <div key={elem.id} onClick={(event) => this.setState({selectedChatID: elem.id, box: 'details'}, () => {this.getItemDetails(this.props.lang)})} className="media cursor">
                <div className="media-left">
                  <img src={elem.fromUser.image} className="img-circle img-md smallAvatar" />
                </div>
                <div className="media-body">
                  <h4 className="media-heading">{elem.title}
                    <br/><small><span class={elem.unread ? "label label-danger" : "label label-default"}>{elem.unread ? i18n.t('unread') : i18n.t('read')}</span></small><br/>
                    <br/><small>{i18n.t('sender')}: <b>{elem.fromUser.name} {elem.fromUser.surname}</b></small>
                    <br/><small>{i18n.t('cdate')}: <b>{elem.cdate}</b></small>
                    <br/><small>{i18n.t('items_count')}: <b>{elem.items}</b></small>
                  </h4>
                </div>
              </div>
            );
          }.bind(this))
        }
      </div>
    );
  }

  renderPeopleView(){
    return(
      <div>
        <h4>{i18n.t('select_person_to_send_msg')}</h4><hr/>
        {
          this.state.people.map(function(elem){
            return(
              <div>
                <div key={elem.id} onClick={(event) => this.showSendMsgForm(elem)} className="media cursor">
                  <div className="media-left">
                    <img src={elem.user.image} className="img-circle img-md smallAvatar" />
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">
                      {elem.user.name} {elem.user.surname}
                      <p>
                        <small>{i18n.t('account_type')}: <b>{i18n.t(elem.role)}</b></small>
                        <br/>
                        <small>{i18n.t('status')}: <span className={elem.user.onlineStatus ? "text-success" : "text-danger"}><strong>{elem.user.onlineStatus ? "Online" : "Offline"}</strong></span></small>
                        <br/>
                        <small>{i18n.t('kids_module')}: <b>{elem.kids}</b></small>
                    </p>
                    </h4>
                  </div>
                </div>
                <hr/>
              </div>
            );
          }.bind(this))
        }
      </div>
    )
  }

  renderNewMsgForm(){
    return(
      <form onSubmit={(event) => this.submitNewMsg(event)}>

        <h4>{i18n.t('new_msg')}:</h4><hr/>
        <div className="media">
          <div className="media-left">
            <img src={this.state.user.user.image} className="img-circle img-md smallAvatar" />
          </div>
          <div className="media-body">
            <h4 className="media-heading">{this.state.user.user.name} {this.state.user.user.surname}<br/><p><small>{i18n.t('account_type')}: <b>{i18n.t(this.state.user.role)}</b></small><br/><small>{i18n.t('status')}: <span className={this.state.user.user.onlineStatus ? "text-success" : "text-danger"}><strong>{this.state.user.user.onlineStatus ? "Online" : "Offline"}</strong></span></small></p></h4>
          </div>
        </div>

        <br/><br/>

        <div className="form-group">
          <label>{i18n.t('set_msg_title')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <input type="text" name="title" className="form-control" value={this.state.title} onChange={(event) => this.changeState(event, 'title')} required />
        </div>

        <div className="form-group">
          <label>{i18n.t('set_msg_content')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <textarea name="" cols="" rows="5" className="form-control" value={this.state.content} onChange={(event) => this.changeState(event, 'content')} required />
        </div>

        <hr/>

        <button className="btn btn-success" type="submit">{i18n.t('button_send')}</button>
        <button className="btn btn-default" onClick={(event) => this.setState({newMsgForm: false})} type="button">{i18n.t('button_cancel')}</button>



      </form>
    )
  }

  renderCreateView(){
    var view = helperFunctions.renderNoContentInfo();
    if(this.state.people !== undefined && this.state.people.length > 0){
      view = this.renderPeopleView();
    }
    if(this.state.newMsgForm === true){
      view = this.renderNewMsgForm()
    }
    return (
      <div>
        {view}
      </div>
    );
  }

  renderResponseButton(){
    return(<div><button className="btn btn-default" onClick={(event) => this.setState({responseForm: true})}>{i18n.t('add_response')}</button><hr/></div>)
  }

  renderResponseForm(){
    return(
      <form onSubmit={(event) => this.submitResponse(event)}>

        <div className="form-group">
          <label>{i18n.t('set_msg_content')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <textarea onChange={(event) => this.changeState(event, 'responseContent')} name="content" cols="" rows="5" className="form-control"></textarea>
        </div>

        <button className="btn btn-success" type="submit">{i18n.t('button_send')}</button>
        <button className="btn btn-default" onClick={(event) => this.setState({responseForm: false})} type="button">{i18n.t('button_cancel')}</button>

        <hr/>

      </form>
    );
  }

  renderDetailsView(){
    var responseView = this.renderResponseButton();
    if(this.state.responseForm === true){
      responseView = this.renderResponseForm()
    }
    return(
      <div>
        <div className="info-box">
            <span className="info-box-icon bg-orange"><i className="fas fa-envelope-open-text"></i></span>

            <div className="info-box-content">
              <span className="info-box-text">{this.state.selectedChat.title}</span>
              <span className="info-box-number">
                <small>{i18n.t('cdate')}: <b>{this.state.selectedChat.cdate}</b></small>
              </span>
            </div>

          </div>

          {responseView}

          {
            this.state.selectedChat.items.map(function(item){
              return(
                <div>
                  <div class="media">
                    <div class="media-left">
                      <img class="media-object img-circle img-md smallAvatar" src={item.user.image} alt="..." />
                    </div>
                    <div class="media-body">
                      <h4 class="media-heading"><b>{item.cdate}</b> {i18n.t('user')} <b>{item.user.name} {item.user.surname}</b> {i18n.t('wrote')}:</h4>
                      <div dangerouslySetInnerHTML={{__html: item.msg}} />
                    </div>
                  </div>
                  <hr/>
                </div>
              )
            }.bind(this))
          }

      </div>
    );
  }

  renderMainView(){
    var view;
    if(this.state.box === 'create'){
      view = this.renderCreateView();
    }
    else if(this.state.box === 'details'){
      view = this.renderDetailsView();
    }
    else{
      view = helperFunctions.renderNoContentInfo();
      if(this.state.items !== undefined && this.state.items.length > 0){
        view = this.renderBoxView();
      }
    }
    return(
      <div className="nav-tabs-custom">
        <ul className="nav nav-tabs">
          <li className={this.state.box === 'inbox' ? "active" : null}><a href="#tab" onClick={(event) => this.setState({box: 'inbox', status: '', msg: null}, () => {this.getChatList('inbox')})} data-toggle="tab">{i18n.t('inbox')}</a></li>
          <li className={this.state.box === 'create' ? "active" : null}><a href="#tab" onClick={(event) => this.setState({box: 'create', newMsgForm: false, user: [], status: '', msg: null}, () => {this.getChatPeople()})} data-toggle="tab">{i18n.t('new_msg')}</a></li>
        </ul>
        <div className="tab-content">
          <div className="tab-pane active" id="tab">
            {view}
          </div>
        </div>
      </div>


    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
        view = this.renderMainView();
    }
    else{
      view = <LoaderComponent />;
    }


    return(
      <div>

        <section className="content-header">
          <div className="row">
              <div className="col-md-8 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-orange"><i className="fas fa-envelope"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-number">
                      <h3>{i18n.t('msg_module')}<br/><small>{i18n.t('msg_module_subtitle')}</small></h3>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                  <ObjectSelectComponent
                    objectSelectChangeHandler={this.objectSelectChangeHandler}
                    currentlyChoosen={localStorage.getItem('objectID')}
                    loading={this.state.objectSelectLoading}
                    />
              </div>

          </div>
        </section>

        <section className="content container-fluid">
          {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
          <div className="nav-tabs-custom"><div className="box-body">{view}</div></div>
        </section>

      </div>
    );
  }

}
