import React, {Component} from 'react';

import KidInfoComponent from '../KidInfoComponent';
import KidParentsComponent from '../KidParentsComponent';
import KidPhotosComponent from '../KidPhotosComponent';
import KidTransferComponent from '../KidTransferComponent';
import KidAvatarComponent from '../KidAvatarComponent';

import i18n from "i18next";

export default
class InitialComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'kidInfo'
    }
  }

  getView(){
    var view;
    if(this.state.view === 'kidInfo'){
      view = <KidInfoComponent kidID={this.props.kidID}/>
    }
    if(this.state.view === 'kidParents'){
      view = <KidParentsComponent kidID={this.props.kidID}/>
    }
    if(this.state.view === 'kidPhotos'){
      view = <KidPhotosComponent kidID={this.props.kidID}/>
    }
    if(this.state.view === 'kidTransfer'){
      view = <KidTransferComponent kidID={this.props.kidID}/>
    }
    if(this.state.view === 'kidAvatar'){
      view = <KidAvatarComponent kidID={this.props.kidID}/>
    }
    return(<div>{view}</div>);
  }

  render(){
    var view = this.getView();
    return(
      <div class="nav-tabs-custom">
        <ul class="nav nav-tabs">
          <li className={this.state.view === 'kidInfo' ? "active" : null}><a onClick={(event) => this.setState({view: 'kidInfo'})} href="#tab" data-toggle="tab">{i18n.t('main_info')}</a></li>
          <li className={this.state.view === 'kidAvatar' ? "active" : null}><a onClick={(event) => this.setState({view: 'kidAvatar'})} href="#tab" data-toggle="tab">{i18n.t('avatar')}</a></li>
          <li className={this.state.view === 'kidParents' ? "active" : null}><a onClick={(event) => this.setState({view: 'kidParents'})} href="#tab" data-toggle="tab">{i18n.t('parents_and_caregivers')}</a></li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active" id="tab">
            {view}
          </div>

        </div>
      </div>
    )
  }

}
