import React, {Component} from 'react'
import i18n from "i18next";

class FooterComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      date: new Date().getFullYear()
    }
  }

  render(){
    return(
      <footer className="main-footer">

          <div className="pull-right hidden-xs">
              {i18n.t('software_version')}: <strong>1.0</strong>
          </div>

          <strong>Copyright &copy; {this.state.date} <a href="https://klett.pl" target="_blank">Klett.pl</a>.</strong> {i18n.t('all_rights_reserved')}
      </footer>
    );
  }

}

export default FooterComponent;
