import React, {Component} from 'react';

import LoaderComponent from '../../../../Utils/LoaderComponent';
import AttachmentsComponent from '../../../../Utils/AttachmentsComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import * as requests from '../requests.js';
import * as moment from "moment";
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';

export default
class NewsComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      items: [],
      isLoading: false,
      status: '',
      msg: null,
      view: 'list',
      fromDate: null,
      toDate: null,
    }
  }

  componentDidUpdate(nextProps){
    if(nextProps.reload !== this.props.reload){
      this.props.afterReloadHandler(false);
      this.getNewsList();
      return true;
    }
    return false;
  }

  componentDidMount(){
    this.setState(
      {
        fromDate: localStorage.getItem('lastLoginTime'),
        toDate: new Date(moment().format('YYYY-MM-DD'))
      }, () => {this.getNewsList()}
    )

  }

  getNewsList(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
    }

    let request = requests.getNews(array);
    request.then(function(response){
      this.setState({items: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({items: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderEventItem(elem){
    var attachments;
    if(elem.attachments.length > 0){
      attachments = <AttachmentsComponent view={"front"} items={elem.attachments} title={i18n.t('attachments_list')} />
    }
    return(
      <div>
      <h4 className="media-heading">
        <small>{moment(elem.cdate).format("YYYY-MM-DD")}, {i18n.t('groups_module')}, {i18n.t('upcoming_events')}</small><br/>
        {elem.title}
        <br/><small>{i18n.t('status')}: <b>{i18n.t('event_'+elem.status)}</b></small>
        <br/><small>{i18n.t('start_date')}: <b>{elem.dates.start}</b>. {i18n.t('finish_date')}: <b>{elem.dates.finish}</b></small>
      </h4>
      <br/>
      <p><strong>{i18n.t('event_place')}</strong><br/>{elem.place.name}<br/>{elem.place.address}</p><br/>
      <div><strong>{i18n.t('content')}</strong><br/><div dangerouslySetInnerHTML={{__html: elem.content}} /></div>

      {attachments}

      {elem.status === 'cancelled' ? <span><br/><p className="text-danger"><strong>{i18n.t('event_cancel_reason')}</strong><br/>{elem.cancelled_reason}</p></span> : null}
      </div>
    )
  }

  renderSurveyItem(elem){
    return(
      <div>
        <h4 className="media-heading">
          <small>{moment(elem.cdate).format("YYYY-MM-DD")}, {i18n.t('survey_module')}</small><br/>
          {elem.title}
          <br/><small>{i18n.t('object')}: <b>{elem.school}</b></small>
          <br/><small>{i18n.t('for_group')}: <b>{elem.for_all_groups ? i18n.t('all_groups') : elem.group}</b>, {i18n.t('questions_count')}: <b>{elem.questions}</b></small>
          <br/><small>{i18n.t('finish_date')}: <b>{elem.finish_date}</b></small>
          <br/><small>{i18n.t('cdate')}: <b>{elem.cdate}</b></small>
        </h4>
        <br/><div><strong>{i18n.t('content')}</strong><br/><div dangerouslySetInnerHTML={{__html: elem.content}} /></div>
      </div>
    )
  }

  renderGroupDashboardItem(elem){
    var attachments;
    if(elem.attachments.length > 0){
      attachments = <AttachmentsComponent view={"front"} items={elem.attachments} title={i18n.t('attachments_list')} />
    }
    return(
      <div>
        <h4 className="media-heading">
          <small>{moment(elem.cdate).format("YYYY-MM-DD")}, {i18n.t('groups_module')}, {i18n.t('group_dashboard')}</small><br/>
          {elem.title}
          <br/><small>{i18n.t('for_all_groups')}: <b>{elem.for_all_groups ? i18n.t('yes') : i18n.t('no')}</b></small>
          <br/><small>{i18n.t('cdate')}: <b>{elem.cdate}</b></small>
        </h4>
      <br/>

      <div><strong>{i18n.t('content')}</strong><br/><div dangerouslySetInnerHTML={{__html: elem.content}} /></div>

      {attachments}
      </div>
    )
  }

  renderProfileMessageItem(elem){
    var attachments;
    if(elem.attachments.length > 0){
      attachments = <AttachmentsComponent view={"front"} items={elem.attachments} title={i18n.t('attachments_list')} />
    }
    return(
      <div>
        <h4 className="media-heading">
          <small>{moment(elem.cdate).format("YYYY-MM-DD")}, {i18n.t('groups_module')}, {i18n.t('profile_messages')}</small><br/>
          {elem.title}
        </h4>
      <br/>

      <p>{elem.content}...</p>

      {attachments}

      </div>
    )
  }

  renderMainView(){
    return (
      <div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <ul className="list-unstyled">
              {
                this.state.items.map(function(elem){
                  var module;
                  if(elem.module === 'events'){
                    module = this.renderEventItem(elem);
                  }
                  else if(elem.module === 'survey'){
                    module = this.renderSurveyItem(elem);
                  }
                  else if(elem.module === 'group_dashboard'){
                    module = this.renderGroupDashboardItem(elem);
                  }
                  else if(elem.module === 'profile_messages'){
                    module = this.renderProfileMessageItem(elem);
                  }
                  return(
                    <li key={elem.key}>
                      <div className="media">
                        <div className="media-left">
                          <span className={"info-box-icon " + elem.icon.class}><i className={elem.icon.fontawesome}></i></span>
                        </div>
                        <div className="media-body">
                          {module}
                        </div>
                      </div>

                      <hr/><br/>

                    </li>
                  )
                }.bind(this))
              }
            </ul>
          </div>
        </div>
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.items !== undefined && this.state.items.length > 0){
        view = this.renderMainView();
      }
      else{
        view = helperFunctions.renderNoContentInfo();
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
        {view}
      </div>
    );
  }

}
