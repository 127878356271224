import API from '../../../Utils/api';

export function getUser(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('auth/get/me', {params: array, headers: { Authorization: AuthStr }});
}

export function getNavigation(){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('modules/get/navigation', {headers: { Authorization: AuthStr }});
}

export function getMyObjectsList(){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('me/get/objects', {headers: { Authorization: AuthStr }});
}

export function getObjectGroupsList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/groups', {params: array, headers: { Authorization: AuthStr }});
}

export function postChangeSystemLang(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('me/post/lang', array, {headers: { Authorization: AuthStr }});
}

export function getSystemLangs(){
  return API.get('system/get/languages');
}
