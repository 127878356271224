import API from '../../../../Utils/api';

export function getGroupsCalendar(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('group/get/daily-log', {params: array, headers: { Authorization: AuthStr }});
}

export function getGroupsCalendarDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('group/get/daily-log/details', {params: array, headers: { Authorization: AuthStr }});
}

export function postGroupDailyLogAccept(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/post/daily-log/accept', array, {headers: { Authorization: AuthStr }});
}

export function postGroupDailyLogStore(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/post/daily-log/store', array, {headers: { Authorization: AuthStr }});
}

export function getGroupActvityLogUnusedCards(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('group/get/daily-log/unused-cards', {params: array, headers: { Authorization: AuthStr }});
}

export function postGroupDailyLogUnusedCard(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/post/daily-log/unused-card', array, {headers: { Authorization: AuthStr }});
}
