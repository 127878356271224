import React, {Component} from 'react';

import PersonalDataComponent from '../PersonalDataComponent';
import AccessDataComponent from '../AccessDataComponent';
import MobileDataComponent from '../MobileDataComponent';
import AvatarComponent from '../AvatarComponent';
import InfoComponent from '../InfoComponent';
import i18n from "i18next";

class ProfileComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
        currentModule: 'PersonalDataComponent',
        currentModuleParams: [],
    }

    this.onClickModuleLoaderHandler = this.onClickModuleLoaderHandler.bind(this);
  }

  onClickModuleLoaderHandler(event, module, params = []){
    this.setState({currentModule: module, currentModuleParams: params});
  }

  chooseModuleHandler(){
    var module = null;
    switch (this.state.currentModule) {
      case 'PersonalDataComponent':
        module = PersonalDataComponent;
        break;

      case 'AccessDataComponent':
        module = AccessDataComponent;
        break;

      case 'MobileDataComponent':
        module = MobileDataComponent;
        break;

      case 'AvatarComponent':
        module = AvatarComponent;
        break;

      case 'InfoComponent':
        module = InfoComponent;
        break;
    }

    return module;
  }

  render(){
    const Module = this.chooseModuleHandler();
    return(
      <div>

      <section className="content-header">
        <div className="info-box">
          <span className="info-box-icon bg-violet"><i className="fas fa-user"></i></span>

          <div className="info-box-content">
          <span className="info-box-number">
            <h3>{i18n.t('profile_module')}<br/><small>{i18n.t('profile_module_subtitle')}</small></h3>
          </span>
          </div>
        </div>
      </section>

      <section className="content container-fluid">

        <div className="nav-tabs-custom">
            <ul className="nav nav-tabs">
              <li className="active"><a href="#tab" onClick={(event) => this.onClickModuleLoaderHandler(event, 'PersonalDataComponent')} data-toggle="tab" aria-expanded="false"><i className="far fa-user"></i> {i18n.t('personal_info')}</a></li>
              <li className=""><a href="#tab" onClick={(event) => this.onClickModuleLoaderHandler(event, 'InfoComponent')} data-toggle="tab" aria-expanded="false"><i className="far fa-user"></i> {i18n.t('address_data')}</a></li>
              <li className=""><a href="#tab" onClick={(event) => this.onClickModuleLoaderHandler(event, 'AvatarComponent')} data-toggle="tab" aria-expanded="false"><i className="fas fa-portrait"></i> {i18n.t('avatar')}</a></li>
              <li className=""><a href="#tab" onClick={(event) => this.onClickModuleLoaderHandler(event, 'AccessDataComponent')} data-toggle="tab" aria-expanded="false"><i className="fas fa-key"></i> {i18n.t('access_info')}</a></li>
              <li className=""><a href="#tab" onClick={(event) => this.onClickModuleLoaderHandler(event, 'MobileDataComponent')} data-toggle="tab" aria-expanded="false"><i className="fas fa-mobile-alt"></i> {i18n.t('mobile_devices')}</a></li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active" id="tab">

                <Module params={this.state.currentModuleParams}/>

              </div>

            </div>

          </div>


      </section>

      </div>
    );
  }

}

export default ProfileComponent;
