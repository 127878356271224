import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';

import OtherApplicationsComponent from '../OtherApplicationsComponent';
import InvitationsFromParentsComponent from '../InvitationsFromParentsComponent';



export default
class ApplicationsComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'applications',
      objectSelectLoading: false,
      reloadList: false,
    }
    this.objectSelectChangeHandler = this.objectSelectChangeHandler.bind(this);
    this.changeView = this.changeView.bind(this)
  }

  objectSelectChangeHandler(event){
    this.setState({objectSelectLoading: true});
    localStorage.setItem('objectID', event.target.value);
    this.setState({objectSelectLoading: false, reloadList: true});
    this.checkAdminAccess();
  }

  componentDidMount(){
    this.checkAdminAccess()
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.reloadList == true && this.state.reloadList == false) {
      this.setState({view: 'applications'})
    }
  }

  checkAdminAccess(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.checkAdminAccess(array);
    request.then(function(response){

    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  changeView(view, id){
    this.setState({view: view});
  }

  setAccessDenied(){
    this.setState({view: 'access_denied'});
  }

  getSubComponentView(){
    var view = null;
    switch (this.state.view) {
      case 'applications':
        view = <OtherApplicationsComponent changeView={this.changeView} reloadList={this.state.reloadList}/>
        break;

      case 'invitations':
        view = <InvitationsFromParentsComponent changeView={this.changeView} reloadList={this.state.reloadList}/>
        break;

    }
    return (<div>{view}</div>);
  }

  renderAccessDeniedView(){
    return(<div>Zabroniony</div>);
  }

  getMainView(){
    var view = this.getSubComponentView();
    return(
      <div>

        <section className="content-header">
          <div className="row">
              <div className="col-md-8 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-red"><i className="fas fa-bell"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-number">
                      <h3>{i18n.t('applications_module')}<br/><small>{i18n.t('applications_module_subtitle')}</small></h3>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                  <ObjectSelectComponent
                    objectSelectChangeHandler={this.objectSelectChangeHandler}
                    currentlyChoosen={localStorage.getItem('objectID')}
                    loading={this.state.objectSelectLoading}
                    />
              </div>

          </div>
        </section>

        <section className="content container-fluid">
          <div className="box box-solid">
            <div className="box-body">

                <div className="row">
                  <div className="col-md-3 col-sm-4 col-xs-6">
                    <div className="box box-solid">
                      <div className="box-body no-padding">
                        <ul className="nav nav-pills nav-stacked">
                          <li className={this.state.view === 'applications' ? "active" : null}><a onClick={(event) => this.setState({view: 'applications'})} href="#">{i18n.t('applications_from_user_submenu_1')}</a></li>
                          <li className={this.state.view === 'invitations' ? "active" : null}><a onClick={(event) => this.setState({view: 'invitations'})} href="#">{i18n.t('applications_from_user_submenu_2')}</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9 col-sm-8 col-xs-12">{view}</div>
                </div>

            </div>
          </div>
        </section>

      </div>
    );
  }


  render(){
    var view;
    if(this.state.view === 'access_denied'){
      view = this.renderAccessDeniedView()
    }
    else{
      view = this.getMainView();
    }

    return(
      <div>

        {view}

      </div>
    );
  }

}
