import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import CkEditorComponent from '../../../../../Utils/CkEditorComponent';
import AttachmentsComponent from '../../../../../Utils/AttachmentsComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import Select from 'react-select';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

export default
class ObjectSurveyComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      isLoading: false,
      items: [],
      seletedRange: "1",
      id: null,
      title: '',
      content: '',
      finished: 0,
      groups: [],
      selectedGroup: [],
      status: '',
      msg: null,
      view: 'list',
      fromDate: null,
      toDate: null,
      questions: [],
      question: [],
      questionID: null,
      subView: 'edit',
      answers: [],
      public: 0,
    }

    this.setDate = this.setDate.bind(this);
    this.onChangeContentHandler = this.onChangeContentHandler.bind(this);
    this.onChangeTitleHandler = this.onChangeTitleHandler.bind(this);
    this.changeSelectedRange = this.changeSelectedRange.bind(this);
    this.handleSelectedGroupChange = this.handleSelectedGroupChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getItemDetails = this.getItemDetails.bind(this);
    this.changFinishStatus = this.changFinishStatus.bind(this);
    this.changePublicStatus = this.changePublicStatus.bind(this);
    this.loadQuestions = this.loadQuestions.bind(this);
    this.submitQuestionForm = this.submitQuestionForm.bind(this);
    this.setQuestionContent = this.setQuestionContent.bind(this);
    this.submitQuestionDeleteForm = this.submitQuestionDeleteForm.bind(this);
    this.loadAnswers = this.loadAnswers.bind(this);
    this.deleteSurveyForm = this.deleteSurveyForm.bind(this);
  }

  componentWillMount(){
    this.getSchoolGroupDashboard();
  }

  setDate(date, type){
    if(type === 'fromDate'){
      this.setState({fromDate : date}, () => {this.getSchoolGroupDashboard()});
    }
    else{
      this.setState({toDate : date}, () => {this.getSchoolGroupDashboard()});
    }
  }

  changFinishStatus(event){
    this.setState({finished: event.target.value});
  }

  changePublicStatus(event){
    this.setState({public: event.target.value});
  }

  changeSelectedRange(event){
    this.setState({seletedRange: event.target.value, groups: []});
    if(event.target.value === "0"){
      this.getAllGroups();
    }
  }

  onChangeContentHandler(event, editor, data){
    this.setState({content: data });
  }

  onChangeTitleHandler(event){
    this.setState({title: event.target.value});
  }

  handleSelectedGroupChange(groups){
    this.setState({selectedGroup: groups});
  }

  setQuestionContent(event){
    this.setState({question: {content: event.target.value}});
  }

  getAllGroups(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getSchoolGroupsMultiselect(array);
    request.then(function(response){
      this.setState(
        {
          groups: response.data,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getSchoolGroupDashboard(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'fromDate' : this.state.fromDate,
      'toDate' : this.state.toDate
    }

    let request = requests.getSchoolSurveyList(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data.items,
          fromDate : new Date(moment(response.data.fromDate, 'YYYY-MM-DD')),
          toDate : new Date(moment(response.data.toDate, 'YYYY-MM-DD')),
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'title' : this.state.title,
      'content' : this.state.content,
      'for_all_groups' : this.state.seletedRange,
      'groups': this.state.selectedGroup,
      'survey_finished' : this.state.finished,
      'id'  : this.state.id,
      'public': this.state.public,
    }

    let request = requests.postSchoolSurveyUpdate(array);
    request.then(function(response){
      this.setState({view: 'edit',items: [], status: 'success', msg: i18n.t(response.data.msg), groups: [], selectedGroup:[], seletedRange: "1"}, () => {this.getItemDetails(response.data.id)});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitQuestionForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'content' : this.state.question.content,
      'parent_id'  : this.state.id,
      'id'    : this.state.questionID,
    }

    let request = requests.postSchoolSurveyQuestionUpdate(array);
    request.then(function(response){
      this.setState({subView: 'questions', status: 'success', msg: i18n.t(response.data.msg)}, () => {this.loadQuestions()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitQuestionDeleteForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'parent_id'  : this.state.id,
      'id'    : this.state.questionID,
    }

    let request = requests.deleteSchoolSurveyQuestion(array);
    request.then(function(response){
      this.setState({subView: 'questions', status: 'success', msg: i18n.t(response.data.msg)}, () => {this.loadQuestions()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getItemDetails(itemID){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : itemID,
    }

    let request = requests.getSchoolSurveyDetails(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data,
          id: response.data.id,
          title: response.data.title,
          content: response.data.content,
          finished: response.data.finished ? "1" : "0",
          finish_date: response.data.finishDate,
          seletedRange: response.data.for_all_groups ? "1" : "0",
          selectedGroup: response.data.groups,
          public: response.data.public,
        }, () => {this.getAllGroups()}
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  loadQuestions(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.state.id,
    }

    let request = requests.getSchoolSurveyQuestions(array);
    request.then(function(response){
      this.setState(
        {
          questions: response.data,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  loadAnswers(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.state.id,
    }

    let request = requests.getSchoolSurveyAnswers(array);
    request.then(function(response){
      this.setState(
        {
          answers: response.data,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  deleteSurveyForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.state.id,
    }

    let request = requests.deleteSchoolSurvey(array);
    request.then(function(response){
      this.setState({view: 'list', subView: 'edit', items: [], status: 'success', msg: response.data.msg}, () => {this.getSchoolGroupDashboard()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderListView(){
    return(
      <div>
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-12">

            <div className="form-group">
              <label>{i18n.t('select_start_date')}</label>
              <DatePicker
                  selected={this.state.fromDate}
                  onSelect={(value) => this.setDate(value, 'fromDate')}
                  filterDate={this.isWeekday}
                  todayButton={i18n.t('set_current_date')}
                  shouldCloseOnSelect={false}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
              />
            </div>

          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">

            <div className="form-group">
              <label>{i18n.t('select_finish_date')}</label>
              <DatePicker
                  selected={this.state.toDate}
                  onSelect={(value) => this.setDate(value, 'toDate')}
                  todayButton={i18n.t('set_current_date')}
                  filterDate={this.isWeekday}
                  shouldCloseOnSelect={false}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
              />
            </div>

          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">

            <div className="form-group">
              <label>{i18n.t('add_new_item')}</label>
              <button onClick={(event) => this.setState(
                  {
                    view: 'create',
                    status: '',
                    msg: null,
                    title: '',
                    content: '',
                    seletedRange: "1",
                    selectedGroup: [],
                    id: null,
                    public: 0,
                  }
                )} className="btn btn-default"><i className="fas fa-plus-circle"></i> {i18n.t('add_new_item')}</button>
            </div>

          </div>
        </div>

        <hr/>
        <ul className="list-unstyled">
          {
            this.state.items.map(function(elem){
              return(
                <li key={elem.id}>
                  <div onClick={(event) => this.setState({view: 'edit', status: '', msg: null, groups: [], selectedGroup:[], seletedRange: "1"}, () => {this.getItemDetails(elem.id)})} className="media cursor">
                    <div className="media-left">
                      <span className="info-box-icon bg-red"><i class="fas fa-poll"></i></span>
                    </div>
                    <div className="media-body">
                      <h4 className="media-heading">{elem.title}
                        <br/><small>{i18n.t('for_all_groups')}: <b>{elem.for_all_groups ? i18n.t('yes') : i18n.t('no')}</b>, {i18n.t('cdate')}: <b>{elem.cdate}</b></small>
                        <br/><small>{i18n.t('questions_count')}: <b>{elem.questions}</b>, status: <b>{elem.finished ? i18n.t('vote_inactive') : i18n.t('vote_active')}</b></small>
                      </h4>
                      <p>{elem.content}</p>
                    </div>
                  </div>
                  <hr/>
                </li>
              )
            }.bind(this))
          }
        </ul>
      </div>

    );
  }

  renderGroupsSelect(){
    return(
      <div className="form-group">
        <label>{i18n.t('select_group_this_event_applies')}</label>
        {
          <Select
            value={this.state.selectedGroup}
            onChange={this.handleSelectedGroupChange}
            options={this.state.groups}
            isMulti={true}
            placeholder={i18n.t('select_group_this_event_applies')}
          />
        }
      </div>
    );
  }


  renderFormView(){
    var groups = null;
    var changeStatus = null;
    if(this.state.seletedRange === "0"){
      groups = this.renderGroupsSelect();
    }

    return(
      <form onSubmit={(event) => this.submitForm(event)}>
        <div className="form-group">
          <label>{i18n.t('set_item_title')}</label>
          <input type="text" name="title" className="form-control" value={this.state.title} onChange={(event) => this.onChangeTitleHandler(event)} required/>
        </div>


        {<CkEditorComponent label={i18n.t('set_item_content')} content={this.state.content} onChangeContentHandler={this.onChangeContentHandler}/>}

        <div className="form-group">
          <label>{i18n.t('set_event_area')}</label>
          <select className="form-control" name="range" onChange={(event) => this.changeSelectedRange(event)} value={this.state.seletedRange}>
            <option value="1">{i18n.t('all_groups')}</option>
            <option value="0">{i18n.t('select_group_this_event_applies')}</option>
          </select>
        </div>

        {groups}

        <div  className="form-group">
          <label>{i18n.t('change_vote_status')}</label>
          <select name="finished" value={this.state.finished} onChange={(event) => this.changFinishStatus(event)} className="form-control">
            <option value="1">{i18n.t('vote_inactive')}</option>
            <option value="0">{i18n.t('vote_active')}</option>
          </select>
        </div>

        <div  className="form-group">
          <label>{i18n.t('survey_is_public')}</label>
          <select name="public" value={this.state.public} onChange={(event) => this.changePublicStatus(event)} className="form-control">
            <option value="1">{i18n.t('yes')}</option>
            <option value="0">{i18n.t('no')}</option>
          </select>
        </div>

        <hr/>
        <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
        <button type="button" onClick={(event) => this.setState({view: 'list', items: []}, () => {this.getSchoolGroupDashboard()})} className="btn btn-danger">{i18n.t('button_cancel')}</button>
      </form>
    );
  }

  renderQuestionsList(){
    if(this.state.questions.length){
      return(
        <div>
        <button onClick={(event) => this.setState({subView: 'questionManage', question: {content: ''}, questionID: null})} className="btn btn-default">{i18n.t('add_new_item')}</button><hr/>
        <table className="table table-border table-hover">
          <thead>
            <tr>
              <th>{i18n.t('lp')}</th>
              <th>{i18n.t('question_content')}</th>
              <th>{i18n.t('options')}</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.questions.map(function(item){
                return(
                  <tr key={item.id}>
                    <td>{item.lp}</td>
                    <td>{item.content}</td>
                    <td>
                    <div className="btn-group" role="group">
                      <button onClick={(event) => this.setState({subView: 'questionManage', question: item, questionID: item.id})} type="button" className="btn btn-xs btn-default">{i18n.t('button_edit')}</button>
                      <button onClick={(event) => this.setState({subView: 'questionDelete', question: item, questionID: item.id})} type="button" className="btn btn-xs btn-danger">{i18n.t('button_delete')}</button>
                    </div>
                    </td>
                  </tr>
                );
              }.bind(this))
            }
          </tbody>
        </table>

        </div>
      );
    }
    else{
      return(
        <div>
        <button onClick={(event) => this.setState({subView: 'questionManage', question: {content: ''}, questionID: null})} className="btn btn-default">{i18n.t('add_new_item')}</button><hr/>
        {helperFunctions.renderNoContentInfo()}
        </div>
      )
    }
  }

  renderQuestionManage(){
    return(
      <form onSubmit={(event) => this.submitQuestionForm(event)}>
        <div className="form-group">
          <label>{i18n.t('set_question_content')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <input type="text" name="content" value={this.state.question.content} onChange={(event) => this.setQuestionContent(event)} required className="form-control" />
        </div>

        <hr/>
        <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
        <button type="button" onClick={(event) => this.setState({subView: 'questions', question: {content: ''}})} className="btn btn-default">{i18n.t('button_cancel')}</button>
      </form>
    );
  }

  renderQuestionDelete(){
    return(
      <form onSubmit={(event) => this.submitQuestionDeleteForm(event)}>
        <p>{i18n.t('confirm_delete_action')}</p>

        <hr/>
        <button type="submit" className="btn btn-danger">{i18n.t('button_delete')}</button>
        <button type="button" onClick={(event) => this.setState({subView: 'questions', question: {content: ''}})} className="btn btn-default">{i18n.t('button_cancel')}</button>
      </form>
    );
  }

  renderAnswersChart(){
    return(
      <div style={{ width: '100%', height: 500 }}><br/><br/>
      <ResponsiveContainer>
        <BarChart
          data={this.state.answers}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="content" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey={i18n.t('yes')} fill="#00c0ef" />
          <Bar dataKey={i18n.t('no')} fill="#dd4b39" />
        </BarChart>
      </ResponsiveContainer>
      </div>
    )
  }

  renderDeleteSurveyView(){
    return(
      <form onSubmit={(event) => this.deleteSurveyForm(event)}>
      <p>{i18n.t('confirm_delete_action')}</p>

      <hr/>
      <button type="submit" className="btn btn-danger">{i18n.t('button_delete')}</button>

      </form>
    );
  }

  renderEditView(){
    var subView = this.renderFormView();
    if(this.state.subView === 'questions'){
      subView = this.renderQuestionsList()
    }
    else if (this.state.subView === 'questionManage') {
      subView = this.renderQuestionManage()
    }
    else if (this.state.subView === 'questionDelete') {
      subView = this.renderQuestionDelete()
    }
    else if (this.state.subView === 'questionChart') {
      subView = this.renderAnswersChart()
    }
    else if (this.state.subView === 'deleteSurvey') {
      subView = this.renderDeleteSurveyView()
    }
    return(
      <div>
        <div className="nav-tabs-custom">
          <ul className="nav nav-tabs">
            <li><a href="#tab_2" onClick={(event) => this.setState({view: 'list', subView: 'edit', items: [], status: '', msg: null}, () => {this.getSchoolGroupDashboard()})} data-toggle="tab">{i18n.t('go_back_to_list')}</a></li>
            <li className={this.state.subView === 'edit' ? "active" : null}><a href="#tab" onClick={(event) => this.setState({subView: 'edit'})} data-toggle="tab">{i18n.t('main_info')}</a></li>
            <li className={this.state.subView === 'questions' ? "active" : null}><a href="#tab" data-toggle="tab" onClick={(event) => this.setState({subView: 'questions'}, () => {this.loadQuestions()})}>{i18n.t('questions_list')}</a></li>
            <li className={this.state.subView === 'questionChart' ? "active" : null}><a href="#tab" data-toggle="tab" onClick={(event) => this.setState({subView: 'questionChart'}, () => {this.loadAnswers()})}>{i18n.t('survey_results')}</a></li>
            <li className={this.state.subView === 'surveyDelete' ? "active" : null}><a href="#tab" data-toggle="tab" onClick={(event) => this.setState({subView: 'deleteSurvey'})}>{i18n.t('button_delete')}</a></li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active" id="tab_1">
              {subView}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView()
      }
      else if(this.state.view === 'create'){
        view = this.renderFormView()
      }
      else if(this.state.view === 'edit'){
        view = this.renderEditView()
      }
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
