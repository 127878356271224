import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';


export default
class OtherApplicationsComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      reloadList: false,
      view: 'list',
      currentListType: 'active',
      items: [],
      isLoading: false,
      status: '',
      msg: null,
      objectID: 0,
      currentItem: [],
    }


      this.submitApplication = this.submitApplication.bind(this);
  }

  componentDidMount(){
    this.getObjectApplicationsList(localStorage.getItem('objectID'));
  }

  componentWillUpdate(nextProps){
    if(nextProps.reloadList !== this.props.reloadList){
      this.getObjectApplicationsList(localStorage.getItem('objectID'));
    }
  }

  submitApplication(id, action){
    this.setState({isLoading: true});

    var array = {
      'objectID' : localStorage.getItem('objectID'),
      'id' : id,
      'action' : action
    }

    let request = requests.postObjectApplicationsExecute(array);
    request.then(function(response){
      this.setState({view: 'list'}, () => {this.getObjectApplicationsList(localStorage.getItem('objectID'))});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  getObjectApplicationsList(objectID){
    this.setState({isLoading: true});

    var array = {
      'objectID' : objectID,
    }

    let request = requests.getObjectApplicationsList(array);
    request.then(function(response){
      this.setState({items: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      this.props.reloadList = false;
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  renderListView(){
    return(
      <div>
        {
          this.state.items.map(function(item){
            return(
              <div key={item.id}>
                <div className="media cursor" onClick={(event) => this.setState({view: 'details', currentItem: item})}>
                  <div className="media-left">
                    <span className={item.data.icon.color}><i className={item.data.icon.icon}></i></span>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">{i18n.t(item.data.module)}</h4>
                    <p>{i18n.t('cdate')}: <strong>{item.cdate}</strong>. {i18n.t('who_reports')}: <strong>{item.author}</strong></p>
                    <p>{i18n.t('application_type')}: <strong>{i18n.t(item.type)}</strong></p>
                  </div>
                </div>
                <hr/>
              </div>
            );
          }.bind(this))
        }

      </div>

    );
  }

  renderDailyLogContent(data){
    return(
      <div>
          <ul className="list-unstyled">
            <li>{i18n.t('kid')}: <strong>{data.kid.name} {data.kid.surname}</strong></li>
            <li>{i18n.t('absence_dates')}: <strong>{data.date_from} - {data.date_to}</strong></li>
            <li>{i18n.t('exempt_from_fees')}: <strong>{data.exempt_from_fees ? i18n.t('yes') : i18n.t('no')}</strong></li>
            <li>{i18n.t('status')}: <strong>{i18n.t(data.status.translation_index)}</strong></li>
            <li>{i18n.t('absence_reason')}: <strong>{data.reason}</strong></li>
          </ul>
      </div>
    );
  }

  renderKidsContent(data){
    return(
      <div>
          <ul className="list-unstyled">
            <li>{i18n.t('kid')}: <strong>{data.kid.name} {data.kid.surname}</strong></li>
            <li>

              <table className="table table-hover">

              <thead>
                <tr>
                  <th></th>
                  <th>{i18n.t('current_data')}</th>
                  <th>{i18n.t('requested_changes')}</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>{i18n.t('name_and_surname')}</td>
                  <td>{data.kid.name} {data.kid.surname}</td>
                  <td>{data.request.name} {data.request.surname}</td>
                </tr>
                <tr>
                  <td>{i18n.t('nick')}</td>
                  <td>{data.kid.nick}</td>
                  <td>{data.request.nick}</td>
                </tr>
                <tr>
                  <td>{i18n.t('birth_date')}</td>
                  <td>{data.kid.date_of_birth}</td>
                  <td>{data.request.date_of_birth}</td>
                </tr>
                
              </tbody>

              </table>

            </li>
          </ul>
      </div>
    );
  }

  renderAdditionalActivitiesContent(data){
    return(
      <div>
        <ul className="list-unstyled">
          <li>{i18n.t('kid')}: <strong>{data.kid.name} {data.kid.surname}</strong></li>
          <li>{i18n.t('additional_activity')}: <strong>{data.activity.title}</strong></li>
        </ul>
      </div>
    );
  }

  renderAccountRelationsContent(data){
    return(
      <div>
        <ul className="list-unstyled">
          <li>{i18n.t('kid')}: <strong>{data.relation.kid}</strong></li>
          <li>{i18n.t('parents_single')} / {i18n.t('caregivers_single')}: <strong>{data.relation.account}</strong></li>
          <li>{i18n.t('relation_type')}: <strong>{i18n.t(data.relation.role)}</strong></li>
        </ul>
      </div>
    );
  }

  renderGalleryContent(data){
    return(
      <div className="media">
        <div className="media-left">
          <img className="media-object" src={data.data.photo.src} alt="..." />
        </div>
        <div className="media-body">
          <h4 className="media-heading">{i18n.t('user_request_to_remove_photo')}</h4>
          <p><strong>{i18n.t('reason')}:</strong><br/>{data.data.reason}</p>
        </div>
      </div>
    );
  }

  renderDetailsView(){
    var view;
    if(this.state.currentItem.data.module === 'daily_log_module'){
      view = this.renderDailyLogContent(this.state.currentItem.data)
    }
    if(this.state.currentItem.data.module === 'link_additional_activities'){
      view = this.renderAdditionalActivitiesContent(this.state.currentItem.data)
    }
    if(this.state.currentItem.data.module === 'link_account_relations'){
      view = this.renderAccountRelationsContent(this.state.currentItem.data)
    }
    if(this.state.currentItem.data.module === 'kids_module'){
      view = this.renderKidsContent(this.state.currentItem.data)
    }
    if(this.state.currentItem.data.module === 'gallery_module'){
      view = this.renderGalleryContent(this.state.currentItem.data)
    }
    return(
      <div className="media">
        <div className="media-left">
          <span className={this.state.currentItem.data.icon.color}><i className={this.state.currentItem.data.icon.icon}></i></span>
        </div>
        <div className="media-body">
          <h4 className="media-heading">{i18n.t(this.state.currentItem.data.module)}</h4>
          <p>{i18n.t('cdate')}: <strong>{this.state.currentItem.cdate}</strong>. {i18n.t('who_reports')}: <strong>{this.state.currentItem.author}</strong></p>
          <hr/>
          <p>{i18n.t('application_type')}: <strong>{i18n.t(this.state.currentItem.type)}</strong></p>
          <h4>{i18n.t('content')}:</h4>
          {view}
          <hr/>
          <button onClick={(event) => this.submitApplication(this.state.currentItem.id, 'accept')} type="button" className="btn btn-success">{i18n.t('button_accept')}</button>
            <button onClick={(event) => this.submitApplication(this.state.currentItem.id, 'reject')} type="button" className="btn btn-danger">{i18n.t('button_reject')}</button>
        </div>
      </div>
    )
  }

  renderMainView(){

    var view;
    if(this.state.isLoading === false){
      view = helperFunctions.renderNoContentInfo();
      if(this.state.items !== undefined && this.state.items.length > 0){
        if(this.state.view === 'list'){
          view = this.renderListView()
        }
        if(this.state.view === 'details'){
          view = this.renderDetailsView()
        }
      }
    }
    else{
      view = <LoaderComponent />
    }

    return(<div>{view}</div>);
  }

  render(){
    var view;
    view = this.renderMainView();
    return(
      <div>{view}</div>
    );
  }

}
