import React, {Component} from 'react';

import i18n from "i18next";

import GroupsComponent from '../GroupsComponent';
import AccountComponent from '../AccountComponent';
import InfoComponent from '../InfoComponent';
import KidsComponent from '../KidsComponent';
import DeleteComponent from '../DeleteComponent';

export default
class InitialComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'groups'
    }

    this.changeView = this.changeView.bind(this);
  }

  changeView(view){
    this.props.changeView(view, 0);
  }

  getView(){
    var view;
    if(this.state.view === 'groups'){
      view = <GroupsComponent teacherID={this.props.teacherID}/>
    }
    if(this.state.view === 'account'){
      view = <AccountComponent teacherID={this.props.teacherID}/>
    }
    if(this.state.view === 'info'){
      view = <InfoComponent teacherID={this.props.teacherID}/>
    }
    if(this.state.view === 'kids'){
      view = <KidsComponent teacherID={this.props.teacherID}/>
    }
    if(this.state.view === 'delete'){
      view = <DeleteComponent changeView={this.changeView} teacherID={this.props.teacherID}/>
    }

    return(<div>{view}</div>);
  }

  render(){
    var view = this.getView();
    return(
      <div class="nav-tabs-custom">
        <ul class="nav nav-tabs">
          <li className={this.state.view === 'groups' ? "active" : null}><a onClick={(event) => this.setState({view: 'groups'})} href="#tab" data-toggle="tab">{i18n.t('groups_module')}</a></li>
          <li className={this.state.view === 'account' ? "active" : null}><a onClick={(event) => this.setState({view: 'account'})} href="#tab" data-toggle="tab">{i18n.t('account_edit')}</a></li>
          <li className={this.state.view === 'info' ? "active" : null}><a onClick={(event) => this.setState({view: 'info'})} href="#tab" data-toggle="tab">{i18n.t('additional_info')}</a></li>
          <li className={this.state.view === 'kids' ? "active" : null}><a onClick={(event) => this.setState({view: 'kids'})} href="#tab" data-toggle="tab">{i18n.t('kids_module')}</a></li>
          <li className={this.state.view === 'delete' ? "active" : null}><a onClick={(event) => this.setState({view: 'delete'})} href="#tab" data-toggle="tab">{i18n.t('button_delete')}</a></li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active" id="tab">
            {view}
          </div>

        </div>
      </div>
    )
  }

}
