import React, {Component} from 'react';

import GroupInfoComponent from '../GroupInfoComponent';
import GroupKidsComponent from '../GroupKidsComponent';
import GroupTeachersComponent from '../GroupTeachersComponent';
import GroupGallery from '../GalleryComponent';
import AvatarComponent from '../AvatarComponent';
import DailyLogComponent from '../DailyLogComponent';

import i18n from "i18next";

export default
class InitialComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'info'
    }
  }

  getView(){
    var view;
    if(this.state.view === 'info'){
      view = <GroupInfoComponent groupID={this.props.groupID}/>
    }
    if(this.state.view === 'kids'){
      view = <GroupKidsComponent groupID={this.props.groupID}/>
    }
    if(this.state.view === 'teachers'){
      view = <GroupTeachersComponent groupID={this.props.groupID}/>
    }
    if(this.state.view === 'gallery'){
      view = <GroupGallery groupID={this.props.groupID}/>
    }
    if(this.state.view === 'avatar'){
      view = <AvatarComponent groupID={this.props.groupID}/>
    }
    if(this.state.view === 'dailyLog'){
      view = <DailyLogComponent groupID={this.props.groupID}/>
    }

    return(<div>{view}</div>);
  }

  render(){
    var view = this.getView();
    return(
      <div class="nav-tabs-custom">
        <ul class="nav nav-tabs">
          <li className={this.state.view === 'info' ? "active" : null}><a onClick={(event) => this.setState({view: 'info'})} href="#tab" data-toggle="tab">{i18n.t('main_info')}</a></li>
          <li className={this.state.view === 'avatar' ? "active" : null}><a onClick={(event) => this.setState({view: 'avatar'})} href="#tab" data-toggle="tab">{i18n.t('avatar')}</a></li>
          <li className={this.state.view === 'dailyLog' ? "active" : null}><a onClick={(event) => this.setState({view: 'dailyLog'})} href="#tab" data-toggle="tab">{i18n.t('daily_log_module')}</a></li>
          <li className={this.state.view === 'kids' ? "active" : null}><a onClick={(event) => this.setState({view: 'kids'})} href="#tab" data-toggle="tab">{i18n.t('link_children')}</a></li>
          <li className={this.state.view === 'teachers' ? "active" : null}><a onClick={(event) => this.setState({view: 'teachers'})} href="#tab" data-toggle="tab">{i18n.t('cadre')}</a></li>
          <li className={this.state.view === 'gallery' ? "active" : null}><a onClick={(event) => this.setState({view: 'gallery'})} href="#tab" data-toggle="tab">{i18n.t('group_gallery')}</a></li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active" id="tab">
            {view}
          </div>

        </div>
      </div>
    )
  }

}
