import React, {Component} from 'react';

import i18n from "i18next";

import AboutComponent from '../AboutComponent';
import EmployeesComponent from '../EmployeesComponent';
import AdditionalActivityComponent from '../AdditionalActivityComponent';
import ContentComponent from '../ContentComponent';
import ContactComponent from '../ContactComponent';

export default
class ProfileComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      items: [],
      isLoading: false,
      status: '',
      msg: null,
      view: 'news',
      reload: true,
    }

    this.finishReload = this.finishReload.bind(this);
  }

  componentDidUpdate(nextProps){
    if(nextProps.reload !== this.props.reload){
      this.props.afterReloadHandler(false);
      this.setState({view: 'news', reload: true});
    }
  }

  finishReload(){
    this.setState({reload: false});
  }



  renderSidebarNavigation(){
    return(
      <div className="box box-solid">
        <div className="box-body no-padding">
          <ul className="nav nav-pills nav-stacked">
            <li className={this.state.view === "news" ? "active" : null}><a onClick={(event) => this.setState({view: 'news'})} href="#">{i18n.t('object_announcements')}</a></li>
            <li className={this.state.view === "employees" ? "active" : null}><a onClick={(event) => this.setState({view: 'employees'})} href="#">{i18n.t('cadre')}</a></li>
            <li className={this.state.view === "additional_activity" ? "active" : null}><a onClick={(event) => this.setState({view: 'additional_activity'})} href="#">{i18n.t('additional_activities')}</a></li>
            <li className={this.state.view === "download" ? "active" : null}><a onClick={(event) => this.setState({view: 'download'})} href="#">{i18n.t('files_download')}</a></li>
            <li className={this.state.view === "parents_info" ? "active" : null}><a onClick={(event) => this.setState({view: 'parents_info'})} href="#">{i18n.t('parents_info')}</a></li>
            <li className={this.state.view === "psychological_and_pedagogical_help" ? "active" : null}><a onClick={(event) => this.setState({view: 'psychological_and_pedagogical_help'})} href="#">{i18n.t('psycho_peda_help')}</a></li>
            <li className={this.state.view === "social_actions" ? "active" : null}><a onClick={(event) => this.setState({view: 'social_actions'})} href="#">{i18n.t('social_actions')}</a></li>
            <li className={this.state.view === "daily_routine" ? "active" : null}><a onClick={(event) => this.setState({view: 'daily_routine'})} href="#">{i18n.t('daily_rhythm')}</a></li>
            <li className={this.state.view === "holiday_duty" ? "active" : null}><a onClick={(event) => this.setState({view: 'holiday_duty'})} href="#">{i18n.t('holiday_duty')}</a></li>
            <li className={this.state.view === "certifications" ? "active" : null}><a onClick={(event) => this.setState({view: 'certifications'})} href="#">{i18n.t('certificates_and_awards')}</a></li>
            <li className={this.state.view === "projects" ? "active" : null}><a onClick={(event) => this.setState({view: 'projects'})} href="#">{i18n.t('programs_and_projects')}</a></li>
            <li className={this.state.view === "statute_and_regulations" ? "active" : null}><a onClick={(event) => this.setState({view: 'statute_and_regulations'})} href="#">{i18n.t('statute_and_regulations')}</a></li>
            <li className={this.state.view === "contact" ? "active" : null}><a onClick={(event) => this.setState({view: 'contact'})} href="#">{i18n.t('school_contact_info')}</a></li>
          </ul>
        </div>
      </div>
    );
  }

  render(){
    let sidebar = this.renderSidebarNavigation();
    var view;

    if(this.state.view === 'employees'){
      view = <EmployeesComponent finishReload={this.finishReload}/>
    }
    else if(this.state.view === 'additional_activity'){
      view = <AdditionalActivityComponent finishReload={this.finishReload}/>
    }
    else if(this.state.view === 'download'){
      view = <ContentComponent type={"download"} view={"list"} finishReload={this.finishReload} />
    }
    else if(this.state.view === 'parents_info'){
      view = <ContentComponent type={"parents_info"} view={"list"} finishReload={this.finishReload} />
    }
    else if(this.state.view === 'projects'){
      view = <ContentComponent type={"projects"} view={"list"} finishReload={this.finishReload} />
    }
    else if(this.state.view === 'social_actions'){
      view = <ContentComponent type={"social_actions"} view={"list"} finishReload={this.finishReload} />
    }
    else if(this.state.view === 'statute_and_regulations'){
      view = <ContentComponent type={"statute_and_regulations"} view={"list"} finishReload={this.finishReload} />
    }
    else if(this.state.view === 'certifications'){
      view = <ContentComponent type={"certifications"} view={"list"} finishReload={this.finishReload} />
    }
    else if(this.state.view === 'psychological_and_pedagogical_help'){
      view = <ContentComponent type={"psychological_and_pedagogical_help"} view={"single"} finishReload={this.finishReload} />
    }
    else if(this.state.view === 'daily_routine'){
      view = <ContentComponent type={"daily_routine"} view={"single"} finishReload={this.finishReload} />
    }
    else if(this.state.view === 'holiday_duty'){
      view = <ContentComponent type={"holiday_duty"} view={"single"} finishReload={this.finishReload} />
    }
    else if(this.state.view === 'contact'){
      view = <ContactComponent finishReload={this.finishReload} />
    }
    else if(this.state.view === 'news'){
      view = <ContentComponent type={"messages"} view={"list"} finishReload={this.finishReload} reload={this.state.reload}/>
    }
    return(
      <div>
        <div className="row">
          <div className="col-md-3 col-sm-6 col-cs-12">{sidebar}</div>
          <div className="col-md-9 col-sm-6 col-cs-12">{view}</div>
        </div>
      </div>
    );
  }

}
