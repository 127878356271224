import API from '../../../../Utils/api';

export function getGroupDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('group/get/details', {params: array, headers: { Authorization: AuthStr }});
}

export function getGroupActivityCalendar(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('group/get/activity', {params: array, headers: { Authorization: AuthStr }});
}

export function getGroupActivityDayInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('group/get/activity/details', {params: array, headers: { Authorization: AuthStr }});
}

export function postGroupActivityAbsence(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/post/activity/absence', array, {headers: { Authorization: AuthStr }});
}

export function getGroupEvents(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('group/get/events', {params: array, headers: { Authorization: AuthStr }});
}

export function postSchoolGroupEvent(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/post/events', array, {headers: { Authorization: AuthStr, 'Content-Type': 'multipart/form-data' }});
}

export function deleteSchoolGroupEvent(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/delete/events', array, {headers: { Authorization: AuthStr }});
}

export function getGroupDashboard(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('group/get/dashboard', {params: array, headers: { Authorization: AuthStr }});
}

export function postSchoolGroupDashboard(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/post/dashboard', array, {headers: { Authorization: AuthStr }});
}

export function deleteSchoolGroupDashboard(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/delete/dashboard', array, {headers: { Authorization: AuthStr }});
}

export function getGroupGallery(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('group/get/gallery', {params: array, headers: { Authorization: AuthStr }});
}

export function postGalleryStore(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/post/gallery', array, {headers: { Authorization: AuthStr }});
}

export function deleteGallery(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/delete/gallery', array, {headers: { Authorization: AuthStr }});
}

export function deleteImage(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/delete/gallery/image', array, {headers: { Authorization: AuthStr }});
}

export function postGalleryPhotosStore(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/post/gallery/photos', array, {headers: { Authorization: AuthStr, 'Content-Type': 'multipart/form-data' }});
}

export function getGroupGalleryDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('group/get/gallery/details', {params: array, headers: { Authorization: AuthStr }});
}

export function postGroupImageReport(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/post/gallery/image/report', array, {headers: { Authorization: AuthStr }});
}

export function getGroupSurvey(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('group/get/survey', {params: array, headers: { Authorization: AuthStr }});
}

export function getSurveyDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('group/get/survey/details', {params: array, headers: { Authorization: AuthStr }});
}

export function postSurveyVote(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/post/survey/vote', array, {headers: { Authorization: AuthStr }});
}

export function postSurveyStore(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/post/survey/store', array, {headers: { Authorization: AuthStr }});
}

export function getSchoolSurveyQuestions(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('group/get/survey/questions', {params: array, headers: { Authorization: AuthStr }});
}

export function postGroupSurveyQuestionUpdate(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/post/survey/questions', array, {headers: { Authorization: AuthStr }});
}

export function deleteSchoolSurveyQuestion(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/delete/survey/question', array, {headers: { Authorization: AuthStr }});
}

export function getSchoolSurveyAnswers(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('group/get/survey/answers', {params: array, headers: { Authorization: AuthStr }});
}

export function deleteSchoolSurvey(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('group/delete/survey', array, {headers: { Authorization: AuthStr }});
}
