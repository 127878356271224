import React, {Component} from 'react';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import i18n from "i18next";

export default
class CommentComponent extends Component{

  constructor(props){
    super(props);
  }

  renderDetailsView(){
    return(<p>komentarze</p>)
  }

  render(){
    var view = helperFunctions.showBlockadeBlock(i18n.t('access_denied'), i18n.t('access_denied_info'));
    if(this.props.currentKid.canISeeDetails === true){
      view = this.renderDetailsView();
    }

    return(<div>{view}</div>);
  }

}
