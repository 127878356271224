import React, {Component} from 'react';
import * as requests from '../requests.js';
import LoaderComponent from '../../Utils/LoaderComponent';
import i18n from "i18next";

class RestorePassword extends Component{

  constructor(){
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      isLoading: false,
      error: '',
      hash: '',
      view: '',
      password: '',
      passwordInfo: '',
      passwordStatus: false,
    }
  }

  componentDidMount(){
    const { hash } = this.props.match.params;
    this.checkHash(hash);
  }

  changeValue(event, type){
    if(type === 'password'){
      this.passwordCheck(event.target.value);
      this.setState({password: event.target.value});
    }
  }

  checkHash(hash){
    this.setState({isLoading: true});

    var array = {
      'hash' : hash
    }

    let request = requests.checkHash(array);
    request.then(function(response){
      this.setState(
        {
          status: 'success',
          hash: hash,
          view: 'form',
          msg: i18n.t(response.data.msg),
        }
      )
    }.bind(this));

    request.catch(function(error){
      this.setState(
        {
          status: 'error',
          view: 'error',
          msg: i18n.t(error.response.data.error),
        }
      )
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  passwordCheck(str){
    if (str.length < 8) {
        this.setState({passwordInfo: 'Podane hasło jest za krótkie, minimum 8 znaków.', passwordStatus: false})
    } else if (str.length > 50) {
        this.setState({passwordInfo: 'Podane hasło jest za długie, maksimum to 50 znaków', passwordStatus: false})
    } else if (str.search(/\d/) == -1) {
        this.setState({passwordInfo: 'Podane hasło nie zawiera cyfr', passwordStatus: false})
    } else if (str.search(/[a-zA-Z]/) == -1) {
        this.setState({passwordInfo: 'Podane hasło nie zawiera liter', passwordStatus: false})
    } else if (str.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:]/) != -1) {
        this.setState({passwordInfo: 'Podane hasło zawiera znaki niedozwolone', passwordStatus: false})
    }
    else{
      this.setState({passwordInfo: 'Podane hasło jest bardzo dobre!', passwordStatus: true})
    }
  }

  handleSubmit(event){
    event.preventDefault();



    if(this.state.passwordStatus === false){
      this.setState({error: 'Hasło jest niepoprawne'});
    }
    else{
      this.setState({isLoading: true, error: ''});
      var array = {
        'password' : this.state.password,
        'hash'  : this.state.hash
      }

      let request = requests.restorePassword(array);
      request.then(function(response){
        this.props.history.push('/');
      }.bind(this));

      request.catch(function(error){
        this.setState({error: i18n.t("error_data_input")})
      }.bind(this));

      request.finally(function(){
        this.setState({isLoading: false})
      }.bind(this));
    }


  }

  renderForm(){
    return(

      <form onSubmit={this.handleSubmit}>
        <label>Podaj nowe hasło, powinno się składać z minimum 8 znaków i zawierać litery oraz cyfry.</label>
        <div className="form-group has-feedback">
          <input type="password" name="password" onChange={(event) => this.changeValue(event, 'password')} className="form-control" placeholder="Podaj nowe hasło" required/>
          <span className="glyphicon glyphicon-lock form-control-feedback"></span>
        </div>

        <div className="row">

          <div className="col-xs-12">
            <button type="submit" className="btn btn-warning btn-flat">Zmień hasło</button>
          </div>

        </div>

        <br/><p>{this.state.passwordInfo}</p>

      </form>
    );
  }

  renderError(){
    return(
      <div>
        <p>
          Niestety ale nie możesz przeprowadzić tej operacji. Przesłany token jest niewłaściwy lub nieaktualny.
          <br/><br/>
          Pamiętaj, linki które wysyłamy aby umożliwić Ci wykonanie operacji w systemie mają czasową ważność, po jej upłynięciu musisz przeprowadzić proces od nowa.
          <br/><br/>
          <small><strong>Pozdrawiamy serdecznie</strong><br/>Zespół NETkid</small>
        </p>
      </div>
    );
  }

  render(){
    var view
    if(this.state.view === 'form'){
      view = this.renderForm();
    }
    else if (this.state.view === 'error') {
      view = this.renderError();
    }
    if(this.state.isLoading === true){
      view = <LoaderComponent />;
    }
    return(
      <div className="mp_blue">
        <div className="mp_headerWrapper">
          <div className="container">

            <div className="mp_remindPassword">
              <div className="mp_login_form">

                {view}

              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }

}

export default RestorePassword;
