import API from '../../../../Utils/api';

export function getNews(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('dashboard/get/news', {params: array, headers: { Authorization: AuthStr }});
}

export function getProfileAbout(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/about', {params: array, headers: { Authorization: AuthStr }});
}

export function getProfileEmployees(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/employees', {params: array, headers: { Authorization: AuthStr }});
}

export function getProfileAdditionalActivity(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/activity', {params: array, headers: { Authorization: AuthStr }});
}

export function getProfileContent(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/content', {params: array, headers: { Authorization: AuthStr }});
}

export function getProfileContentDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/content/details', {params: array, headers: { Authorization: AuthStr }});
}

export function getGallery(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/gallery', {params: array, headers: { Authorization: AuthStr }});
}

export function getGalleryDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/gallery/details', {params: array, headers: { Authorization: AuthStr }});
}

export function getObjectBranches(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/branches', {params: array, headers: { Authorization: AuthStr }});
}

export function getMySettlements(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('me/get/settlement', {params: array, headers: { Authorization: AuthStr }});
}

export function getSystemNotifications(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('system/get/notifications', {params: array, headers: { Authorization: AuthStr }});
}
