import React, {Component} from 'react';

import LoaderComponent from '../../../../../../Utils/LoaderComponent';
import CkEditorComponent from '../../../../../../Utils/CkEditorComponent';
import AttachmentsComponent from '../../../../../../Utils/AttachmentsComponent';
import i18n from "i18next";
import * as acl from '../../../acl.js';
import * as requests from '../../../requests.js';
import * as helperFunctions from '../../../../../../Utils/HelperFunctions.js';

import CalendarComponent from '../CalendarComponent';
import MealTimeComponent from '../MealTimeComponent';

export default
class InitialComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      submodule: 'calendar'
    }
  }

  render(){
    var view = null;
    if(this.state.submodule === 'calendar'){
      view = <CalendarComponent />
    }
    if(this.state.submodule === 'mealTimes'){
      view = <MealTimeComponent />
    }
    return(

      <div>
        <div className="nav-tabs-custom">
          <ul className="nav nav-tabs">
            <li className={this.state.submodule === 'calendar' ? "active" : null}><a onClick={(event) => this.setState({submodule: 'calendar'})} href="#">{i18n.t('calendar')}</a></li>
            <li className={this.state.submodule === 'mealTimes' ? "active" : null}><a onClick={(event) => this.setState({submodule: 'mealTimes'})} href="#">{i18n.t('meal_times')}</a></li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active" id="tab_1">
              {view}
            </div>
          </div>
        </div>
      </div>

    );
  }
}
