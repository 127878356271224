import ProfileComponent from '../Modules/ProfileModule/ProfileComponent';
import DashboardComponent from '../Modules/DashboardModule/DashboardComponent';
import KidsComponent from '../Modules/KidsModule/KidsComponent';
import GroupsComponent from '../Modules/GroupsModule/GroupsComponent';
import SurveyComponent from '../Modules/SurveyModule/SurveyComponent';
import MsgComponent from '../Modules/MsgModule/MsgComponent';
import DailyLogComponent from '../Modules/DailyLogModule/DailyLogComponent';
import FoodMenuComponent from '../Modules/FoodMenuModule/FoodMenuComponent';
import KnowledgeBaseComponent from '../Modules/KnowledgeBaseModule/KnowledgeBaseComponent';
import AdministrationComponent from '../Modules/AdministrationModule/AdministrationComponent';
import ApplicationsComponent from '../Modules/ApplicationsModule/ApplicationsComponent';
import TasksComponent from '../Modules/TasksModule/TasksComponent';
import NotificationsComponent from '../Modules/NotificationsModule/NotificationsComponent';
import BugsReportComponent from '../Modules/BugsReportModule/BugsReportComponent';
import SearchComponent from '../Modules/SearchModule/SearchComponent';

const components = [
  {
    id: 'profileEdit',
    component: ProfileComponent
  },
  {
    id: 'dashboard',
    component: DashboardComponent
  },
  {
    id: 'kids',
    component: KidsComponent
  },
  {
    id: 'groups',
    component: GroupsComponent
  },
  {
    id: 'survey',
    component: SurveyComponent
  },
  {
    id: 'msg',
    component: MsgComponent
  },
  {
    id: 'activity_log',
    component: DailyLogComponent
  },
  {
    id: 'food_menu',
    component: FoodMenuComponent
  },
  {
    id: 'knowledge_base',
    component: KnowledgeBaseComponent
  },
  {
    id: 'admin',
    component: AdministrationComponent
  },
  {
    id: 'applications',
    component: ApplicationsComponent
  },
  {
    id: 'tasks',
    component: TasksComponent
  },
  {
    id: 'notifications',
    component: NotificationsComponent
  },
  {
    id: 'bugsReport',
    component: BugsReportComponent
  },
  {
    id: 'search',
    component: SearchComponent
  },
];

export default components;
