import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import CkEditorComponent from '../../../../../Utils/CkEditorComponent';
import AttachmentsComponent from '../../../../../Utils/AttachmentsComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';



export default
class ObjectContentComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      isLoading: false,
      status: '',
      msg: null,
      view: 'main',
      object: {id: null},
      objectContent: '',
      gallery: [],
      objectGalleryID: 0,
      objectAttachments: [],
      items: [],
      validationFields: [],
      filesStatus: false
    }

    this.submitForm = this.submitForm.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.onChangeContentHandler = this.onChangeContentHandler.bind(this);
    this.getGallery = this.getGallery.bind(this);
    this.setGalleryID = this.setGalleryID.bind(this);
    this.assignFilesHandler = this.assignFilesHandler.bind(this);
  }

  componentDidUpdate(prevProps){
    if(prevProps.type !== this.props.type){
      this.getObjectItems();
      this.setState({
        view: 'main',
        status: '',
        msg: '',
        object: {id: null},
        objectContent: '',
        objectAttachments: [],
        items: [],
        validationFields: [],
        objectFileFormData: [],
        objectGalleryID: 0,
        filesStatus: false
      });
      return true;
    }
    return false;
  }

  componentDidMount(){
    this.getObjectItems();
  }

  onChangeContentHandler(event, editor, data){
    this.setState({objectContent: data});
  }

/*
  onChangeContentHandler(event){
    this.setState({objectContent: event.target.value});
  }
  */

  setGalleryID(event){
    this.setState({objectGalleryID: event.target.value});
  }

  assignFilesHandler(event){
    event.preventDefault();
    this.setState({
      objectAttachments: event.target.files.files,
      filesStatus: true
    })
  }

  getGallery(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
    }

    let request = requests.getSchoolGallery(array);
    request.then(function(response){
      this.setState({gallery: response.data});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getObjectItems(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'type' : this.props.type
    }

    let request = requests.getMainSettingsObjectContent(array);
    request.then(function(response){
      this.setState({items: response.data});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  deleteItem(event){
    event.preventDefault();
    this.setState(
      {
        isLoading: true,
      }
    );
    var array = {
      school_id: localStorage.getItem('objectID'),
      id: this.state.object.id,
    }
    let request = requests.deleteMainSettingsContent(array);
    request.then(function(response){
      this.setState({status: 'success', msg: i18n.t(response.data.msg), view: 'main', object: []}, () => {this.getObjectItems()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else if (error.response.data.error === 'validation_error') {
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationFields: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));

  }

  submitForm(event){
    event.preventDefault();
    this.setState(
      {
        isLoading: true,
        object: {
          objectID: localStorage.getItem('objectID'),
        }
      }
    );

    const data = new FormData();
    for (var i = 0; i < this.state.objectAttachments.length; i++) {
      data.append('file[]', this.state.objectAttachments[i]);
    }

    data.append('school_id', localStorage.getItem('objectID'));
    data.append('id', this.state.object.id);
    data.append('type', this.props.type);
    data.append('title', event.target.title.value);
    data.append('content', this.state.objectContent);
    data.append('gallery_id', this.state.objectGalleryID);

    let request = requests.postMainSettingsObjectContentManage(data);
    request.then(function(response){
      this.setState({status: 'success', msg: i18n.t(response.data.msg), view: 'main', object: []}, () => {this.getObjectItems()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else if (error.response.data.error === 'validation_error') {
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationFields: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  loadListView(){
    return(
      <div>
        {
          this.state.items.map(function(item){
            var gallery;
            var attachments;
            if(item.gallery_id > 0){
              gallery = i18n.t('group_gallery') + item.gallery.title;
            }
            if(item.attachments.length > 0){
              attachments = i18n.t('files') + item.attachments.length
            }
            return(
              <div>
                <div onClick={(event) => this.setState({
                  view: 'edit',
                  status: '',
                  msg: null,
                  object: item,
                  objectContent: item.content !== null ? item.content : '',
                  objectAttachments: [],
                  gallery: [],
                  objectGalleryID: item.gallery_id,
                }, () => {this.getGallery()})} className="media cursor">
                  <div className="media-left">
                    <span className="info-box-icon bg-red"><i className={item.icon}></i></span>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">
                      <small>{i18n.t('cdate')}: {item.cdate}</small><br/>
                      {item.title}
                    </h4>
                    <p>{item.preface}</p>
                    <p>{gallery}<br/>{attachments}</p>
                  </div>
                </div>
                <hr/>
              </div>
            );
          }.bind(this))
        }
      </div>
    );
  }

  loadTempAttachments(){
    return(
      <div className="callout callout-info">
        <h4>{i18n.t('new_attachments_added_to_the_collection')}</h4>
        <p>{i18n.t('files_count')}: <b>{this.state.objectAttachments.length}</b></p>
      </div>
    );
  }

  renderFormView(){
    var view;
    if(this.state.view === 'edit'){
      view = <button onClick={(event) => this.deleteItem(event)} type="button" class="text-right btn btn-danger"><i className="fas fa-trash-alt"></i> {i18n.t('button_delete')}</button>
    }

    var tempAttachments = null;
    if(this.state.objectAttachments.length){
      tempAttachments = this.loadTempAttachments()
    }

    return(
      <div>
        <form onSubmit={(event) => this.submitForm(event)}>
          {helperFunctions.getTextInput(
            i18n.t('set_item_title'),
            "title",
            this.state.object.title,
            this.state.validationFields.indexOf('title') === -1 ? false : true,
            i18n.t('error_data_input')
          )}

          {<CkEditorComponent label={i18n.t('set_item_content')} content={this.state.object.content} onChangeContentHandler={this.onChangeContentHandler}/>}

          <div className="form-group">
            <label>{i18n.t('choose_gallery_you_want_to_add')}</label>
            <select onChange={(event) => this.setGalleryID(event)} value={this.state.objectGalleryID} className="form-control">
              <option value="0">{i18n.t('no_item_select')}</option>
              {
                this.state.gallery.map(function(gallery){
                  return(<option key={gallery.id} value={gallery.id}>{gallery.title}</option>);
                }.bind(this))
              }
            </select>
          </div>

          {tempAttachments}

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
              <button onClick={(event) => this.setState({view: 'main'}, () => {this.getObjectItems()})} type="button" className="btn btn-danger">{i18n.t('button_cancel')}</button>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">{view}</div>
          </div>

        </form>

        <hr/>
          {
            <AttachmentsComponent
              items={this.state.object.attachments}
              title={i18n.t('files')}
              view={"admin"}
              itemID={this.state.object.id}
              contentType={this.props.type}
              assignFilesHandler={this.assignFilesHandler}
              filesStatus={this.state.filesStatus}
              reloadFilesList={this.reloadFilesList}
            />
          }

      </div>
    )
  }

  renderMainView(){
    var view;
    if(this.state.items.length){
      view = this.loadListView();
    }
    else{
      view = helperFunctions.renderNoContentInfo();
    }
    return(
      <div>
        <button onClick={(event) => this.setState(
          {
            view: 'create',
            object: {id: null},
            objectContent: null,
            objectAttachments: [],
            gallery: [],
            objectGalleryID: 0,
            status: '',
            msg: null,
          }
          , () => {this.getGallery()})} className="btn btn-default"><i className="fas fa-plus-circle"></i> {i18n.t('add_new_item')}</button>
        <hr/>
        {view}
      </div>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'main'){
        view = this.renderMainView();
      }
      else if(this.state.view === 'create' || this.state.view === 'edit'){
        view = this.renderFormView();
      }
    }
    else{
      view = <LoaderComponent />;
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
