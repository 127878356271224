import React, {Component} from 'react';
import * as requests from '../../Auth/requests.js';
import LoaderComponent from '../../Utils/LoaderComponent';
import i18n from "i18next";
export default class RestorePasswordPassword extends Component{

  constructor(){
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      isLoading: false,
      error: '',
      view: 'form',
    }
  }

  handleSubmit(event){
    event.preventDefault();

    this.setState({isLoading: true, error: ''});

    var email = event.target.username.value;

    let request = requests.remindPassword(email);
    request.then(function(response){
      this.setState({view: 'end'})
    }.bind(this));

    request.catch(function(error){
      this.setState({error: i18n.t("error_data_input")})
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false})
    }.bind(this));

  }

  renderForm(){
    return(
      <div className="mp_headerWrapper">
        <div className="container">

          <div className="mp_remindPassword">
            <div className="mp_login_form">

              <form onSubmit={this.handleSubmit}>
                <label>Podaj adres e-mail</label>
                <div className="form-group">
                  <input type="email" name="username" className="form-control" placeholder={i18n.t("login_form_email")} required/>
                </div>

                <div className="row">

                  <div className="col-xs-12">
                    <button type="submit" className="btn btn-warning btn-flat">Przejdź dalej</button>
                  </div>

                </div>
              </form>

            </div>
          </div>

        </div>
      </div>
    );
  }

  renderEndInfo(){
    return(
      <div>Postępuj zgodnie z informacjami przesłanymi na podany wcześniej adres e-mail.</div>
    );
  }


  render(){
    var view = this.renderForm();
    if(this.state.view === 'end'){
      view = this.renderEndInfo();
    }
    if(this.state.isLoading === true){
      view = <LoaderComponent />;
    }
    return(
      <div>
        {view}
      </div>
    );
  }

}
