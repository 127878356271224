import React, {Component} from 'react';

import LoaderComponent from '../../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../../acl.js';
import * as requests from '../../../requests.js';
import * as helperFunctions from '../../../../../../Utils/HelperFunctions.js';

export default
class GroupKidsComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      items: [],
      status: '',
      msg: null,
      view: 'list',
    }

    this.deleteFromGroup = this.deleteFromGroup.bind(this);
  }

  componentDidMount(){
    this.getGroupKids();
  }

  deleteFromGroup(id){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.groupID,
      'kid_id' : id,
    }

    let request = requests.deleteKidFormGroup(array);
    request.then(function(response){
      this.setState(
        {
          msg: response.data.msg,
          status: 'success'
        },
        () => {this.getGroupKids()}
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getGroupKids(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.groupID,
    }

    let request = requests.getGroupKids(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data,

        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderTableView(){
    return(
      <table className="table table-border table-hover">

        <thead>
          <tr>
            <th>{i18n.t('avatar')}</th>
            <th>{i18n.t('name_and_surname')}</th>
            <th>{i18n.t('in_system_since')}:</th>
          </tr>
        </thead>

        <tbody>
          {
            this.state.items.map(function(item){
              return(
                <tr key={item.id}>
                  <td><img className="img-circle img-md smallAvatar" src={item.avatar} /></td>
                  <td>{item.name}</td>
                  <td>{item.cdate}</td>
                  <td><button onClick={(event) => this.deleteFromGroup(item.id)} className="btn btn-xs btn-danger">{i18n.t('remove_from_group')}</button></td>
                </tr>
              )
            }.bind(this))
          }
        </tbody>

      </table>
    )
  }

  renderListView(){
    var view = helperFunctions.renderNoContentInfo();
    if(this.state.items !== undefined && this.state.items.length > 0){
      view = this.renderTableView()
    }
    return(<div>{view}</div>)
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView()
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
