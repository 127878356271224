import React, {Component} from 'react';

import LoaderComponent from '../../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../../acl.js';
import * as requests from '../../../requests.js';
import * as helperFunctions from '../../../../../../Utils/HelperFunctions.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import Select from 'react-select';


export default
class KidInfoComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      view: 'form',
      name: '',
      surname: '',
      nick: '',
      birth: new Date(moment().format("YYYY-MM-DD")),
      isLoading: false,
      status: '',
      msg: null,
      groups: [],
      selectedGroup: 0,
      validationErrors: [],
    }

    this.changeState = this.changeState.bind(this);
    this.handleSelectedGroupChange = this.handleSelectedGroupChange.bind(this);
    this.submitUpdateKid = this.submitUpdateKid.bind(this);
  }

  componentDidMount(){
    this.getGroups();
    this.getKidInfo();
  }

  getKidInfo(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.kidID
    }

    let request = requests.getKidInfo(array);
    request.then(function(response){
      this.setState(
        {
          name: response.data.kid.name,
          surname: response.data.kid.surname,
          nick: response.data.kid.nick,
          selectedGroup: response.data.kid.group,
          birth: new Date(moment(response.data.kid.birth).format("YYYY-MM-DD")),
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getGroups(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getGroupsList(array);
    request.then(function(response){
      this.setState(
        {
          groups: response.data,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitUpdateKid(event){
    event.preventDefault();
    this.setState({isLoading: true, validationErrors: []});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id'  : this.props.kidID,
      'name' : this.state.name,
      'surname' : this.state.surname,
      'date_of_birth' : this.state.birth,
      'group' : this.state.selectedGroup,
      'nick' : this.state.nick,
    }

    let request = requests.postKidUpdateInfo(array);
    request.then(function(response){
      this.setState({status: 'success', msg: i18n.t(response.data.msg)});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        if(error.response.data.error === 'validation_error'){
          this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
        }
        else{
          this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
        }
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  changeState(event, type){
    if(type === 'birth'){
      this.setState({birth: event});
    }
    else{
      if(type === 'name'){
        this.setState({name: event.target.value});
      }
      if(type === 'surname'){
        this.setState({surname: event.target.value});
      }
      if(type === 'nick'){
        this.setState({nick: event.target.value});
      }
    }
  }

  handleSelectedGroupChange(group){
    this.setState({selectedGroup: group});
  }

  renderFormView(){
    return(<form onSubmit={(event) => this.submitUpdateKid(event)}>

      <div className="row">

        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={'name' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('set_kid_name')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <input type="text" name="name" className="form-control" onChange={(event) => this.changeState(event, 'name')} value={this.state.name} required />
            <span className="help-block">{'name' in this.state.validationErrors ? i18n.t(this.state.validationErrors['name']) : null}</span>
          </div>
        </div>

        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={'surname' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('set_kid_surname')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <input type="text" name="surname" className="form-control" onChange={(event) => this.changeState(event, 'surname')} value={this.state.surname} required />
            <span className="help-block">{'surname' in this.state.validationErrors ? i18n.t(this.state.validationErrors['surname']) : null}</span>
          </div>
        </div>

      </div>

      <div className="row">

        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={'nick' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('set_kid_nick')}</label>
            <input type="text" name="nick" className="form-control" onChange={(event) => this.changeState(event, 'nick')} value={this.state.nick} />
            <span className="help-block">{'nick' in this.state.validationErrors ? i18n.t(this.state.validationErrors['nick']) : null}</span>
          </div>
        </div>

        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={'group' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('choose_group')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              {
                <Select
                  value={this.state.selectedGroup}
                  onChange={this.handleSelectedGroupChange}
                  options={this.state.groups}
                  isMulti={false}
                  placeholder={i18n.t('choose_group')}
                />
              }
              <span className="help-block">{'group' in this.state.validationErrors ? i18n.t(this.state.validationErrors['group']) : null}</span>
          </div>
        </div>

      </div>

      <div className="row">



        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={'birth' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('set_kid_birth')}</label>
            {
              <DatePicker
                  selected={this.state.birth}
                  onSelect={(value) => this.changeState(value, 'birth')}
                  todayButton={i18n.t('set_current_date')}
                  showYearDropdown
                  shouldCloseOnSelect={false}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
              />
            }
            <span className="help-block">{'birth' in this.state.validationErrors ? i18n.t(this.state.validationErrors['birth']) : null}</span>
          </div>
        </div>

      </div>

      <hr/>
      <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>

    </form>);
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'form'){
        view = this.renderFormView()
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
