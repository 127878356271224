import API from '../../Utils/api';

export function postAttachmentUpdateInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/attachments/post/info', array, {headers: { Authorization: AuthStr }});
}

export function postAttachmentDelete(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.delete('admin/attachments/delete', {data: array, headers: { Authorization: AuthStr }});
}

export function getSchoolAdditionalActivityTeachers(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/additional-activity/get/teachers', {params: array, headers: { Authorization: AuthStr }});
}

export function getSchoolTeachersSelect(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/teachers/get/select', {params: array, headers: { Authorization: AuthStr }});
}

export function postSchoolTeacherCalendarUpdate(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/additional-activity/post/calendar/update', array, {headers: { Authorization: AuthStr }});
}

export function postSchoolTeacherCalendarDelete(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.delete('admin/additional-activity/delete/calendar', {data: array, headers: { Authorization: AuthStr }});
}

export function getSchoolAdditionalActivityKids(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/additional-activity/get/kids', {params: array, headers: { Authorization: AuthStr }});
}
