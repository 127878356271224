import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import i18n from "i18next";
import LoaderComponent from '../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';


export default
class BugsReportComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      objectSelectLoading: false,
      reloadList: false,
      isLoading: false,
      status: '',
      msg: null,
      validationErrors: [],
      title: '',
      content: '',
    }

    this.submitBugForm = this.submitBugForm.bind(this);
    this.changeState = this.changeState.bind(this);

  }

  changeState(event, type){
    if(type === 'title'){
      this.setState({title: event.target.value});
    }
    if(type === 'content'){
      this.setState({content: event.target.value});
    }
  }

  submitBugForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'title' : this.state.title,
      'content' : this.state.content
    }

    var request = requests.postBugForm(array);
    request.then(function(response){
      this.setState({msg: response.data.msg, status: 'success', title: '', content: ''});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'validation_error'){
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));

  }

  renderForm(){
    return(
      <form onSubmit={(event) => this.submitBugForm(event)}>

        <h3>{i18n.t('bug_report_form')}<br/><small>{i18n.t('bug_report_form_subtitle')}</small></h3><hr/>


        <div className={'title' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
          <label>{i18n.t('set_title_of_request')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <input type="text" name="title" value={this.state.title} onChange={(event) => this.changeState(event, 'title')} className="form-control" required />
          <span class="help-block">{'title' in this.state.validationErrors ? i18n.t(this.state.validationErrors['title']) : null}</span>
        </div>

        <div className={'content' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
          <label>{i18n.t('describe_problem')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <textarea name="content" value={this.state.content} onChange={(event) => this.changeState(event, 'content')} className="form-control" rows="10" required />
          <span class="help-block">{'content' in this.state.validationErrors ? i18n.t(this.state.validationErrors['content']) : null}</span>
        </div>

        <hr/>
        <button className="btn btn-success">{i18n.t('button_send_request')}</button>

      </form>
    )
  }

  render(){
    var form;
    if(this.state.isLoading === false){
      form = this.renderForm();
    }
    else{
      form = <LoaderComponent />;
    }


    return(
      <div>

        <section className="content-header">
          <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-teal"><i className="fas fa-headset"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-number">
                      <h3>{i18n.t('helpdesk')}<br/><small>{i18n.t('helpdesk_subtitle')}</small></h3>
                    </span>
                  </div>
                </div>
              </div>

          </div>
        </section>

        <section className="content container-fluid">
          {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}

          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12">

              <div class="box box-default">
                <div className="box-body">
                  {form}
                </div>
              </div>



            </div>
            <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
              <div class="box box-default">
                <div class="box-body box-profile">
                  <img class="profile-user-img img-responsive img-circle" src="https://net-kid.com/images/callcenter.jpg" alt={i18n.t('customer_service_office')} />

                  <h3 class="profile-username text-center">{i18n.t('customer_service_office')}</h3>

                  <p class="text-muted text-center">{i18n.t('customer_service_office_subtitle')}</p>

                  <ul class="list-group list-group-unbordered">
                    <li class="list-group-item">
                      <b>{i18n.t('customer_service_office_email')}</b> <a class="pull-right"><a href="mailto:doradcy@net-kid.com">doradcy@net-kid.com</a></a>
                    </li>
                    <li class="list-group-item">
                      <b>{i18n.t('customer_service_office_phone')}</b> <a class="pull-right"> 602 499 835</a>
                    </li>

                  </ul>

                </div>

              </div>
            </div>
          </div>

        </section>

      </div>
    );
  }

}
