import API from '../../../../Utils/api';

export function getSearchableModules(){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('modules/get/searchable', {headers: { Authorization: AuthStr }});
}

export function postSearchForm(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('system/post/search', array, {headers: { Authorization: AuthStr }});
}
