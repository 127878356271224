import React, {Component} from 'react';

import i18n from "i18next";
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';

class MobileDataComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      list: [],
      isLoading: false,
      view: 'list'
    }

    this.changeConfirmStatus = this.changeConfirmStatus.bind(this);
    this.changePushStatus = this.changePushStatus.bind(this);
  }

  componentDidMount(){
    this.getMyMobile();
  }

  getMyMobile(){
    this.setState({isLoading: true});
    let request = requests.getMyMobile();
    request.then(function(response){
      this.setState({list: response.data});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  changeConfirmStatus(event, id){
    this.setState({isLoading: true});
    var array = {
      'mobileID' : id
    }
    let request = requests.postMyMobileConfirmationStatus(array);
    request.then(function(response){
      this.setState(
        {
          status: 'success',
          msg: i18n.t(response.data.msg),
        }
      )
      this.getMyMobile();
    }.bind(this));

    request.catch(function(error){
      this.setState(
        {
          status: 'error',
          msg: i18n.t(error.response.data.error),
        }
      )
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  changePushStatus(event, id){
    this.setState({isLoading: true});
    var array = {
      'mobileID' : id
    }
    let request = requests.postMyMobilePushStatus(array);
    request.then(function(response){
      this.setState(
        {
          status: 'success',
          msg: i18n.t(response.data.msg),
        }
      )
      this.getMyMobile();
    }.bind(this));

    request.catch(function(error){
      this.setState(
        {
          status: 'error',
          msg: i18n.t(error.response.data.error),
        }
      )
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderListTable(){
    return(
      <table className="table">
        <thead>
          <tr>
            <th>{i18n.t('table_th_os')}</th>
            <th>{i18n.t('table_th_os_ver')}</th>
            <th>{i18n.t('table_th_last_activity_date')}</th>
            <th>{i18n.t('table_th_push_notifications')}</th>
          </tr>
        </thead>

        <tbody>
          {
            this.state.list.map(function(item){
              return(
                <tr key={item.id}>
                  <td>{item.os}</td>
                  <td>{item.os_ver}</td>
                  <td>{item.last_active}</td>
                  <td><button onClick={(event) => this.changePushStatus(event, item.id)} className={item.push ? "btn btn-xs btn-success" : "btn btn-xs btn-default"}><i className="fas fa-check"></i></button></td>
                </tr>
              );
            }.bind(this))
          }
        </tbody>

      </table>
    );
  }

  render(){
    var view;
    if(this.state.view == 'list'){
      view = this.renderListTable();
    }
    if(this.state.isLoading === true){
      view = <LoaderComponent />;
    }
    return(
      <div className="row">
        <div className="col-md-9 col-sm-12 col-xs-12">

          {view}

        </div>
      </div>
    );
  }

}
export default MobileDataComponent;
