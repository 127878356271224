import React, {Component} from 'react';

export default
class ForumComponent extends Component{

  constructor(props){
    super(props);
  }

  componentDidUpdate(prevProps){
    if(prevProps.reloadList !== this.props.reloadList){
      this.props.finishListReloadHandler(false);
      return true;
    }
    return false;
  }

  render(){
    return(<div>Forum</div>);
  }

}
