import React, {Component} from 'react';
import i18n from "i18next";

import PersonalInfoComponent from '../PersonalInfoComponent';
import ParentsInfoComponent from '../ParentsInfoComponent';
import ActivityLogComponent from '../ActivityLogComponent';
import AdditionalSettingsComponent from '../AdditionalSettingsComponent';
import AdditionalActivityComponent from '../AdditionalActivityComponent';
import FoodPreferencesComponent from '../FoodPreferencesComponent';

class InitialComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      currentModule: 'PersonalInfoComponent',
      currentModuleParams: [],
    }

    this.changeModule = this.changeModule.bind(this);
  }

  chooseModuleHandler(){
    var module = null;
    switch (this.state.currentModule) {
      case 'PersonalInfoComponent':
        module = PersonalInfoComponent;
        break;

      case 'ParentsInfoComponent':
        module = ParentsInfoComponent;
        break;

      case 'ActivityLogComponent':
        module = ActivityLogComponent;
        break;

      case 'AdditionalSettingsComponent':
        module = AdditionalSettingsComponent;
        break;
    }

    return module;
  }

  changeModule(event, module){
    this.setState({currentModule: module});
  }

  render(){
    const Module = this.chooseModuleHandler();
    return(
      <div className="box box-warning box-solid">
        <div className="box-header with-border">

          <div className="media">
            <div className="media-left">
              <img className="media-object img-circle img-md" src={this.props.currentKid.avatar} alt="" />
              </div>
              <div className="media-body">
                  <h4 className="media-heading">{i18n.t('full_kid_profile')}: <b>{this.props.currentKid.kid}</b></h4>
                  <small className="text-white">{i18n.t('object')}: <b>{this.props.currentKid.school}</b>, {i18n.t('group')}: <b>{this.props.currentKid.group}</b><br/>{i18n.t('in_system_since')}: <b>{this.props.currentKid.cdate}</b></small>
              </div>
            </div>

        </div>

        <div className="box-body">

          <div className="nav-tabs-custom">
              <ul className="nav nav-tabs">
                <li className=""><a onClick={(event) => this.props.changeMainView(event, 'main', this.props.currentKidID)} href="#tab" data-toggle="tab" aria-expanded="false"><i className="fas fa-arrow-alt-circle-left"></i> {i18n.t('go_back_to_list')}</a></li>
                <li className="active"><a onClick={(event) => this.changeModule(event, 'PersonalInfoComponent')} href="#tab" data-toggle="tab" aria-expanded="false"><i className="far fa-folder"></i> {i18n.t('kid_data')}</a></li>
                <li className=""><a onClick={(event) => this.changeModule(event, 'ParentsInfoComponent')} href="#tab" data-toggle="tab" aria-expanded="false"><i className="far fa-folder"></i> {i18n.t('parents_and_caregivers')}</a></li>
                <li className=""><a onClick={(event) => this.changeModule(event, 'ActivityLogComponent')} href="#tab" data-toggle="tab" aria-expanded="false"><i className="far fa-folder"></i> {i18n.t('activity_log')}</a></li>
                <li className=""><a onClick={(event) => this.changeModule(event, 'AdditionalSettingsComponent')} href="#tab" data-toggle="tab" aria-expanded="false"><i className="far fa-folder"></i> {i18n.t('additional_settings')}</a></li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane active" id="tab">

                  <Module
                    currentKid={this.props.currentKid}
                    currentUser={this.props.currentUser}
                  />

                </div>

              </div>

            </div>

        </div>
      </div>
    );
  }

}
export default InitialComponent;
