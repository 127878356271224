import React, {Component} from 'react';

import * as requests from '../requests.js';
import LoaderComponent from '../../Utils/LoaderComponent';
import i18n from "i18next";

export default
class RegisterComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      isLoading: false,
      step: 1,
      error: '',
      email: '',
      password: '',
      account: [],
      name: '',
      name_short: '',
      city: '',
      street: '',
      postal: '',
      nip: '',
      regon: '',
      acc_name: '',
      acc_surname: '',
      aggr_1: 0,
      aggr_2: 0,
      status: null,
      msg: '',
      passwordInfo: '',
      passwordStatus: true,
      errorType: '',
      inputError: '',
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeState = this.changeState.bind(this);
    this.redirectToLogin = this.redirectToLogin.bind(this);
  }

  passwordCheck(str){
    if (str.length < 8) {
        this.setState({passwordInfo: 'Podane hasło jest za krótkie, minimum 8 znaków.', passwordStatus: false})
    } else if (str.length > 50) {
        this.setState({passwordInfo: 'Podane hasło jest za długie, maksimum to 50 znaków', passwordStatus: false})
    } else if (str.search(/\d/) == -1) {
        this.setState({passwordInfo: 'Podane hasło nie zawiera cyfr', passwordStatus: false})
    } else if (str.search(/[a-zA-Z]/) == -1) {
        this.setState({passwordInfo: 'Podane hasło nie zawiera liter', passwordStatus: false})
    } else if (str.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:]/) != -1) {
        this.setState({passwordInfo: 'Podane hasło zawiera znaki niedozwolone', passwordStatus: false})
    }
    else{
      this.setState({passwordInfo: 'Podane hasło jest bardzo dobre!', passwordStatus: true})
    }
  }

  changeState(event, type){
    if(type === 'email'){
      this.setState({email: event.target.value});
    }
    if(type === 'password'){
      this.passwordCheck(event.target.value);
      this.setState({password: event.target.value});
    }
    if(type === 'login_password'){
      this.setState({password: event.target.value});
    }
    if(type === 'name'){
      this.setState({name: event.target.value});
    }
    if(type === 'name_short'){
      this.setState({name_short: event.target.value});
    }
    if(type === 'city'){
      this.setState({city: event.target.value});
    }
    if(type === 'street'){
      this.setState({street: event.target.value});
    }
    if(type === 'postal'){
      this.setState({postal: event.target.value});
    }
    if(type === 'nip'){
      this.setState({nip: event.target.value});
    }
    if(type === 'regon'){
      this.setState({regon: event.target.value});
    }
    if(type === 'acc_name'){
      this.setState({acc_name: event.target.value});
    }
    if(type === 'acc_surname'){
      this.setState({acc_surname: event.target.value});
    }
    if(type === 'aggr_1'){
      this.setState({aggr_1: event.target.value});
    }
    if(type === 'aggr_2'){
      this.setState({aggr_2: event.target.value});
    }
  }

  redirectToLogin(){
    this.props.history.push('/');
  }

  handleSubmit(event){
    event.preventDefault();

    if(this.state.passwordStatus === false){
      this.setState({passwordInfo: 'Hasło które podajesz wymaga zmiany ponieważ nie spełnia wymogów bezpieczeństwa systemu NETkid.'});
    }
    else{

      this.setState({isLoading: true, error: '', status: null, msg: ''});

      var request;
      if(this.state.step === 1){
        var array = {
          'email' : this.state.email
        }
        request = requests.register_step1(array);
      }
      if(this.state.step === 2){
        var array = {
          'email' : this.state.email,
          'password' : this.state.password
        }
        request = requests.register_step2(array);
      }
      if(this.state.step === 3){
        var array = {
          'email' : this.state.email,
          'password' : this.state.password,
          'name' : this.state.acc_name,
          'surname' : this.state.acc_surname,
        }
        request = requests.register_step3(array);
      }
      if(this.state.step === 4){
        var array = {
          'userID' : this.state.user.id,
          'name' : this.state.name,
          'name_short' : this.state.name_short,
          'city' : this.state.city,
          'street' : this.state.street,
          'postal' : this.state.postal,
          'nip' : this.state.nip,
          'regon' : this.state.regon,
          'aggr_1' : this.state.aggr_1,
          'aggr_2' : this.state.aggr_2,
        }
        request = requests.register_step4(array);
      }
      request.then(function(response){
        this.setState({step: response.data.step, status: response.data.class, msg: response.data.msg, user: response.data.user});
      }.bind(this));

      request.catch(function(error){
        this.setState({status: error.response.data.class, msg: i18n.t(error.response.data.error), inputError: i18n.t(error.response.data.inputError), errorType: error.response.data.type});
      }.bind(this));

      request.finally(function(){
        this.setState({isLoading: false, reloadList: false});
      }.bind(this));

    }



  }

  renderForm_step1(){
    return(
      <form onSubmit={(event) => this.handleSubmit(event)}>
        <p className="login-box-msg">Tworzenie profilu dyrektora (1/2)</p>
        <label>Podaj adres e-mail <span className="text-danger">(Pole wymagane)</span></label>
        <div className="form-group has-feedback">
          <input type="email" name="username" onChange={(event) => this.changeState(event, 'email')} value={this.state.email} className="form-control" placeholder={i18n.t("login_form_email")} required/>
          <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
        </div>


        <div className="row">
          <div className="col-xs-12">
            <button type="submit" className="btn btn-warning btn-flat">Przejdź dalej</button>
          </div>
        </div>
      </form>
    );
  }

  renderForm_step2(){
    return(
      <form onSubmit={(event) => this.handleSubmit(event)}>
        <p className="login-box-msg">Tworzenie profilu dyrektora (2/2)</p>

          <div className="media">
            <div className="media-left">
              <img className="media-object img-circle img-sm" src={this.state.user.image} />
            </div>
            <div className="media-body">
              <h4 className="media-heading">{this.state.user.name}</h4>
              <small>Aby przejść dalej podaj hasło do konta: <b>{this.state.user.email}</b></small>
            </div>
          </div>

        <div className="form-group has-feedback">
          <input type="password" name="password" onChange={(event) => this.changeState(event, 'login_password')} value={this.state.password} className="form-control" placeholder={i18n.t("login_form_password")} required/>
          <span className="glyphicon glyphicon-lock form-control-feedback"></span>
        </div>

        <p>{this.state.passwordInfo}</p>

        <div className="row">
          <div className="col-xs-12">
            <button type="submit" className="btn btn-warning btn-flat">Przejdź dalej</button>
          </div>
        </div>
      </form>
    );
  }

  renderForm_step3(){
    return(
      <form onSubmit={(event) => this.handleSubmit(event)}>
        <p className="login-box-msg">Tworzenie profilu dyrektora (2/2)</p>

          <div className="form-group has-feedback">
            <label>Podaj imię <span className="text-danger">(Pole wymagane)</span></label>
            <input type="text" name="acc_name" onChange={(event) => this.changeState(event, 'acc_name')} value={this.state.acc_name} className="form-control" required/>
          </div>

          <div className="form-group has-feedback">
            <label>Podaj nazwisko <span className="text-danger">(Pole wymagane)</span></label>
            <input type="text" name="acc_surname" onChange={(event) => this.changeState(event, 'acc_surname')} value={this.state.acc_surname} className="form-control" required/>
          </div>

          <hr/>

        <div className="form-group has-feedback">
          <label>{i18n.t("login_form_password")} <span className="text-danger">(Pole wymagane)</span></label>
          <input type="password" name="password" onChange={(event) => this.changeState(event, 'password')} value={this.state.password} className="form-control" required/>
        </div>

        <p>{this.state.passwordInfo}</p>

        <hr/>



        <div className="row">
          <div className="col-xs-12">
            <button type="submit" className="btn btn-warning btn-flat">Przejdź dalej</button>
          </div>
        </div>
      </form>
    );
  }

  renderForm_step4(){
    return(
      <form onSubmit={(event) => this.handleSubmit(event)}>
        <p className="login-box-msg">Tworzenie profilu przedszkola</p>

          <div className="media">
            <div className="media-left">
              <img className="media-object img-circle img-sm" src={this.state.user.image} />
            </div>
            <div className="media-body">
              <h4 className="media-heading">{this.state.user.name}</h4>
              Nowy profil przedszkola zostanie dopisany do tego konta
            </div>
          </div>
          <hr/>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">

            <div className="form-group has-feedback">
              <label>Podaj pełną nazwę przedszkola <span className="text-danger">(Pole wymagane)</span></label>
              <input type="text" name="name" onChange={(event) => this.changeState(event, 'name')} value={this.state.name} className="form-control" required/>
            </div>

            <div className="form-group has-feedback">
              <label>Podaj skróconą nazwę przedszkola</label>
              <input type="text" name="name_short" onChange={(event) => this.changeState(event, 'name_short')} value={this.state.name_short} className="form-control"/>
            </div>

            <div className="form-group has-feedback">
              <label>Podaj adres <span className="text-danger">(Pole wymagane)</span></label>
              <input type="text" name="street" onChange={(event) => this.changeState(event, 'street')} value={this.state.street} className="form-control" required/>
            </div>

            <div className={this.state.errorType === 'zip' ? "form-group has-feedback has-error" : "form-group has-feedback" }>
              <label>Podaj kod pocztowy <span className="text-danger">(Pole wymagane)</span></label>
              <input type="text" name="postal" onChange={(event) => this.changeState(event, 'postal')} value={this.state.postal} className="form-control" required/>
              <span class="help-block">{this.state.errorType === 'zip' ? i18n.t(this.state.inputError) : null }</span>
            </div>

          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">

            <div className="form-group has-feedback">
              <label>Podaj miejscowość <span className="text-danger">(Pole wymagane)</span></label>
              <input type="text" name="city" onChange={(event) => this.changeState(event, 'city')} value={this.state.city} className="form-control" required/>
            </div>

            <div className={this.state.errorType === 'nip' ? "form-group has-feedback has-error" : "form-group has-feedback" }>
              <label>Podaj NIP <span className="text-danger">(Pole wymagane)</span></label>
              <input type="text" name="nip" onChange={(event) => this.changeState(event, 'nip')} value={this.state.nip} className="form-control" required/>
              <span class="help-block">{this.state.errorType === 'nip' ? i18n.t(this.state.inputError) : null }</span>
            </div>

            <div className={this.state.errorType === 'regon' ? "form-group has-feedback has-error" : "form-group has-feedback" }>
              <label>Podaj REGON <span className="text-danger">(Pole wymagane)</span></label>
              <input type="text" name="regon" onChange={(event) => this.changeState(event, 'regon')} value={this.state.regon} className="form-control" required/>
              <span class="help-block">{this.state.errorType === 'regon' ? i18n.t(this.state.inputError) : null }</span>
            </div>

          </div>
        </div>



        <div className="form-group has-feedback">
          <input type="checkbox" name="aggr_1" onChange={(event) => this.changeState(event, 'aggr_1')} checked={this.state.aggr_1 === 1 ? "checked" : null} value="1" required/>
          &nbsp;&nbsp;Wyrażam zgodę na przetwarzanie moich danych osobowych w celu założenia konta NetKid
        </div>

        <div className="form-group has-feedback">
          <input type="checkbox" name="aggr_2" onChange={(event) => this.changeState(event, 'aggr_2')} checked={this.state.aggr_2 === 1 ? "checked" : null} value="1" required/>
          &nbsp;&nbsp;Oświadczam, iż zapoznałem się z <a href="/regulamin-korzystania-z-uslugi" target="_blank">Regulaminem</a> usługi NetKid
      </div>

      <br/>
        <small>

          <p>Administratorem danych osobowych, przechowywanych w ramach usługi NetKid, jest Klett Polska sp. z o.o. sp.k. z siedzibą w Poznaniu (60-401) przy ul. Polskiej 114. Dane osobowe będą przetwarzane w celu założenia i korzystania z funkcjonalności usługi NetKid, a także w celach analitycznych i statystycznych. Szczegółowe informacje na temat funkcjonalności NetKid oraz przetwarzania danych osobowych można znaleźć <a href="/regulamin-korzystania-z-uslugi" target="_blank">tutaj</a>.</p>
          <p>Podanie powyższych danych osobowych i wyrażenie zgód jest dobrowolne, ale niezbędne do założenia konta i korzystania z funkcjonalności NetKid.</p>


        </small>


        <div className="row">
          <div className="col-xs-12">
            <button type="submit" className="btn btn-warning btn-flat">Przejdź dalej</button>
          </div>
        </div>
      </form>
    );
  }

  renderForm_step5(){
    return(
      <div>
        <p className="login-box-msg">Zakończenie rejestracji</p>

          <p>Gratulacje! Profil przedszkola został utworzony, możesz już skorzystać z formularza logowania aby przejść do swojego panelu.</p>
          <br/><p><small>Pozdrawiamy serdecznie oraz życzymy miłej i owocnej pracy.<br/>Zespół NETkid</small></p><br/><br/><br/>

        <div className="row">
          <div className="col-xs-12">
            <button type="button" onClick={(event) => this.redirectToLogin()} className="btn btn-warning btn-flat">Przejdź na stronę główną</button>
          </div>
        </div>
      </div>
    )
  }

  render(){
    var view;
    if(this.state.step === 1){
      view = this.renderForm_step1()
    }
    if(this.state.step === 2){
      view = this.renderForm_step2()
    }
    if(this.state.step === 3){
      view = this.renderForm_step3()
    }
    if(this.state.step === 4){
      view = this.renderForm_step4()
    }
    if(this.state.step === 5){
      view = this.renderForm_step5()
    }
    if(this.state.isLoading === true){
      view = <LoaderComponent />;
    }
    return(
      <div className="mp_register">
        <h1>Zarejestruj się<br/><small>Aby zaczać korzystać z systemu NETkid</small></h1>

        <div className="login-box">
          <div className="login-box-body">

            <div className="infoBox">
              <p className={this.state.status}><small>{i18n.t(this.state.msg)}</small></p>
            </div>

            {view}



            <p>{this.state.error}</p>

          </div>

        </div>
      </div>
    );
  }

}
