import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import * as requests from '../requests.js';

import ModulesComponent from '../ModulesComponent';
import NewsComponent from '../NewsComponent';
import ProfileComponent from '../ProfileComponent';
import ForumComponent from '../ForumComponent';
import SettlementComponent from '../SettlementComponent';
import GalleryComponent from '../GalleryComponent';
import SystemNotificationsComponent from '../SystemNotificationsComponent';

export default
class DashboardComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      objectSelectLoading: false,
      reloadList: false,
      view: 'modules',
      group: [],
      objectID: localStorage.getItem('objectID'),
      remoteSubmodule: null,
      remoteItemID: 0,
    }

    this.objectSelectChangeHandler = this.objectSelectChangeHandler.bind(this);
    this.finishListReloadHandler = this.finishListReloadHandler.bind(this);
    this.changeMainView = this.changeMainView.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    if(nextProps.moduleParams.module !== undefined && nextProps.moduleParams.submodule !== undefined && nextProps.moduleParams.id !== undefined){
      this.setState({view: nextProps.moduleParams.module, remoteSubmodule: nextProps.moduleParams.submodule, remoteItemID: nextProps.moduleParams.id});
      nextProps.moduleParams.module = undefined;
      nextProps.moduleParams.submodule = undefined;
      nextProps.moduleParams.id = undefined
    }
  }

  changeMainView(event, view, group){
    this.setState({view: view});
  }

  finishListReloadHandler(status){
    this.setState({reloadList: status});
  }

  objectSelectChangeHandler(event){
    this.setState({objectSelectLoading: true});
    localStorage.setItem('objectID', event.target.value);
    this.setState({objectSelectLoading: false, reloadList: true, objectID: event.target.value});
  }



  render(){
    var view;
    if(this.state.view === 'modules'){
      view = <ModulesComponent
          onClickModuleHandler={this.props.onClickModuleHandler}
          currentUser={this.props.currentUser}
          reload = {this.state.reloadList}
          afterReloadHandler = {this.finishListReloadHandler}
        />;
    }
    else if(this.state.view === 'news'){
      view = <NewsComponent
        reload = {this.state.reloadList}
        afterReloadHandler = {this.finishListReloadHandler}
      />;
    }
    else if(this.state.view === 'profile'){
      view = <ProfileComponent
        reload = {this.state.reloadList}
        afterReloadHandler = {this.finishListReloadHandler}
        submodule = {this.state.remoteSubmodule}
        />;
    }

    else if(this.state.view === 'settlement'){
      view = <SettlementComponent
        reload = {this.state.reloadList}
        afterReloadHandler = {this.finishListReloadHandler}
        objectID = {this.state.objectID}
        />;
    }
    else if(this.state.view === 'gallery'){
      view = <GalleryComponent
        reload = {this.state.reloadList}
        afterReloadHandler = {this.finishListReloadHandler}
        />;
    }

    else if(this.state.view === 'systemNotications'){
      view = <SystemNotificationsComponent
        reload = {this.state.reloadList}
        afterReloadHandler = {this.finishListReloadHandler}
        />;
    }

    return(
      <div>

        <section className="content-header">
          <div className="row">
              <div className="col-md-8 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-green"><i className="fas fa-chalkboard"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-number">
                      <h3>{i18n.t('dashboard_module')}<br/><small>{i18n.t('dashboard_module_subtitle')}</small></h3>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                  <ObjectSelectComponent
                    objectSelectChangeHandler={this.objectSelectChangeHandler}
                    currentlyChoosen={localStorage.getItem('objectID')}
                    loading={this.state.objectSelectLoading}
                    />
              </div>

          </div>
        </section>

        <section className="content container-fluid">
        <div className="nav-tabs-custom">
            <ul className="nav nav-tabs">
              <li className={this.state.view === 'modules' ? "active" : null}><a href="#tab_1" onClick={(event) => this.changeMainView(event, 'modules')} data-toggle="tab" aria-expanded="false"><i className="far fa-smile-beam"></i> {i18n.t('welcome')} {this.props.currentUser.name}!</a></li>
              <li className={this.state.view === 'news' ? "active" : null}><a href="#tab_1" onClick={(event) => this.changeMainView(event, 'news')} data-toggle="tab" aria-expanded="false"><i className="far fa-newspaper"></i> {i18n.t('news')}</a></li>
              <li className={this.state.view === 'profile' ? "active" : null}><a href="#tab_1" onClick={(event) => this.changeMainView(event, 'profile')} data-toggle="tab" aria-expanded="false"><i className="fas fa-school"></i> {i18n.t('school_profile')}</a></li>
              <li className={this.state.view === 'gallery' ? "active" : null}><a href="#tab_1" onClick={(event) => this.changeMainView(event, 'gallery')} data-toggle="tab" aria-expanded="false"><i className="fas fa-images"></i> {i18n.t('group_gallery')}</a></li>
              <li className={this.state.view === 'settlement' ? "active" : null}><a href="#tab_1" onClick={(event) => this.changeMainView(event, 'settlement')} data-toggle="tab" aria-expanded="false"><i className="fas fa-wallet"></i> {i18n.t('wallet')}</a></li>
              <li className={this.state.view === 'systemNotications' ? "active" : null}><a href="#tab_1" onClick={(event) => this.changeMainView(event, 'systemNotications')} data-toggle="tab" aria-expanded="false"><i className="fas fa-info-circle"></i> {i18n.t('msg_from_netkid')}</a></li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active" id="tab_1">{view}</div>
            </div>

          </div>
        </section>

      </div>
    );
  }

}
