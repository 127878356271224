import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import * as moment from "moment";

export default
class CreateGroupComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      name: '',
      age: 3,
      year_start: moment().format("YYYY"),
      year_end: moment().format("YYYY"),
      status: '',
      msg: null,
      validationErrors: [],
    }

    this.changeState = this.changeState.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  changeState(event, type){
    if(type === 'name'){
      this.setState({name: event.target.value});
    }
    if(type === 'age'){
      this.setState({age: event.target.value});
    }
    if(type === 'year_start'){
      this.setState({year_start: event.target.value});
    }
    if(type === 'year_end'){
      this.setState({year_end: event.target.value});
    }
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true, validationErrors: []});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'name'  : this.state.name,
      'age' : this.state.age,
      'year_start' : this.state.year_start,
      'year_end' : this.state.year_end,
    }

    let request = requests.postGroupStore(array);
    request.then(function(response){
      this.setState(
        {
          status: 'success',
          msg: i18n.t(response.data.msg),
          name: '',
          age: 3,
          year_start: moment().format("YYYY"),
          year_end: moment().format("YYYY"),
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        if(error.response.data.error === 'validation_error'){
          this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
        }
        else{
          this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
        }
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderCreateForm(){
    return(
      <form onSubmit={(event) => this.submitForm(event)}>

        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12">

            <div className={'name' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('set_group_name')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <input type="text" name="name" value={this.state.name} onChange={(event) => this.changeState(event, 'name')} className="form-control" required />
              <span class="help-block">{'name' in this.state.validationErrors ? i18n.t(this.state.validationErrors['name']) : null}</span>
            </div>

            <div className={'age' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('set_age_level')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <select name="age" className="form-control" onChange={(event) => this.changeState(event, 'age')} value={this.state.age}>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
              <span class="help-block">{'age' in this.state.validationErrors ? i18n.t(this.state.validationErrors['age']) : null}</span>
            </div>

            <div className={'year_start' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('set_year_start')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <input type="number" step="1" name="year_start" value={this.state.year_start} onChange={(event) => this.changeState(event, 'year_start')} className="form-control" required />
              <span class="help-block">{'year_start' in this.state.validationErrors ? i18n.t(this.state.validationErrors['year_start']) : null}</span>
            </div>

            <div className={'year_end' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('set_year_end')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <input type="number" step="1" name="year_end=" value={this.state.year_end} onChange={(event) => this.changeState(event, 'year_end')} className="form-control" required />
              <span class="help-block">{'year_end' in this.state.validationErrors ? i18n.t(this.state.validationErrors['year_end']) : null}</span>
            </div>

          </div>
        </div>



        <hr/>
        <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>

      </form>
    )
  }

  render(){
    return(
      <div>
        {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
        {this.renderCreateForm()}
      </div>
    )
  }

}
