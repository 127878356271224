import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';

export default
class ParentDetailsViewComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      isLoading: false,
      status: '',
      error: null,
      details: [],
    }
  }

  renderMainView(){
    return(
      <div>
        <button className="btn btn-default" onClick={(event) => this.props.changeView('main')}>{i18n.t('go_back_to_calendar')}</button>
        <h3><small>{i18n.t('activity_title')}:</small><br/>{this.props.details.title_string}</h3>

          <br/><br/>
          <h5><b>{i18n.t('daily_log_morning_ac')}:</b><br/>{this.props.details.morning_activity}</h5>
          <h5><b>{i18n.t('daily_log_activities_1')}:</b><br/>{this.props.details.activities_main_1}</h5>
          <h5><b>{i18n.t('daily_log_activities_2')}:</b><br/>{this.props.details.activities_main_2}</h5>
          <h5><b>{i18n.t('daily_log_activities_indoor')}:</b><br/>{this.props.details.afternoon_classes}</h5>
          <h5><b>{i18n.t('daily_log_activities_outdoor')}:</b><br/>{this.props.details.outdoor_activities}</h5>
          <h5><b>{i18n.t('daily_log_card_content')}:</b><br/>{this.props.details.card_content}</h5>

      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderMainView();
    }
    else{
      view = <LoaderComponent />;
    }

    return(
      <div>
        {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
        <div className="nav-tabs-custom"><div className="box-body">{view}</div></div>
      </div>
    );
  }

}
