import React, {Component} from 'react';

import * as requests from '../requests.js';
import i18n from "i18next";
import LoaderComponent from '../LoaderComponent';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';

export default
class TeacherAssignToActivityComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'list',
      currentTeacherID: 0,
      currentRelationID: 0,
      teachers: [],
      teachersSelect: [],
      selectedTeacher: [],
      selectedDay: [],
      startDate: moment(),
      endDate: moment(),
      daysSelect: [
        {value: 'Mon', label: i18n.t('Mon')},
        {value: 'Tue', label: i18n.t('Tue')},
        {value: 'Wed', label: i18n.t('Wed')},
        {value: 'Thu', label: i18n.t('Thu')},
        {value: 'Fri', label: i18n.t('Fri')},
      ],
      status: '',
      msg: null,
    }

    this.getAllTeachersList = this.getAllTeachersList.bind(this);
    this.getTeacherDetails = this.getTeacherDetails.bind(this);
    this.handleSelectedTeacherChange = this.handleSelectedTeacherChange.bind(this);
    this.handleSelectedDayChange = this.handleSelectedDayChange.bind(this);
    this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
    this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
  }

  componentWillMount(){
    this.getTeachersFromActivity(this.props.activityID);

  }

  componentDidUpdate(prevProps) {
    if (this.props.activityID !== prevProps.activityID) {
      this.getTeachersFromActivity(this.props.activityID);
    }
  }

  handleSelectedTeacherChange(teacher){
    this.setState({selectedTeacher: teacher});
  }

  handleSelectedDayChange(day){
    this.setState({selectedDay: day})
  }

  handleChangeStartTime(date){
    this.setState({startDate: date});
  }

  handleChangeEndTime(date){
    this.setState({endDate: date});
  }

  getAllTeachersList(){
    this.setState({isLoading: true, teachers: []});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getSchoolTeachersSelect(array);
    request.then(function(response){
      this.setState({teachersSelect: response.data});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true, teachers: []});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.state.currentTeacherID,
      'teacher_id' : this.state.selectedTeacher.value,
      'relation_id' : this.state.currentRelationID,
      'day' : this.state.selectedDay.value,
      'time_start': moment(this.state.startDate).format("HH:mm"),
      'time_end' : moment(this.state.endDate).format("HH:mm"),
    }

    let request = requests.postSchoolTeacherCalendarUpdate(array);
    request.then(function(response){
      this.setState({status : 'success', msg: response.data.msg, view: 'list'}, ()=>{this.getTeachersFromActivity(this.props.activityID)});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  deleteForm(event){
    event.preventDefault();
    this.setState({isLoading: true, teachers: []});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.state.currentTeacherID,
    }

    let request = requests.postSchoolTeacherCalendarDelete(array);
    request.then(function(response){
      this.setState({status : 'success', msg: response.data.msg, view: 'list'}, ()=>{this.getTeachersFromActivity(this.props.activityID)});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getTeacherDetails(id){}

  getTeachersFromActivity(activityID){
    this.setState({isLoading: true, teachers: []});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : activityID,
    }

    let request = requests.getSchoolAdditionalActivityTeachers(array);
    request.then(function(response){
      this.setState({teachers: response.data[0]});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderTeachersList(){
    return(
      <div>
        <ul className="list-unstyled">
            {
              this.state.teachers.map(function(cal){
                return(
                  <li key={cal.id}>
                    <div className="media">
                      <div className="media-left">
                        <img className="media-object img-md img-circle" src={cal.teacher.account.image} alt="..." />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">{cal.teacher.account.name} {cal.teacher.account.surname}</h4>
                        <p>{cal.day}. {i18n.t('in_hours')}: {cal.time_start} - {cal.time_end}</p><br/>
                        <ul className="list-inline">
                          <li><button onClick={(event) => this.setState(
                              {
                                view : 'edit',
                                currentTeacherID: cal.id,
                                currentRelationID: cal.relation_id,
                                selectedDay: {value: cal.day, label: i18n.t(cal.day)},
                                selectedTeacher: {value: cal.teacher.id, label: cal.teacher.account.name + " " + cal.teacher.account.surname},
                                startDate: new Date(moment(cal.time_start, "HH:mm")),
                                endDate: new Date(moment(cal.time_end, "HH:mm"))
                              }, () => {this.getTeacherDetails(cal.id); this.getAllTeachersList()})} className="btn btn-default">{i18n.t('button_edit')}</button></li>
                          <li><button onClick={(event) => this.setState({view : 'delete', currentTeacherID: cal.id})} className="btn btn-danger">{i18n.t('button_delete')}</button></li>
                        </ul>
                      </div>
                    </div>
                    <hr/>
                  </li>
                )
              }.bind(this))
            }
        </ul>
      </div>
    );
  }

  renderListView(){
    var view = null
    if(this.state.teachers !== undefined){
      view = this.renderTeachersList();
    }
    return(
      <div>
      <button onClick={(event) => this.setState(
          {
            view : 'create',
            currentTeacherID: 0,
            currentRelationID: this.props.activityID,
            selectedDay: [],
            selectedTeacher: [],
            startDate: new Date(),
            endDate: new Date()
          }, () => {this.getAllTeachersList()})} className="btn btn-default">{i18n.t('add_new_item')}</button><hr/>
        {view}
      </div>
    );
  }

  renderFormView(){
    return (
      <form onSubmit={(event) => {this.submitForm(event)}}>

      <div className="form-group">
        <label>{i18n.t('select_teacher')}</label>
        {
          <Select
            value={this.state.selectedTeacher}
            onChange={this.handleSelectedTeacherChange}
            options={this.state.teachersSelect}
            isMulti={false}
            placeholder={i18n.t('select_teacher')}
          />
        }
      </div>
      <div className="form-group">
        <label>{i18n.t('select_activity_day')}</label>
        {
          <Select
            value={this.state.selectedDay}
            onChange={this.handleSelectedDayChange}
            options={this.state.daysSelect}
            isMulti={false}
            placeholder={i18n.t('select_activity_day')}
          />
        }
      </div>

      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className="form-group">
            <label>{i18n.t('select_activity_hour')}</label>
              <DatePicker
                selected={this.state.startDate}
                onChange={this.handleChangeStartTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                timeCaption={i18n.t('select_hour')}
                className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className="form-group">
            <label>{i18n.t('select_activity_ends_hour')}</label>
              <DatePicker
                selected={this.state.endDate}
                onChange={this.handleChangeEndTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                timeCaption={i18n.t('select_hour')}
                className="form-control"
            />
          </div>
        </div>
      </div>

      <hr/>
      <ul className="list-inline">
        <li><button type="submit" className="btn btn-success">{i18n.t('button_save')}</button></li>
        <li><button type="button" onClick={(event) => this.setState({view : 'list', selectedTeacher: [], selectedDay: []}, () => {this.getTeachersFromActivity(this.props.activityID)})} className="btn btn-default">{i18n.t('button_cancel')}</button></li>
      </ul>

      </form>
    );
  }

  renderDeleteView(){
    return (
      <form onSubmit={(event) => this.deleteForm(event)}>
        <p>{i18n.t('confirm_delete_action')}</p><hr/>
          <ul className="list-inline">
            <li><button type="submit" className="btn btn-danger">{i18n.t('button_delete')}</button></li>
            <li><button type="button" onClick={(event) => this.setState({view : 'list', selectedTeacher: [], selectedDay: []}, () => {this.getTeachersFromActivity(this.props.activityID)})} className="btn btn-default">{i18n.t('button_cancel')}</button></li>
          </ul>
      </form>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView();
      }
      else if(this.state.view === 'create' || this.state.view === 'edit'){
        view = this.renderFormView();
      }
      else if(this.state.view === 'delete'){
        view = this.renderDeleteView();
      }
    }
    else{
      view = <LoaderComponent />
    }

    return(
      <div>
        {view}
      </div>
    )
  }

}
