import React, {Component} from 'react';
import i18n from "i18next";

export default class RegulationsComponent extends Component{

  constructor(props){
    super(props);
  }

  render(){
    return(
      <div className="container">

        <div className="content-body">


          <p><strong>Regulamin NetKid</strong></p>
          <p><strong>I Definicje.</strong></p>
          <ul>
          <li><strong>Właściciel Systemu NetKid &ndash; </strong>Klett Polska Sp. z o.o. ul. Polska 114, 60-401 Poznań, zarejestrowana przez Sad Rejonowy Poznań Nowe Miasto i Wilda, VIII Wydział Gospodarczy Krajowego Rejestru Sadowego,&nbsp;KRS 0000175262, NIP 779-00-00-273 (zwany dalej Klett).</li>
          <li><strong>Przedszkole &ndash; </strong>jednostka korzystająca z funkcjonalności Systemu na podstawie zawartej z&nbsp;Właścicielem Systemu (Klett) umowy.</li>
          <li><strong>Użytkownik Systemu &ndash; </strong>rodzic/opiekun prawny dziecka, kt&oacute;ry otrzyma od Przedszkola (jego dyrektora bądź osoby przez niego uprawnionej), do kt&oacute;rego dziecko uczęszcza uprawnienia, niezbędne do rejestracji i korzystania z Systemu NetKid.</li>
          <li><strong>Konto dziecka &ndash; </strong>zbi&oacute;r informacji wprowadzanych dobrowolnie przez pracownik&oacute;w Przedszkola na podstawie umowy z Właścicielem Systemu (Klett), kt&oacute;re są zabezpieczone unikalnym loginem i hasłem.</li>
          <li><strong>System &ndash; </strong>narzędzie do przekazywania informacji i gromadzenia danych pomiędzy Przedszkolem a Użytkownikiem.</li>
          <li><strong>Aplikacja &ndash; </strong>mobilna wersja Systemu działająca na urządzeniach mobilnych (takich jak smartfony, tablety).</li>
          </ul>
          <p><strong>II Postanowienia og&oacute;lne.</strong></p>
          <ul>
          <li>Za niezawodność działania Systemu, na serwerach oraz tworzenie kopii bezpieczeństwa, odpowiada Klett a także pracownicy Przedszkola, mający bezpośredni dostęp do edycji i przeglądania danych oraz rodzice/opiekunowie prawni w zakresie udostępnionych im danych.</li>
          <li>Za prawidłowość wprowadzenia danych do Systemu, ochronę danych osobowych umieszczonych na serwerach oraz tworzenie kopii bezpieczeństwa, odpowiadają pracownicy Przedszkola, kt&oacute;rzy mają bezpośredni dostęp do edycji i przeglądania danych oraz rodzice/opiekunowie prawni w zakresie udostępnionych im danych.</li>
          <li>Szczeg&oacute;łową odpowiedzialność stron reguluje umowa zawarta między Przedszkolem, a Klett oraz przepisy obowiązującego w Polsce prawa.</li>
          <li>NetKid korzysta z Google Analytics, aby śledzić anonimowe dane dotyczące korzystania z Systemu.</li>
          <li>Zapisy zawarte w niniejszym Regulaminie zostały oparte i respektują przepisy prawne obowiązujące na terytorium RP.</li>
          </ul>
          <p><strong>III Techniczne warunki korzystania z NetKid.</strong></p>
          <ul>
          <li>W celu korzystania z NetKid Użytkownik powinien posiadać:</li>
          <ul>
          <li>dostęp do sieci Internet;</li>
          <li>adres poczty email;</li>
          </ul>
          <li>W celu zwiększenia bezpieczeństwa powierzonych danych osobowych system zbiera informacje zawarte w plikach cookies, kt&oacute;re są plikami tekstowymi zawierającymi identyfikator urządzenia, z kt&oacute;rego korzysta Użytkownik.</li>
          <li>Szczeg&oacute;łowe informacje na temat funkcjonowania plik&oacute;w cookies zawarte zostały w ich Polityce (link do Polityki Cookies)</li>
          <li>NetKid dostępny jest dla wszystkich użytkownik&oacute;w urządzeń mobilnych oraz stacjonarnych spełniających wskazane poniżej wymagania techniczne, kt&oacute;rzy pobiorą ją i prawidłowo uruchomią na urządzeniu mobilnym.</li>
          <li>NetKid może zostać uruchomiony na przeglądarkach internetowych funkcjonujących na komputerach (zar&oacute;wno laptopach jak i urządzeniach stacjonarnych). Do prawidłowego użytkowania NetKid zaleca się następujących przeglądarek internetowych:</li>
          <ul>
          <li>Microsoft Edge,</li>
          <li>Google Chrome,</li>
          <li>Mozilla,</li>
          <li>Opera,</li>
          <li>Safari.</li>
          </ul>
          <li>Urządzenie mobilne, na kt&oacute;rym ma zostać uruchomiona aplikacja, spełniać musi następujące wymagania techniczne dotyczące systemu operacyjnego:</li>
          <ul>
          <li>dla wersji aplikacji pobranej z App Store - iOS w wersji minimum &hellip;&hellip;,</li>
          <li>dla wersji aplikacji pobranej z Google Play - Android w wersji minimum &hellip;&hellip;,</li>
          </ul>
          <li>Do prawidłowego działania NetKid niezbędne jest aktywne połączenie internetowe.</li>
          </ul>
          <p><strong>IV Rejestracja w Systemie NetKid.</strong></p>
          <ul>
          <li>Użytkownik w celu korzystania z NetKid dokonuje rejestracji poprzez podanie swojego imienia, nazwiska, adresu e-mail oraz hasła.</li>
          </ul>
          <ul>
          <li>Każdy Użytkownik może jednocześnie korzystać z jednego loginu.</li>
          <li>Akceptacja Regulaminu przez Użytkownika NetKid staje się jednocześnie deklaracją, iż Użytkownik:</li>
          <ul>
          <li>dobrowolnie przystąpił do korzystania z NetKid;</li>
          <li>nie będzie przekazywał danych zawartych w NetKid nieuprawnionym osobom trzecim;</li>
          <li>podał wszelkie dane zgodnie z prawdą.</li>
          </ul>
          </ul>
          <p><strong>V Korzystanie z Systemu.</strong></p>
          <ul>
          <li>Korzystanie z NetKid jest dla Użytkownika dobrowolne oraz nie ponosi w związku z tym żadnych opłat.</li>
          <li>Umowa o korzystanie z funkcjonalności NetKid zawarta zostaje poprzez:</li>
          </ul>
          <ul>
          <ul>
          <li>wypełnienie formularza rejestracyjnego;</li>
          <li>zapoznanie się z treścią i akceptacją niniejszego Regulaminu;</li>
          </ul>
          </ul>
          <ul>
          <li>Rejestracja w NetKid umożliwia:</li>
          </ul>
          <ul>
          <ul>
          <li>zgłaszanie do Przedszkola informacji o nieobecności dziecka;</li>
          <li>edycje danych dziecka</li>
          <li>dodawanie nowych użytkownik&oacute;w do konta</li>
          <li>zapoznanie się ze wszystkimi informacjami związanymi z przebywaniem dziecka w Przedszkolu;</li>
          <li>wgląd w kalendarz;</li>
          <li>możliwość zapoznania się z komunikatami i informacjami przekazywanymi przez Przedszkole.</li>
          <li>Przeglądanie galerii</li>
          </ul>
          </ul>
          <p><strong>VI Prawa i Obowiązki Przedszkola</strong></p>
          <ul>
          <li>Wszelkie prawa i obowiązki pomiędzy Przedszkolem a Klett określone zostały w umowie zawartej pomiędzy w/w stronami.</li>
          <li>Przedszkole, zgodnie z przepisami prawa jest Administratorem Danych Osobowych przechowywanych na Koncie Dziecka i decyduje w pełni o celach i środkach ich przetwarzania. Szczeg&oacute;łowe informacja na temat przetwarzania danych zawarte zostały w rozdziale X Regulaminu.</li>
          <li>Na podstawie zawartej umowy określonej w ust. 1 powyżej oraz umowy powierzenia przetwarzania danych osobowych Przedszkole powierza Klett dane, tylko i wyłącznie w celu obsługi technicznej NetKid.</li>
          <li>Zarządzaniem danymi (tj. m.in. ich wprowadzaniem) zajmuje się wyłącznie Przedszkole, jak r&oacute;wnież na własną odpowiedzialność tworzy, zmienia, zarządza, redaguje, przegląda oraz przetwarza dane zawarte na Koncie Dziecka.</li>
          <li>Przedszkole nie może przekazywać danych osobom do tego nieuprawnionym.</li>
          </ul>
          <p><strong>VII Prawa i Obowiązki Użytkownika</strong></p>
          <ul>
          <li>Użytkownik rejestruje się w NetKid w celu korzystania z informacji wprowadzonych przez Przedszkole.</li>
          </ul>
          <ul>
          <li>Użytkownik korzysta z funkcjonalności NetKid w spos&oacute;b świadomy i na własną odpowiedzialność.</li>
          </ul>
          <ul>
          <li>Zaleca się, aby Użytkownik upewnił się, czy jego środowisko komputerowe i środowisko jego urządzenia mobilnego jest bezpieczne.</li>
          <li>Użytkownik zobowiązany jest do ochrony i stosowania podstawowych metod zabezpieczeń urządzeń, za pomocą kt&oacute;rych loguje się do NetKid. Rekomendowane metody zabezpieczeń, kt&oacute;re powinny być stosowane:</li>
          </ul>
          <ul>
          <li>programy antywirusowe i antyspamowe, przy czym istotne jest, aby je na bieżąco aktualizować;</li>
          <li>zapory sieciowe &ndash; firewall;</li>
          <li>aktualizacja system&oacute;w operacyjnych komputera, instalowanie poprawek wydanych przez producenta systemu operacyjnego;</li>
          <li>stosowanie haseł zabezpieczających dostęp do komputera lub telefonu, wygaszaczy ekranu chronionych hasłem &ndash; szczeg&oacute;lnie jeśli poza Użytkownikiem z urządzenia korzysta więcej os&oacute;b;</li>
          <li>nieudostępniania dostępu do swojego Konta NetKid innym osobom;</li>
          <li>niekorzystanie z opcji automatycznego zapamiętywania przez urządzenie Użytkownika login&oacute;w/haseł dostępu do NetKid.</li>
          </ul>
          <ul>
          <li>Użytkownik lub każda osoba, posiadająca wiedzę o naruszeniu praw, może zgłosić powyższe naruszenie za pomocą poczty elektronicznej. W treści informacji należy wskazać rodzaj naruszenia oraz podmiot, kt&oacute;ry się go dopuścił. Naruszenie zasad należy zgłosić za pośrednictwem poczty elektronicznej na adres email: <strong>kontakt@netkid.pl</strong></li>
          <li>W ramach NetKid zabronione jest działanie Użytkownika, kt&oacute;re naruszałoby w rażący spos&oacute;b postanowienia Regulaminu lub przepisy obowiązującego prawa.</li>
          <li>W celu poprawnego działania Użytkownik ma obowiązek bieżącego aktualizowania aplikacji mobilnej korzystającej z NetKid.</li>
          </ul>
          <p><strong>VIII Prawa i Obowiązki Klett</strong></p>
          <ul>
          <li>Klett troszczy się o należyte działanie Systemu NetKid.</li>
          <li>Klett stosuje zabezpieczenia techniczne w celu ochrony danych zapisanych na Koncie Plac&oacute;wki przed dostępem os&oacute;b trzecich.</li>
          <li>Klett może przetwarzać dane osobowe wprowadzone do NetKid wyłącznie na podstawie zawartej z Przedszkolem umowy powierzenia przetwarzania danych osobowych.</li>
          <li>Klett ma możliwość zablokowania Przedszkolu lub Użytkownikowi korzystanie z NetKid, w następujących sytuacjach:</li>
          <ul>
          <li>Uciążliwego bądź notorycznego łamania zasad określonych w Regulaminie zar&oacute;wno przez Przedszkole jak i Użytkownik&oacute;w;</li>
          <li>podejmowania działań na szkodę Przedszkola, Użytkownika lub Właściciela Systemu;</li>
          <li>nie uregulowaniu przez Przedszkole faktury VAT za usługę w terminie i kwocie podanych w Umowie zawartej między Przedszkolem a Klett.</li>
          </ul>
          <li>Klett nie ponosi odpowiedzialności za:</li>
          <ul>
          <li>spos&oacute;b i skutki wykorzystania danych i informacji, kt&oacute;re zostały wprowadzone przez Przedszkole lub Użytkownika;</li>
          <li>brak należytej staranności przy przetwarzaniu danych osobowych przez Przedszkole;</li>
          <li>za szkody powstałe w związku z brakiem w Przedszkolu odpowiednich zabezpieczeń, bądź udostępnieniem przez Przedszkole lub Użytkownika Konta Użytkownika osobom nieuprawnionym.</li>
          </ul>
          <li>Klett nie odpowiada za bezprawne działania Przedszkola, jeżeli nie posiada świadomości fakt&oacute;w i okoliczności tego działania.</li>
          </ul>
          <p><strong>IX</strong><strong> Zgłaszania reklamacji </strong></p>
          <ul>
          <li>Użytkownik ma możliwość zgłoszenia wszelkich zakł&oacute;ceń w funkcjonowaniu NetKid, wysyłając wiadomość e-mail na adres dostępny na stronie <strong>kontakt@netkid.pl</strong> lub podając poniższe informacje w innej formie:</li>
          </ul>
          <ul>
          <ul>
          <li>swoje dane umożliwiające kontakt;</li>
          <li>rodzaj błędnego funkcjonowania;</li>
          <li>czas, kt&oacute;ry stanowi podstawę reklamacji;</li>
          <li>wskazanie problemu</li>
          <li>okoliczności uzasadniające wystąpienie problemu;</li>
          <li>spos&oacute;b usunięcia problemu.</li>
          </ul>
          <li>Klett rozpatrzy zgłoszenia w terminie 14 dni od chwili prawidłowego zgłoszenia problemu. Wskaże, czy uznaje roszczenie oraz spos&oacute;b, w jaki zamierza je zrealizować lub poinformuje o braku podstaw do uznania reklamacji wraz z uzasadnieniem swojego stanowiska.</li>
          <li>Odpowiedź ze strony Klett w sprawie reklamacji jest wysyłana na podany przez Użytkownika adres e-mail.</li>
          </ul>
          <p><strong>X. Ochrona danych osobowych</strong></p>
          <ul>
          <li>Administratorem danych osobowych podawanych przez Użytkownika w ramach Konta Dziecka jest Przedszkole (ADO). Podane dane osobowe przetwarzane są w celu realizacji usług związanych z&nbsp;funkcjonowaniem NetKid.</li>
          <li>W przypadku danych podawanych podczas rejestracji i służących następnie do logowania przez Użytkownika oraz przedstawiciela Przedszkola (dyrektora, wskazanego pracownika) Administratorem Danych Osobowych staje się Klett.</li>
          <li>Podanie danych osobowych podczas rejestracji jest dobrowolne, ale niezbędne, aby Użytkownik m&oacute;gł korzystać z funkcjonalności NetKid.</li>
          <li>ADO uprawniony jest do ujawnienia danych osobowych wyłącznie podmiotom upoważnionym na podstawie umowy, właściwych przepis&oacute;w prawa, zgodnie z postanowieniami Regulaminu.</li>
          <li>Zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony os&oacute;b fzycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (og&oacute;lne rozporządzenie o ochronie danych - RODO) użytkownikowi przysługują następujące prawa dotyczące podanych przez niego danych osobowych:</li>
          </ul>
          <ul>
          <ul>
          <li>Prawo dostępu do danych osobowych,</li>
          <li>Prawo do usunięcia danych osobowych/bycia zapomnianym.</li>
          <li>Prawo do wniesienia sprzeciwu przetwarzania danych osobowych.</li>
          <li>Prawo do ograniczenia przetwarzania jego danych osobowych.</li>
          <li>Prawo do przeniesienia danych.</li>
          <li>Prawo do niepodejmowania zautomatyzowanych decyzji wobec jego osoby w tym profilowania.</li>
          </ul>
          </ul>
          <ul>
          <li>W przypadku braku możliwości bezwzględnej identyfkacji osoby, na przykład w związku z za- kresem podanych przez nią danych, Administrator może odm&oacute;wić podjęcia działań na żądanie osoby, kt&oacute;rej dane dotyczą informując o tym tą osobę, chyba że osoba ta dostarczy dodatkowych informacji pozwalających ją zidentyfkować.</li>
          <li>Przedszkole oraz Klett zobowiązują się do zastosowania środk&oacute;w technicznych i&nbsp;organizacyjnych zapewniających ochronę danych, w szczeg&oacute;lności przez zabezpieczenie danych przed ich udostępnieniem osobom nieupoważnionym, w tym ze szczeg&oacute;lnym uwzględnieniem art. 32 RODO.</li>
          <li>Wszelkie dane, kt&oacute;re mają status danych osobowych, w tym danych szczeg&oacute;lnych kategorii (określonych w Art. 9 RODO), wprowadzane są do Konta Dziecka przez Użytkownika bądź Przedszkole tylko i wyłącznie w spos&oacute;b dobrowolny i świadomy.</li>
          <li>W każdym przypadku uznania, że jej prawa wynikające z przepis&oacute;w prawa i niniejszej polityki prywatności są naruszane osobie fizycznej przysługuje prawo do wniesienia skargi do Urzędu Ochrony Danych Osobowych.</li>
          <li>Więcej szczeg&oacute;ł&oacute;w na temat przetwarzania danych osobowych zawarto w Polityce Prywatnosci Klett pod adresem: https://klett.pl/polityka-prywatnosci.&nbsp;</li>
          </ul>
          <p><strong>XI Własność Intelektualna</strong></p>
          <ul>
          <li>System NetKid jest programem komputerowym w rozumieniu ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (Dz. U. Nr 2010 r. 152, poz. 1016, z p&oacute;źn.zm.).</li>
          </ul>
          <ul>
          <li>Wszelkie prawa własności intelektualnej wynikające z konstrukcji NetKid, zastosowane technologie, Know-how oraz funkcjonalności są własnością Klett i chronione są przepisami prawa autorskiego i pokrewnymi.</li>
          <li>Użytkownik i Przedszkole korzystające z funkcjonalności NetKid zobowiązują się do przestrzegania autorskich praw majątkowych oraz praw wynikających z rejestracji wynalazk&oacute;w, patent&oacute;w, znak&oacute;w towarowych, wzor&oacute;w użytkowych i przemysłowych.</li>
          <li>Użytkownik i Przedszkole nie mają prawa bez wcześniejszej zgody Klett do kopiowania bądź rozpowszechniania wszelkich materiał&oacute;w udostępnianych w NetKid w celach majątkowych.</li>
          <li>Wyłączne prawa autorskie do oprogramowania, loga, grafiki, zdjęć oraz innych informacji NetKid należą do Klett i podlegają ochronie prawnej zgodnie z Ustawą o prawie autorskim i&nbsp;prawach pokrewnych z dnia 4 lutego 1994 r. (Dz. U. z 1994r. nr 24, Poz.83 ze zm.).</li>
          </ul>
          <p><strong>XII Rozwiązanie umowy</strong></p>
          <ul>
          <li>Użytkownik może wypowiedzieć umowę o świadczenie usług drogą elektroniczną w każdym czasie. Oświadczenie o wypowiedzeniu składane przez Użytkownika powinno zostać złożone za pośrednictwem poczty e-mail, przy czym wiadomość powinna zostać wysłana z adresu podanego w Koncie Użytkownika. Za datę złożenia oświadczenia o wypowiedzeniu uznaje się datę odebrania wiadomości e-mail zawierającej oświadczenie o wypowiedzeniu.</li>
          <li>Klett może rozwiązać w trybie natychmiastowym umowę o świadczenie usług drogą elektroniczną w stosunku do Przedszkola bądź Użytkownika w przypadku, gdy:</li>
          </ul>
          <ul>
          <ul>
          <li>Przedszkole bądź Użytkownik, w przypadku zmiany, niezwłocznie nie dokonał aktualizacji danych wymaganych do rejestracji w Systemie;</li>
          <li>Przedszkole bądź Użytkownik w rażący lub uporczywy spos&oacute;b narusza postanowienia Regulaminu lub podejmuje działania sprzeczne z celami i przeznaczeniem NetKid;</li>
          <li>Przedszkole bądź Użytkownik przekazuje dostęp do Konta oraz dane osobom trzecim do tego nieuprawnionym.</li>
          </ul>
          </ul>
          <ul>
          <li>Oświadczenie o wypowiedzeniu umowy o świadczenie usług drogą elektroniczną składane przez Klett zostanie przesłane na podany w Koncie Przedszkole bądź Użytkownik adres e-mail.</li>
          <li>Wypowiedzenie umowy przez kt&oacute;rąkolwiek ze stron, jak r&oacute;wnież jej rozwiązanie za obustronną zgodą jest r&oacute;wnoznaczne z zablokowaniem Przedszkola bądź Użytkownikowi dostępu do Konta Użytkownika oraz jego usunięciem.</li>
          </ul>
          <p><strong>XIII Przepisy Końcowe.</strong></p>
          <ul>
          <li>Klett ma prawo do zmiany Regulaminu w dowolnym czasie. Zmiany Regulaminu obowiązują od momentu jego umieszczenia. Zmiany uważane są za zaakceptowane przez użytkownika z momentem skorzystania przez niego z NetKid.</li>
          <li>W sprawach nieuregulowanych niniejszym regulaminem zastosowanie mają przepisy Ustawy o Prawie Autorskim i Prawach Pokrewnych z dnia 4 lutego 1994 r. oraz przepisy Kodeksu cywilnego oraz inne postanowienia prawa polskiego.</li>
          <li>Spory powstałe na gruncie Regulaminu lub um&oacute;w o świadczenie usług będą rozwiązywane w drodze negocjacji i mediacji, a w razie braku porozumienia przez Sąd Powszechny według właściwości Klett.</li>
          </ul>


        </div>

      </div>
    )
  }

}
