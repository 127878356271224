import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';

export default
class CreateComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      type: 1,
      status: '',
      msg: null,
      isLoading: false,
      view: 'step1',
      name: '',
      surname: '',
      email: '',
      phone: '',
      address: '',
      home_no: '',
      flat_no: '',
      postal_code: '',
      city: '',
      validationErrors: [],
    }

    this.changeState = this.changeState.bind(this);
    this.submitFormStep1 = this.submitFormStep1.bind(this);
    this.submitFormStep2 = this.submitFormStep2.bind(this);
  }

  changeState(event, type){
    if(type === 'type'){
      this.setState({type: event.target.value});
    }
    if(type === 'email'){
      this.setState({email: event.target.value});
    }
    if(type === 'name'){
      this.setState({name: event.target.value});
    }
    if(type === 'surname'){
      this.setState({surname: event.target.value});
    }
    if(type === 'phone'){
      this.setState({phone: event.target.value});
    }
    if(type === 'address'){
      this.setState({address: event.target.value});
    }
    if(type === 'home_no'){
      this.setState({home_no: event.target.value});
    }
    if(type === 'flat_no'){
      this.setState({flat_no: event.target.value});
    }
    if(type === 'postal_code'){
      this.setState({postal_code: event.target.value});
    }
    if(type === 'city'){
      this.setState({city: event.target.value});
    }
  }

  submitFormStep1(event){
    event.preventDefault();
    this.setState({isLoading: true, validationErrors: []});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'email'  : this.state.email,
      'type' : this.state.type,
    }

    let request = requests.postTeacherStore(array);
    request.then(function(response){
      this.setState(
        {
          status: 'success',
          msg: i18n.t(response.data.msg),
          view: response.data.view,
          name: '',
          surname: '',
          phone: '',
          address: '',
          home_no: '',
          flat_no: '',
          postal_code: '',
          city: '',
        }
      );
      if(response.data.view === 'principal' || response.data.view === 'administration' || response.data.view === 'teachers'){
        this.props.changeView(response.data.view, 0);
      }
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        if(error.response.data.error === 'validation_error'){
          this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
        }
        else{
          this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
        }
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitFormStep2(event){
    event.preventDefault();
    this.setState({isLoading: true, status: '', msg: null});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'name' : this.state.name,
      'surname' : this.state.surname,
      'phone' : this.state.phone,
      'address' : this.state.address,
      'home_no' : this.state.home_no,
      'flat_no' : this.state.flat_no,
      'postal_code' : this.state.postal_code,
      'city' : this.state.city,
      'email' : this.state.email,
      'type' : this.state.type,
    }
    let request;
    request = requests.postTeacherStoreStep2(array);

    request.then(function(response){
      this.setState(
        {
          status: 'success',
          msg: i18n.t(response.data.msg),
          view: response.data.view,
        }
      );
      if(response.data.view === 'principal' || response.data.view === 'administration' || response.data.view === 'teachers'){
        this.props.changeView(response.data.view, 0);
      }
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        if(error.response.data.error === 'validation_error'){
          this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
        }
        else{
          this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
        }
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderStep1View(){
    return(
      <form onSubmit={(event) => this.submitFormStep1(event)}>
        <div className={'type' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
          <label>{i18n.t('select_account_type')}</label>
          <select className="form-control" name="type" onChange={(event) => this.changeState(event, 'type')} defaultValue={this.state.type}>
            <option value="1">{i18n.t('role_principal')}</option>
            <option value="4">{i18n.t('role_administration_worker')}</option>
            <option value="2">{i18n.t('role_teacher')}</option>
          </select>
          <span className="help-block">{'type' in this.state.validationErrors ? i18n.t(this.state.validationErrors['type']) : null}</span>
        </div>

        <div className={'email' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
          <label>{i18n.t('label_set_email')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <input type="email" className="form-control" value={this.state.email} onChange={(event) => this.changeState(event, 'email')} required/>
          <span className="help-block">{'email' in this.state.validationErrors ? i18n.t(this.state.validationErrors['email']) : null}</span>
        </div>

        <hr/><button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>

      </form>
    );
  }

  renderStep2View(){
    return(
      <form onSubmit={(event) => this.submitFormStep2(event)}>

        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={'name' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_name')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <input type="text" name="name" onChange={(event) => this.changeState(event, 'name')} className="form-control" value={this.state.name} required />
              <span className="help-block">{'name' in this.state.validationErrors ? i18n.t(this.state.validationErrors['name']) : null}</span>
            </div>
          </div>

          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={'surname' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_surname')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <input type="text" name="surname" onChange={(event) => this.changeState(event, 'surname')} className="form-control" value={this.state.surname} required />
              <span className="help-block">{'surname' in this.state.validationErrors ? i18n.t(this.state.validationErrors['surname']) : null}</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={'phone' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_phone')}</label>
              <input type="text" name="phone" onChange={(event) => this.changeState(event, 'phone')} className="form-control" value={this.state.phone} />
              <span className="help-block">{'phone' in this.state.validationErrors ? i18n.t(this.state.validationErrors['phone']) : null}</span>
            </div>
          </div>

          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={'address' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_address')}</label>
              <input type="text" name="street" onChange={(event) => this.changeState(event, 'address')} className="form-control" value={this.state.address} />
              <span className="help-block">{'address' in this.state.validationErrors ? i18n.t(this.state.validationErrors['address']) : null}</span>
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={'home_no' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_home_no')}</label>
              <input type="text" name="home_no" onChange={(event) => this.changeState(event, 'home_no')} className="form-control" value={this.state.home_no} />
              <span className="help-block">{'home_no' in this.state.validationErrors ? i18n.t(this.state.validationErrors['home_no']) : null}</span>
            </div>
          </div>

          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={'flat_no' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_flat_no')}</label>
              <input type="text" name="flat_no" onChange={(event) => this.changeState(event, 'flat_no')} className="form-control" value={this.state.flat_no} />
              <span className="help-block">{'flat_no' in this.state.validationErrors ? i18n.t(this.state.validationErrors['flat_no']) : null}</span>
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={'postal_code' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_postal_code')}</label>
              <input type="text" name="postal" onChange={(event) => this.changeState(event, 'postal_code')} className="form-control" value={this.state.postal_code} />
              <span className="help-block">{'postal_code' in this.state.validationErrors ? i18n.t(this.state.validationErrors['postal_code']) : null}</span>
            </div>
          </div>

          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={'city' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_city')}</label>
              <input type="text" name="city" onChange={(event) => this.changeState(event, 'city')} className="form-control" value={this.state.city} />
              <span class="help-block">{'city' in this.state.validationErrors ? i18n.t(this.state.validationErrors['city']) : null}</span>
            </div>
          </div>

        </div>

        <hr/><button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>


      </form>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'step1'){
        view = this.renderStep1View()
      }
      if(this.state.view === 'step2'){
        view = this.renderStep2View()
      }
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
