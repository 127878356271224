import React, {Component} from 'react';

export default
class KidPhotosComponent extends Component{

  constructor(props){
    super(props);
  }

  render(){
    return(<div>Zdjęcia</div>)
  }

}
