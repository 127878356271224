import React, {Component} from 'react';

import principal from '../../../Img/dyrekcja.png';
import teacher from '../../../Img/nauczycielka.png';
import parent from '../../../Img/ociec.png';

export default class HomeSecondComponent extends Component{

  constructor(props){
    super(props);
  }

  render(){
    return(
      <div className="mp_contentList">
        <div className="container">

          <h2 className="mp_listTitle">NETkid - szybki i prosty sposób na komunikację w przedszkolu!</h2>

          <ul className="list-unstyled">
            <li>
              <div className="media">
                <div className="media-left hidden-xs">
                  <img className="media-object img-responsive" src={parent} />
                </div>
                <div className="media-body">
                  <h2 className="media-heading">Jako rodzic</h2><br/>
                  <ul>
                    <li>Masz natychmiastowy kontakt z przedszkolem</li>
                    <li>Możesz zgłosić nieobecność dziecka</li>
                    <li>Zadajesz pytanie nauczycielce lub dyrekcji</li>
                    <li>Sprawdzisz menu na dany dzień</li>
                  </ul>
                  <button onClick={(event) => this.props.changeContent('for_parent')} className="btn btn-danger">Sprawdź!</button>
                </div>
              </div>

            </li>
            <li>
              <div className="media">
                <div className="media-left hidden-xs">
                  <img className="media-object img-responsive" src={teacher} />
                </div>
                <div className="media-body">
                  <h2 className="media-heading">Jako nauczyciel</h2><br/>
                  <ul>
                    <li>Masz natychmiastowy kontakt z rodzicem każdego dziecka</li>
                    <li>Dzięcki opcji szybkiej wiadomości przypomnisz wszystkich o wycieczce czy przyniesieniu widokówek z wakacji</li>
                  </ul>
                  <button onClick={(event) => this.props.changeContent('for_teacher')} className="btn btn-danger">Sprawdź!</button>
                </div>
              </div>

            </li>
            <li>
              <div className="media">
                <div className="media-left hidden-xs">
                  <img className="media-object img-responsive" src={principal} />
                </div>
                <div className="media-body">
                  <h2 className="media-heading">Jako dyrektor</h2><br/>
                  <ul>
                    <li>Masz możlwiwość natychmiastowej komunikacji z rodzicami</li>
                    <li>Zadzasz im pytanie w ankiecie</li>
                    <li>Dysponujesz systemem zgodnym z RODO</li>
                    <li>Przedstawisz rodzicom kalendarium nadchodzących wydarzeń</li>
                  </ul>
                  <button onClick={(event) => this.props.changeContent('for_principal')} className="btn btn-danger">Sprawdź!</button>
                </div>
              </div>

            </li>


          </ul>

        </div>
      </div>
    );
  }

}
