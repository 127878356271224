import React, {Component} from 'react';

import footerImage from '../../../Img/footerInfo.png';

export default class FooterComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      date: new Date().getFullYear()
    }
  }

  render(){
    return(
      <div className="mp_footerWrapper">
        <div className="mp_footerImage"></div>
        <div className="mp_footerContent">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-4 col-xs-12"><img src={footerImage} className="img-responsive"/></div>
              <div className="col-lg-4 col-sm-4 col-xs-12">
                <p>
                  Twórcą serwisu NET kid jest grupa Klett - jedna z największych europejskich firm o profilu edukacyjnym, obecna w 16 krajach. Oferta Kletta obejmuje najwyższej jakości tradycyjne i elektroniczne publikacje edukacyjne, serwisy dla uczniów i nauczycieli przeznaczone do codziennego wykorzystania w szkołach, a także materiały dydaktyczne, literaturę specjalistyczną i prozę.
                </p>
              </div>
              <div className="col-lg-4 col-sm-4 col-xs-12">
                <p>
                  Ponadto Grupa Klett prowadzi placówki oświatowe różnego typu – od tych dziennego pobytu, poprzez szkoły, kursy korespondencyjne, aż po wyższe uczelnie (stacjonarne i korespondencyjne).
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mp_footerCopyrights">
          <p>Projekt i wykonanie <a href="https://klett.pl" target="_blank">Klett</a><br/>Wszelkie prawa zastrzeżone. &copy; {this.state.date}</p>
          <p>Strona korzysta z plików Cookies, więcej informacji znajdziesz tutaj: <a href="/polityka-prywatnosci">Polityka prywatności</a></p>
        </div>
      </div>
    );
  }

}
