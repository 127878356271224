import React, {Component} from 'react';

import LoaderComponent from '../../../../../../Utils/LoaderComponent';
import CkEditorComponent from '../../../../../../Utils/CkEditorComponent';
import AttachmentsComponent from '../../../../../../Utils/AttachmentsComponent';
import i18n from "i18next";
import * as acl from '../../../acl.js';
import * as requests from '../../../requests.js';
import * as helperFunctions from '../../../../../../Utils/HelperFunctions.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";

export default
class CalendarComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      items: [],
      status: '',
      msg: null,
      fromDate: null,
      toDate: null,
      isLoading: false,
      view: 'list',
      selectedDayID: 0,
      selectedDayDetails: {meal: []},
      selectedMealContent: '',
      selectedMealID: 0,
      selectedMealName: '',
      newDay: new Date(moment().format("YYYY-MM-DD")),
    }

    this.setDate = this.setDate.bind(this);
    this.getItemDetails = this.getItemDetails.bind(this);
    this.onChangeSelectedMealContentHandler = this.onChangeSelectedMealContentHandler.bind(this);
    this.submitCreateNewDay = this.submitCreateNewDay.bind(this);
    this.updateMealItem = this.updateMealItem.bind(this);
  }

  setDate(date, type){
    if(type === 'fromDate'){
      this.setState({fromDate : date}, () => {this.getCalendarItems()});
    }
    if(type === 'newDay'){
      this.setState({newDay : date});
    }
    else{
      this.setState({toDate : date}, () => {this.getCalendarItems()});
    }
  }

  onChangeSelectedMealContentHandler(event, editor, data){
    this.setState({selectedMealContent: data });
  }

  componentWillMount(){
    this.getCalendarItems();
  }

  submitCreateNewDay(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'day' : this.state.newDay
    }

    let request = requests.postNewSchoolFoodMenuDay(array);
    request.then(function(response){
      this.setState({selectedDayID: response.data.id, view: 'mealTimes'}, () => {this.getItemDetails()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  updateMealItem(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.state.selectedMealID,
      'description' : this.state.selectedMealContent,
    }

    let request = requests.postUpdateSchoolFoodMenuItem(array);
    request.then(function(response){
      this.setState({status: 'success', msg: i18n.t(response.data.msg)}, () => {this.getItemDetails()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getItemDetails(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.state.selectedDayID,
    }

    let request = requests.getSchoolFoodMenuDayDetails(array);
    request.then(function(response){
      this.setState({
        selectedDayDetails: response.data
      });
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getCalendarItems(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'fromDate' : this.state.fromDate,
      'toDate' : this.state.toDate
    }

    let request = requests.getSchoolFoodMenuDays(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data.items,
          fromDate : new Date(moment(response.data.fromDate, 'YYYY-MM-DD')),
          toDate : new Date(moment(response.data.toDate, 'YYYY-MM-DD')),
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderList(){
    var view = helperFunctions.renderNoContentInfo();
    if(this.state.items.length){
      view =
        this.state.items.map(function(elem){
          return(

              <div key={elem.id} onClick={(event) => this.setState({selectedDayID: elem.id, view: 'mealTimes'}, () => {this.getItemDetails()})} className="media cursor">
                <div className="media-left">
                  <span className="info-box-icon bg-red"><i class="fas fa-utensils"></i></span>
                </div>
                <div className="media-body">
                  <h4 className="media-heading">{elem.day}
                    <br/><small>{i18n.t('meal_count')}: <b>{elem.mealsCount}</b></small>
                    <br/><small>{i18n.t('cdate')}: <b>{elem.cdate}</b></small>
                  </h4>
                </div>
              </div>

          )
        }.bind(this))
      }


    return (<div>{view}</div>);

  }

  renderListView(){
    var view = this.renderList();
    return(
      <div>
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-12">

            <div className="form-group">
              <label>{i18n.t('select_start_date')}</label>
              <DatePicker
                  selected={this.state.fromDate}
                  onSelect={(value) => this.setDate(value, 'fromDate')}
                  filterDate={this.isWeekday}
                  todayButton={i18n.t('set_current_date')}
                  shouldCloseOnSelect={false}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
              />
            </div>

          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">

            <div className="form-group">
              <label>{i18n.t('select_finish_date')}</label>
              <DatePicker
                  selected={this.state.toDate}
                  onSelect={(value) => this.setDate(value, 'toDate')}
                  todayButton={i18n.t('set_current_date')}
                  filterDate={this.isWeekday}
                  shouldCloseOnSelect={false}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
              />
            </div>

          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">

            <div className="form-group">
              <label>{i18n.t('add_new_item')}</label>
              <button onClick={(event) => this.setState({view: 'create'})} className="btn btn-default"><i className="fas fa-plus-circle"></i> {i18n.t('add_new_item')}</button>
            </div>

          </div>
        </div>

        <hr/>
        {view}
      </div>
    );
  }

  renderMealTimesListView(){
    var view = helperFunctions.renderNoContentInfo();
    if(this.state.selectedDayDetails.meal.length){
      view =
        this.state.selectedDayDetails.meal.map(function(elem){
          return(
            <div key={elem.id}>
              <div onClick={(event) => this.setState({selectedMealID: elem.id, selectedMealContent: elem.description, selectedMealName: elem.name, view: 'mealEdit'})} className="media cursor">
                <div className="media-left">
                  <span className="info-box-icon bg-red"><i className="fas fa-clock"></i></span>
                </div>
                <div className="media-body">
                  <h4 className="media-heading">{elem.name}
                    <br/><small>{i18n.t('scheduled_meal_time')}: <b>{elem.time}</b></small>
                  </h4>
                  <div dangerouslySetInnerHTML={{__html: elem.description}} />
                </div>
              </div><hr/>
            </div>
          )
        }.bind(this))
      }


    return (
      <div>
      <button className="btn btn-default" onClick={(event) => this.setState({view: 'list'}, () => {this.getCalendarItems()})}>{i18n.t('go_back_to_list')}</button>
        <div className="page-header">
          <h3>{this.state.selectedDayDetails.day}</h3>
        </div>
        {view}
      </div>
    );
  }

  renderCreateView(){
    return(
      <form onSubmit={(event) => this.submitCreateNewDay(event)}>
        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12">

            <div className="form-group">
              <label>{i18n.t('select_the_day_for_which_you_create_the_menu')}</label>
              <DatePicker
                  selected={this.state.newDay}
                  onSelect={(value) => this.setDate(value, 'newDay')}
                  filterDate={this.isWeekday}
                  todayButton={i18n.t('set_current_date')}
                  shouldCloseOnSelect={false}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
              />
            </div>

            <hr/>
            <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
            <button type="button" onClick={(event) => this.setState({view: 'list'}, () => {this.getCalendarItems()})} className="btn btn-default">{i18n.t('button_cancel')}</button>

          </div>
        </div>
      </form>
    );
  }

  renderMealEditView(){
    return(
      <form onSubmit={(event) => this.updateMealItem(event)}>

      <div class="page-header">
        <h3>{this.state.selectedDayDetails.day} <small>{i18n.t('meal_type')}: <b>{this.state.selectedMealName}</b></small></h3>
      </div>

        {<CkEditorComponent label="Podaj treść" content={this.state.selectedMealContent} onChangeContentHandler={this.onChangeSelectedMealContentHandler}/>}
        <hr/>
        <button className="btn btn-success" type="submit">{i18n.t('button_save')}</button>
        <button className="btn btn-default" onClick={(event) => this.setState({view: 'mealTimes', selectedMealName: '', selectedMealContent: '', selectedMealID: 0}, () => {this.getItemDetails()})} type="button">{i18n.t('button_cancel')}</button>
      </form>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView()
      }
      if(this.state.view === 'mealTimes'){
        view = this.renderMealTimesListView()
      }
      if(this.state.view === 'mealEdit'){
        view = this.renderMealEditView()
      }
      if(this.state.view === 'create'){
        view = this.renderCreateView()
      }
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
