import React, {Component} from 'react';

import KidListAccordionComponent from '../KidListAccordionComponent';
import FullProfileCard from '../FullProfileCard/InitialComponent';
import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import * as requests from '../requests.js';

class KidsComponent extends Component{

  constructor(props){
    super(props);

    this.objectSelectChangeHandler = this.objectSelectChangeHandler.bind(this);
    this.finishListReloadHandler = this.finishListReloadHandler.bind(this);
    this.changeMainView = this.changeMainView.bind(this);
    this.handleLoader = this.handleLoader.bind(this);

    this.state = {
      objectSelectLoading: false,
      reloadKidsList: false,
      view: 'main',
      kid: [],
      isLoading: false,
    }

  }

  changeMainView(event, view, kid){
    this.setState({view: view, kid: kid});
  }

  handleLoader(status){
    this.setState({isLoading: status});
  }

  objectSelectChangeHandler(event){
    this.setState({objectSelectLoading: true});
    localStorage.setItem('objectID', event.target.value);
    this.setState({objectSelectLoading: false, reloadKidsList: true});
  }

  componentWillUpdate(nextProps, nextState){
    if(nextProps.moduleParams.id !== undefined){
      this.setState({isReloadingToProfile: true});
      this.loadKidData(nextProps.moduleParams.id);
      nextProps.moduleParams.id = undefined
    }
  }

  finishListReloadHandler(status){
    this.setState({reloadKidsList: status});
  }

  loadKidData(kidID){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
      'kidID' : kidID,
    }

    let request = requests.getKidSingle(array);
    request.then(function(response){
      this.setState({kid: response.data[0], view: 'full_kid_profile'});
    }.bind(this));

    request.catch(function(error){
      this.setState({kid: []});
    }.bind(this));

    request.finally(function(){
      this.setState({ isLoading: false});
    }.bind(this));
  }

  renderMainView(){
    return(
      <div className="nav-tabs-custom">
          <ul className="nav nav-tabs">
            <li className="active"><a href="#tab_1-1" data-toggle="tab" aria-expanded="false"><i className="fas fa-ellipsis-v"></i> {i18n.t('kids_list')}</a></li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active" id="tab_1-1">

              <KidListAccordionComponent
                objectID={localStorage.getItem('objectID')}
                reload={this.state.reloadKidsList}
                afterReloadHandler={this.finishListReloadHandler}
                changeMainView={this.changeMainView}
                handleLoader={this.handleLoader}
              />

            </div>
          </div>

        </div>
    );
  }

  render(){
    var view = <LoaderComponent />
    if(this.state.isLoading === false){
      if(this.state.view === 'main'){
        view = this.renderMainView();
      }
      else if (this.state.view === 'full_kid_profile') {
        view = <FullProfileCard
          currentUser={this.props.currentUser}
          currentKid={this.state.kid}
          changeMainView={this.changeMainView}
          handleLoader={this.handleLoader}
          />
      }
    }

    return(
      <div>

        <section className="content-header">
          <div className="row">
              <div className="col-md-8 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-yellow"><i className="fas fa-users"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-number">
                      <h3>{i18n.t('kids_module')}<br/><small>{i18n.t('kids_module_subtitle')}</small></h3>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                  <ObjectSelectComponent
                    objectSelectChangeHandler={this.objectSelectChangeHandler}
                    currentlyChoosen={localStorage.getItem('objectID')}
                    loading={this.state.objectSelectLoading}
                    />
              </div>

          </div>
        </section>

        <section className="content container-fluid">
          {view}
        </section>

      </div>
    );
  }

}
export default KidsComponent;
