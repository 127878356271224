import React, {Component} from 'react';

import LoaderComponent from '../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import * as requests from '../requests.js';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as moment from "moment";

import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';

export default
class GalleryComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      items: [],
      isLoading: false,
      status: '',
      msg: null,
      view: 'list',
      gallery: [],
      currentImage: 0,
      lightboxIsOpen: false,
    }

    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }

  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  componentDidMount(){
    this.getGalleryList();
  }



  componentDidUpdate(nextProps){
    if(nextProps.reload !== this.props.reload){
      this.props.afterReloadHandler(false);
      this.getGalleryList();
      return true;
    }
    return false;
  }

  getGalleryDetails(id){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
      'galleryID' : id
    }

    let request = requests.getGalleryDetails(array);
    request.then(function(response){
      this.setState({gallery: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({gallery: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getGalleryList(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
    }

    let request = requests.getGallery(array);
    request.then(function(response){
      this.setState({items: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({items: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderMainView(){
    return (
      <div>
      {
        this.state.items.map(function(elem){
          return(
            <div key={elem.id}>
              <div onClick={(event) => this.setState({view: 'details', gallery: []}, () => {this.getGalleryDetails(elem.id)})} className="media cursor">
                <div class="media-left">
                  <span className="info-box-icon bg-green"><i className="far fa-images"></i></span>
                </div>
                <div className="media-body">
                  <h4 class="media-heading">
                    <small>{moment(elem.cdate).format("YYYY-MM-DD")}</small><br/>
                    {elem.title}
                  </h4>
                  <p>{elem.description}</p>
                  <small className="text-muted">{i18n.t('photos_count')}: <b>{elem.itemsCount}</b></small>
                </div>
              </div>
              <hr/>
            </div>
          );
        }.bind(this))
      }
      </div>
    );
  }

  renderDetailsView(){
    return(
      <div>
        <button onClick={(event) => this.setState({view: 'list'}, () => {this.getGalleryList()})} className="btn btn-xs btn-default">{i18n.t('go_back_to_list')}</button>
        <div class="page-header">
          <h1>{this.state.gallery.title}<br/><small>{this.state.gallery.cdate}</small></h1>
        </div>

        <p>{this.state.gallery.description}</p>

        <Gallery photos={this.state.gallery.items} onClick={this.openLightbox} />
          <Lightbox images={this.state.gallery.fullSize}
            onClose={this.closeLightbox}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            currentImage={this.state.currentImage}
            isOpen={this.state.lightboxIsOpen}
          />
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.items !== undefined && this.state.items.length > 0){
        if(this.state.view === 'list'){
          view = this.renderMainView();
        }
        else if (this.state.view === 'details') {
          view = this.renderDetailsView();
        }
      }
      else{
        view = helperFunctions.renderNoContentInfo();
      }
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
        {view}
      </div>
    );
  }

}
