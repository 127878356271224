import API from '../../../../Utils/api';

export function getAllItems(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('knowledge-base/get/all', {params: array, headers: { Authorization: AuthStr }});
}

export function getItemDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('knowledge-base/get/details', {params: array, headers: { Authorization: AuthStr }});
}
