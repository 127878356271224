import React, {Component} from 'react';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import * as requests from '../../requests.js';
import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";

import AdditionalActivityComponent from '../AdditionalActivityComponent';
import FoodPreferencesComponent from '../FoodPreferencesComponent';
import CommentComponent from '../CommentComponent';
import AddressComponent from '../AddressComponent';

export default
class AdditionalSettingsComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'list',
      module: '',
      moduleData: [],
      loadedModule: null,
      isLoading: false,
      status: '',
    }

    this.changeModuleView = this.changeModuleView.bind(this);
  }

  changeModuleView(event, module){
    this.setState({module: module}, () => {this.getModuleRequest()});
  }

  getModuleRequest(){
    this.setState({isLoading: true});

    let array = {
      'module' : this.state.module,
      'kidID' : this.props.currentKid.kidID
    }

    let request = requests.getKidsAdditionalModuleSettings(array);
    request.then(function(response){
      this.setState({moduleData: response.data, status: 'success'});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error'});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));

  }

  renderNavigationView(){
    var loadedModule = null;
    if(this.state.module === 'additional_activities' && this.state.status !== 'error'){
      loadedModule = <AdditionalActivityComponent
        moduleData={this.state.moduleData}
        currentKid={this.props.currentKid}
        currentUser={this.props.currentUser}
        />
    }
    else if(this.state.module === 'food_preferences' && this.state.status !== 'error'){
      loadedModule = <FoodPreferencesComponent
        moduleData={this.state.moduleData}
        currentKid={this.props.currentKid}
        currentUser={this.props.currentUser}
        />
    }
    else if(this.state.module === 'comments' && this.state.status !== 'error'){
      loadedModule = <CommentComponent
        moduleData={this.state.moduleData}
        currentKid={this.props.currentKid}
        currentUser={this.props.currentUser}
        />
    }
    else if(this.state.module === 'address' && this.state.status !== 'error'){
      loadedModule = <AddressComponent
        moduleData={this.state.moduleData}
        currentKid={this.props.currentKid}
        currentUser={this.props.currentUser}
        />
    }
    else{
      loadedModule = helperFunctions.showErrorBlock(i18n.t('an_error_occured'), i18n.t('an_error_occured_info'));
    }
    return(
      <div>
        <div className="box-group" id="accordion">
          <div className="panel box box-warning">
            <div className="box-header with-border">
              <h4 className="box-title">
                <a onClick={(event) => this.changeModuleView(event, 'additional_activities')} data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                  {i18n.t('additional_activities')}
                </a>
              </h4>
            </div>
            <div id="collapse1" className={this.state.module === 'additional_activities' ? "panel-collapse collapse in" : "panel-collapse collapse"}>
              <div className="box-body">
                {loadedModule}
              </div>
            </div>
          </div>
          <div className="panel box box-warning">
            <div className="box-header with-border">
              <h4 className="box-title">
                <a onClick={(event) => this.changeModuleView(event, 'food_preferences')} data-toggle="collapse" data-parent="#accordion" href="#collapse2">
                  {i18n.t('food_preferences')}
                </a>
              </h4>
            </div>
            <div id="collapse2" className={this.state.module === 'food_preferences' ? "panel-collapse collapse in" : "panel-collapse collapse"}>
              <div className="box-body">
                {loadedModule}
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }

  render(){
    var view = helperFunctions.showBlockadeBlock(i18n.t('access_denied'), i18n.t('access_denied_info'));
    if(this.props.currentKid.canISeeDetails === true){
      if(this.state.isLoading === false){
        if(this.state.view === 'list'){
          view = this.renderNavigationView();
        }
      }
      else{
        view = <LoaderComponent />;
      }
    }

    return(
      <div>
        {view}
      </div>
    );
  }
}
