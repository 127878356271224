import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';

export default
class SurveyComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      objectSelectLoading: false,
      reloadList: false,
      view: 'list',
      listType: 'active',
      items: [],
      surveyDetails : [],
      isLoading: false,
      selectedAnswers: [],
      status: '',
      msg: null,
      title: '',
      content: '',
      finished: 0,
    }

    this.objectSelectChangeHandler = this.objectSelectChangeHandler.bind(this);
    this.changeMainView = this.changeMainView.bind(this);
    this.changeListView = this.changeListView.bind(this);
    this.getSurveyDetails = this.getSurveyDetails.bind(this);
    this.changeRadioButton = this.changeRadioButton.bind(this);
    this.submitQuestion = this.submitQuestion.bind(this);
  }

  changeMainView(event, view){
    if(view === 'list'){
      this.setState({view: 'list', surveyDetails: []}, () => {this.getSurveysList()});
    }
  }

  changeListView(event, view){
    this.setState({listType: view}, () => this.getSurveysList());
  }

  objectSelectChangeHandler(event){
    this.setState({objectSelectLoading: true});
    localStorage.setItem('objectID', event.target.value);
    this.setState({objectSelectLoading: false, reloadList: true});
  }

  componentWillMount(){
    this.getSurveysList();
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.reloadList == true && this.state.reloadList == false) {
      this.getSurveysList();
    }
  }

  changeRadioButton(event, id){
    const {selectedAnswers} = this.state;
    selectedAnswers[id] = event.target.value;
    this.setState({selectedAnswers})
  }

  submitQuestion(event){
    event.preventDefault();
    this.setState({isLoading: true});
    let array = {
      'id' : this.state.surveyDetails.id,
      'answer': this.state.selectedAnswers,
      'objectID' : localStorage.getItem('objectID'),
    }


    var request = requests.postSurveyVote(array);
    request.then(function(response){
      this.setState({view: 'list', status: 'success', msg: i18n.t(response.data.msg)}, () => { return this.getSurveysList() });
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getSurveyDetails(event, id, schoolID){
    this.getSurveyDetailsRequest(id, schoolID);
  }

  getSurveyDetailsRequest(id, schoolID){
    this.setState({isLoading: true});

    var array = {
      'objectID' : schoolID,
      'surveyID' : id,
    }

    let request = requests.getSurveyDetails(array);
    request.then(function(response){
      this.setState({surveyDetails: response.data, status: '', msg: null, selectedAnswers: response.data.questionsVotes, view: 'details'});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  getSurveysList(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
      'type' : this.state.listType,
    }

    let request = requests.getSurveyList(array);
    request.then(function(response){
      this.setState({items: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  renderActiveListView(){
    return(
      <div>

        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <ul className="list-unstyled">
              {
                this.state.items.map(function(elem){

                  return(
                    <li key={elem.id}>
                      <div className="media">
                        <div className="media-left">
                          <span className={"info-box-icon " + elem.icon.css}><i className={elem.icon.fontawesome}></i></span>
                        </div>
                        <div className="media-body">
                          <h4 className="media-heading">{elem.title}
                            <br/><small>{i18n.t('object')}: <b>{elem.school}</b></small>
                            <br/><small>{i18n.t('for_group')}: <b>{elem.for_all_groups ? i18n.t('all_groups') : elem.group}</b>, {i18n.t('questions_count')}: <b>{elem.questions}</b></small>
                            <br/><small>{i18n.t('cdate')}: <b>{elem.cdate}</b></small>
                          </h4>

                          <br/>
                          <button onClick={(event) => this.getSurveyDetails(event, elem.id, elem.schoolID)} className="btn btn-default btn-xs">{i18n.t('survey_details')}</button>

                        </div>
                      </div>
                      <hr/>
                    </li>
                  )
                }.bind(this))
              }
            </ul>

          </div>
        </div>
      </div>
    );
  }

  renderQuestionsForm(questions){
    return(
      <form onSubmit={(event) => this.submitQuestion(event)}>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>{i18n.t('question_content')}</th>
              <th>{i18n.t('yes')}</th>
              <th>{i18n.t('no')}</th>
            </tr>
          </thead>
          <tbody>
            {
              questions.map(function(item){
                return(
                  <tr key={item.id} onChange={(event) => this.changeRadioButton(event, item.id)}>
                    <td>{item.content}</td>
                    <td><input type="radio" name={"answer[" + item.id + "]"} value="1" required/></td>
                    <td><input type="radio" name={"answer[" + item.id + "]"} value="0"  /></td>
                  </tr>
                );
              }.bind(this))
            }
          </tbody>
        </table>
        <hr/>
        <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
      </form>
    )
  }

  renderQuestionsFormSuccessAnswered(){
    return(
      <div className="alert alert-success">{i18n.t('your_answer_was_stored')}</div>
    );
  }

  renderSurveyItem(){
    var questions;
    if(this.state.surveyDetails.can_vote === true && this.state.surveyDetails.user_answered === false){
      questions = this.renderQuestionsForm(this.state.surveyDetails.questionsList)
    }
    else if (this.state.surveyDetails.user_answered === true) {
      questions = this.renderQuestionsFormSuccessAnswered()
    }
    return(
      <div>

        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <ul className="list-unstyled">

              <li>
                <div className="media">
                  <div className="media-left">
                    <span className={"info-box-icon " + this.state.surveyDetails.icon.css}><i className={this.state.surveyDetails.icon.fontawesome}></i></span>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">{this.state.surveyDetails.title}
                      <br/><small>{i18n.t('object')}: <b>{this.state.surveyDetails.school}</b></small>
                      <br/><small>{i18n.t('for_group')}: <b>{this.state.surveyDetails.for_all_groups ? i18n.t('all_groups') : this.state.surveyDetails.group}</b>, {i18n.t('questions_count')}: <b>{this.state.surveyDetails.questions}</b></small>
                      <br/><small>{i18n.t('cdate')}: <b>{this.state.surveyDetails.cdate}</b></small>
                    </h4>
                    <br/>

                    <p><strong>{i18n.t('content')}</strong></p>
                    <div dangerouslySetInnerHTML={{__html: this.state.surveyDetails.content}} />

                    {questions}


                  </div>
                </div>
                <hr/>
              </li>

            </ul>

          </div>
        </div>
      </div>
    );
  }

  renderDetailsView(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderSurveyItem();
    }
    else{
      view = <LoaderComponent />;
    }
    return(
      <div className="nav-tabs-custom">
          <ul className="nav nav-tabs">
            <li className="active"><a href="#tab" onClick={(event) => this.changeMainView(event, 'list')} data-toggle="tab" aria-expanded="false"><i className="fas fa-arrow-alt-circle-left"></i> {i18n.t('go_back_to_list')}</a></li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active" id="tab_1">

              {view}

            </div>

          </div>

        </div>
    );
  }

  renderMainView(){
    var view;

    if(this.state.isLoading === false){
      if(this.state.items !== undefined && this.state.items.length > 0){
        view = this.renderActiveListView();
      }
      else{
        view = helperFunctions.renderNoContentInfo();
      }
    }
    else{
      view = <LoaderComponent />;
    }
    return(
      <div className="nav-tabs-custom">
          <ul className="nav nav-tabs">
            <li className={this.state.listType === 'active' ? "active" : null}><a href="#tab" onClick={(event) => this.changeListView(event, 'active')} data-toggle="tab" aria-expanded="false"><i className="fas fa-calendar-check"></i> {i18n.t('active_survey_list')}</a></li>
            <li className={this.state.listType === 'inactive' ? "active" : null}><a href="#tab" onClick={(event) => this.changeListView(event, 'inactive')} data-toggle="tab" aria-expanded="false"><i className="far fa-calendar-times"></i> {i18n.t('inactive_survey_list')}</a></li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active" id="tab_1">

              {view}

            </div>

          </div>

        </div>
    );
  }

  render(){
    var view;
    if(this.state.view === 'list'){
      view = this.renderMainView();
    }
    else if (this.state.view === 'details') {
      view = this.renderDetailsView();
    }

    return(
      <div>

        <section className="content-header">
          <div className="row">
              <div className="col-md-8 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-light-blue"><i className="fas fa-poll"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-number">
                      <h3>{i18n.t('survey_module')}<br/><small>{i18n.t('survey_module_subtitle')}</small></h3>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                  <ObjectSelectComponent
                    objectSelectChangeHandler={this.objectSelectChangeHandler}
                    currentlyChoosen={localStorage.getItem('objectID')}
                    loading={this.state.objectSelectLoading}
                    />
              </div>

          </div>
        </section>

        <section className="content container-fluid">
          {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
          {view}
        </section>

      </div>
    );
  }



}
