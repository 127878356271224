import React, {Component} from 'react';

import footerImage from '../../../Img/footerInfo.png';

export default class LoginRestoreManageComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      section: 'login'
    }
  }

  componentDidMount(){
    this.setState({section: this.props.section})
  }

  componentWillUpdate(nextProps, nextState){
    if(nextProps.section !== this.props.section){
      this.setState({section: nextProps.section});
    }
  }

  renderLoginInfo(){
    return(
      <div>Znasz swoje dane logowania do serwisu NETkid?<br/>W takim razie nie czekaj tylko od razu się zaloguj!<br/><button onClick={(event) => this.props.changeContent('login')} className="btn btn-warning">Kliknij tutaj!</button></div>
    );
  }

  renderRemindInfo(){
    return(
      <div>Nie pamiętasz hasła do swojego konta?<br/>Nic nie szkodzi, pomożemy Ci rozwiązać ten problem!<br/><button onClick={(event) => this.props.changeContent('remind_password')} className="btn btn-warning">Kliknij tutaj!</button></div>
    );
  }

  render(){
    var view = null;
    if(this.state.section === 'login'){
      view = this.renderLoginInfo();
    }
    else{
      view = this.renderRemindInfo();
    }
    return(
      <div className="mp_darkSection">
        <div className="container">
          {view}
        </div>
      </div>
    );
  }

}
