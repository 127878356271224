import React, {Component} from 'react';

import LoaderComponent from '../../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../../acl.js';
import * as requests from '../../../requests.js';
import * as helperFunctions from '../../../../../../Utils/HelperFunctions.js';


export default
class KidParentsComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'list',
      parents: [],
      caregivers: [],
      isLoading: false,
      status: '',
      msg: null,
      exists: false,
      account: [],
      name: '',
      surname: '',
      email: '',
      phone: '',
      address: '',
      home_no: '',
      flat_no: '',
      postal_code: '',
      city: '',
      id: 0,
      validationErrors: [],
    }

    this.checkEmail = this.checkEmail.bind(this);
    this.handleInputFill = this.handleInputFill.bind(this);
    this.getAccountInfo = this.getAccountInfo.bind(this);
    this.submitCreateForm = this.submitCreateForm.bind(this);
  }

  componentDidMount(){
    this.getKidParentsList();
  }

  getAccountInfo(accountID){
    this.setState({isLoading: true, status: '', msg: null});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id'        : accountID,
    }

    let request = requests.getAccountInfo(array);
    request.then(function(response){
      this.setState(
        {
          name: response.data.name,
          surname: response.data.surname,
          phone: response.data.phone,
          address: response.data.address,
          home_no: response.data.home_no,
          flat_no: response.data.flat_no,
          postal_code: response.data.postal_code,
          city: response.data.city,
          email: response.data.email,
          id: response.data.id,
          view: 'edit',
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitCreateForm(event){
    event.preventDefault();
    this.setState({isLoading: true, status: '', msg: null, validationErrors: []});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'kid_id' : this.props.kidID,
      'name' : this.state.name,
      'surname' : this.state.surname,
      'phone' : this.state.phone,
      'address' : this.state.address,
      'home_no' : this.state.home_no,
      'flat_no' : this.state.flat_no,
      'postal_code' : this.state.postal_code,
      'city' : this.state.city,
      'email' : this.state.email,
      'id' : this.state.id,
    }
    let request;
    var view = 'edit';
    if(this.state.view === 'edit'){
      request = requests.postUpdateAccount(array);
    }
    else{
      request = requests.postCreateAccount(array);
      view = 'list'
    }

    request.then(function(response){
      this.setState({view: view, status: 'success', msg: response.data.msg}, () => {this.getKidParentsList()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        if(error.response.data.error === 'validation_error'){
          this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
        }
        else{
          this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
        }
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  checkEmail(event){
    this.setState({isLoading: true, status: '', msg: null, validationErrors: []});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'email' : this.state.email,
      'kid_id' : this.props.kidID,
    }

    let request = requests.postCheckEmailExists(array);
    request.then(function(response){
      this.setState(
        {
          account: response.data.account,
          view: response.data.view,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        if(error.response.data.error === 'validation_error'){
          this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
        }
        else{
          this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
        }
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }



  handleInputFill(event, type){
    if(type === 'email'){
      this.setState({email: event.target.value});
    }
    if(type === 'name'){
      this.setState({name: event.target.value});
    }
    if(type === 'surname'){
      this.setState({surname: event.target.value});
    }
    if(type === 'phone'){
      this.setState({phone: event.target.value});
    }
    if(type === 'address'){
      this.setState({address: event.target.value});
    }
    if(type === 'home_no'){
      this.setState({home_no: event.target.value});
    }
    if(type === 'flat_no'){
      this.setState({flat_no: event.target.value});
    }
    if(type === 'postal_code'){
      this.setState({postal_code: event.target.value});
    }
    if(type === 'city'){
      this.setState({city: event.target.value});
    }

  }

  getKidParentsList(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.kidID,
    }

    let request = requests.getKidParents(array);
    request.then(function(response){
      this.setState(
        {
          parents: response.data.parents,
          caregivers: response.data.caregivers,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderExistsView(){
    return(
      <div className="media">
        <div className="media-left">
            <img className="media-object" src={this.state.account.avatar} alt="..." />
        </div>
        <div className="media-body">
          <h4 className="media-heading">{this.state.account.name} {this.state.account.surname}</h4>
          <p>Konto zostało pomyślnie przypisane, wiadomość zawierająca instrukcje dla rodzica została wysłana na główny adres e-mail rodzica.</p>
            <button type="button" onClick={(event) => this.setState({view: 'list'}, () => {this.getKidParentsList()})} className="btn btn-default">Wróć do listy</button>

        </div>
      </div>
    )
  }

  renderCreateView(){
    return(
      <div>

        <div className="info-box">
            <span className="info-box-icon bg-aqua"><i className="fas fa-info"></i></span>

            <div className="info-box-content">
              <span className="info-box-text">Informacja systemowa</span>
              <span>Aby utworzyć konto rodzica możesz skorzystać z poniższych opcji. Pierwsza z nich pozwoli Ci stworzyć konto dla posiadanego adresu e-mail rodzica.<br/>Druga natomiast pozwoli Ci wygenerować konto autmatycznie bez podawania adresu e-mail, system utworzy dla tego konto numer ID</span>
            </div>
        </div>

        <div className="panel panel-default">
          <div className="panel-heading">Utwórz konto rodzica dla adresu e-mail</div>
          <div className="panel-body">
            <form onSubmit={(event) => this.checkEmail(event)}>

              <div className={'email' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
                <label>Podaj adres e-mail <span className="text-danger">({i18n.t('input_is_required')})</span></label>
                <input type="email" className="form-control" onChange={(event) => this.handleInputFill(event, 'email')} value={this.state.email} required />
                <span className="help-block">{'email' in this.state.validationErrors ? i18n.t(this.state.validationErrors['email']) : null}</span>
              </div>

              <button className="btn btn-success" type="submit">Przypisz</button>
              <button type="button" onClick={(event) => this.setState({view: 'list'}, () => {this.getKidParentsList()})} className="btn btn-default">{i18n.t('button_cancel')}</button>


            </form>
          </div>
        </div>

        <div className="panel panel-default">
          <div className="panel-heading">Utwórz konto rodzica bez adresu e-mail</div>
          <div className="panel-body">

            <button className="btn btn-success" type="button" onClick={(event) => this.setState({view: 'createForm'})}>Wygeneruj nowe konto użytkownika</button>

          </div>
        </div>



      </div>
    );
  }

  renderCreateProfile(){
    return(
      <form onSubmit={(event) => this.submitCreateForm(event)}>

        <div className="info-box">
          <span className="info-box-icon bg-aqua"><i className="fas fa-user-plus"></i></span>

          <div className="info-box-content">
            <span className="info-box-text">{i18n.t('kid_parent_edit_form')}:</span>
            <span className="info-box-number">{this.state.email}</span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={'name' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_name')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <input type="text" name="name" onChange={(event) => this.handleInputFill(event, 'name')} className="form-control" value={this.state.name} required />
              <span className="help-block">{'name' in this.state.validationErrors ? i18n.t(this.state.validationErrors['name']) : null}</span>
            </div>
          </div>

          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={'surname' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_surname')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <input type="text" name="surname" onChange={(event) => this.handleInputFill(event, 'surname')} className="form-control" value={this.state.surname} required />
              <span className="help-block">{'surname' in this.state.validationErrors ? i18n.t(this.state.validationErrors['surname']) : null}</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={'phone' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_phone')}</label>
              <input type="text" name="phone" onChange={(event) => this.handleInputFill(event, 'phone')} className="form-control" value={this.state.phone} />
              <span className="help-block">{'phone' in this.state.validationErrors ? i18n.t(this.state.validationErrors['phone']) : null}</span>
            </div>
          </div>

          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={'street' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_address')}</label>
              <input type="text" name="street" onChange={(event) => this.handleInputFill(event, 'address')} className="form-control" value={this.state.address} />
              <span className="help-block">{'street' in this.state.validationErrors ? i18n.t(this.state.validationErrors['street']) : null}</span>
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={'home_no' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_home_no')}</label>
              <input type="text" name="home_no" onChange={(event) => this.handleInputFill(event, 'home_no')} className="form-control" value={this.state.home_no} />
              <span className="help-block">{'home_no' in this.state.validationErrors ? i18n.t(this.state.validationErrors['home_no']) : null}</span>
            </div>
          </div>

          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={'flat_no' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_flat_no')}</label>
              <input type="text" name="flat_no" onChange={(event) => this.handleInputFill(event, 'flat_no')} className="form-control" value={this.state.flat_no} />
              <span className="help-block">{'flat_no' in this.state.validationErrors ? i18n.t(this.state.validationErrors['flat_no']) : null}</span>
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={'postal_code' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_postal_code')}</label>
              <input type="text" name="postal" onChange={(event) => this.handleInputFill(event, 'postal_code')} className="form-control" value={this.state.postal_code} />
              <span className="help-block">{'postal_code' in this.state.validationErrors ? i18n.t(this.state.validationErrors['postal_code']) : null}</span>
            </div>
          </div>

          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={'city' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('label_set_city')}</label>
              <input type="text" name="city" onChange={(event) => this.handleInputFill(event, 'city')} className="form-control" value={this.state.city} />
              <span className="help-block">{'city' in this.state.validationErrors ? i18n.t(this.state.validationErrors['city']) : null}</span>
            </div>
          </div>

        </div>

        <hr/>
        <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
        <button type="button" onClick={(event) => this.setState({view: 'list', validationErrors: []}, () => {this.getKidParentsList()})} className="btn btn-default">{i18n.t('button_cancel')}</button>

      </form>
    );
  }

  renderListView(){
    return(
      <div>
      <button className="btn btn-default" onClick={(event) => this.setState(
          {
            view: 'create',
            name: '',
            surname: '',
            email: '',
            phone: '',
            address: '',
            home_no: '',
            flat_no: '',
            postal_code: '',
            city: '',
            id: 0
          }
        )}>{i18n.t('add_new_account')}</button><hr/>
      <legend>{i18n.t('parents_accounts_list')}</legend>
      <table className="table table-hover">
      <thead>
        <tr>
          <th>{i18n.t('avatar')}</th>
          <th>{i18n.t('name_and_surname')}</th>
          <th>{i18n.t('table_th_uid_address')}</th>
          <th>{i18n.t('table_th_email_address')}</th>
          <th>{i18n.t('status')}</th>
          <th>{i18n.t('invitation')}</th>
        </tr>
      </thead>
      <tbody>
      {
        this.state.parents.map(function(item){
          return(
            <tr key={item.id} className="cursor" onClick={(event) => this.setState({view: 'edit'}, () => {this.getAccountInfo(item.id)})}>
              <td><img src={item.avatar} className="img-circle img-md smallAvatar" /></td>
              <td>{item.name} {item.surname}</td>
              <td>{item.uid}</td>
              <td>{item.email}</td>
              <td>{item.active ? i18n.t('active') : i18n.t('inactive')}</td>
              <td>{item.invitationStatus ? i18n.t('confirmed') : i18n.t('unconfirmed')}</td>
            </tr>
          );
        }.bind(this))
      }
      </tbody>

      </table>

      <hr/>

      <legend>{i18n.t('caregivers_accounts_list')}</legend>
      <table className="table table-hover">
      <thead>
        <tr>
          <th>{i18n.t('avatar')}</th>
          <th>{i18n.t('name_and_surname')}</th>
          <th>{i18n.t('table_th_uid_address')}</th>
          <th>{i18n.t('table_th_email_address')}</th>
          <th>{i18n.t('status')}</th>
          <th>{i18n.t('invitation')}</th>
        </tr>
      </thead>
      <tbody>
      {
        this.state.caregivers.map(function(item){
          return(
            <tr key={item.id} className="cursor" onClick={(event) => this.setState({view: 'edit'}, () => {this.getAccountInfo(item.id)})}>
              <td><img src={item.avatar} className="img-circle img-md smallAvatar" /></td>
              <td>{item.name} {item.surname}</td>
              <td>{item.uid}</td>
              <td>{item.email}</td>
              <td>{item.active ? i18n.t('active') : i18n.t('inactive')}</td>
              <td>{item.invitationStatus ? i18n.t('confirmed') : i18n.t('unconfirmed')}</td>
            </tr>
          );
        }.bind(this))
      }
      </tbody>

      </table>

      </div>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView()
      }
      if(this.state.view === 'create'){
        view = this.renderCreateView();
      }
      if(this.state.view === 'exists'){
        view = this.renderExistsView();
      }
      if(this.state.view === 'createForm' || this.state.view === 'edit'){
        view = this.renderCreateProfile();
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
