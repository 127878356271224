import React, {Component} from 'react';



export default class YoutubeComponent extends Component{

  render(){
    return(
      <div className="mp_youtubeWrapper visible-md-block visible-lg-block">
        <div className="container">

          <h3 className="mp_sectionTitle">Zobacz jak NETkid ułatwia komunikację wokół przedszkola</h3>

          <div className="mp_youtubeMonitor">
            <div className="mp_youtubeIframe">
              <iframe width="862" height="420" src="https://www.youtube.com/embed/dRM_A9mtseQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
          <div className="mp_youtubeTablet"></div>

        </div>
      </div>
    );
  }

}
