import React, {Component} from 'react';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import * as requests from '../../requests.js';
import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import plLocale from '@fullcalendar/core/locales/pl';

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";


class ActivityLogComponent extends Component{

  calendarRef = React.createRef()

  constructor(props){
    super(props);

    this.state = {
      isLoading: false,
      view: 'calendar',
      items: [],
      from: null,
      to: null,
      status: '',
      msg: null,
      currentItemID: 0,
      fromDate: null,
      toDate: null,
      reason: '',
      reason: '',
    }

    this.getItemDetails = this.getItemDetails.bind(this);
    this.setStateValue = this.setStateValue.bind(this);
    this.submitCreateForm = this.submitCreateForm.bind(this);
  }

  componentDidMount(){
    this.getCalendar();
  }

  componentWillMount(){
    this.setState(
      {
        fromDate: new Date(),
        toDate: new Date(),
      }
    );
  }

  setDate(date, type){
    if(type === 'fromDate'){
      this.setState({fromDate : date});
    }
    else{
      this.setState({toDate : date});
    }
  }

  setStateValue(event, type){
    if(type === 'reason'){
      this.setState({reason: event.target.value});
    }
  }

  getCalendar(){
    if(this.props.currentKid.canISeeDetails === true){
      this.getKidsAbsenceCalendar();
    }
  }

  getKidsAbsenceCalendar(){
    this.setState({isLoading: true});
    let array = {
      'kidID': this.props.currentKid.kidID,
      'schoolID' : this.props.currentKid.schoolID,
    }

    let request = requests.getKidsAbsenceInfo(array);
    request.then(function(response){
      this.setState({
        items: response.data.items,
        from: response.data.from_date,
        to: response.data.to_date
      });
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getItemDetails(){
    this.setState({isLoading: true});
    let array = {
      'kidID': this.props.currentKid.kidID,
      'schoolID' : this.props.currentKid.schoolID,
      'id'  : this.state.currentItemID
    }

    let request = requests.getKidsAbsenceDetails(array);
    request.then(function(response){
      this.setState({
        items: response.data.items,
      });
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitCreateForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'kidID': this.props.currentKid.kidID,
      'schoolID' : this.props.currentKid.schoolID,
      'fromDate' : this.state.fromDate,
      'endDate' : this.state.toDate,
      'reason' : this.state.reason,
    }

    let request = requests.postAbsenceCreate(array);
    request.then(function(response){
      this.setState({status: 'success', reason: '', fromDate: null, toDate: null, msg: response.data.msg, view: 'calendar'}, () => {this.getCalendar()});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  renderCalendarView(){
    return(
      <div>

        <div className="row">
          <div className="col-md-4 col-sm-6 col-xs-12">

            <div className="info-box">
              <span className="info-box-icon bg-green"><i className="fas fa-plus-circle"></i></span>

                <div className="info-box-content">
                  <span className="info-box-text"></span><br/>
                  <span className="info-box-number">
                    <button onClick={(event) => this.setState({view: 'create', status: '', msg: null})} className="btn btn-default">{i18n.t('add_new_item')}</button>
                  </span>
                </div>

            </div>

          </div>
        </div>

        <FullCalendar
          defaultView="dayGridMonth"
          locale={plLocale}
          ref={this.calendarRef}
          header={{ left: 'prev, next', center: 'title', right: null }}
          showNonCurrentDates={false}
          plugins={[dayGridPlugin]}
          weekends={false}
          events={this.state.items}
          eventClick={function(calEvent){
            this.setState({currentItemID: calEvent.event.id, view: 'details', status: '', msg: null}, () => {this.getItemDetails()})
          }.bind(this)}
          />
      </div>
    )
  }

  renderCalendarItemDetails(){
    return(
      <div className="row">
        <div className="col-lg-6 col-md-8 col-sm-12">

          <button onClick={(event) => this.setState({view: 'calendar', status: '', msg: null}, () => {this.getCalendar()})} className="btn btn-default">{i18n.t('go_back_to_list')}</button>

          <table className="table table-hover">
            <tbody>

              <tr>
                <td>{i18n.t('kid_absence_from')}</td>
                <td><strong>{this.state.items[0].start}</strong></td>
              </tr>
              <tr>
                <td>{i18n.t('kid_absence_to')}</td>
                <td><strong>{this.state.items[0].end}</strong></td>
              </tr>
              <tr>
                <td>{i18n.t('who_reports')}</td>
                <td><strong>{this.state.items[0].author}</strong></td>
              </tr>
              <tr>
                <td>{i18n.t('report_date')}</td>
                <td><strong>{this.state.items[0].cdate}</strong></td>
              </tr>


            </tbody>
          </table>

          <p><strong>{i18n.t('absence_reason')}</strong></p>
          <p>{this.state.items[0].title}</p>

        </div>
      </div>
    );
  }

  renderCreateForm(){
    return(
      <div className="row">
        <div className="col-lg-6 col-md-8 col-sm-12">

          <form onSubmit={(event) => this.submitCreateForm(event)}>

            <div className="form-group">
              <label>{i18n.t('select_start_date')}</label>
              <DatePicker
                  locale="lang"
                  selected={this.state.fromDate}
                  onSelect={(value) => this.setDate(value, 'fromDate')}
                  todayButton={i18n.t('set_current_date')}
                  filterDate={this.isWeekday}
                  shouldCloseOnSelect={false}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
              />
            </div>

            <div className="form-group">
              <label>{i18n.t('select_finish_date')}</label>
              <DatePicker
                  locale="lang"
                  selected={this.state.toDate}
                  onSelect={(value) => this.setDate(value, 'toDate')}
                  todayButton={i18n.t('set_current_date')}
                  filterDate={this.isWeekday}
                  shouldCloseOnSelect={false}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
              />
            </div>

            <div className="form-group">
              <label>{i18n.t('absence_reason')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <textarea className="form-control" name="reason" rows="10" value={this.state.reason} onChange={(event) => this.setStateValue(event, 'reason')} required />
            </div>

            <hr/>
            <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
            <button type="button" onClick={(event) => this.setState({view: 'calendar'}, () => {this.getCalendar()})} className="btn btn-default">{i18n.t('button_cancel')}</button>

          </form>

        </div>
      </div>
    )
  }

  render(){
    var view = helperFunctions.showBlockadeBlock(i18n.t('access_denied'), i18n.t('access_denied_info'));
    if(this.props.currentKid.canISeeDetails === true){
      if(this.state.isLoading === false){
        if(this.state.view === 'calendar'){
          view = this.renderCalendarView();
        }
        if(this.state.view === 'details'){
          view = this.renderCalendarItemDetails();
        }

        if(this.state.view === 'create'){
          view = this.renderCreateForm();
        }

      }
      else{
        view = <LoaderComponent />;
      }
    }

    return(
      <div>
        {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
        {view}
      </div>
    );
  }

}

export default ActivityLogComponent;
