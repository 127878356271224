import React, {Component} from 'react';

import * as requests from '../requests.js';
import i18n from "i18next";
import LoaderComponent from '../LoaderComponent';


export default
class KidsAssignedToActivityComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'list',
      currentKidRelationID: 0,
      currentRelationID: 0,
      kids: [],
      status: '',
      msg: null,
    }

    this.getRelatedKidsList = this.getRelatedKidsList.bind(this);
  }

  componentWillMount(){
    this.getRelatedKidsList(this.props.activityID);
  }

  componentDidUpdate(prevProps) {
    if (this.props.activityID !== prevProps.activityID) {
      this.getRelatedKidsList(this.props.activityID);
    }
  }

  getRelatedKidsList(activityID){
    this.setState({isLoading: true, teachers: []});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : activityID,
    }

    let request = requests.getSchoolAdditionalActivityKids(array);
    request.then(function(response){
      this.setState({kids: response.data[0]});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderKidsList(){
    return(
      <div>
        <ul className="list-unstyled">
            {
              this.state.kids.map(function(cal){
                return(
                  <li key={cal.id}>
                    <div className="media">
                      <div className="media-left">
                        <img className="media-object img-md img-circle" src={cal.kid.image} alt="..." />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">{cal.kid.name} {cal.kid.surname}</h4>
                        <p>{i18n.t('relation_cdate')}: <b>{cal.cdate}</b></p><br/>

                      </div>
                    </div>
                    <hr/>
                  </li>
                )
              }.bind(this))
            }
        </ul>
      </div>
    );
  }

  renderListView(){
    var view = null
    if(this.state.kids !== undefined){
      view = this.renderKidsList();
    }
    return(
      <div>
      {view}
      </div>
    );
  }

  renderDeleteView(){}

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView();
      }

      else if(this.state.view === 'delete'){
        view = this.renderDeleteView();
      }
    }
    else{
      view = <LoaderComponent />
    }

    return(
      <div>
        {view}
      </div>
    )
  }

}
