import React, {Component} from 'react';
import * as requests from '../../Auth/requests.js';
import LoaderComponent from '../../Utils/LoaderComponent';
import i18n from "i18next";


class LoginComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      isLoading: false,
      error: '',
      aggr_1: 0,
      aggr_2: 0,
      needToAgree: false,
      view: 'form',
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitAggr = this.handleSubmitAggr.bind(this);
    this.changeState = this.changeState.bind(this);
  }

  componentWillUnmount(){
    this.setState({
      isLoading: false,
      error: '',
      aggr_1: 0,
      aggr_2: 0,
      needToAgree: false,
      view: 'form',
    });
  }

  handleSubmit(event){
    event.preventDefault();

    this.setState({isLoading: true, error: ''});

    var email = event.target.username.value;
    var password = event.target.password.value;

    let request = requests.auth(email, password);
    request.then(function(response){
      var token = response.data.token;
      var objectID = response.data.objectID;
      var lastLoginTime = response.data.lastLoginTime;
      var lang = response.data.lang;
      if(token !== null && objectID > 0){
        localStorage.removeItem('token');
        localStorage.setItem('token', token);
        localStorage.removeItem('objectID');
        localStorage.setItem('objectID', objectID);
        localStorage.removeItem('lastLoginTime');
        localStorage.setItem('lastLoginTime', lastLoginTime);
        localStorage.removeItem('lang');
        localStorage.setItem('lang', lang);

        if(response.data.needToAgree === true && localStorage.getItem("token") !== null){
          this.setState({
            aggr_1: response.data.aggr_1,
            aggr_2: response.data.aggr_2,
            view: 'aggr'
          })
        }
        else{
          this.props.history.push('/panel');
        }
      }
      else{
        this.props.history.push('/');
      }
    }.bind(this));

    request.catch(function(error){
      this.setState({error: i18n.t("error_data_input")})
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false})
    }.bind(this));

  }

  handleSubmitAggr(event){
    event.preventDefault();

    this.setState({isLoading: true, error: ''});

    var array = {
      aggr_1: this.state.aggr_1,
      aggr_2: this.state.aggr_2
    }

    let request = requests.acceptAggr(array, this.state.token);
    request.then(function(response){
      this.props.history.push('/panel');
    }.bind(this));

    request.catch(function(error){
      localStorage.removeItem('token');
      localStorage.removeItem('objectID');
      localStorage.removeItem('lastLoginTime');
      localStorage.removeItem('lang');
      this.props.history.push('/');
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false})
    }.bind(this));
  }

  changeState(event, type){

    if(type === 'aggr_1'){
      this.setState({aggr_1: event.target.value});
    }
    if(type === 'aggr_2'){
      this.setState({aggr_2: event.target.value});
    }
  }

  renderForm(){
    return(
      <div className="mp_headerWrapper">
        <div className="container">

          <div className="mp_login">
            <div className="mp_login_form">

              <form onSubmit={this.handleSubmit}>
                <label>{i18n.t("login_form_email")}</label>
                <div className="form-group">
                  <input type="text" name="username" className="form-control" placeholder={i18n.t("login_form_email")} required/>
                </div>
                <label>{i18n.t("login_form_password")}</label>
                <div className="form-group">
                  <input type="password" name="password" className="form-control" placeholder={i18n.t("login_form_password")} required/>
                </div>
                <div className="row">

                  <div className="col-xs-12">
                    <button type="submit" className="btn btn-warning btn-flat">{i18n.t("login_submit")}</button>
                    <p>{this.state.error}</p>
                  </div>

                </div>
              </form>

            </div>
          </div>

        </div>
      </div>
    );
  }

  renderAggrForm(){
    return(
      <div className="mp_headerWrapper">
        <div className="container">

          <div className="mp_login">
            <div className="mp_login_form">

              <form onSubmit={this.handleSubmitAggr}>

                <p>Aby móc skorzystać z systemu NETkid musisz zaakceptować postanowienia zawarte w poniższych dokumentach.</p>

                <ul>
                  <li><input type="checkbox" name="aggr_1" value="1" onChange={(event) => this.changeState(event, 'aggr_1')} checked={this.state.aggr_1 ? "checked" : null} required /> <small>&nbsp;&nbsp;Wyrażam zgodę na przetwarzanie moich danych osobowych.</small></li>
                  <li><input type="checkbox" name="aggr_2" value="1" onChange={(event) => this.changeState(event, 'aggr_2')} checked={this.state.aggr_2 ? "checked" : null} required /> <small>&nbsp;&nbsp;Oświadczam, iż zapoznałem się z <a href="/regulamin-korzystania-z-uslugi" target="_blank">Regulaminem</a> usługi NETkid.</small></li>
                </ul>

                <div className="row">

                  <div className="col-xs-12">
                    <button type="submit" className="btn btn-warning btn-flat">{i18n.t("submit_and_login_submit")}</button>
                    <p>{this.state.error}</p>
                  </div>

                </div>
              </form>

            </div>
          </div>

          <div className="mp_privacyBox">
            <p>Administratorem danych osobowych, przechowywanych w ramach usługi NetKid, jest Klett Polska sp. z o.o. sp.k. z siedzibą w Poznaniu (60-401) przy ul. Polskiej 114. Dane osobowe będą przetwarzane w celu założenia i korzystania z funkcjonalności usługi NetKid, a także w celach analitycznych i statystycznych. Szczegółowe informacje na temat funkcjonalności NetKid oraz przetwarzania danych osobowych można znaleźć <a href="/regulamin-korzystania-z-uslugi" target="_blank">tutaj</a>.</p>

            <p>*Podanie powyższych danych osobowych i wyrażenie zgód jest dobrowolne, ale niezbędne do założenia konta i korzystania z funkcjonalności NetKid.</p>

          </div>
        </div>
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === true){
      view = <LoaderComponent />;
    }
    else{
      if(this.state.view === 'form'){
        view = this.renderForm();
      }
      else if (this.state.view === 'aggr') {
        view = this.renderAggrForm();
      }
    }
    return(
      <div>
        {view}

      </div>
    );
  }

}

export default LoginComponent;
