import React, {Component} from 'react'

import * as DashboardRequests from '../../Requests/DashboardRequests.js';
import i18n from "i18next";

class AsideNavigationComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      navigation: [],
      activeModule: '',
    }
  }

  componentDidMount(){
    this.loadNavigation();
  }



  loadNavigation(){
    let request = DashboardRequests.getNavigation();
    request.then(function(response){
      this.setState({navigation: response.data.data});
    }.bind(this));

    request.catch(function(error){
    });
  }

  render(){
    return(
      <aside className="main-sidebar">
          <section className="sidebar">


              <div className="user-panel hidden-sm hidden-xs">
                <div className="logoWrapper"><a href="/panel"><img className="logo" src="https://net-kid.com/images/logo.png" /></a></div>
              </div>


              <ul className="sidebar-menu" data-widget="tree">
                  <li className="header">{i18n.t('modules_menu')}</li>
                  {
                    this.state.navigation.map(function(item)
                      {
                        return (<li className={this.props.activeModule == item.link ? 'active' : null} key={item.id}><a href="#" onClick={(event) => this.props.onClickModuleHandler(event, item.link)}><i className={item.icon}></i><span> {i18n.t(item.name)}</span></a></li>);
                      }.bind(this)
                    )
                  }

              </ul>

          </section>

      </aside>
    );
  }

}
export default AsideNavigationComponent;
