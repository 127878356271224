import React, {Component} from 'react';

import * as DashboardRequests from '../../../Requests/DashboardRequests.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';

export default
class ModulesComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      navigation: [],
      isLoading: false,
    }
  }

  componentDidMount(){
    this.loadNavigation();
  }

  componentDidUpdate(prevProps){
    if(prevProps.reloadList !== this.props.reloadList){
      this.props.finishListReloadHandler(false);
      return true;
    }
    return false;
  }

  loadNavigation(){
    this.setState({isLoading: true});
    let request = DashboardRequests.getNavigation();
    request.then(function(response){
      this.setState({navigation: response.data.data});
    }.bind(this));

    request.catch(function(error){
    });

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderMainView(){
    return(
      <div>
        <div>
          <div className="jumbotron">

            <div className="media">
              <div className="media-left hidden-sm hidden-xs">
                <img className="media-object" src="https://net-kid.com/images/logo.png" />
              </div>
              <div className="media-body">
                <h2 className="media-heading">{i18n.t('welcome')} {this.props.currentUser.name} {i18n.t('in_system')} <strong>NET</strong>kid!</h2>
                <p><small>{i18n.t('welcome_info')}<br/><strong>{i18n.t('have_a_nice_day')}<br/></strong></small></p>
              </div>
            </div>

          </div>
        </div>
        <div className="page-header">
          <h2><i className="fas fa-cubes"></i> {i18n.t('system_modules_list')}</h2>
        </div>
        <div className="row">
        {
          this.state.navigation.map(function(item)
            {
              return (
                <div className="col-md-3 col-sm-4 col-xs-12" key={item.id}>
                  <div onClick={(event) => this.props.onClickModuleHandler(event, item.link)} className="info-box cursor hoverBox">
                    <span className={"info-box-icon " + item.css  }><i className={item.icon}></i></span>

                    <div className="info-box-content">
                      <span className="info-box-text"></span>
                      <span className="info-box-number">{i18n.t(item.name)}</span>
                    </div>
                  </div>
                </div>);
            }.bind(this)
          )
        }
        </div>
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderMainView();
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
        {view}
      </div>
    );
  }

}
