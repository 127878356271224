import React, {Component} from 'react';

import principal from '../../../Img/dyrekcja.png';
import teacher from '../../../Img/nauczycielka.png';
import parent from '../../../Img/ociec.png';

export default class HomeInlineSectionComponent extends Component{

  render(){
    return(
      <div className="mp_contentList">
        <div className="container">

          <div className="row">
            <div className="col-sm-6 col-md-4">
              <div className="thumbnail">
                <img src={principal} />
                <div className="caption">
                  <h3>Dla dyrekcji</h3>
                  <p>
                    Komunikacja z rodzicami nigdy nie była łatwiejsza!
                  </p>

                </div>
              </div>
            </div>


              <div className="col-sm-6 col-md-4">
                <div className="thumbnail">
                  <img src={teacher} />
                  <div className="caption">
                    <h3>Dla nauczycieli</h3>
                    <p>
                      Minimum administracji. Maksimum czasu dla dzieci
                    </p>

                  </div>
                </div>
              </div>


            <div className="col-sm-6 col-md-4">
              <div className="thumbnail">
                <img src={parent} />
                <div className="caption">
                  <h3>Dla rodziców</h3>
                  <p>
                    Komunikacja z przedszkolami nigdy nie była łatwiejsza!
                  </p>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }

}
