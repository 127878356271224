import React, {Component} from 'react';

import i18n from "i18next";
import * as acl from '../../acl.js';
import LoaderComponent from '../../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import * as requests from '../../requests.js';

import Moment from 'moment';

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import plLocale from '@fullcalendar/core/locales/pl';

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

export default
class ActivityListComponent extends Component{

  calendarRef = React.createRef();

  constructor(props){
    super(props);

    this.state = {
      view: 'list',
      isLoading: false,
      status: '',
      msg: null,
      group: [],
      currentMonth: '',
      ref: null,
      current: '',
      currentItemDay: '',
      kids: [],
      canEdit: false,
    }

    this.changeState = this.changeState.bind(this);
    this.getActivityDetails = this.getActivityDetails.bind(this);
    this.absenceAction = this.absenceAction.bind(this);
    this.getActivityParentDetails = this.getActivityParentDetails.bind(this);
  }

  componentDidMount(){
    this.setState({
      currentMonth: this.props.currentGroup.currentMonth,
      ref: this.calendarRef.current.getApi(),
    }, () => {this.getGroupActivityCalendar()})

  }

  componentWillMount(){
    this.setState({group: this.props.currentGroup});
  }

  changeState(event, type){
    if(type === 'month_select'){
      this.setState({currentMonth: event.target.value}, () => {this.getGroupActivityCalendar()})
    }
  }

  absenceAction(event, kidID, action){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'objectID' : this.props.currentGroup.schoolID,
      'groupID' : this.props.currentGroup.id,
      'kidID' : kidID,
      'action' : action,
      'day' : this.state.currentItemDay,
    }

    let request = requests.postGroupActivityAbsence(array);
    request.then(function(response){
      this.getActivityDetails();
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getGroupActivityCalendar(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : this.props.currentGroup.schoolID,
      'groupID' : this.props.currentGroup.id,
      'month' : this.state.currentMonth,
    }

    let request = requests.getGroupActivityCalendar(array);
    request.then(function(response){
      this.setState({group: response.data, current: response.data.current});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getActivityDetails(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : this.props.currentGroup.schoolID,
      'groupID' : this.props.currentGroup.id,
      'day' : this.state.currentItemDay,
    }

    let request = requests.getGroupActivityDayInfo(array);
    request.then(function(response){
      this.setState({kids: response.data.kids, canEdit: response.data.canEdit});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getActivityParentDetails(){}

  componentDidUpdate(prevProps, prevState){
    if(this.state.current !== '' && this.calendarRef.current !== null){
      let calendarApi = this.calendarRef.current.getApi();
      calendarApi.gotoDate(new Date(this.state.group.current));
    }
  }

  renderListView(){
    return(
      <div>

        <div className="row">

          <div className="col-md-4 col-sm-6 col-xs-12">

            <div className="info-box">
              <span className="info-box-icon bg-groups"><i className="fas fa-calendar-alt"></i></span>

                <div className="info-box-content">
                  <span className="info-box-text">{i18n.t('choose_month')}</span><br/>
                  <span className="info-box-number">
                    <select className="form-control" value={this.state.currentMonth} onChange={(event) => this.changeState(event, 'month_select')}>
                      {
                        this.props.currentGroup.months.map(function(item){
                          return(
                            <option key={item.key} value={item.month}>{item.month}</option>
                          );
                        }.bind(this))
                      }
                    </select>
                  </span>
                </div>

            </div>
          </div>

        </div>

        <FullCalendar
          defaultView="dayGridMonth"
          locale={plLocale}
          ref={this.calendarRef}
          header={{ left: null, center: null, right: null }}
          showNonCurrentDates={false}
          plugins={[dayGridPlugin]}
          weekends={false}
          events={this.state.group.details}
          eventClick={function(calEvent){
            this.setState({currentItemDay: calEvent.event.id, view: 'details'}, () => {this.getActivityDetails()})
          }.bind(this)}
          />

      </div>
    );
  }

  renderRevertButton(kidID){
    if(this.state.canEdit === true){
      return(
        <button  onClick={(event) => this.absenceAction(event, kidID, 'revert')} className="btn btn-warning">{i18n.t('withdraw')}</button>
      );
    }
    else{
      return(<div></div>);
    }
  }

  renderAssignButtons(kidID){
    if(this.state.canEdit === true){
      return(
        <div className="btn-group" role="group" aria-label="...">
          <button type="button" onClick={(event) => this.absenceAction(event, kidID, 'absent')} class="btn btn-danger">{i18n.t('absent')}</button>
          <button type="button" onClick={(event) => this.absenceAction(event, kidID, 'present')} class="btn btn-success">{i18n.t('present')}</button>
        </div>
      );
    }
    else{
      return(<div></div>);
    }
  }

  renderDetailsView(){
    return(
      <div>
        <button className="btn btn-default" onClick={(event) => this.setState({view: 'list'}, () => this.getGroupActivityCalendar())}>{i18n.t('go_back_to_list')}</button><hr/>
        <form>

          <table className="table table-hover">
            <tbody>
              {
                this.state.kids.map(function(item){
                  var buttons = this.renderAssignButtons(item.id)
                  if(item.exists === true){
                    buttons = this.renderRevertButton(item.id);
                  }

                  return(
                    <tr className={item.checked === false ? "danger" : "success"}>
                      <td><img className="media-object img-circle img-sm" src={item.avatar} alt="" /></td>
                      <td>{item.name}</td>
                      <td>{item.absence === true ? i18n.t('reported_absence') : null}</td>
                      <td>{buttons}</td>
                    </tr>
                  )
                }.bind(this))
              }
            </tbody>
          </table>

        </form>
      </div>
    );
  }

  renderParentDetailsView(){
    return(
      <div>
        <button className="btn btn-default" onClick={(event) => this.setState({view: 'list'}, () => this.getGroupActivityCalendar())}>{i18n.t('go_back_to_list')}</button><hr/>
        <p>Podgląd do obgadania</p>
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView();
      }
      if(this.state.view === 'details'){
        view = this.renderDetailsView();
      }
      if(this.state.view === 'parentDetails'){
        view = this.renderParentDetailsView();
      }
    }
    else{
      view = <LoaderComponent />;
    }
    return(
      <div>
        {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
        {view}
      </div>
    );
  }

}
