import React, {Component} from 'react';
import * as requests from '../requests.js';
import LoaderComponent from '../../Utils/LoaderComponent';
import i18n from "i18next";

class RemindPassword extends Component{

  constructor(){
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      isLoading: false,
      error: '',
      view: 'form',
    }
  }

  handleSubmit(event){
    event.preventDefault();

    this.setState({isLoading: true, error: ''});

    var email = event.target.username.value;

    let request = requests.remindPassword(email);
    request.then(function(response){
      this.setState({view: 'end'})
    }.bind(this));

    request.catch(function(error){
      this.setState({error: i18n.t("error_data_input")})
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false})
    }.bind(this));

  }

  renderForm(){
    return(
      <form onSubmit={this.handleSubmit}>
        <div className="form-group has-feedback">
          <input type="email" name="username" className="form-control" placeholder={i18n.t("login_form_email")} required/>
          <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
        </div>

        <div className="row">

          <div className="col-xs-12">
            <button type="submit" className="btn btn-success btn-block btn-flat">Przejdź dalej</button>

            <br/>
            <a href="/">Zaloguj się</a>

          </div>

        </div>
      </form>
    );
  }

  renderEndInfo(){
    return(
      <div>Postępuj zgodnie z informacjami przesłanymi na podany wcześniej adres e-mail.</div>
    );
  }


  render(){
    var view = this.renderForm();
    if(this.state.view === 'end'){
      view = this.renderEndInfo();
    }
    if(this.state.isLoading === true){
      view = <LoaderComponent />;
    }
    return(
      <div className="login-box">
        <div className="login-logo">
          <a href="/"><img src="https://api.net-kid.com/assets/img/logo.png" /></a>
        </div>

        <div className="login-box-body">
          <p className="login-box-msg">Aby zmienić hasło podaj adres e-mail do swojego konta</p>

          {view}
          <p>{this.state.error}</p>

        </div>

      </div>
    );
  }

}

export default RemindPassword;
