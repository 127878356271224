import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import CkEditorComponent from '../../../../../Utils/CkEditorComponent';
import AttachmentsComponent from '../../../../../Utils/AttachmentsComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';

export default
class ObjectContentSingleComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      isLoading: false,
      status: '',
      msg: null,
      view: 'edit',
      objectContent: null,
      gallery: [],
      objectGalleryID: 0,
      objectAttachments: [],
      items: [],
      validationFields: [],
      filesStatus: false
    }

    this.submitForm = this.submitForm.bind(this);

    this.onChangeContentHandler = this.onChangeContentHandler.bind(this);
    this.getGallery = this.getGallery.bind(this);
    this.setGalleryID = this.setGalleryID.bind(this);
    this.assignFilesHandler = this.assignFilesHandler.bind(this);
  }

  componentDidMount(){
    this.getObjectContent();
    this.getGallery();
  }

  componentDidUpdate(prevProps){
    if(prevProps.type !== this.props.type){
      this.getObjectContent(this.props.type);
      this.setState({
        view: 'edit',
        status: '',
        msg: '',
        object: {id: null},
        objectContent: null,
        objectAttachments: [],
        items: [],
        validationFields: [],
        objectFileFormData: [],
        filesStatus: false
      });
      return true;
    }
    return false;
  }

  submitForm(event){
    event.preventDefault();
    this.setState(
      {
        isLoading: true,
        object: {
          objectID: localStorage.getItem('objectID'),
        }
      }
    );

    const data = new FormData();
    for (var i = 0; i < this.state.objectAttachments.length; i++) {
      data.append('file[]', this.state.objectAttachments[i]);
    }

    data.append('school_id', localStorage.getItem('objectID'));
    data.append('id', this.state.items.id);
    data.append('type', this.props.type);
    data.append('title', event.target.title.value);
    data.append('content', this.state.objectContent);
    data.append('gallery_id', this.state.objectGalleryID);

    let request = requests.postMainSettingsObjectContentManage(data);
    request.then(function(response){
      this.setState({status: 'success', msg: i18n.t(response.data.msg), object: []}, () => {this.getObjectContent()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else if (error.response.data.error === 'validation_error') {
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationFields: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getGallery(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
    }

    let request = requests.getSchoolGallery(array);
    request.then(function(response){
      this.setState({gallery: response.data});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  onChangeContentHandler(event, editor, data){
    this.setState({objectContent: data});
  }

  setGalleryID(event){
    this.setState({objectGalleryID: event.target.value});
  }

  assignFilesHandler(event){
    event.preventDefault();
    this.setState({
      objectAttachments: event.target.files.files,
      filesStatus: true
    })
  }

  getObjectContent(type){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'type' : this.props.type
    }

    let request = requests.getMainSettingsObjectContent(array);
    request.then(function(response){
      this.setState({items: response.data[0], objectGalleryID: response.data[0].gallery_id, objectContent: response.data[0].content});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  loadTempAttachments(){
    return(
      <div className="callout callout-info">
        <h4>{i18n.t('new_attachments_added_to_the_collection')}</h4>
        <p>{i18n.t('files_count')}: <b>{this.state.objectAttachments.length}</b></p>
      </div>
    );
  }

  renderFormView(){
    var view;
    if(this.state.view === 'edit'){
      view = <button onClick={(event) => this.deleteItem(event)} type="button" class="text-right btn btn-danger"><i className="fas fa-trash-alt"></i> {i18n.t('button_delete')}</button>
    }

    var tempAttachments = null;
    if(this.state.objectAttachments.length){
      tempAttachments = this.loadTempAttachments()
    }

    return(
      <div>
        <form onSubmit={(event) => this.submitForm(event)}>
          {helperFunctions.getTextInput(
              i18n.t('set_item_title'),
            "title",
            this.state.items.title,
            this.state.validationFields.indexOf('title') === -1 ? false : true,
            i18n.t('error_data_input')
          )}

          {<CkEditorComponent label={i18n.t('set_item_content')} content={this.state.items.content} onChangeContentHandler={this.onChangeContentHandler}/>}

          

          {tempAttachments}

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
            </div>

          </div>

        </form>

        <hr/>
          {
            <AttachmentsComponent
              items={this.state.items.attachments}
              title={i18n.t('files')}
              view={"admin"}
              itemID={this.state.items.id}
              contentType={this.props.type}
              assignFilesHandler={this.assignFilesHandler}
              filesStatus={this.state.filesStatus}
              reloadFilesList={this.reloadFilesList}
            />
          }

      </div>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderFormView();
    }
    else{
      view = <LoaderComponent />;
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
