import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';

export default class DocumentsComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      file: [],
      filesArray: [],
      documents: [],
      view: 'list',
      description: '',
      isLoading: false,
      status: '',
      msg: null,
      url: '',
      validationErrors: [],
      id: 0,
    }

    this.submitFile = this.submitFile.bind(this);
    this.changeState = this.changeState.bind(this);

  }

  componentDidMount(){
    this.getInfo();
  }

  getInfo(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getIOSchoolUploadedDocuments(array);
    request.then(function(response){
      this.setState(
        {
          documents: response.data,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getDocumentInfo(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.state.id
    }

    let request = requests.getIOSchoolUploadedDocumentsInfo(array);
    request.then(function(response){
      this.setState(
        {
          id: response.data.id,
          description: response.data.description,
          filesArray: response.data.files,
          view: 'addDocumentView'
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  changeState(event, type){
    if(type === 'file'){
      this.setState({file: event.target.files})
    }
    if(type == 'addDocumentView'){
      this.setState({view: 'addDocumentView'})
    }
    if(type == 'documentsDescripton'){
      this.setState({description: event.target.value})
    }
  }

  deleteFile(id){
    this.setState({isLoading: true, status: '', msg: null, validationErrors: []});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.state.id,
      'fileID': id
    }

    let request = requests.postIOSchoolUploadedDocumentsDeleteFile(array);
    request.then(function(response){
      this.getDocumentInfo();
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitFile(event){
    event.preventDefault();
    this.setState({isLoading: true, status: '', msg: null, validationErrors: []});

    const data = new FormData();
    for (var i = 0; i < this.state.file.length; i++) {
      data.append('file', this.state.file[i]);
    }

    data.append('school_id', localStorage.getItem('objectID'));
    data.append('id', this.state.id);
    data.append('description', this.state.description);
    let request = requests.postImportObjectDocuments(data);
    request.then(function(response){
      this.setState({status: 'success', msg: i18n.t(response.data.msg), description: response.data.description, id: response.data.id, filesArray: response.data.files});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        if(error.response.data.error === 'validation_error'){
          this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
        }
        else{
          this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
        }
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  deleteAllDocument(){
    this.setState({isLoading: true, status: '', msg: null, validationErrors: []});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.state.id,
    }

    let request = requests.postIOSchoolUploadedDocumentsDelete(array);
    request.then(function(response){
      this.setState({
          view: 'list',
          id: 0,
          description: '',
          filesArray: [],
          files: [],
        }, () => this.getInfo())
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderDocumentsList(){
    return(
      <div>
        <hr/>
        {
          this.state.documents.map(function(item){
            return(
              <div className="info-box cursor" key={item.key} onClick={(event) => this.setState({
                  id: item.id
                }, () => {this.getDocumentInfo()})}>
                <span className="info-box-icon bg-aqua"><i className="fas fa-folder"></i></span>

                <div className="info-box-content">
                  <span className="info-box-text">{i18n.t('sent_by')}: <strong>{item.author}</strong></span>
                  <span className="info-box-number"><small>{i18n.t('files_count')}: </small>{item.files}<br/><small>{i18n.t('cdate')}: <strong>{item.cdate}</strong></small></span>
                </div>
              </div>
            )
          }.bind(this))
        }
      </div>
    );
  }

  renderListView(){
    return(
      <div>

        <div className="info-box">
          <span className="info-box-icon bg-red">
            <i className="fas fa-cloud-upload-alt"></i>
          </span>
          <div className="info-box-content">
            <span className="info-box-number">
              <small>
                {i18n.t('list_of_files_sent_by_principal')}
              </small>
            </span>
          </div>
        </div>

        <br/>

        <button className="btn btn-default" onClick={(event) => this.changeState(event, 'addDocumentView')}>{i18n.t('add_new_documents')}</button><br/>
        {this.renderDocumentsList()}

      </div>
    );
  }

  renderFilesList(){
    return(
      <div>
        {
          this.state.filesArray.map(function(item){
            return(
              <div className="info-box">
                <span className="info-box-icon bg-aqua"><i className={item.icon}></i></span>

                <div className="info-box-content">
                  <span className="info-box-text"><small>{item.name}</small></span>
                  <span className="info-box-number">{item.weight}<br/>
                  <button type="button" className="btn btn-xs btn-danger" onClick={(event) => this.deleteFile(item.id)}>{i18n.t('remove_this_file')}</button>
                </span>
                </div>

              </div>
            );
          }.bind(this))
        }
      </div>
    );
  }

  renderTextarea(){
    return(
      <div className={'description' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
        <label>{i18n.t('sended_files_description')}</label>
        <textarea name="description" className="form-control" rows="10" onChange={(event) => this.changeState(event, 'documentsDescripton')} value={this.state.description} />
        <span className="help-block">{'description' in this.state.validationErrors ? i18n.t(this.state.validationErrors['description']) : null}</span>
      </div>
    )
  }

  renderAddDocumentFormView(){
    var view = helperFunctions.renderNoContentInfo();
    if(this.state.filesArray !== undefined && this.state.filesArray.length > 0){
      view = this.renderFilesList()
    }

    var textarea = this.renderTextarea();
    var deleteButton;
    if(this.state.id > 0){
      deleteButton = <button className="btn btn-danger" onClick={(event) => this.deleteAllDocument()}>{i18n.t('delete_whole_item')}</button>
      var desc = i18n.t('no_content_to_display');
      if(this.state.description !== null && this.state.description !== ''){
        desc = this.state.description
      }
      textarea = <div><p><strong>{i18n.t('uploaded_documents_description')}</strong><br/>{desc}</p></div>
    }

    return(
      <div>

        <div className="info-box">
          <span className="info-box-icon bg-red">
            <i className="fas fa-cloud-upload-alt"></i>
          </span>
          <div className="info-box-content">
            <span className="info-box-number">
              <small>
                {i18n.t('add_files_that_you_want_to_send_to_us')}
              </small>
            </span>
          </div>
        </div>

        <br/>

        <form onSubmit={(event) => this.submitFile(event)}>

          {textarea}

          <p><strong>{i18n.t('information_about_sending_documents')}:</strong></p>
          <ul>
            <li><p className="text-danger">{i18n.t('system_accepts_only_few_types_of_documets_formats')}: <strong>PDF, DOC, DOCX, XLS, JPG, PNG, GIF</strong></p></li>
          </ul>

          <div className={'file' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('select_file_to_send')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <div class="input-group">
                <input type="file" className="form-control" name="file" onChange={(event) => this.changeState(event, 'file')} required />
                <span class="input-group-btn">
                  <button class="btn btn-danger" type="submit">{i18n.t('upload_file')}</button>
                </span>
              </div>
              <span class="help-block">{'file' in this.state.validationErrors ? i18n.t(this.state.validationErrors['file']) : null}</span>
          </div>

        </form>

        <div class="box box-default">
          <div class="box-header with-border">
            <h3 class="box-title">{i18n.t('list_of_sended_files')}</h3>
          </div>
          <div class="box-body">
            {view}
          </div>
        </div>

        {deleteButton}
        <button className="btn btn-default" onClick={(event) => this.setState({
            view: 'list',
            id: 0,
            description: '',
            filesArray: [],
            files: [],
          })}>{i18n.t('button_cancel')}</button>

      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView()
      }
      if(this.state.view === 'addDocumentView'){
        view = this.renderAddDocumentFormView()
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
