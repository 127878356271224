import React, {Component} from 'react';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';
import * as dashboardRequests from '../../../Requests/DashboardRequests.js';
import * as acl from '../acl.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import i18n from "i18next";

class KidListAccordionComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      list: [],
      objectGroups: [],
      currentGroupSelected: 0,
      isLoading: false,
    }

    this.changeGroupSelect = this.changeGroupSelect.bind(this);
  }

  componentDidMount(){
    this.getKidsList(this.props.objectID, 0);
    this.getObjectGroupsList(this.props.objectID);
  }

  componentDidUpdate(prevProps){
    if(prevProps.reload !== this.props.reload){
      this.props.afterReloadHandler(false);
      this.setState({currentGroupSelected: 0});
      this.getKidsList(this.props.objectID, 0);
      this.getObjectGroupsList(this.props.objectID);
      return true;
    }
    return false;
  }

  componentWillUnmount(){
    this.setState({list: [], objectGroups: []});
  }

  getKidsList(objectID, currentGroupSelected){
    this.setState({isLoading: true});
    var array = {
      'objectID' : objectID,
      'groupID' : currentGroupSelected
    }

    let request = requests.getKidsList(array);
    request.then(function(response){
      this.setState({list: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({list: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
      this.props.handleLoader(false);
    }.bind(this));

  }

  getObjectGroupsList(objectID){
    this.setState({isLoading: true});
    var array = {
      'objectID' : objectID
    }

    let request = dashboardRequests.getObjectGroupsList(array);
    request.then(function(response){
      this.setState({objectGroups: response.data});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  changeGroupSelect(event){
    this.setState({currentGroupSelected: event.target.value});
    this.getKidsList(this.props.objectID, event.target.value);
  }

  renderKidDetails(item){
    return(
      <div className="row">
        <div className="col-md-4 col-sm-6 col-xs-12">
          <h4>{i18n.t('parents')}</h4><hr/>
          <ul className="list-unstyled">
            {
              item.parents.map(function(elem){
                return(
                  <li key={elem.key}>
                    <div className="media">
                      <div className="media-left">
                        <img className="media-object img-circle img-sm" src={elem.avatar} alt="" />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">{elem.name}</h4>
                        <ul className="list-unstyled">

                          <li>{i18n.t('mobile_app')}: <b>{elem.mobile ? i18n.t('yes') : i18n.t('no')}</b></li>
                        </ul>
                      </div>
                    </div>
                    <br/>
                  </li>
                )
              }.bind(this))
            }
          </ul>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <h4>{i18n.t('caregivers')}</h4><hr/>
          <ul className="list-unstyled">
              {
                item.caregivers.map(function(elem){
                  return(
                    <li key={elem.key}>
                      <div className="media">
                        <div className="media-left">
                          <img className="media-object img-circle img-sm" src={elem.avatar} alt="" />
                        </div>
                        <div className="media-body">
                          <h4 className="media-heading">{elem.name}</h4>
                          <ul className="list-unstyled">

                            <li>{i18n.t('mobile_app')}: <b>{elem.mobile ? i18n.t('yes') : i18n.t('no')}</b></li>
                          </ul>
                        </div>
                      </div>
                      <br/>
                    </li>
                  )
                }.bind(this))
              }
            </ul>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <h4>{i18n.t('can_recieve')}</h4><hr/>
          <ul className="list-unstyled">
              {
                item.can_recieve.map(function(elem){
                  return(
                    <li key={elem.key}>
                      <div className="media">
                        <div className="media-left">
                          <img className="media-object img-circle img-sm" src={elem.avatar} alt="" />
                        </div>
                        <div className="media-body">
                          <h4 className="media-heading">{elem.name}</h4>
                          <ul className="list-unstyled">
                            <li>{i18n.t('mobile_app')}: <b>{elem.mobile ? i18n.t('yes') : i18n.t('no')}</b></li>
                          </ul>
                        </div>
                      </div>
                      <br/>
                    </li>
                  )
                }.bind(this))
              }
            </ul>
        </div>
      </div>
    );
  }

  renderAccordion(){
    return(
      <div>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-xs-12">

            <div className="info-box">
              <span className="info-box-icon bg-yellow"><i className="fas fa-user-friends"></i></span>

                <div className="info-box-content">
                  <span className="info-box-text">{i18n.t('choose_group')}</span><br/>
                  <span className="info-box-number">
                    <select className="form-control" onChange={(event) => this.changeGroupSelect(event)} value={this.state.currentGroupSelected}>
                      <option value="0">{i18n.t('select_option_show_all')}</option>
                      {
                        this.state.objectGroups.map(function(item){
                          return(<option key={item.id} value={item.id}>{item.name}</option>)
                        }.bind(this))
                      }
                    </select>
                  </span>
                </div>

            </div>
          </div>

        </div>

        <div className="box-group" id="accordion">

          {
            this.state.list.map(function(item){

              var view = helperFunctions.showBlockadeBlock(i18n.t('access_denied'), i18n.t('access_denied_info'));
              if(item.canISeeDetails === true){
                view = this.renderKidDetails(item);
              }

              return(
                <div key={item.id} className="panel box box-warning">
                  <div className="box-header with-border">
                    <h4 className="box-title">
                      <a data-toggle="collapse" data-parent="#accordion" href={"#collapse" + item.id}>
                      <div className="pull-left"><img className="img-circle img-md smallAvatar" src={item.avatar} /></div>
                      <div className="pull-left">
                      {item.kid}
                      <br/><small>{i18n.t('object')}: <b>{item.school}</b>, {i18n.t('group')}: <b>{item.group}</b><br/>{i18n.t('in_system_since')}: <b>{item.cdate}</b></small>
                      </div>
                      </a>
                    </h4>
                  </div>
                  <div id={"collapse" + item.id} className="panel-collapse collapse">
                    <div className="box-body">
                      {view}
                      <hr/>
                      <button onClick={(event) => this.props.changeMainView(event, 'full_kid_profile', item)} className="btn btn-warning">{i18n.t('full_kid_profile')}</button>
                    </div>
                  </div>
                </div>
              );
            }.bind(this))
          }

        </div>
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderAccordion();
    }
    else{
      view = <LoaderComponent />;
    }
    return(

      <div>{view}</div>

    );
  }

}

export default KidListAccordionComponent;
