import React, {Component} from 'react';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import * as requests from '../../requests.js';
import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';

class FoodPreferencesComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'list',
      isLoading: false,
      preferencesList: [],
      preferencesTypesList: [],
      selectedPreferenceType: 1,
      status: '',
      msg: null,
      currentItemID : 0,
      currentItemObj: [],
    }

    this.changeView = this.changeView.bind(this);
    this.changeSelectedPreferenceType = this.changeSelectedPreferenceType.bind(this);
    this.submitCreate = this.submitCreate.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);
    this.submitDelete = this.submitDelete.bind(this);
  }

  componentDidMount(){
    this.setState({preferencesList: this.props.moduleData.foodPreferences});
  }

  changeView(event, view, item = null){
    this.setState({status: '', msg: null});

    if(view === 'list'){
      this.setState({view: 'list'});
    }
    else if (view === 'create') {
      this.setState({view: 'create', selectedPreferenceType: 1}, () => {this.getFoodPreferecesTypes()});
    }
    else if (view === 'edit') {
      this.setState({view: 'edit', currentItemObj: item, selectedPreferenceType: item.type_id}, () => {this.getFoodPreferecesTypes()});
    }
    else if (view === 'delete') {
      this.setState({view: 'delete', currentItemID: item});
    }
  }

  changeSelectedPreferenceType(event){
    this.setState({selectedPreferenceType : event.target.value});
  }

  submitCreate(event){
    event.preventDefault();
    this.setState({isLoading: true});

    let array = {
      'objectID' : this.props.currentKid.schoolID,
      'kidID' : this.props.currentKid.kidID,
      'typeID' : this.state.selectedPreferenceType,
      'content' : event.target.content.value,
    }

    let request = requests.postKidFoodPreferencesItem(array);
    request.then(function(response){
      this.setState({view: 'list', status: 'success', msg: i18n.t(response.data.msg)}, () => {this.getFoodPreferecesList()});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitUpdate(event){
    event.preventDefault();
    this.setState({isLoading: true});

    let array = {
      'objectID' : this.props.currentKid.schoolID,
      'kidID' : this.props.currentKid.kidID,
      'typeID' : this.state.selectedPreferenceType,
      'content' : event.target.content.value,
      'itemID' : this.state.currentItemObj.id,
    }

    let request = requests.postKidFoodPreferencesItemUpdate(array);
    request.then(function(response){
      this.setState({view: 'list', status: 'success', msg: i18n.t(response.data.msg)}, () => {this.getFoodPreferecesList()});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitDelete(event){
    event.preventDefault();
    this.setState({isLoading: true});

    let array = {
      'objectID' : this.props.currentKid.schoolID,
      'kidID' : this.props.currentKid.kidID,
      'itemID' : this.state.currentItemID,
    }

    let request = requests.postKidFoodPreferencesItemDelete(array);
    request.then(function(response){
      this.setState({view: 'list', status: 'success', msg: i18n.t(response.data.msg)}, () => {this.getFoodPreferecesList()});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getFoodPreferecesTypes(){
    this.setState({isLoading: true});

    let array = {
      'objectID' : this.props.currentKid.schoolID,
      'kidID' : this.props.currentKid.kidID
    }

    let request = requests.getKidFoodPreferencesTypesList(array);
    request.then(function(response){
      this.setState({preferencesTypesList: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getFoodPreferecesList(){
    this.setState({isLoading: true});

    let array = {
      'objectID' : this.props.currentKid.schoolID,
      'kidID' : this.props.currentKid.kidID
    }

    let request = requests.getKidFoodPreferencesList(array);
    request.then(function(response){
      this.setState({preferencesList: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderDetailsView(){
    var canCreateItem = acl.check('create_kid_food_preference', ['role_principal'], this.props.currentUser.userRoles);
    if(canCreateItem === false && this.props.currentKid.isMyKid === true){
      canCreateItem = true;
    }

    return(
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">

          {canCreateItem ? <div><button onClick={(event) => this.changeView(event, 'create', null)} className="btn btn-default">{i18n.t('add_new_item')}</button><hr/></div> : null}
          {
            this.state.preferencesList.map(function(item){
              var buttons = null;
              if(canCreateItem){
                buttons = <div><hr/><button onClick={(event) => this.changeView(event, 'edit', item)} className="btn btn-xs btn-default">{i18n.t('button_edit')}</button><button onClick={(event) => this.changeView(event, 'delete', item.id)} className="btn btn-xs btn-danger">{i18n.t('button_delete')}</button></div>
              }
              return(
                <div key={item.id}>
                  <div className="media">
                    <div className="media-left">
                      <span className={"info-box-icon " + item.css}><i className={item.icon}></i></span>
                    </div>
                    <div className="media-body">
                      <h4 className="media-heading">{i18n.t(item.trans)}<br/><small>Data dodania: <b>{item.cdate}</b></small></h4>
                      <p>{item.content}</p>
                      {buttons}
                    </div>
                  </div>
                  <hr/>
                </div>
              );
            }.bind(this))
          }

        </div>
      </div>
    )
  }

  renderCreateView(){
    return(
      <div className="row">
        <div className="col-md-6 col-sm-9 col-xs-12">

          <form onSubmit={(event) => this.submitCreate(event)}>

            <div className="form-group">
              <label>{i18n.t('select_item_type')}</label>
              <select className="form-control" onChange={(event) => this.changeSelectedPreferenceType(event)} value={this.state.selectedPreferenceType}>
                {
                  this.state.preferencesTypesList.map(function(item){
                    return(<option key={item.id} value={item.id}>{i18n.t(item.trans)}</option>)
                  }.bind(this))
                }
              </select>
            </div>

            <div className="form-group">
              <label>{i18n.t('set_item_content')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <textarea name="content" className="form-control" rows="5" required />
            </div>

            <hr/>
            <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
            <button type="button" onClick={(event) => this.changeView(event, 'list')} className="btn btn-default">{i18n.t('button_cancel')}</button>

          </form>

        </div>
      </div>
    )
  }

  renderEditView(){
    return(
      <div className="row">
        <div className="col-md-6 col-sm-9 col-xs-12">

          <form onSubmit={(event) => this.submitUpdate(event)}>

            <div className="form-group">
              <label>{i18n.t('select_item_type')}</label>
              <select className="form-control" onChange={(event) => this.changeSelectedPreferenceType(event)} value={this.state.selectedPreferenceType}>
                {
                  this.state.preferencesTypesList.map(function(item){
                    return(<option key={item.id} value={item.id}>{i18n.t(item.trans)}</option>)
                  }.bind(this))
                }
              </select>
            </div>

            <div className="form-group">
              <label>{i18n.t('set_item_content')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <textarea name="content" className="form-control" rows="5" required>{this.state.currentItemObj.content}</textarea>
            </div>

            <hr/>
            <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
            <button type="button" onClick={(event) => this.changeView(event, 'list')} className="btn btn-default">{i18n.t('button_cancel')}</button>

          </form>

        </div>
      </div>
    )
  }

  renderDeleteView(){
    return(
      <div className="row">
        <div className="col-md-6 col-sm-9 col-xs-12">

          <form onSubmit={(event) => this.submitDelete(event)}>

            <p>{i18n.t('confirm_delete_action')}</p>

            <hr/>
            <button type="submit" className="btn btn-danger">{i18n.t('button_delete')}</button>
            <button type="button" onClick={(event) => this.changeView(event, 'list')} className="btn btn-default">{i18n.t('button_cancel')}</button>

          </form>

        </div>
      </div>
    )
  }

  render(){
    var view = helperFunctions.showBlockadeBlock(i18n.t('access_denied'), i18n.t('access_denied_info'));
    if(this.props.currentKid.canISeeDetails === true){
      if(this.state.isLoading === false){
        if(this.state.view === 'list'){
          view = this.renderDetailsView();
        }
        if(this.state.view === 'create'){
          view = this.renderCreateView();
        }
        if(this.state.view === 'edit'){
          view = this.renderEditView();
        }
        if(this.state.view === 'delete'){
          view = this.renderDeleteView();
        }
      }
      else{
        view = <LoaderComponent />;
      }
    }

    return(
      <div>
        {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
        {view}
      </div>
    );
  }

}

export default FoodPreferencesComponent;
