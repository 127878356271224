import API from '../../../../Utils/api';

export function checkAdminAccess(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/access', {params: array, headers: { Authorization: AuthStr }});
}

export function getMainSettingsObjectInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/main-settings/get/object-info', {params: array, headers: { Authorization: AuthStr }});
}

export function postMainSettingsObjectInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/main-settings/post/object-info', array, {headers: { Authorization: AuthStr }});
}

export function getMainSettingsObjectAddress(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/main-settings/get/object-address', {params: array, headers: { Authorization: AuthStr }});
}

export function postMainSettingsObjectAddress(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/main-settings/post/object-address', array, {headers: { Authorization: AuthStr }});
}

export function getMainSettingsObjectBranches(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/main-settings/get/object-branches', {params: array, headers: { Authorization: AuthStr }});
}

export function postMainSettingsObjectBranchManage(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/main-settings/post/object-branches', array, {headers: { Authorization: AuthStr }});
}

export function deleteMainSettingsObjectBranch(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/main-settings/post/object-branches/delete', array, {headers: { Authorization: AuthStr }});
}

export function getMainSettingsObjectContent(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/main-settings/get/object-content', {params: array, headers: { Authorization: AuthStr }});
}

export function postMainSettingsObjectContentManage(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/main-settings/post/object-content', array, {headers: { Authorization: AuthStr, 'Content-Type': 'multipart/form-data' }});
}

export function getSchoolGallery(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/gallery', {params: array, headers: { Authorization: AuthStr }});
}

export function deleteMainSettingsContent(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/main-settings/delete/object-content',array, {headers: { Authorization: AuthStr }});
}

export function getSchoolGroupDashboard(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/groups/get/dashboard', {params: array, headers: { Authorization: AuthStr }});
}

export function deleteSchoolDashboard(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/groups/delete/dashboard',array, {headers: { Authorization: AuthStr }});
}

export function getSchoolGroupDashboardDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/groups/get/dashboard/details', {params: array, headers: { Authorization: AuthStr }});
}

export function getSchoolGroupsMultiselect(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/groups/get/multiselect', {params: array, headers: { Authorization: AuthStr }});
}

export function postSchoolGroupDashboard(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/groups/post/dashboard', array, {headers: { Authorization: AuthStr }});
}

export function getSchoolGroupEvents(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/events/get/list', {params: array, headers: { Authorization: AuthStr }});
}

export function deleteSchoolEvent(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/events/delete',array, {headers: { Authorization: AuthStr }});
}

export function getSchoolGroupEventsDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/events/get/details', {params: array, headers: { Authorization: AuthStr }});
}

export function postSchoolGroupEvent(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/events/post/store', array, {headers: { Authorization: AuthStr, 'Content-Type': 'multipart/form-data' }});
}

export function getSchoolAdditionalActivityList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/additional-activity/get/list', {params: array, headers: { Authorization: AuthStr }});
}

export function getSchoolAdditionalActivityDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/additional-activity/get/details', {params: array, headers: { Authorization: AuthStr }});
}

export function postSchoolAdditionalActivity(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/additional-activity/post/details/update', array, {headers: { Authorization: AuthStr }});
}

export function deleteSchoolAdditionalActivity(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/additional-activity/delete',array, {headers: { Authorization: AuthStr }});
}

export function getSchoolSurveyList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/survey/get/list', {params: array, headers: { Authorization: AuthStr }});
}

export function getSchoolSurveyDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/survey/get/details', {params: array, headers: { Authorization: AuthStr }});
}

export function postSchoolSurveyUpdate(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/survey/post/update', array, {headers: { Authorization: AuthStr }});
}

export function getSchoolSurveyQuestions(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/survey/get/questions', {params: array, headers: { Authorization: AuthStr }});
}

export function postSchoolSurveyQuestionUpdate(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/survey/post/question/update', array, {headers: { Authorization: AuthStr }});
}

export function deleteSchoolSurveyQuestion(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/survey/delete/question',array, {headers: { Authorization: AuthStr }});
}

export function getSchoolSurveyAnswers(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/survey/get/answers', {params: array, headers: { Authorization: AuthStr }});
}

export function deleteSchoolSurvey(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/survey/delete', array, {headers: { Authorization: AuthStr }});
}

export function getSchoolFoodMenuDays(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/food/get/calendar', {params: array, headers: { Authorization: AuthStr }});
}

export function getSchoolFoodMenuDayDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/food/get/calendar/details', {params: array, headers: { Authorization: AuthStr }});
}

export function postNewSchoolFoodMenuDay(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/food/post/calendar/store', array, {headers: { Authorization: AuthStr }});
}

export function postUpdateSchoolFoodMenuItem(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/food/post/calendar/menu/item/update', array, {headers: { Authorization: AuthStr }});
}

export function getSchoolFoodMealTimes(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/food/get/meal-time', {params: array, headers: { Authorization: AuthStr }});
}

export function getSchoolFoodMealTimeDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/food/get/meal-time/details', {params: array, headers: { Authorization: AuthStr }});
}

export function postUpdateSchoolFoodMealTimeItem(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/food/post/meal-time/details/update', array, {headers: { Authorization: AuthStr }});
}

export function postStoreSchoolFoodMealTimeItem(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/food/post/meal-time/details/store', array, {headers: { Authorization: AuthStr }});
}

export function getKidsList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/kids/get/list', {params: array, headers: { Authorization: AuthStr }});
}

export function getWaitingKidsList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/kids/get/waiting', {params: array, headers: { Authorization: AuthStr }});
}

export function getGroupsList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/groups/get/multiselect', {params: array, headers: { Authorization: AuthStr }});
}

export function getGroupDailyLog(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/groups/get/daily-log', {params: array, headers: { Authorization: AuthStr }});
}

export function getGroupDailyLogDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/groups/get/daily-log/details', {params: array, headers: { Authorization: AuthStr }});
}

export function getGroupActvityLogUnusedCards(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/groups/get/daily-log/unused-cards', {params: array, headers: { Authorization: AuthStr }});
}

export function postGroupDailyLogAccept(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/groups/post/daily-log/accept', array, {headers: { Authorization: AuthStr }});
}

export function postGroupDailyLogStore(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/groups/post/daily-log/store', array, {headers: { Authorization: AuthStr }});
}

export function postGroupDailyLogUnusedCard(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/groups/post/daily-log/unused-card', array, {headers: { Authorization: AuthStr }});
}

export function postKidStore(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/kids/post/store', array, {headers: { Authorization: AuthStr }});
}

export function getKidInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/kids/get/info', {params: array, headers: { Authorization: AuthStr }});
}

export function postKidUpdateInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/kids/post/update/info', array, {headers: { Authorization: AuthStr }});
}

export function postKidUpdateAvatar(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/kids/post/update/avatar', array, {headers: { Authorization: AuthStr, 'Content-Type': 'multipart/form-data' }});
}

export function getKidParents(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/kids/get/parents', {params: array, headers: { Authorization: AuthStr }});
}

export function postCheckEmailExists(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/accounts/post/check-email', array, {headers: { Authorization: AuthStr }});
}

export function postCreateAccount(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/accounts/post/create', array, {headers: { Authorization: AuthStr }});
}

export function postUpdateAccount(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/accounts/post/update', array, {headers: { Authorization: AuthStr }});
}

export function getAccountInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/accounts/get/details', {params: array, headers: { Authorization: AuthStr }});
}

export function getAllGroupsList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/groups/get/list', {params: array, headers: { Authorization: AuthStr }});
}

export function postGroupStore(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/groups/post/store', array, {headers: { Authorization: AuthStr }});
}

export function getGroupDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/groups/get/details', {params: array, headers: { Authorization: AuthStr }});
}

export function postGroupUpdate(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/groups/post/update', array, {headers: { Authorization: AuthStr }});
}

export function postGroupUpdateAvatar(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/groups/post/update/avatar', array, {headers: { Authorization: AuthStr, 'Content-Type': 'multipart/form-data' }});
}

export function getGroupKids(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/groups/get/kids', {params: array, headers: { Authorization: AuthStr }});
}

export function deleteKidFormGroup(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/groups/delete/kid', array, {headers: { Authorization: AuthStr }});
}

export function getGroupTeachers(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/groups/get/teachers', {params: array, headers: { Authorization: AuthStr }});
}

export function getGroupGallery(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/groups/get/gallery', {params: array, headers: { Authorization: AuthStr }});
}

export function deleteTeacherFormGroup(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/groups/delete/teacher', array, {headers: { Authorization: AuthStr }});
}

export function getGalleryList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/gallery/get/list', {params: array, headers: { Authorization: AuthStr }});
}

export function getGallerySelect(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/gallery/get/select', {params: array, headers: { Authorization: AuthStr }});
}

export function getGalleryInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/gallery/get/info', {params: array, headers: { Authorization: AuthStr }});
}

export function postGalleryInfoUpdate(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/gallery/post/info', array, {headers: { Authorization: AuthStr }});
}

export function postGalleryStore(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/gallery/post/store', array, {headers: { Authorization: AuthStr }});
}

export function getGalleryPhotos(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/gallery/get/photos', {params: array, headers: { Authorization: AuthStr }});
}

export function postGalleryPhotosStore(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/gallery/post/photos', array, {headers: { Authorization: AuthStr, 'Content-Type': 'multipart/form-data' }});
}

export function updateGalleryPhotoCaptions(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/gallery/post/photo/captions', array, {headers: { Authorization: AuthStr }});
}

export function deleteGalleryPhoto(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/gallery/delete/photo', array, {headers: { Authorization: AuthStr }});
}

export function deleteGallery(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/gallery/delete', array, {headers: { Authorization: AuthStr }});
}

export function getTeachersList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/teachers/get/list', {params: array, headers: { Authorization: AuthStr }});
}

export function postTeacherStore(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/teachers/post/store', array, {headers: { Authorization: AuthStr }});
}

export function postTeacherStoreStep2(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/teachers/post/store-step-2', array, {headers: { Authorization: AuthStr }});
}

export function getTeacherGroups(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/teachers/get/groups', {params: array, headers: { Authorization: AuthStr }});
}

export function getTeacherDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/teachers/get/details', {params: array, headers: { Authorization: AuthStr }});
}

export function getTeacherInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/teachers/get/info', {params: array, headers: { Authorization: AuthStr }});
}

export function getTeacherRelatedInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/teachers/get/related', {params: array, headers: { Authorization: AuthStr }});
}

export function getTeacherDeleteInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/teachers/get/delete-info', {params: array, headers: { Authorization: AuthStr }});
}

export function postDeleteTeacher(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/teachers/delete', array, {headers: { Authorization: AuthStr }});
}

export function postTeacherUpdate(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/teachers/post/update', array, {headers: { Authorization: AuthStr }});
}

export function postTeacherInfoUpdate(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/teachers/post/info-update', array, {headers: { Authorization: AuthStr }});
}

export function getTeachersRolesSelect(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/teachers/get/roles-select', {params: array, headers: { Authorization: AuthStr }});
}

export function getTeachersGroupDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/teachers/get/group-details', {params: array, headers: { Authorization: AuthStr }});
}

export function postAssignTeacherToGroup(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/teachers/post/assign', array, {headers: { Authorization: AuthStr }});
}

export function deleteTeacherGroupAssignment(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/teachers/delete-assignment',array, {headers: { Authorization: AuthStr }});
}

export function postGroupGalleryStore(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/groups/post/gallery/store', array, {headers: { Authorization: AuthStr }});
}

export function getGroupGalleryDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/groups/get/gallery/details', {params: array, headers: { Authorization: AuthStr }});
}

export function deleteGroupGallery(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/groups/delete/gallery',array, {headers: { Authorization: AuthStr }});
}

export function postImportObjectInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/io/post/object-info', array, {headers: { Authorization: AuthStr }});
}

export function postImportObjectDocuments(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/io/post/object-documents', array, {headers: { Authorization: AuthStr }});
}

export function getKnowledgeItems(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/knowledge-base/get/items', {params: array, headers: { Authorization: AuthStr }});
}

export function getKnowledgeItemDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/knowledge-base/get/item/details', {params: array, headers: { Authorization: AuthStr }});
}

export function postKnowledgeItemUpdate(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/knowledge-base/post/item/update', array, {headers: { Authorization: AuthStr, 'Content-Type': 'multipart/form-data' }});
}

export function deleteKnowledgeItem(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/knowledge-base/delete/item', array, {headers: { Authorization: AuthStr }});
}

export function getIOSchoolInfoDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/io/get/object-info', {params: array, headers: { Authorization: AuthStr }});
}

export function getIOSchoolUploadedDocuments(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/io/get/object-documents', {params: array, headers: { Authorization: AuthStr }});
}

export function getIOSchoolUploadedDocumentsInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/io/get/object-documents/info', {params: array, headers: { Authorization: AuthStr }});
}

export function postIOSchoolUploadedDocumentsDeleteFile(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/io/post/object-documents/delete-file', array, {headers: { Authorization: AuthStr }});
}

export function postIOSchoolUploadedDocumentsDelete(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/io/post/object-documents/delete', array, {headers: { Authorization: AuthStr }});
}

export function getIOUsersInfoExports(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/io/get/users-info/reports', {params: array, headers: { Authorization: AuthStr }});
}

export function getIOUsersInfoReportDownload(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/io/get/users-info/reports/download', {params: array, responseType: 'arraybuffer', headers: { Authorization: AuthStr }});
}

export function postIOUsersInfoGenerateReport(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/io/post/users-info/reports', array, {headers: { Authorization: AuthStr }});
}

export function getSystemSettings(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('admin/settings/get', {params: array, headers: { Authorization: AuthStr }});
}

export function postSystemSettings(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('admin/settings/post', array, {headers: { Authorization: AuthStr }});
}
