import React, {Component} from 'react';

import ObjectInfoComponent from '../ObjectInfoComponent';
import ObjectAddressComponent from '../ObjectAddressComponent';
import ObjectBranchesComponent from '../ObjectBranchesComponent';
import ObjectContentComponent from '../ObjectContentComponent';
import ObjectContentSingleComponent from '../ObjectContentSingleComponent';
import ObjectGroupsDashboardComponent from '../ObjectGroupsDashboardComponent';
import ObjectAddtionalActivityComponent from '../ObjectAddtionalActivityComponent';
import ObjectFoodMenuComponent from '../ObjectFoodMenuModule/InitialComponent';
import ObjectSurveyComponent from '../ObjectSurveyComponent';
import ObjectEventsComponent from '../ObjectEventsComponent';

import i18n from "i18next";

export default
class InitialComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'objectInfo'
    }
  }

  getSubComponentView(){
    var view = null;
    switch (this.state.view) {
      case 'objectInfo':
        view = <ObjectInfoComponent setAccessDenied={this.props.setAccessDenied}/>
        break;

      case 'objectMainBranch':
        view = <ObjectAddressComponent setAccessDenied={this.props.setAccessDenied}/>
        break;

      case 'objectBranch':
        view = <ObjectBranchesComponent setAccessDenied={this.props.setAccessDenied}/>
        break;

      case 'objectMessages':
        view = <ObjectContentComponent setAccessDenied={this.props.setAccessDenied} type={"messages"}/>
        break;

      case 'objectDownload':
        view = <ObjectContentComponent setAccessDenied={this.props.setAccessDenied} type={"download"}/>
        break;

      case 'objectParentsInfo':
        view = <ObjectContentComponent setAccessDenied={this.props.setAccessDenied} type={"parents_info"}/>
        break;

      case 'objectSocialActions':
        view = <ObjectContentComponent setAccessDenied={this.props.setAccessDenied} type={"social_actions"}/>
        break;

      case 'objectCertificates':
        view = <ObjectContentComponent setAccessDenied={this.props.setAccessDenied} type={"certifications"}/>
        break;

      case 'objectProjects':
        view = <ObjectContentComponent setAccessDenied={this.props.setAccessDenied} type={"projects"}/>
        break;

      case 'objectPolicy':
        view = <ObjectContentComponent setAccessDenied={this.props.setAccessDenied} type={"statute_and_regulations"}/>
        break;

      case 'objectPsycho':
        view = <ObjectContentSingleComponent setAccessDenied={this.props.setAccessDenied} type={"psychological_and_pedagogical_help"}/>
        break;

      case 'objectDailyRoutine':
        view = <ObjectContentSingleComponent setAccessDenied={this.props.setAccessDenied} type={"daily_routine"}/>
        break;

      case 'objectHolidayDuity':
        view = <ObjectContentSingleComponent setAccessDenied={this.props.setAccessDenied} type={"holiday_duty"}/>
        break;

      case 'objectGroupDashboard':
        view = <ObjectGroupsDashboardComponent setAccessDenied={this.props.setAccessDenied} />
        break;

      case 'objectAdditionalActivity':
        view = <ObjectAddtionalActivityComponent setAccessDenied={this.props.setAccessDenied} />
        break;

      case 'objectFoodMenu':
        view = <ObjectFoodMenuComponent setAccessDenied={this.props.setAccessDenied} />
        break;

      case 'objectSurvey':
        view = <ObjectSurveyComponent setAccessDenied={this.props.setAccessDenied} />
        break;

      case 'objectEvents':
        view = <ObjectEventsComponent setAccessDenied={this.props.setAccessDenied} />
        break;
    }
    return (<div>{view}</div>);
  }

  render(){
    var view = this.getSubComponentView();
    return(
      <div className="row">
        <div className="col-md-3 col-sm-4 col-xs-6">
          <div className="box box-solid">
            <div className="box-body no-padding">
              <ul className="nav nav-pills nav-stacked">
                <li className={this.state.view === 'objectInfo' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectInfo'})} href="#">{i18n.t('object_info')}</a></li>
                <li className={this.state.view === 'objectMainBranch' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectMainBranch'})} href="#">{i18n.t('address_module')}</a></li>
                <li className={this.state.view === 'objectBranch' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectBranch'})} href="#">{i18n.t('object_branches')}</a></li>
                <li className={this.state.view === 'objectMessages' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectMessages'})} href="#">{i18n.t('object_announcements')}</a></li>
                <li className={this.state.view === 'objectGroupDashboard' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectGroupDashboard'})} href="#">{i18n.t('object_group_announcements')}</a></li>
                <li className={this.state.view === 'objectAdditionalActivity' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectAdditionalActivity'})} href="#">{i18n.t('link_additional_activities')}</a></li>
                <li className={this.state.view === 'objectDownload' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectDownload'})} href="#">{i18n.t('files_download')}</a></li>
                <li className={this.state.view === 'objectParentsInfo' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectParentsInfo'})} href="#">{i18n.t('parents_info')}</a></li>
                <li className={this.state.view === 'objectPsycho' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectPsycho'})} href="#">{i18n.t('psycho_peda_help')}</a></li>
                <li className={this.state.view === 'objectSocialActions' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectSocialActions'})} href="#">{i18n.t('social_actions')}</a></li>
                <li className={this.state.view === 'objectDailyRoutine' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectDailyRoutine'})} href="#">{i18n.t('daily_rhythm')}</a></li>
                <li className={this.state.view === 'objectHolidayDuity' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectHolidayDuity'})} href="#">{i18n.t('holiday_duty')}</a></li>
                <li className={this.state.view === 'objectCertificates' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectCertificates'})} href="#">{i18n.t('certificates_and_awards')}</a></li>
                <li className={this.state.view === 'objectProjects' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectProjects'})} href="#">{i18n.t('programs_and_projects')}</a></li>
                <li className={this.state.view === 'objectPolicy' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectPolicy'})} href="#">{i18n.t('statute_and_regulations')}</a></li>
                <li className={this.state.view === 'objectFoodMenu' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectFoodMenu'})} href="#">{i18n.t('menus')}</a></li>
                <li className={this.state.view === 'objectSurvey' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectSurvey'})} href="#">{i18n.t('survey_module')}</a></li>
                <li className={this.state.view === 'objectEvents' ? "active" : null}><a onClick={(event) => this.setState({view: 'objectEvents'})} href="#">{i18n.t('link_events')}</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-8 col-xs-12">{view}</div>
      </div>


    );
  }

}
