import React, {Component} from 'react';
import i18n from "i18next";

export default class CookiesInfoComponent extends Component{

  constructor(props){
    super(props);
  }

  render(){
    return(
      <div className="container">

        <div className="content-body">


          <p><strong><em>Polityka Prywatności &ndash; Pliki COOKIES (Ciasteczka);</em></strong></p>
            <p>Niniejszy dokument stanowi integralną część regulaminu i dotyczy wyłącznie przeglądania zawartości Systemu NetKid.</p>
            <p>Administratorem Systemu NetKid jest: Klett Polska Sp. z o.o. ul. Polska 114, 60-401 Poznań, zarejestrowana przez Sad Rejonowy Poznań Nowe Miasto i Wilda, VIII Wydział Gospodarczy Krajowego Rejestru Sadowego,&nbsp;KRS 0000175262, NIP 779-00-00-273, kt&oacute;ry dba o ochronę prywatności Klient&oacute;w, a zbierane informację za pomocą plik&oacute;w Cookies, służą do pomocy w administrowaniu Systemu.</p>
            <p><strong><em>Czym są Pliki Cookies?</em></strong></p>
            <p><strong>Pliki Cookies</strong> &ndash; są to kr&oacute;tkie pliki tekstowe stanowiące dane informatyczne, służące do przeglądania zawartości Systemu, kt&oacute;re przechowywane są na urządzeniu ostatecznym (np. komputerze) Klienta.</p>
            <p>Klett informuje, że ruch na stronie jest monitorowany przez statystyki Google Analytics ma to na celu gromadzenie danych o sposobie korzystania i popularności NetKid. Korzystając ze stron NetKid, Klient zgadza się na analizowanie jego danych przez Google Analytics w opisanych w tym punkcie celu.</p>
            <p><strong><em>Rodzaje i cel korzystania przez Aplikacje Plik&oacute;w Cookies?</em></strong></p>
            <p><strong>SESYJNE:</strong> przechowywane tymczasowo i usuwane automatycznie po upływie pewnego czasu lub po wyłączeniu strony lub przeglądarki internetowej.</p>
            <p><strong>Cel:</strong> m.in. polepszenie nawigacji na stronie.</p>
            <p><strong>STAŁE:</strong> pozostają na urządzeniu przez określony czas lub do momentu usunięcia ich przez Klienta na jego Urządzeniu końcowym.</p>
            <p><strong>Cel:</strong> przechowywanie informacji o tym czy Użytkownik jest nowy, czy powracający oraz czy korzystał z Systemu za pośrednictwem Partnera.</p>
            <p><strong>ZEWNĘTRZNE:</strong> pliki cookies od serwis&oacute;w partnerskich: np. facebook, google, google analytics.</p>
            <p><strong>Cel:</strong> prowadzenie statystyk.</p>
            <p><strong><em>Jak włączyć/wyłączyć Pliki Cookies?</em></strong></p>
            <p>Klient w każdym czasie może dokonać zmiany ustawień przeglądarki, jednakże każda przeglądarka ma sw&oacute;j spos&oacute;b zmiany ustawień i tak np. używając przeglądarki internetowej Internet Explorer w wersji 8.0 należy: 1) Wybrać z menu &bdquo;Narzędzia&rdquo;, a następnie &bdquo;Opcje internetowe&rdquo;; 2) Przejść do zakładki &bdquo;Prywatność&rdquo;; 3) Użyć suwaka w celu ustawienia preferencji."</p>
            <p>Przeglądarki internetowe często domyślnie mają ustawioną możliwość instalowania Plik&oacute;w Cookies na urządzeniu końcowym Klienta.</p>
            <p>Administrator informuje, iż wyłączenie przez Klienta możliwości korzystania z plik&oacute;w Cookies może wpłynąć na funkcjonalność z Systemu.</p>
            <p><strong><em>Jakie dane są przetwarzane?</em></strong></p>
            <p>Administrator przetwarza dane podane dobrowolnie przez Klienta w celu otrzymywania aktualności związanych z działalnością Systemu.</p>
            <p>Administrator zobowiązuje się do bezpiecznego przechowywania danych i nie odstępowania ich osobom trzecim.</p>
            <p>Klient ma prawo wglądu do swoich danych oraz ma możliwość zgłoszenia żądania usunięcia bądź poprawienia danych poprzez wysłanie wiadomości e-mail.</p>
            <p><strong><em>Przekierowanie do innych stron.</em></strong></p>
            <p>System zawiera odnośniki do innych stron WWW. Klient każdorazowo wchodząc na nową stronę WWW powinien zapoznać się z Polityka Prywatności &ndash; Pliki Cookies. Administrator nie ponosi odpowiedzialności za Politykę Prywatności &ndash; Pliki Cookies obowiązujące na tych stronach.</p>
            <p><strong><em>Zmiany.</em></strong></p>
            <p>W przypadku zmiany obowiązującej polityki prywatności &ndash; plik&oacute;w Cookies, wprowadzone zostaną odpowiednie modyfikacje do powyższego zapisu.</p>



        </div>

      </div>
    )
  }

}
