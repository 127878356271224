import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import * as DashboardRequests from '../../../Requests/DashboardRequests.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';


export default
class SearchComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      objectSelectLoading: false,
      reloadList: false,
      isLoading: false,
      status: '',
      msg: null,
      validationErrors: [],
      query: '',
      searchModules: [],
      modules: [],
      results: [],
      levenshtein: [],
    }

    this.submitSearch = this.submitSearch.bind(this);
  }

  componentDidMount(){
    this.loadModules();
  }

  loadModules(){
    this.setState({isLoading: true});
    let request = requests.getSearchableModules();
    request.then(function(response){
      this.setState({modules: response.data.data});
    }.bind(this));

    request.catch(function(error){

    });

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  changeState(event, type){
    if(type === 'query'){
      this.setState({query: event.target.value});
    }
    if(type === 'content'){
      this.setState({content: event.target.value});
    }
  }

  redirectToKidDetails(event, kid){
    if(this.props.currentUser.isAdmin == true){
        this.props.onClickModuleHandler(event, 'admin', {'submodule' : 'kidsSettings', 'id' : kid.id});
    }
    else{
      this.props.onClickModuleHandler(event, 'kids', {'id' : kid.id});
    }
  }

  redirectToTeacherDetails(event, teacher){
    if(this.props.currentUser.isAdmin == true){
        this.props.onClickModuleHandler(event, 'admin', {'submodule' : 'teachersSettings', 'id' : teacher.id});
    }
  }

  submitSearch(event){
    event.preventDefault();
    this.setState({isLoading: true, validationErrors: []});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'query' : this.state.query,
    }

    var request = requests.postSearchForm(array);
    request.then(function(response){
      this.setState({msg: response.data.msg, status: 'success', results: response.data.results, levenshtein: response.data.levenshtein});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'validation_error'){
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderLevenshtein(levenshtein){
    return(
      <div className="alert alert-default" role="alert"><ul className="list-inline"><li><i className="fab fa-searchengin"></i> Podpowiedzi systemu:</li>
        {
          levenshtein.map(function(item){
            return (<li className="cursor" onClick={(event) => this.setState({query: item.item}, () => this.submitSearch(event))}><strong>{item.item}</strong></li>)
          }.bind(this))
        }
      </ul></div>
    );
  }

  renderKidsResults(result, levenshtein){
    var lev;
    var res;
    if(levenshtein !== undefined && levenshtein.length > 0){
      lev = this.renderLevenshtein(levenshtein)
    }

    if(result !== undefined && result.length > 0){
      res = result.map(function(item){
        return(
          <tr key={item.id} onClick={(event) => this.redirectToKidDetails(event, item)} className="cursor">
            <td><img className="img-circle img-md smallAvatar" src={item.image} /></td>
            <td>{item.name} {item.surname}</td>
            <td>{item.groupName}</td>
          </tr>
        );
      }.bind(this))
    }

    return(
      <div>
        {lev}

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">

            <table className="table table-border table-hover">
              <tbody>

                {res}
              </tbody>
            </table>

          </div>
        </div>

      </div>
    );
  }

  renderCadreResults(result, levenshtein){
    var lev;
    var res;
    if(levenshtein !== undefined && levenshtein.length > 0){
      lev = this.renderLevenshtein(levenshtein)
    }

    if(result !== undefined && result.length > 0){
      res = result.map(function(item){
        return(
          <tr key={item.id} onClick={(event) => this.redirectToTeacherDetails(event, item)} className="cursor">
            <td><img className="img-circle img-md smallAvatar" src={item.image} /></td>
            <td>{item.name} {item.surname}<br/><strong>{i18n.t(item.role)}</strong></td>
          </tr>
        );
      }.bind(this))
    }

    return(
      <div>
        {lev}

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">

            <table className="table table-border table-hover">
              <tbody>

                {res}
              </tbody>
            </table>

          </div>
        </div>

      </div>
    );
  }

  renderSearchResultsModule(module, results, levenshtein){
    var view;
    {
      switch (module) {
        case 'link_children':
          view = this.renderKidsResults(results, levenshtein)
          break;

        case 'cadre':
          view = this.renderCadreResults(results, levenshtein)
          break;
      }
    }
    return(
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title">{i18n.t('search_result_for_module')}: <strong>{i18n.t(module)}</strong></h3>
        </div>

        <div className="box-body">
          {view}
        </div>
      </div>
    );
  }

  renderMainView(){
    return(

      <div>
      <div className="box">
        <div className="box-body">
          <form onSubmit={(event) => this.submitSearch(event)}>

            <div className={'query' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('set_query')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>

                <div className="input-group">
                  <input type="text" name="query" value={this.state.query} onChange={(event) => this.changeState(event, 'query')} className="form-control" required />
                  <span className="input-group-btn">
                    <button className="btn btn-success" type="submit">{i18n.t('search')}</button>
                  </span>
                </div>

                <span className="help-block">{'query' in this.state.validationErrors ? i18n.t(this.state.validationErrors['query']) : null}</span>
            </div>

          </form>

        </div>
      </div>

      {
        this.state.modules.map(function(item){

          if((this.state.results[item.name] !== undefined || this.state.levenshtein[item.name] !== undefined) && (this.state.results[item.name].length > 0 || this.state.levenshtein[item.name].length > 0)){
            return(<div key={item.name}>{this.renderSearchResultsModule(item.name, this.state.results[item.name], this.state.levenshtein[item.name])}</div>);
          }
          else{
            return (<div></div>)
          }

        }.bind(this))
      }
    </div>


    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderMainView();
    }
    else{
      view = <LoaderComponent />;
    }


    return(
      <div>

        <section className="content-header">
          <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-olive"><i className="fas fa-search"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-number">
                      <h3>{i18n.t('search_module')}<br/><small>{i18n.t('search_module_subtile')}</small></h3>
                    </span>
                  </div>
                </div>
              </div>

          </div>
        </section>

        <section className="content container-fluid">
        {view}
        </section>

      </div>
    );
  }

}
