import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';

export default
class CreateComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      isLoading: false,
      status: '',
      msg: null,
      view: 'form',
      title: '',
      description: ''
    }

    this.changeState = this.changeState.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  changeState(event, type){
    if(type === 'title'){
      this.setState({title: event.target.value});
    }
    if(type === 'description'){
      this.setState({description: event.target.value});
    }
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'title'  : this.state.title,
      'description' : this.state.description,
    }

    let request = requests.postGalleryStore(array);
    request.then(function(response){
      this.setState(
        {
          status: 'success',
          msg: i18n.t(response.data.msg),
        }, () => {this.props.changeView('edit', response.data.id)}
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderFormView(){
    return(
      <form onSubmit={(event) => this.submitForm(event)}>

        <div className="form-group">
          <label>{i18n.t('set_gallery_title')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <input type="text" name="title" value={this.state.title} onChange={(event) => this.changeState(event, 'title')} className="form-control" required/>
        </div>

        <div className="form-group">
          <label>{i18n.t('set_gallery_description')}</label>
          <textarea name="description" value={this.state.description} onChange={(event) => this.changeState(event, 'description')} className="form-control" rows="10"/>
        </div>

        <hr/>
        <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>

      </form>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'form'){
        view = this.renderFormView()
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
