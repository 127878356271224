import React, {Component} from 'react';
import i18n from "i18next";

import PaymentComponent from '../PaymentComponent';


export default class InitialComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'payment',
    }

    this.changeView = this.changeView.bind(this)
  }

  changeView(view){
    this.setState({view: view});
  }

  getSubComponentView(){
    var view = null;
    switch (this.state.view) {
      case 'payment':
        view = <PaymentComponent setAccessDenied={this.props.setAccessDenied} changeView={this.changeView}/>
        break;

    }
    return (<div>{view}</div>);
  }

  render(){
    var view = this.getSubComponentView();
    return(
      <div className="row">
        <div className="col-md-3 col-sm-4 col-xs-6">
          <div className="box box-solid">
            <div className="box-body no-padding">
              <ul className="nav nav-pills nav-stacked">
                <li className={this.state.view === 'payment' ? "active" : null}><a onClick={(event) => this.setState({view: 'payment'})} href="#">{i18n.t('kid_tuition')}</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-8 col-xs-12">{view}</div>
      </div>


    );
  }

}
