import React, {Component} from 'react';
import i18n from "i18next";
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';

class PersonalDataComponent extends Component{

  constructor(props){
    super(props);
    this.state = {
      name: '',
      surname: '',
      isLoading: false,
      status: '',
      msg: '',
      validationErrors: [],
    }

    this.onChangeValue = this.onChangeValue.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount(){
    this.getMyPersonal();
  }

  onChangeValue(event, type){
    if(type == 'name'){
      this.setState({name: event.target.value});
    }
    if(type == 'surname'){
      this.setState({surname: event.target.value});
    }

  }

  getMyPersonal(){
    this.setState({isLoading: true});
    let request = requests.getMyPersonal();
    request.then(function(response){
      this.setState({name: response.data.name, surname: response.data.surname});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true, status: '', msg: '', validationErrors: []});

    var array = {
      'name': this.state.name,
      'surname': this.state.surname
    };

    let request = requests.postMyPersonal(array);
    request.then(function(response){
      this.setState(
        {
          name: response.data.name,
          surname: response.data.surname,
          status: 'success',
          msg: i18n.t(response.data.msg),
        }
      )
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'validation_error'){
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));

  }

  renderForm(){
    return(
      <form onSubmit={(event) => this.submitForm(event)}>

        <div className={'name' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
          <label>{i18n.t('label_set_name')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <input type="text" name="name" className="form-control" required value={this.state.name} onChange={(event) => this.onChangeValue(event, 'name')}/>
          <span className="help-block">{'name' in this.state.validationErrors ? i18n.t(this.state.validationErrors['name']) : null}</span>
        </div>

        <div className={'surname' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
          <label>{i18n.t('label_set_surname')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <input type="text" name="surname" className="form-control" required value={this.state.surname} onChange={(event) => this.onChangeValue(event, 'surname')}/>
          <span className="help-block">{'surname' in this.state.validationErrors ? i18n.t(this.state.validationErrors['surname']) : null}</span>
        </div>

          <hr/>
          <input type="submit" className="btn btn-success" value={i18n.t('button_submit_update')} />
      </form>
    );
  }

  render(){
    var view = this.renderForm();
    if(this.state.isLoading === true){
      view = <LoaderComponent />;
    }
    return(
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12">

          {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
          {view}

        </div>
      </div>
    );
  }

}

export default PersonalDataComponent;
