import React, {Component} from 'react';

import i18n from "i18next";
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';

class AccessDataComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      list: [],
      merge: [],
      view: 'list',
      emailID: 0,
      email: '',
      email_1_code: '',
      email_2_code: '',
      password: '',
      isLoading: false,
      profile: [],
      status: '',
      msg: '',
      passwordInfo: '',
      passwordStatus: false,
      new_password: '',
      new_password_repeat: '',
      validationErrors: [],
      emailExists: null,
    }

    this.openEditView = this.openEditView.bind(this);
    this.submitPasswordUpdate = this.submitPasswordUpdate.bind(this);
    this.cancelPasswordUpdate = this.cancelPasswordUpdate.bind(this);
    this.changeState = this.changeState.bind(this);
    this.submitCreateForm = this.submitCreateForm.bind(this);
    this.submitMergeForm = this.submitMergeForm.bind(this);
    this.submitEmailUpdate = this.submitEmailUpdate.bind(this);
  }

  componentDidMount(){
    this.getMyEmails();
  }

  changeState(event, type, id = null){
    this.setState({passwordInfo: '', passwordStatus: false});
    if(type === 'email'){
      this.setState({email: event.target.value});
    }
    if(type === 'password'){
      this.passwordCheck(event.target.value);
      this.setState({password: event.target.value});
    }
    if(type === 'email_1_code'){
      this.setState({email_1_code: event.target.value});
    }
    if(type === 'email_2_code'){
      this.setState({email_2_code: event.target.value});
    }
    if(type === 'new_password'){
      //this.passwordCheck(event.target.value);
      this.setState({new_password: event.target.value});
    }
    if(type === 'new_email'){
      //this.passwordCheck(event.target.value);
      this.setState({email: event.target.value});
    }
    if(type === 'new_password_repeat'){
      //this.passwordCheck(event.target.value, this.state.new_password);
      this.setState({new_password_repeat: event.target.value});
    }
    if(type === 'inspect'){
      this.setState({view: 'inspectProfile', validationErrors: []}, () => {this.getMergeEmail(id)});
    }
  }

  passwordCheck(str, str2){
    if (str.length < 8) {
        this.setState({passwordInfo: 'Za krótkie', passwordStatus: false})
    } else if (str.length > 50) {
        this.setState({passwordInfo: 'Za długie', passwordStatus: false})
    } else if (str.search(/\d/) == -1) {
        this.setState({passwordInfo: 'Brak numerów', passwordStatus: false})
    } else if (str.search(/[a-zA-Z]/) == -1) {
        this.setState({passwordInfo: 'Brak liter', passwordStatus: false})
    } else if (str.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:]/) != -1) {
        this.setState({passwordInfo: 'złe znaki', passwordStatus: false})
    }
    else{
      this.setState({passwordInfo: 'Jest ok', passwordStatus: true})
    }
  }

  submitMergeForm(event){
    event.preventDefault();
    this.setState({isLoading: true});

    var array = {
      'email_1_code' : this.state.email_1_code,
      'email_2_code' : this.state.email_2_code
    }
    var request = requests.postMyEmailMergeCode(array);
    request.then(function(response){
      this.setState({status: 'success', msg: i18n.t(response.data.msg), view: 'list'}, () => {this.getMyEmails()});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitCreateForm(event){
    event.preventDefault();
    this.setState({isLoading: true, validationErrors: []});

    var array = {
      'email' : this.state.email,
      'password' : this.state.password
    }
    var request = requests.postMyNewEmail(array);
    request.then(function(response){
      if(response.data.view === 'list'){
        this.setState({status: 'success', msg: i18n.t(response.data.msg), profile: response.data.profile, view: response.data.view}, () => {this.getMyEmails()});
      }
      else{
        this.setState({status: 'success', msg: i18n.t(response.data.msg), profile: response.data.profile, view: response.data.view});
      }
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'validation_error'){
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitEmailUpdate(event){
    event.preventDefault();
    this.setState({isLoading: true, validationErrors: []});

    var array = {
      'id' : this.state.emailID,
      'email' : this.state.email,
    }
    var request = requests.postMyEmailUpdate(array);
    request.then(function(response){
      this.setState({status: 'success', emailExists: true, msg: i18n.t(response.data.msg), view: 'list'}, () => {this.getMyEmails()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'validation_error'){
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getMergeEmail(id){
    this.setState({isLoading: true});
    var array = {
      'id': id
    }
    let request = requests.getMyMergeEmail(array);
    request.then(function(response){
      this.setState({profile: response.data.profile});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getMyEmails(){
    this.setState({isLoading: true});
    let request = requests.getMyEmails();
    request.then(function(response){
      this.setState({list: response.data.accept, merge: response.data.waiting});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  openEditView(event, id, email, emailExists){
    this.setState({emailID: id, email: email, emailExists: emailExists, view: 'edit', status: '', msg: '', validationErrors: []});
  }

  cancelPasswordUpdate(event){
    this.setState({emailID: 0, email: '', view: 'list', status: '', msg: '', validationErrors: []});
  }

  submitPasswordUpdate(event){
    event.preventDefault();
    this.setState({isLoading: true, validationErrors: []});

    var array = {
      'password': this.state.new_password,
      'password_repeat' : this.state.new_password_repeat,
      'emailID' : this.state.emailID,
    }

    let request = requests.postMyEmailAccess(array);
    request.then(function(response){
      this.setState(
        {
          status: 'success',
          msg: i18n.t(response.data.msg),
          new_password: '',
          new_password_repeat: '',
        }
      )
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'validation_error'){
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));

  }

  renderEmailEditForm(){
    return(
      <div class="alert alert-danger"><h4><i class="fas fa-info-circle"></i> Brak adresu e-mail!</h4>

        <p>Adres e-mail nie został jeszcze przez Ciebie dodany do tego konta, w tej sytuacji system NETkid nie może wysyłać do Ciebie żadnych powiadomień.</p><br/>

        <form onSubmit={this.submitEmailUpdate}>

          <div className={'email' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('label_set_email')}</label>
            <input type="email" placeholder={i18n.t('label_set_email')} className="form-control" value={this.state.email} onChange={(event) => this.changeState(event, 'new_email')} required />
            <span className="help-block">{'email' in this.state.validationErrors ? i18n.t(this.state.validationErrors['email']) : null}</span>
          </div>

          <hr/>

          <button className="btn btn-success" type="submit">{i18n.t('button_submit_update')}</button>

        </form>

      </div>
    );
  }

  renderEditView(){
    var emailEdit = null;
    if(this.state.emailExists == false){
      emailEdit = this.renderEmailEditForm();
    }
    return(
      <div>

        <div className="info-box">
          <span className="info-box-icon bg-red">
            <i className="fas fa-info-circle"></i>
          </span>
          <div className="info-box-content">
            <span className="info-box-number">
              <h3>{i18n.t('security_alert')}<br/><small>{i18n.t('security_alert_info_1')}</small></h3>
            </span>
          </div>
        </div>

        <br/>

        {emailEdit}

        <form onSubmit={this.submitPasswordUpdate}>

          <h3>{i18n.t('password_form_requirements')}:</h3>
          <ul>
            <li><p className="text-danger">{i18n.t('password_form_requirements_1')}</p></li>
            <li><p className="text-danger">{i18n.t('password_form_requirements_2')}</p></li>
          </ul><hr/>

          <h4 className="lead">{i18n.t('title_currently_you_are_edit')} <b>{this.state.email}</b></h4>

          <div className={'password' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('label_set_new_password')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <input type="password" className="form-control" value={this.state.new_password} onChange={(event) => this.changeState(event, 'new_password')} required />
            <span className="help-block">{'password' in this.state.validationErrors ? i18n.t(this.state.validationErrors['password']) : null}</span>
          </div>

          <div className={'password_repeat' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('label_set_new_password_repeat')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <input type="password" className="form-control" value={this.state.new_password_repeat} onChange={(event) => this.changeState(event, 'new_password_repeat')} required />
            <span className="help-block">{'password_repeat' in this.state.validationErrors ? i18n.t(this.state.validationErrors['password_repeat']) : null}</span>
          </div>

          {this.state.passwordInfo}

          <hr/>

          <button className="btn btn-success" type="submit">{i18n.t('button_submit_update')}</button>
          <button onClick={(event) => this.cancelPasswordUpdate(event)} type="button" className="btn btn-danger">{i18n.t('button_cancel')}</button>

        </form>

      </div>
    );
  }

  renderListTable(){
    return(
      <div>
        <button className="btn btn-default" onClick={(event) => this.setState({view: 'createNewEmail', email: '', password: ''})}>{i18n.t('add_new_email_address')}</button>
        <hr/>

        <h3>{i18n.t('list_of_verif_emails')}</h3>
        <table className="table">
          <thead>
            <tr>
              <th>{i18n.t('table_th_uid_address')}</th>
              <th>{i18n.t('table_th_email_address')}</th>
              <th>{i18n.t('table_th_main_address')}</th>
              <th>{i18n.t('table_th_cdate')}</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {
              this.state.list.map(function(item){
                return(
                  <tr key={item.id}>
                    <td>{item.uid}</td>
                    <td>{item.email}</td>
                    <td>{i18n.t(item.main)}</td>
                    <td>{item.cdate}</td>
                    <td><button onClick={(event) => this.openEditView(event, item.id, item.email, item.emailExists)} className="btn btn-xs btn-default"><i className="fas fa-edit"></i></button></td>
                  </tr>
                );
              }.bind(this))
            }
          </tbody>

        </table>

        <br/><br/>
          <h3>{i18n.t('list_of_verif_waiting_emails')}</h3>
          <table className="table">
            <thead>
              <tr>
                <th>{i18n.t('table_th_email_address')}</th>
                <th>{i18n.t('table_th_email_address')}</th>
                <th>{i18n.t('table_th_cdate')}</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {
                this.state.merge.map(function(item){
                  return(
                    <tr key={item.id}>
                      <td>{item.email_1}</td>
                      <td>{item.email_2}</td>
                      <td>{item.cdate}</td>
                      <td><button onClick={(event) => this.changeState(event, 'inspect', item.id)} className="btn btn-xs btn-default"><i className="fas fa-edit"></i></button></td>
                    </tr>
                  );
                }.bind(this))
              }
            </tbody>

          </table>
      </div>
    );
  }

  renderCreateNew(){
    return(
      <div>
        <div className="info-box">
          <span className="info-box-icon bg-red">
            <i className="fas fa-info-circle"></i>
          </span>
          <div className="info-box-content">
            <span className="info-box-number">
              <h3>{i18n.t('security_alert')}<br/><small>{i18n.t('security_alert_info_1')}</small></h3>
            </span>
          </div>
        </div>

        <br/>

        <form onSubmit={(event) => this.submitCreateForm(event)}>

          <h3>{i18n.t('password_form_requirements')}:</h3>
          <ul>
            <li><p className="text-danger">{i18n.t('password_form_requirements_1')}</p></li>
            <li><p className="text-danger">{i18n.t('password_form_requirements_2')}</p></li>
          </ul><hr/>

          <div className={'email' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('label_set_email')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <input type="email" className="form-control" value={this.state.email} onChange={(event) => this.changeState(event, 'email')} required />
            <span class="help-block">{'email' in this.state.validationErrors ? i18n.t(this.state.validationErrors['email']) : null}</span>
          </div>

          <div className={'password' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('login_form_password')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <input type="password" className="form-control" value={this.state.password} onChange={(event) => this.changeState(event, 'password')} required />
            <span class="help-block">{'password' in this.state.validationErrors ? i18n.t(this.state.validationErrors['password']) : null}</span>
          </div>

          <hr/>
          <button className="btn btn-success" type="submit">{i18n.t('button_save')}</button>
          <button className="btn btn-default" onClick={(event) => this.setState({view: 'list', email: '', password: ''}, () => {this.getMyEmails()})} type="button">{i18n.t('button_cancel')}</button>

        </form>
      </div>
    );
  }

  renderInspectProfileView(){
    return(
      <div className="media">
        <div className="media-left">
          <img className="media-object" src={this.state.profile.avatar} alt="..." />
        </div>
        <div className="media-body">
          <h4 className="media-heading">{this.state.profile.name}</h4>
          <p>{i18n.t('confirm_account_merge_info')}</p>

          <form onSubmit={(event) => this.submitMergeForm(event)}>

            <div className="form-group">
              <label>{i18n.t('merge_code_sent_to_address')}: {this.state.profile.email_1} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <input type="text" name="email_1_code" className="form-control" onChange={(event) => this.changeState(event, 'email_1_code')} value={this.state.email_1_code} required />
            </div>

            <div className="form-group">
              <label>{i18n.t('merge_code_sent_to_address')}: {this.state.profile.email_2} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <input type="text" name="email_2_code" className="form-control" onChange={(event) => this.changeState(event, 'email_2_code')} value={this.state.email_2_code} required />
            </div>
            <hr/>
            <button className="btn btn-success" type="submit">{i18n.t('button_save')}</button>

          </form>

        </div>
      </div>
    );
  }

  render(){
    var view;
    if(this.state.view == 'list'){
      view = this.renderListTable();
    }
    if(this.state.view == 'createNewEmail'){
      view = this.renderCreateNew();
    }
    if(this.state.view == 'inspectProfile'){
      if(this.state.profile.id !== undefined){
        view = this.renderInspectProfileView();
      }
      else{
        view = helperFunctions.renderNoContentInfo();
      }
    }
    if(this.state.view == 'edit'){
      view = this.renderEditView();
    }
    if(this.state.isLoading === true){
      view = <LoaderComponent />;
    }
    return(
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12">

          {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
          {view}

        </div>
      </div>
    );
  }

}

export default AccessDataComponent;
