import React, {Component} from 'react';
import * as requests from '../requests.js';
import LoaderComponent from '../../Utils/LoaderComponent';
import i18n from "i18next";

export default class AssingObjectRelation extends Component{

  constructor(){
    super();
    this.state = {
      isLoading: false,
      error: '',
      hash: '',
      view: '',
    }
  }

  componentDidMount(){
    const { hash } = this.props.match.params;
    this.checkHash(hash);
  }

  checkHash(hash){
    this.setState({isLoading: true});

    var array = {
      'hash' : hash
    }

    let request = requests.checkAssingObjectRelationHash(array);
    request.then(function(response){
      this.setState(
        {
          view: 'success',
        }
      )
    }.bind(this));

    request.catch(function(error){
      this.setState(
        {
          view: 'error',
        }
      )
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderError(){
    return(
      <p>
        {i18n.t('sent_token_is_valid_abort_action')}
        <br/><br/>
        {i18n.t('temporray_links_notification')}
        <br/><br/>
        <small><strong>{i18n.t('best_regards')}</strong><br/>{i18n.t('net_kid_team')}</small>
      </p>
    );
  }

  renderSuccess(){
    return(
      <p>
        {i18n.t('action_success')}
        <br/><br/>
        <small><strong>{i18n.t('best_regards')}</strong><br/>{i18n.t('net_kid_team')}</small>
      </p>
    );
  }

  render(){
    var view
    if(this.state.view === 'success'){
      view = this.renderSuccess();
    }
    else if (this.state.view === 'error') {
      view = this.renderError();
    }
    if(this.state.isLoading === true){
      view = <LoaderComponent />;
    }
    return(
      <div className="mp_blue">
        <div className="mp_headerWrapper">
          <div className="container">

            <div className="mp_otherAction">
              <div className="mp_login_form">

                {view}

              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }

}
