import React, {Component} from 'react';
import i18n from "i18next";

class TokenExpiredComponent extends Component{

  constructor(props){
    super(props)
  }

  render(){
    if(this.props.status === true){
      return(
        <div>
          {i18n.t('token_expires')}
        </div>
      );
    }
    else{
      return(<div></div>);
    }
  }

}
export default TokenExpiredComponent;
