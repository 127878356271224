import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';

export default
class ObjectAddressComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      isLoading: false,
      status: '',
      msg: null,
      view: 'main',
      object: [],
      validationFields: []
    }

    this.submitForm =  this.submitForm.bind(this);
  }

  componentDidMount(){
    this.setState({object: {objectID: localStorage.getItem('objectID')}}, () => {this.getObjectInfo()})
  }

  submitForm(event){
    event.preventDefault();
    this.setState(
      {
        isLoading: true,
        object: {
          objectID: localStorage.getItem('objectID'),
          street: event.target.street.value,
          postal: event.target.postal.value,
          city: event.target.city.value,
          phone: event.target.phone.value,
          fax: event.target.fax.value,
          nip: event.target.nip.value,
          regon: event.target.regon.value,
          krs: event.target.krs.value,
          contact_email: event.target.contact_email.value,
          website: event.target.website.value,
          fb_profile: event.target.fb_profile.value,
          bank_acc: event.target.bank_acc.value,
        }
      }
    );

    var array = {
      school_id: localStorage.getItem('objectID'),
      street: event.target.street.value,
      postal: event.target.postal.value,
      city: event.target.city.value,
      phone: event.target.phone.value,
      fax: event.target.fax.value,
      nip: event.target.nip.value,
      regon: event.target.regon.value,
      krs: event.target.krs.value,
      contact_email: event.target.contact_email.value,
      website: event.target.website.value,
      fb_profile: event.target.fb_profile.value,
      bank_acc: event.target.bank_acc.value,
    }

    let request = requests.postMainSettingsObjectAddress(array);
    request.then(function(response){
      this.setState({status: 'success', msg: i18n.t(response.data.msg), object: []}, () => {this.getObjectInfo()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else if (error.response.data.error === 'validation_error') {
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationFields: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));

  }

  getObjectInfo(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getMainSettingsObjectAddress(array);
    request.then(function(response){
      this.setState({object: response.data});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }



  renderMainView(){
    return(
      <form onSubmit={(event) => this.submitForm(event)}>
        {helperFunctions.getTextInput(
            i18n.t('label_set_address'),
            "street",
            this.state.object.street,
           this.state.validationFields.indexOf('street') === -1 ? false : true,
            i18n.t('error_data_input')
        )}
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {helperFunctions.getTextInput(
                i18n.t('label_set_postal_code'),
              "postal",
              this.state.object.postal,
              this.state.validationFields.indexOf('postal') === -1 ? false : true,
              i18n.t('error_data_input')
            )}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {helperFunctions.getTextInput(
                i18n.t('label_set_city'),
              "city",
              this.state.object.city,
              this.state.validationFields.indexOf('city') === -1 ? false : true,
              i18n.t('error_data_input')
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {helperFunctions.getTextInput(
                i18n.t('label_set_phone'),
              "phone",
              this.state.object.phone,
              this.state.validationFields.indexOf('phone') === -1 ? false : true,
              i18n.t('error_data_input')
            )}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {helperFunctions.getTextInput(
                i18n.t('label_set_fax'),
              "fax",
              this.state.object.fax,
              this.state.validationFields.indexOf('fax') === -1 ? false : true,
              i18n.t('error_data_input'),
              false
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {helperFunctions.getTextInput(
                i18n.t('label_set_nip'),
              "nip",
              this.state.object.nip,
              this.state.validationFields.indexOf('nip') === -1 ? false : true,
              i18n.t('error_data_input')
            )}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {helperFunctions.getTextInput(
                i18n.t('label_set_regon'),
              "regon",
              this.state.object.regon,
              this.state.validationFields.indexOf('regon') === -1 ? false : true,
              i18n.t('error_data_input'),
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {helperFunctions.getTextInput(
                i18n.t('label_set_krs'),
              "krs",
              this.state.object.krs,
              this.state.validationFields.indexOf('krs') === -1 ? false : true,
              i18n.t('error_data_input')
            )}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {helperFunctions.getTextInput(
                i18n.t('label_set_bank_acc'),
              "bank_acc",
              this.state.object.bank_acc,
              this.state.validationFields.indexOf('bank_acc') === -1 ? false : true,
              i18n.t('error_data_input')
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {helperFunctions.getTextInput(
                i18n.t('label_set_contact_email'),
              "contact_email",
              this.state.object.contact_email,
              this.state.validationFields.indexOf('contact_email') === -1 ? false : true,
              i18n.t('error_data_input')
            )}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"></div>
        </div>
        <hr/>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              {helperFunctions.getTextInput(
                i18n.t('label_set_website'),
                "website",
                this.state.object.website,
                this.state.validationFields.indexOf('website') === -1 ? false : true,
                i18n.t('error_data_input'),
                false
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              {helperFunctions.getTextInput(
                i18n.t('label_set_facebook'),
                "fb_profile",
                this.state.object.fb_profile,
                this.state.validationFields.indexOf('fb_profile') === -1 ? false : true,
                i18n.t('error_data_input'),
                false
              )}
            </div>
          </div>
        <hr/>
        <button className="btn btn-success">{i18n.t('button_save')}</button>

      </form>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'main'){
        view = this.renderMainView();
      }
    }
    else{
      view = <LoaderComponent />;
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
