import React, {Component} from 'react';

import LoaderComponent from '../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import * as requests from '../requests.js';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as moment from "moment";

export default
class SettlementComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      kids: [],
      currentMonth: '',
      payment: [],
      isLoading: false,
      status: '',
      msg: null,
      view: 'list',
      objectSelectLoading: false,
      reloadList: false,
    }

    this.objectSelectChangeHandler = this.objectSelectChangeHandler.bind(this);
    this.finishListReloadHandler = this.finishListReloadHandler.bind(this);

  }

  componentDidUpdate(nextProps){
    if(nextProps.reload !== this.props.reload){
      this.props.afterReloadHandler(false);
      this.getSettlementList();
      return true;
    }
    return false;
  }

  componentDidMount(){
    this.getSettlementList();
  }

  finishListReloadHandler(status){
    this.setState({reloadList: status});
  }

  objectSelectChangeHandler(event){
    this.setState({objectSelectLoading: true});
    localStorage.setItem('objectID', event.target.value);
    this.setState({objectSelectLoading: false, reloadList: true});
  }

  getSettlementList(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getMySettlements(array);
    request.then(function(response){
      this.setState({kids: response.data.kids, currentMonth: response.data.current, payment: response.data.info});
    }.bind(this));

    request.catch(function(error){
      this.setState({items: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderList(){
    return(
      <div>

        <div className="info-box">
          <span className="info-box-icon bg-red">
            <i className="fas fa-info-circle"></i>
          </span>
          <div className="info-box-content">
            <span className="info-box-number">
              <h3>
                {i18n.t('parents_info_alert')}
                <br/>
                <small>{i18n.t('settlement_alert_subtitle')}</small>
              </h3>
            </span>
          </div>
        </div>

        <div className="box-group" id="accordion">

          {
            this.state.kids.map(function(item){
              return(
                <div key={item.id} className="panel box box-success">
                  <div className="box-header with-border">
                    <h4 className="box-title">
                      <a data-toggle="collapse" data-parent="#accordion" href={"#collapse" + item.id}>
                      <div className="pull-left"><img className="img-circle img-md smallAvatar" src={item.avatar} /></div>
                      <div className="pull-left">
                      {item.name}
                      <br/><small>{i18n.t('group')}: <b>{item.group}</b><br/>{i18n.t('in_system_since')}: <b>{item.cdate}</b></small>
                      </div>
                      </a>
                    </h4>
                  </div>
                  <div id={"collapse" + item.id} className="panel-collapse collapse">
                    <div className="box-body">

                      <div className="row">
                        <div className="col-md-4 col-sm-6 col-xs-12">
                          <h4>{i18n.t('payment_info')}</h4><hr/>
                          <ul className="list-unstyled">
                            <li>{i18n.t('payment_period')}: <b>{this.state.currentMonth}</b></li>
                            <li>{i18n.t('payment_type')}: <b>{this.state.payment.payment_type === 'daily' ? i18n.t('tuition_daily') : i18n.t('tuition_monthly')}</b></li>
                            <li>{i18n.t('payment_value')}: <b>{this.state.payment.payment_value} PLN</b></li>
                            <li>{i18n.t('quota_to_pay')}: <b>{item.value} PLN</b></li>
                          </ul>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                          <h4>{i18n.t('kid_absence_details')}</h4><hr/>
                          <ul className="list-unstyled">
                            <li>{i18n.t('present_count')}: <b>{item.absence.present}</b></li>
                            <li>{i18n.t('absent_count')}: <b>{item.absence.absent}</b></li>
                          </ul>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              );
            }.bind(this))
          }

        </div>
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderList();
    }
    else{
      view = <LoaderComponent />;
    }
    return(

      <div>{view}</div>

    );
  }

}
