import React, {Component} from 'react';

import * as DashboardRequests from '../../../Requests/DashboardRequests.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';

export default
class SystemNotificationsComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      items: [],
      isLoading: false,
    }
  }

  componentDidMount(){
    this.loadNavigation();
  }

  componentDidUpdate(prevProps){
    if(prevProps.reloadList !== this.props.reloadList){
      this.props.finishListReloadHandler(false);
      return true;
    }
    return false;
  }

  loadNavigation(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getSystemNotifications(array);
    request.then(function(response){
      this.setState({items: response.data.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({items: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderList(){
    return(
      <div>
        <div className="box-group" id="accordion">

          {
            this.state.items.map(function(item){
              return(
                <div key={item.key} className="panel box box-default">
                  <div className="box-header with-border">
                    <h4 className="box-title accoridionBox cursor">
                      <a data-toggle="collapse" data-parent="#accordion" href={"#collapse" + item.key}>
                        <div class="info-box">
                          <span class={"info-box-icon " + item.css}><i class={item.icon}></i></span>

                          <div class="info-box-content">
                            <span class="info-box-number">
                              <h3>{item.title}<br/><small>{i18n.t('cdate')}: {item.cdate}</small></h3>
                            </span>
                          </div>

                        </div>
                      </a>
                    </h4>
                  </div>
                  <div id={"collapse" + item.key} className="panel-collapse collapse">
                    <div className="box-body">

                      {item.content}

                      <br/><br/><br/>
                      <p><small><strong>{i18n.t('best_regards')}</strong><br/>{i18n.t('net_kid_team')}</small></p>

                    </div>
                  </div>
                </div>
              );
            }.bind(this))
          }

        </div>
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderList();
    }
    else{
      view = <LoaderComponent />;
    }
    return(

      <div>{view}</div>

    );
  }

}
