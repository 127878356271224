import React, {Component} from 'react';

import ItemsComponent from '../ItemsComponent';
import CreateComponent from '../EditComponent';

import i18n from "i18next";

export default class InitialComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'items',
    }

    this.changeView = this.changeView.bind(this)
  }

  changeView(view){
    this.setState({view: view});
  }

  getSubComponentView(){
    var view = null;
    switch (this.state.view) {
      case 'items':
        view = <ItemsComponent setAccessDenied={this.props.setAccessDenied} changeView={this.changeView}/>
        break;
      case 'add':
        view = <CreateComponent setAccessDenied={this.props.setAccessDenied} changeView={this.changeView}/>
        break;
    }
    return (<div>{view}</div>);
  }

  render(){
    var view = this.getSubComponentView();
    return(
      <div className="row">
        <div className="col-md-3 col-sm-4 col-xs-6">
          <div className="box box-solid">
            <div className="box-body no-padding">
              <ul className="nav nav-pills nav-stacked">
                <li className={this.state.view === 'items' ? "active" : null}><a onClick={(event) => this.setState({view: 'items'})} href="#">{i18n.t('items_list')}</a></li>
                <li className={this.state.view === 'add' ? "active" : null}><a onClick={(event) => this.setState({view: 'add'})} href="#">{i18n.t('add_new_item')}</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-8 col-xs-12">{view}</div>
      </div>


    );
  }

}
