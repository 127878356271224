import API from '../../../../Utils/api';

export function getChatList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/chat', {params: array, headers: { Authorization: AuthStr }});
}

export function getChatDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/chat/details', {params: array, headers: { Authorization: AuthStr }});
}

export function postChatResponse(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('school/post/chat/response', array, {headers: { Authorization: AuthStr }});
}

export function getChatPeople(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('school/get/chat/people', {params: array, headers: { Authorization: AuthStr }});
}

export function postChatNewMsg(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('school/post/chat/create', array, {headers: { Authorization: AuthStr }});
}
