import React, {Component} from 'react';

import LoaderComponent from '../../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../../acl.js';
import * as requests from '../../../requests.js';
import * as helperFunctions from '../../../../../../Utils/HelperFunctions.js';
import Select from 'react-select';

export default
class GroupsComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      status: '',
      msg: null,
      isLoading: null,
      items: [],
      view: 'list',
      groups: [],
      selectedGroup: 0,
      selectedRole: 0,
      roles: [],
      groupID: 0,
    }

    this.getGroupsList = this.getGroupsList.bind(this);
    this.getRolesList = this.getRolesList.bind(this);
    this.handleSelectedGroupChange = this.handleSelectedGroupChange.bind(this);
    this.handleSelectedRoleChange = this.handleSelectedRoleChange.bind(this);
    this.submitAssignForm = this.submitAssignForm.bind(this);
    this.submitDeleteForm = this.submitDeleteForm.bind(this);
  }

  componentDidMount(){
    this.getTeacherGroups();
  }

  handleSelectedGroupChange(group){
    this.setState({selectedGroup: group});
  }

  handleSelectedRoleChange(group){
    this.setState({selectedRole: group});
  }

  getRolesList(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getTeachersRolesSelect(array);
    request.then(function(response){
      this.setState(
        {
          roles: response.data,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getGroupsList(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getGroupsList(array);
    request.then(function(response){
      this.setState(
        {
          groups: response.data,
        }, () => {this.getRolesList()}
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getTeacherGroups(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.teacherID,
    }

    let request = requests.getTeacherGroups(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitAssignForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.teacherID,
      'role' : this.state.selectedRole,
      'group' : this.state.selectedGroup
    }

    let request = requests.postAssignTeacherToGroup(array);
    request.then(function(response){
      this.setState({view: 'list', status: 'success', msg: response.data.msg}, () => {this.getTeacherGroups()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitDeleteForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.teacherID,
      'group_id' : this.state.groupID,
    }

    let request = requests.deleteTeacherGroupAssignment(array);
    request.then(function(response){
      this.setState({view: 'list', status: 'success', msg: response.data.msg}, () => {this.getTeacherGroups()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderListView(){
    return(
      <div>
        <button onClick={(event) => this.setState({view: 'assign'}, () => this.getGroupsList())} className="btn btn-default">{i18n.t('assign_group')}</button><hr/>
        <table className="table table-border table-hover">

          <thead>
            <tr>
              <th>{i18n.t('avatar')}</th>
              <th>{i18n.t('group_name')}</th>
              <th>{i18n.t('kids_count')}</th>
              <th>{i18n.t('role')}</th>
            </tr>
          </thead>

          <tbody>
            {
              this.state.items.map(function(item){
                return(
                  <tr key={item.id} className="cursor" onClick={(event) => this.setState({view: 'details', groupID: item.group_id})}>
                    <td><img className="img-circle img-md smallAvatar" src={item.avatar} /></td>
                    <td>{item.name}</td>
                    <td>{item.kids}</td>
                    <td>{i18n.t(item.role)}</td>
                  </tr>
                )
              }.bind(this))
            }
          </tbody>

        </table>
      </div>
    )
  }

  renderDetailsView(){
    return(
      <form onSubmit={(event) => this.submitDeleteForm(event)}>

      <p>{i18n.t('confirm_delete_action')}</p>

      <hr/>
      <button className="btn btn-danger">{i18n.t('button_delete')}</button>
      <button onClick={(event) => this.setState({view: 'list'}, () => {this.getTeacherGroups()})} className="btn btn-default">{i18n.t('button_cancel')}</button>

      </form>
    )
  }

  renderAssignView(){
    return(
      <form onSubmit={(event) => this.submitAssignForm(event)}>

      <div className="form-group">
        <label>{i18n.t('choose_group')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          {
            <Select
              value={this.state.selectedGroup}
              onChange={this.handleSelectedGroupChange}
              options={this.state.groups}
              isMulti={false}
              placeholder={i18n.t('choose_group')}
            />
          }
      </div>

      <div className="form-group">
        <label>{i18n.t('select_role')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          {
            <Select
              value={this.state.selectedRole}
              onChange={this.handleSelectedRoleChange}
              options={this.state.roles}
              isMulti={false}
              placeholder={i18n.t('select_role')}
            />
          }
      </div>

      <hr/>
      <button className="btn btn-success">{i18n.t('button_save')}</button>
      <button onClick={(event) => this.setState({view: 'list'}, () => {this.getTeacherGroups()})} className="btn btn-default">{i18n.t('button_cancel')}</button>

      </form>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView()
      }
      if(this.state.view === 'details'){
        view = this.renderDetailsView()
      }
      if(this.state.view === 'assign'){
        view = this.renderAssignView()
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
