import React, {Component} from 'react';

import LoaderComponent from '../../../../Utils/LoaderComponent';
import AttachmentsComponent from '../../../../Utils/AttachmentsComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import * as requests from '../requests.js';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as moment from "moment";

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

export default
class ContentComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      items: [],
      isLoading: false,
      status: '',
      msg: null,
      view: 'list',
      details: {attachments:[]},
      gallery: [],
      currentIndex: 0
    }

    this.getItemDetails = this.getItemDetails.bind(this);
    this.onSlideChanged = this.onSlideChanged.bind(this);
  }

  onSlideChanged = (e) => this.setState({ currentIndex: e.item })

  componentDidMount(){
    this.setState({view: this.props.view}, () => {this.getContentList(this.props.type)});
  }

  componentDidUpdate(nextProps){
    if(nextProps.type !== this.props.type){
      this.setState({view: this.props.view}, () => {this.getContentList(this.props.type)});
    }

    if(nextProps.reload !== this.props.reload){
      this.props.finishReload();
      this.setState({view: this.props.view}, () => {this.getContentList(this.props.type)});
    }

  }

  getContentList(props = null){
    this.setState({isLoading: true});

    var type = this.props.type;
    if(props !== null){
      type = props;
    }

    var array = {
      'objectID' : localStorage.getItem('objectID'),
      'type' : type,
    }

    let request = requests.getProfileContent(array);
    request.then(function(response){
      this.setState({items: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({items: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getItemDetails(event, id){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
      'contentID'  : id,
      'type' : this.props.type
    }

    let request = requests.getProfileContentDetails(array);
    request.then(function(response){
      this.setState({details: response.data, currentIndex: 0, view: 'details'});
    }.bind(this));

    request.catch(function(error){
      this.setState({items: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderSlider(gallery){
    if(gallery.length){
      return(
        <div className="jumbotron" key={this.state.details.gallery.id}>

          <div>
            <h3>{this.state.details.gallery.title}</h3>
            <p>{this.state.details.gallery.description}</p>
          </div>

          <div>
            <AliceCarousel
              items={this.state.details.gallery.items.map((i) => (<img className="img-thumbnail" src={i.file} />))}
              startIndex={this.state.currentIndex}
              onSlideChanged={this.onSlideChanged}
              dotsDisabled={false}
              buttonsDisabled={true}
              autoPlay={true}
              autoPlayInterval={5000}
            />
          </div>

        </div>
      )
    }
    return(<div></div>);
  }

  renderMainView(){
    var view = helperFunctions.renderNoContentInfo();
    if(this.state.items.length){
      view = this.state.items.map(function(item){
        return(
          <div>
            <div key={item.id} onClick={(event) => this.getItemDetails(event, item.id)} className="media cursor">
              <div className="media-left">
                <span className="info-box-icon bg-green"><i className={item.icon}></i></span>
              </div>
              <div className="media-body">
                <h4 className="media-heading"><small>{item.cdate}</small><br/>{item.title}</h4>
                <p><small>{item.preface}...</small></p>
              </div>
            </div><hr/>
          </div>
        );
      }.bind(this));
    }
    return (
      <div className="list-group">
        {view}
      </div>

    );
  }

  renderDetailsView(){
    var attachments;
    if(this.state.details.attachments.length > 0){
      attachments = <AttachmentsComponent view={"front"} items={this.state.details.attachments} title={i18n.t('attachments_list')} />
    }
    var slider = this.renderSlider(this.state.details.gallery.items)
    return(
      <div>
        <button className="btn btn-xs btn-default" onClick={(event) => this.setState({view: 'list'}, () => {this.getContentList()})}>{i18n.t('go_back_to_list')}</button>
        <div className="page-header">
          <h3>{this.state.details.title} <br/><small>{i18n.t('cdate')}: <b>{this.state.details.cdate}</b></small></h3>
        </div>

        <div dangerouslySetInnerHTML={{__html: this.state.details.content}} /><br/>

        {slider}

        {attachments}

      </div>
    )
  }

  renderSingleView(){
    var view = helperFunctions.renderNoContentInfo();
    if(this.state.items.length){
      view = this.state.items.map(function(item){

        var attachments;
        var slider;
        if(item.attachments.length > 0){
          attachments = <AttachmentsComponent view={"front"} items={item.attachments} title={i18n.t('attachments_list')} />
        }

        if(item.gallery !== undefined){
          slider = this.renderSlider(item.gallery)
        }

        return(
          <div key={item.id}>
            <div className="page-header">
              <h3>{item.title}</h3>
            </div>
            <div dangerouslySetInnerHTML={{__html: item.content}} />
            {attachments}
            {slider}
          </div>

        );
      }.bind(this));
    }
    return (
      <div>{view}</div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderMainView();
      }
      if(this.state.view === 'single'){
        view = this.renderSingleView();
      }
      else if (this.state.view === 'details') {
        view = this.renderDetailsView();
      }
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
        {view}
      </div>
    );
  }

}
