import React, {Component} from 'react';

import NavigationComponent from '../FrontComponents/NavigationComponent';
import HomeHeaderComponent from '../FrontComponents/HomeFirstComponent';
import HomeSecondComponent from '../FrontComponents/HomeSecondComponent';
import HomeInlineSectionComponent from '../FrontComponents/HomeInlineSectionComponent';
import FooterComponent from '../FrontComponents/FooterComponent';
import FaqComponent from '../FrontComponents/FaqComponent';
import ContactComponent from '../FrontComponents/ContactComponent';
import YoutubeComponent from '../FrontComponents/YoutubeComponent';
import LoadingComponent from '../FrontComponents/LoadingComponent';
import LoginComponent from '../FrontComponents/LoginComponent';
import RegulationsAndPolicyComponent from '../FrontComponents/RegulationsAndPolicyComponent';
import RestorePasswordComponent from '../FrontComponents/RestorePasswordComponent';
import LoginRestoreManageComponent from '../FrontComponents/LoginRestoreManageComponent';
import PageComponent from '../FrontComponents/PageComponent';

class MainPageComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      currentContent: 'about',
      isLoading: true,
    }

    this.changeContent = this.changeContent.bind(this);
  }

  componentDidMount(){
    this.setState({isLoading: false});

    if(this.props.location.pathname === '/login'){
      this.setState({currentContent: 'login'});
    }

      if(this.props.location.pathname === '/nauczyciel'){
          this.setState({currentContent: 'for_teacher'});
      }

  }

  changeContent(type){
    this.setState({currentContent: type});
  }

  renderAboutStructure(){
    return(
      <div>
        <HomeHeaderComponent />
        <HomeInlineSectionComponent />
        <YoutubeComponent />
        <HomeSecondComponent changeContent={this.changeContent}/>
        <ContactComponent />
        <FooterComponent />
      </div>
    )
  }

  renderPageStructure(){
    return(
      <div>
        <PageComponent section={this.state.currentContent} />
        <ContactComponent />
        <FooterComponent />
      </div>
    )
  }

  renderRemindStructure(){
    return(
      <div>
        <RestorePasswordComponent history={this.props.history} />
        <LoginRestoreManageComponent section="login" changeContent={this.changeContent} />
        <RegulationsAndPolicyComponent />
        <ContactComponent />
        <FooterComponent />
      </div>
    )
  }

  renderLoginStructure(){
    return(
      <div>
        <LoginComponent history={this.props.history} />
        <LoginRestoreManageComponent section="remind" changeContent={this.changeContent} />
        <RegulationsAndPolicyComponent />
        <ContactComponent />
        <FooterComponent />
      </div>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === true){
      view = <LoadingComponent />
    }
    else{
      if(this.state.currentContent === 'about'){
        view = this.renderAboutStructure()
      }
      else if(this.state.currentContent === 'login'){
        view = this.renderLoginStructure()
      }
      else if(this.state.currentContent === 'remind_password'){
        view = this.renderRemindStructure()
      }
      else{
        view = this.renderPageStructure()
      }
    }
    return(
      <div className="mp_body">

          <div className="fiche">
              <div className="callUs">Chcesz dowiedzieć się więcej?<br/><span className="big">Dzwoń</span><br/><strong>508 331 304</strong></div>
          </div>

        <NavigationComponent changeContent={this.changeContent} link={this.state.currentContent}/>
        {view}
      </div>
    );
  }

}

export default MainPageComponent;
