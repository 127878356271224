import API from '../../../../Utils/api';

export function getMyPersonal(){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('me/get/personal', {headers: { Authorization: AuthStr }});
}

export function postMyPersonal(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('me/post/personal', array, {headers: { Authorization: AuthStr }});
}

export function getMyInfo(){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('me/get/info', {headers: { Authorization: AuthStr }});
}

export function postMyInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('me/post/info', array, {headers: { Authorization: AuthStr }});
}

export function getMyAvatar(){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('me/get/avatar', {headers: { Authorization: AuthStr }});
}

export function postMyAvatar(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('me/post/avatar', array, {headers: { Authorization: AuthStr, 'Content-Type': 'multipart/form-data' }});
}

export function getMyEmails(){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('me/get/emails', {headers: { Authorization: AuthStr }});
}

export function getMyMergeEmail(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('me/get/merge', {params: array, headers: { Authorization: AuthStr }});
}

export function postMyEmailAccess(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('me/post/email/access', array, {headers: { Authorization: AuthStr }});
}

export function postMyEmailUpdate(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('me/post/email/update', array, {headers: { Authorization: AuthStr }});
}

export function getMyMobile(){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('me/get/mobile', {headers: { Authorization: AuthStr }});
}

export function postMyMobileConfirmationStatus(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('me/post/mobile/confirmation', array, {headers: { Authorization: AuthStr }});
}

export function postMyMobilePushStatus(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('me/post/mobile/push', array, {headers: { Authorization: AuthStr }});
}

export function postMyNewEmail(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('me/post/email/new', array, {headers: { Authorization: AuthStr }});
}

export function postMyExistingEmail(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('me/post/email/existing', array, {headers: { Authorization: AuthStr }});
}

export function postMyEmailMergeCode(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('me/post/email/merge', array, {headers: { Authorization: AuthStr }});
}
