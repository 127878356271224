import React, {Component} from 'react';

import teacherImage from '../../../Img/teacher.png';
import regulations from '../../../Img/regulamin.png';
import privacy from '../../../Img/privacy.jpg';

export default class RegulationsAndPolicyComponent extends Component{

  render(){
    return(
      <div className="mp_faqWrapper">
        <div className="container">

          <div className="row">

          <div className="col-sm-6 col-md-6">
            <div className="thumbnail">
              <img src={regulations} />
              <div className="caption">
                <h3>Regulamin korzystania z serwisu</h3>
                <p>
                  Regulamin korzystania z serwisu
                </p>
                <a href="/regulamin-korzystania-z-uslugi" role="button" className="btn btn-block btn-warning" target="_blank">Sprawdź</a>

              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-6">
            <div className="thumbnail">
              <img src={privacy} />
              <div className="caption">
                <h3>Polityka prywatności</h3>
                <p>
                  Polityka prywatności
                </p>
                <a href="/polityka-prywatnosci" role="button" className="btn btn-block btn-warning" target="_blank">Sprawdź</a>

              </div>
            </div>
          </div>


          </div>

        </div>
      </div>
    );
  }

}
