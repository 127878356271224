import React, {Component} from 'react';
import loader from '../../../Img/loader.gif';

class LoaderComponent extends Component{

  render(){
    return(
      <div className="loaderImage">
        <img  src={loader} />
      </div>
    );
  }

}

export default LoaderComponent;
