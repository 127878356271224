import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import CkEditorComponent from '../../../../../Utils/CkEditorComponent';
import AttachmentsComponent from '../../../../../Utils/AttachmentsComponent';

export default class EditComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      isLoading: false,
      status: '',
      msg: null,
      items: [],
      view: 'form',
      id: 0,
      title: '',
      description: '',
      objectAttachments: [],
      filesStatus: false,
      attachments: [],
    }


    this.changeState = this.changeState.bind(this);
    this.onChangeContentHandler = this.onChangeContentHandler.bind(this);
    this.assignFilesHandler = this.assignFilesHandler.bind(this);
    this.submitForm = this.submitForm.bind(this);

  }

  componentDidMount(){
    if(this.props.id > 0){
      this.getItemFullDetails();
    }
  }

  changeState(event, type){
    if(type === 'title'){
      this.setState({title: event.target.value});
    }
  }

  onChangeContentHandler(event, editor, data){
    this.setState({description: data });
  }

  assignFilesHandler(event){
    event.preventDefault();
    const { objectAttachments } = this.state;
     let tempArr = [...objectAttachments];
     tempArr.push(event.target.files.files);
     this.setState({
       objectAttachments: tempArr,
       filesStatus: true
    });
  }

  getItemFullDetails(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'item_id'  : this.props.id,
    }

    let request = requests.getKnowledgeItemDetails(array);
    request.then(function(response){
      this.setState(
        {
          id: response.data.id,
          title: response.data.title,
          description: response.data.content,
          attachments: response.data.filesArray,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true});

    const data = new FormData();
    for (var i = 0; i < this.state.objectAttachments.length; i++) {
      data.append('file[]', this.state.objectAttachments[i][0]);
    }

    data.append('school_id', localStorage.getItem('objectID'));
    data.append('item_id', this.state.id);
    data.append('title', this.state.title);
    data.append('content', this.state.description);


    let request = requests.postKnowledgeItemUpdate(data);
    request.then(function(response){
      if(this.props.id > 0){
        this.props.changeView('list');
      }
      else{
        this.props.changeView('items');
      }
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  loadTempAttachments(){
    return(
      <div className="callout callout-info">
        <h4>{i18n.t('new_attachments_added_to_the_collection')}</h4>
        <p>{i18n.t('files_count')}: <b>{this.state.objectAttachments.length}</b></p>
      </div>
    );
  }

  renderFormView(){
    var tempAttachments = null;
    if(this.state.objectAttachments.length){
      tempAttachments = this.loadTempAttachments()
    }
    return(
      <div>

        <form onSubmit={(event) => this.submitForm(event)}>
          <br/>
          <div className="form-group">
            <label>{i18n.t('set_item_title')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <input type="text" name="title" className="form-control" onChange={(event) => this.changeState(event, 'title')} value={this.state.title} required />
          </div>

          {<CkEditorComponent label={i18n.t('set_item_content')} content={this.state.description} onChangeContentHandler={this.onChangeContentHandler}/>}

          {tempAttachments}

          <hr/>
          <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>

        </form>

        <hr/>
          {
            <AttachmentsComponent
              items={this.state.attachments}
              title={i18n.t('files')}
              view={"admin"}
              itemID={this.state.id}
              contentType={"attachment"}
              assignFilesHandler={this.assignFilesHandler}
              filesStatus={this.state.filesStatus}
              reloadFilesList={this.reloadFilesList}
            />
          }

      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'form'){
        view = this.renderFormView()
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
