import API from '../../../../Utils/api';

export function getKidsList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('kids/get/list', {params: array, headers: { Authorization: AuthStr }});
}

export function getKidSingle(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('kids/get/single', {params: array, headers: { Authorization: AuthStr }});
}

export function getKidsPersonalInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('kids/get/personal', {params: array, headers: { Authorization: AuthStr }});
}

export function postKidsPersonalInfoUpdate(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('kids/post/personal/update', array, {headers: { Authorization: AuthStr }});
}

export function postKidsPersonalInfoApplication(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('kids/post/personal/application', array, {headers: { Authorization: AuthStr }});
}

export function getKidsParentsInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('kids/get/parents', {params: array, headers: { Authorization: AuthStr }});
}

export function getKidsParentsList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('kids/get/parents/list', {params: array, headers: { Authorization: AuthStr }});
}

export function postKidsParentEditApplication(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('kids/post/parents/application/edit', array, {headers: { Authorization: AuthStr }});
}

export function postKidsParentDeleteApplication(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('kids/post/parents/application/delete', array, {headers: { Authorization: AuthStr }});
}

export function postCheckParentEmail(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('kids/post/parents/check-email', array, {headers: { Authorization: AuthStr }});
}

export function postCheckParentUID(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('kids/post/parents/check-uid', array, {headers: { Authorization: AuthStr }});
}

export function postInviteParentApplication(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('kids/post/parents/invite-application', array, {headers: { Authorization: AuthStr }});
}

export function getKidsAbsenceInfo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('kids/get/absence', {params: array, headers: { Authorization: AuthStr }});
}

export function getKidsAbsenceDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('kids/get/absence/details', {params: array, headers: { Authorization: AuthStr }});
}

export function postAbsenceUpdate(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('kids/post/absence/update', array, {headers: { Authorization: AuthStr }});
}

export function postAbsenceWithdraw(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('kids/post/absence/withdraw', array, {headers: { Authorization: AuthStr }});
}

export function postAbsenceApplication(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('kids/post/absence/application', array, {headers: { Authorization: AuthStr }});
}

export function postAbsenceCreate(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('kids/post/absence/store', array, {headers: { Authorization: AuthStr }});
}

export function getKidsAdditionalModuleSettings(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('kids/get/additional-settings/module', {params: array, headers: { Authorization: AuthStr }});
}

export function getSchoolAdditionalActivityList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('kids/get/school-activity', {params: array, headers: { Authorization: AuthStr }});
}

export function getKidActivityList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('kids/get/activity', {params: array, headers: { Authorization: AuthStr }});
}

export function postKidActivityAssignApplication(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('kids/post/activity/application', array, {headers: { Authorization: AuthStr }});
}

export function getKidFoodPreferencesTypesList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('kids/get/food-preferences/types', {params: array, headers: { Authorization: AuthStr }});
}

export function getKidFoodPreferencesList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('kids/get/food-preferences', {params: array, headers: { Authorization: AuthStr }});
}

export function postKidFoodPreferencesItem(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('kids/post/food-preferences/store', array, {headers: { Authorization: AuthStr }});
}

export function postKidFoodPreferencesItemUpdate(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('kids/post/food-preferences/update', array, {headers: { Authorization: AuthStr }});
}

export function postKidFoodPreferencesItemDelete(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('kids/post/food-preferences/delete', array, {headers: { Authorization: AuthStr }});
}
