import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';

import EditComponent from '../EditComponent';
import DeleteComponent from '../DeleteComponent';

export default class ItemsComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      isLoading: false,
      status: '',
      msg: null,
      items: [],
      view: 'list',
      subview: 'preview',
      itemID: 0,
      item: [],
    }


    this.changeState = this.changeState.bind(this);
    this.changeView = this.changeView.bind(this)
    this.getItemDetails = this.getItemDetails.bind(this);
  }

  componentDidMount(){
    this.getKnowledgeItems();
  }

  getItemDetails(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'item_id'  : this.state.itemID,
    }

    let request = requests.getKnowledgeItemDetails(array);
    request.then(function(response){
      this.setState(
        {
          item: response.data,
          subview: 'preview',
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getKnowledgeItems(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getKnowledgeItems(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  changeState(event, type){

  }

  changeView(view){
    if(view == 'list'){
      this.setState({view: 'list'}, () =>{this.getKnowledgeItems()})
    }
  }

  renderListView(){
    return(
      <div>
        {
          this.state.items.map(function(item){
            return(
              <div key={item.key}>
                <div className="media cursor" onClick={(event) => this.setState({view: 'details', itemID: item.id}, () => {this.getItemDetails()})}>
                  <div className="media-left">
                    <span className="info-box-icon bg-purple"><i className="fas fa-info-circle"></i></span>
                  </div>
                  <div className="media-body">
                    <h4 class="media-heading">{item.title}</h4>
                    <p>{i18n.t('files_count')}: <strong>{item.files}</strong></p>
                    <p>{i18n.t('cdate')}: <strong>{item.cdate}</strong></p>
                  </div>
                </div>
                <hr/>
              </div>
            );
          }.bind(this))
        }
      </div>
    );
  }

  renderPreview(){
    return(
      <div className="media cursor">
        <div className="media-body">
          <h4 class="media-heading">{this.state.item.title}</h4>
          <p>{i18n.t('files_count')}: <strong>{this.state.item.files}</strong></p>
          <p>{i18n.t('cdate')}: <strong>{this.state.item.cdate}</strong></p>

          <hr/>

          <div dangerouslySetInnerHTML={{__html: this.state.item.content}} />

        </div>
      </div>
    )
  }

  renderDetailsView(){
    var view = this.renderPreview();
    if(this.state.subview === 'edit'){
      view = <EditComponent id={this.state.itemID} changeView={this.changeView}/>
    }
    if(this.state.subview === 'delete'){
      view = <DeleteComponent id={this.state.itemID} changeView={this.changeView}/>
    }
    return(
      <div>
          <div className="btn-group" role="group" aria-label="...">
            <button type="button" className="btn btn-default" onClick={(event) => this.setState({view: 'list'}, () => {this.getKnowledgeItems()})}>{i18n.t('go_back_to_list')}</button>
            <button type="button" className="btn btn-warning" onClick={(event) => this.setState({subview: 'edit'})}>{i18n.t('button_edit')}</button>
            <button type="button" className="btn btn-danger" onClick={(event) => this.setState({subview: 'delete'})}>{i18n.t('button_delete')}</button>
          </div>

          {view}

      </div>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView()
      }
      if(this.state.view === 'details'){
        view = this.renderDetailsView()
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
