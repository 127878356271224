import API from '../../../../Utils/api';

export function getMyToDo(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('me/get/todo', {params: array, headers: { Authorization: AuthStr }});
}

export function getMyToDoDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('me/get/todo/details', {params: array, headers: { Authorization: AuthStr }});
}

export function postMyToDoUpdate(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('me/post/todo/update', array, {headers: { Authorization: AuthStr }});
}

export function postMyToDoCreate(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('me/post/todo/create', array, {headers: { Authorization: AuthStr }});
}
