import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';


export default class PaymentComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      isLoading: false,
      status: '',
      msg: null,
      type: 'daily',
      value: 0.0,
      check_absence: 0,
    }

    this.changeState = this.changeState.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount(){
    this.getSystemSettigns();
  }

  changeState(event, inputType){
    if(inputType === 'type'){
      this.setState({type: event.target.value});
    }
    if(inputType === 'value'){
      this.setState({value: event.target.value});
    }
    if(inputType === 'absence'){
      this.setState({check_absence: event.target.value});
    }
  }

  getSystemSettigns(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'type'  : 'payment',
    }

    let request = requests.getSystemSettings(array);
    request.then(function(response){
      this.setState(
        {
          type: response.data.type,
          value: response.data.value,
          check_absence: response.data.check_absence,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'type'  : 'payment',
      'payment_type'    : this.state.type,
      'payment_value'    : this.state.value,
      'payment_check_absence'    : this.state.check_absence,
    }

    let request = requests.postSystemSettings(array);
    request.then(function(response){
      this.setState({ status: 'success', msg: i18n.t(response.data.msg)});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderFormView(){
    return(
      <div>

        <form onSubmit={(event) => this.submitForm(event)}>
          <div  className="form-group">
            <label>{i18n.t('set_tuition_type')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <select name="type" value={this.state.type} onChange={(event) => this.changeState(event, 'type')} className="form-control">
              <option value="daily">{i18n.t('tuition_daily')}</option>
              <option value="monthly">{i18n.t('tuition_monthly')}</option>
            </select>
          </div>

          <div  className="form-group">
            <label>{i18n.t('tuition_with_count_absence')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <select name="absence" value={this.state.check_absence} onChange={(event) => this.changeState(event, 'absence')} className="form-control">
              <option value="0">{i18n.t('yes')}</option>
              <option value="1">{i18n.t('no')}</option>
            </select>
          </div>

          <div className="form-group">
            <label>{i18n.t('tuition_value')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <input type="number" min="0" step="0.1" name="value" className="form-control" value={this.state.value} onChange={(event) => this.changeState(event, 'value')} required/>
          </div>

          <hr/><button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
        </form>

      </div>
    );
  }



  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderFormView()
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
