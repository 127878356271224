import React, {Component} from 'react';
import i18n from "i18next";
import * as acl from '../../acl.js';
import LoaderComponent from '../../../../../Utils/LoaderComponent';
import CkEditorComponent from '../../../../../Utils/CkEditorComponent';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import * as requests from '../../requests.js';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

export default class SurveyComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'list',
      isLoading: false,
      status: '',
      msg: null,
      items: [],
      surveyID: 0,
      surveyDetails : [],
      selectedAnswers: [],
      canCreate: false,
      finished: 0,
      public: 0,
      questions: [],
      question: [],
      questionID: null,
      subView: 'edit',
      answers: [],
      myDraft: false,
    }

    this.getSurveyDetails = this.getSurveyDetails.bind(this);
    this.changeRadioButton = this.changeRadioButton.bind(this);
    this.submitQuestion = this.submitQuestion.bind(this);
    this.onChangeContentHandler = this.onChangeContentHandler.bind(this);
    this.onChangeTitleHandler = this.onChangeTitleHandler.bind(this);
    this.submitCreateForm = this.submitCreateForm.bind(this);
    this.submitUpdateForm = this.submitUpdateForm.bind(this);
    this.changFinishStatus = this.changFinishStatus.bind(this);
    this.changePublicStatus = this.changePublicStatus.bind(this);
    this.getSurveyQuestions = this.getSurveyQuestions.bind(this);
    this.setQuestionContent = this.setQuestionContent.bind(this);
    this.submitQuestionForm = this.submitQuestionForm.bind(this);
    this.submitQuestionDeleteForm = this.submitQuestionDeleteForm.bind(this);
    this.getAnswers = this.getAnswers.bind(this);
    this.deleteSurveyForm = this.deleteSurveyForm.bind(this);

  }

  componentWillMount(){
    this.setState({canCreate: this.props.currentGroup.canCreate});
  }

  componentDidMount(){
    this.getGroupSurvey();
  }

  changeRadioButton(event, id){
    const {selectedAnswers} = this.state;
    selectedAnswers[id] = event.target.value;
    this.setState({selectedAnswers})
  }

  changFinishStatus(event){
    this.setState({finished: event.target.value});
  }

  changePublicStatus(event){
    this.setState({public: event.target.value});
  }

  onChangeContentHandler(event, editor, data){
    this.setState({content: data });
  }

  onChangeTitleHandler(event){
    this.setState({title: event.target.value});
  }

  setQuestionContent(event){
    this.setState({question: {content: event.target.value}});
  }

  getGroupSurvey(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : this.props.currentGroup.schoolID,
      'groupID' : this.props.currentGroup.id,
    }

    let request = requests.getGroupSurvey(array);
    request.then(function(response){
      this.setState({items: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getSurveyDetails(){
    this.setState({isLoading: true});

    var array = {
      'objectID' : this.props.currentGroup.schoolID,
      'groupID' : this.props.currentGroup.id,
      'surveyID' : this.state.surveyID,
    }

    let request = requests.getSurveyDetails(array);
    request.then(function(response){
      this.setState(
        {
          surveyDetails: response.data,
          status: '',
          msg: null,
          selectedAnswers: response.data.questionsVotes,
          canCreate: response.data.canCreate,
          view: 'details',
          title: response.data.title,
          content: response.data.content,
          finished: response.data.finished,
          public: response.data.public,
          myDraft: response.data.myDraft,
        }
      );
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false, reloadList: false});
    }.bind(this));
  }

  getSurveyQuestions(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : this.props.currentGroup.schoolID,
      'id' : this.state.surveyID,
    }

    let request = requests.getSchoolSurveyQuestions(array);
    request.then(function(response){
      this.setState(
        {
          questions: response.data,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitQuestion(event){
    event.preventDefault();
    this.setState({isLoading: true});
    let array = {
      'objectID' : this.props.currentGroup.schoolID,
      'groupID' : this.props.currentGroup.id,
      'id' : this.state.surveyID,
      'answer': this.state.selectedAnswers,
    }


    var request = requests.postSurveyVote(array);
    request.then(function(response){
      this.setState({view: 'list', status: 'success', msg: i18n.t(response.data.msg)}, () => { return this.getGroupSurvey() });
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitCreateForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : this.props.currentGroup.schoolID,
      'title' : this.state.title,
      'content' : this.state.content,
      'for_all_groups' : 0,
      'groups': [{value: this.props.currentGroup.id}],
      'survey_finished' : this.state.finished,
      'public' : 0,
      'id': null
    }

    let request = requests.postSurveyStore(array);
    request.then(function(response){
      this.setState({view: 'editQuestions', status: 'success', title: '', content: '', survey_finished: 0, msg: i18n.t(response.data.msg), surveyID: response.data.id}, () => {this.getSurveyDetails()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitUpdateForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : this.props.currentGroup.schoolID,
      'title' : this.state.title,
      'content' : this.state.content,
      'for_all_groups' : 0,
      'groups': [{value: this.props.currentGroup.id}],
      'survey_finished' : this.state.finished,
      'public' : this.state.public,
      'id': this.state.surveyID,
    }

    let request = requests.postSurveyStore(array);
    request.then(function(response){
      this.setState({status: 'success', msg: i18n.t(response.data.msg)}, () => {this.getSurveyDetails()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitQuestionForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : this.props.currentGroup.schoolID,
      'content' : this.state.question.content,
      'parent_id'  : this.state.surveyID,
      'id'    : this.state.questionID,
    }

    let request = requests.postGroupSurveyQuestionUpdate(array);
    request.then(function(response){
      this.setState({view: 'editQuestions', status: 'success', msg: i18n.t(response.data.msg)}, () => {this.getSurveyQuestions()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitQuestionDeleteForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : this.props.currentGroup.schoolID,
      'parent_id'  : this.state.surveyID,
      'id'    : this.state.questionID,
    }

    let request = requests.deleteSchoolSurveyQuestion(array);
    request.then(function(response){
      this.setState({view: 'editQuestions', status: 'success', msg: i18n.t(response.data.msg)}, () => {this.getSurveyQuestions()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getAnswers(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : this.props.currentGroup.schoolID,
      'id' : this.state.surveyID,
    }

    let request = requests.getSchoolSurveyAnswers(array);
    request.then(function(response){
      this.setState({answers: response.data}
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  deleteSurveyForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : this.props.currentGroup.schoolID,
      'id' : this.state.surveyID,
    }

    let request = requests.deleteSchoolSurvey(array);
    request.then(function(response){
      this.setState({view: 'list', items: [], status: 'success', msg: response.data.msg}, () => {this.getGroupSurvey()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderEditButton(elem){
    var button;
    if(elem.myDraft === true || elem.public === 1){
      button = <button onClick={(event) => this.setState({surveyID: elem.id}, () => {this.getSurveyDetails(event)})} className="btn btn-default btn-xs">{i18n.t('survey_details')}</button>
    }

    return(
      <div>
        <br/>
        {button}
      </div>
    );
  }

  renderListView(){
    return(
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <ul className="list-unstyled">
            {
              this.state.items.map(function(elem){

                return(
                  <li key={elem.id}>
                    <div className="media">
                      <div className="media-left">
                        <span className={"info-box-icon " + elem.icon.css}><i className={elem.icon.fontawesome}></i></span>
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">{elem.title}
                          <br/><small>{i18n.t('object')}: <b>{elem.school}</b></small>
                          <br/><small>{i18n.t('for_group')}: <b>{elem.for_all_groups ? i18n.t('all_groups') : elem.group}</b>, {i18n.t('questions_count')}: <b>{elem.questions}</b></small>
                          <br/><small>{i18n.t('cdate')}: <b>{elem.cdate}</b></small>
                        </h4>

                        {this.renderEditButton(elem)}

                      </div>
                    </div>
                    <hr/>
                  </li>
                )
              }.bind(this))
            }
          </ul>

        </div>
      </div>
    );
  }

  renderQuestionsForm(questions){
    return(
      <form onSubmit={(event) => this.submitQuestion(event)}>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>{i18n.t('question_content')}</th>
              <th>{i18n.t('yes')}</th>
              <th>{i18n.t('no')}</th>
            </tr>
          </thead>
          <tbody>
            {
              questions.map(function(item){
                return(
                  <tr key={item.id} onChange={(event) => this.changeRadioButton(event, item.id)}>
                    <td>{item.content}</td>
                    <td><input type="radio" name={"answer[" + item.id + "]"} value="1" required/></td>
                    <td><input type="radio" name={"answer[" + item.id + "]"} value="0"  /></td>
                  </tr>
                );
              }.bind(this))
            }
          </tbody>
        </table>
        <hr/>
        <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
      </form>
    )
  }

  renderQuestionsFormSuccessAnswered(){
    return(
        <div className="alert alert-success">{i18n.t('your_answer_was_stored')}</div>
    );
  }

  renderEditButtons(){
    var buttonEditContent;
    var buttonEditQuestions;
    var buttonGetAnswers;
    var buttonDeleteSurvey;

    if(this.state.public === 1 && this.state.myDraft === true){
      buttonEditContent = <button type="button" onClick={(event) => this.setState({view: 'editContent', msg: null, status: ''})} class="btn btn-default">{i18n.t('edit_content')}</button>
      buttonGetAnswers = <button type="button" onClick={(event) => this.setState({view: 'results', msg: null, status: ''}, () => {this.getAnswers()})} class="btn btn-info">{i18n.t('survey_results')}</button>
    }
    else if (this.state.public === 0 && this.state.myDraft === true) {
      buttonEditContent = <button type="button" onClick={(event) => this.setState({view: 'editContent', msg: null, status: ''})} class="btn btn-default">{i18n.t('edit_content')}</button>
      buttonEditQuestions = <button type="button" onClick={(event) => this.setState({view: 'editQuestions', msg: null, status: ''}, () => {this.getSurveyQuestions()})} class="btn btn-default">{i18n.t('edit_questions')}</button>
      buttonGetAnswers = <button type="button" onClick={(event) => this.setState({view: 'results', msg: null, status: ''}, () => {this.getAnswers()})} class="btn btn-info">{i18n.t('survey_results')}</button>
      buttonDeleteSurvey = <button type="button" onClick={(event) => this.setState({view: 'delete', msg: null, status: ''})} class="btn btn-danger">{i18n.t('delete_survey')}</button>
    }
    else if (this.state.public === 1 && this.state.myDraft === false) {
      buttonGetAnswers = <button type="button" onClick={(event) => this.setState({view: 'results', msg: null, status: ''}, () => {this.getAnswers()})} class="btn btn-info">{i18n.t('survey_results')}</button>
    }

    return(
      <div>
        <hr/>
        <div className="btn-group" role="group">
          {buttonEditContent}
          {buttonEditQuestions}
          {buttonGetAnswers}
          {buttonDeleteSurvey}
        </div>
      </div>
    );
  }

  renderDetailsView(){
    var questions;
    if(this.state.surveyDetails.can_vote === true && this.state.surveyDetails.user_answered === false){
      questions = this.renderQuestionsForm(this.state.surveyDetails.questionsList)
    }
    else if (this.state.surveyDetails.user_answered === true) {
      questions = this.renderQuestionsFormSuccessAnswered()
    }

    var buttons = null;
    if(this.state.canCreate === true){
      var buttons = this.renderEditButtons();
    }

    return(
      <div>

        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <ul className="list-unstyled">

              <li>
                <div className="media">
                  <div className="media-left">
                    <span className={"info-box-icon " + this.state.surveyDetails.icon.css}><i className={this.state.surveyDetails.icon.fontawesome}></i></span>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">{this.state.surveyDetails.title}
                      <br/><small>{i18n.t('object')}: <b>{this.state.surveyDetails.school}</b></small>
                      <br/><small>{i18n.t('for_group')}: <b>{this.state.surveyDetails.for_all_groups ? i18n.t('all_groups') : this.state.surveyDetails.group}</b>, {i18n.t('questions_count')}: <b>{this.state.surveyDetails.questions}</b></small>
                      <br/><small>{i18n.t('cdate')}: <b>{this.state.surveyDetails.cdate}</b></small>
                    </h4>
                    <br/>

                    <p><strong>{i18n.t('content')}</strong></p>
                    <div dangerouslySetInnerHTML={{__html: this.state.surveyDetails.content}} />

                    {questions}

                    {buttons}

                  </div>
                </div>
                <hr/>
              </li>

            </ul>

          </div>
        </div>
      </div>
    );
  }

  renderAddButton(){
    return(
      <div className="form-group">
        <button className="btn btn-groups" onClick={(event) => this.setState(
          {
            view: 'create',
            status: '',
            msg: null,
            title: '',
            content: '',
            finished: 0
          }
        )}>{i18n.t('add_new_survey_in_this_group')}</button>
      </div>
    );
  }

  renderCreateView(){
    return(
      <div>
        <form onSubmit={(event) => this.submitCreateForm(event)}>
          <div className="form-group">
            <label>{i18n.t('set_item_title')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <input type="text" name="title" className="form-control" value={this.state.title} onChange={(event) => this.onChangeTitleHandler(event)} required/>
          </div>

          {<CkEditorComponent label={i18n.t('set_item_content')} content={this.state.content} onChangeContentHandler={this.onChangeContentHandler}/>}

          <div  className="form-group">
            <label>{i18n.t('change_vote_status')}</label>
            <select name="finished" value={this.state.finished} onChange={(event) => this.changFinishStatus(event)} className="form-control">
              <option value="1">{i18n.t('vote_inactive')}</option>
              <option value="0">{i18n.t('vote_active')}</option>
            </select>
          </div>

          <hr/>
          <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
          <button type="button" onClick={(event) => this.setState({view: 'list', items: []}, () => {this.getGroupSurvey()})} className="btn btn-danger">{i18n.t('button_cancel')}</button>
        </form>
      </div>
    );
  }

  renderEditContentView(){
    return(
      <div>
        <form onSubmit={(event) => this.submitUpdateForm(event)}>
          <div className="form-group">
            <label>{i18n.t('set_item_title')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <input type="text" name="title" className="form-control" value={this.state.title} onChange={(event) => this.onChangeTitleHandler(event)} required/>
          </div>

          {<CkEditorComponent label={i18n.t('set_item_content')} content={this.state.content} onChangeContentHandler={this.onChangeContentHandler}/>}

          <div  className="form-group">
            <label>{i18n.t('change_vote_status')}</label>
            <select name="finished" value={this.state.finished} onChange={(event) => this.changFinishStatus(event)} className="form-control">
              <option value="1">{i18n.t('vote_inactive')}</option>
              <option value="0">{i18n.t('vote_active')}</option>
            </select>
          </div>

          <div  className="form-group">
            <label>{i18n.t('survey_is_public')}</label>
            <select name="finished" value={this.state.public} onChange={(event) => this.changePublicStatus(event)} className="form-control">
              <option value="1">{i18n.t('yes')}</option>
              <option value="0">{i18n.t('no')}</option>
            </select>
          </div>

          <hr/>
          <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
          <button type="button" onClick={(event) => this.setState({view: 'details'})} className="btn btn-danger">{i18n.t('button_cancel')}</button>
        </form>
      </div>
    );
  }

  renderEditQuestionsView(){
    if(this.state.questions.length){
      return(
        <div>
          <div className="btn-group" role="group" aria-label="...">
            <button onClick={(event) => this.setState({view: 'details', msg: null, status: ''})} className="btn btn-default">{i18n.t('go_back_to_survey_details')}</button>
            <button onClick={(event) => this.setState({view: 'addQuestion', msg: null, status: '', question: {content: ''}, questionID: null})} className="btn btn-groups">{i18n.t('add_new_item')}</button>

          </div>

        <hr/>
        <table className="table table-border table-hover">
          <thead>
            <tr>
              <th>{i18n.t('lp')}</th>
              <th>{i18n.t('question_content')}</th>
              <th>{i18n.t('options')}</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.questions.map(function(item){
                return(
                  <tr key={item.id}>
                    <td>{item.lp}</td>
                    <td>{item.content}</td>
                    <td>
                    <div className="btn-group" role="group">
                      <button onClick={(event) => this.setState({view: 'questionEdit', msg: null, status: '', question: item, questionID: item.id})} type="button" className="btn btn-xs btn-default">{i18n.t('button_edit')}</button>
                      <button onClick={(event) => this.setState({view: 'questionDelete', msg: null, status: '', question: item, questionID: item.id})} type="button" className="btn btn-xs btn-danger">{i18n.t('button_delete')}</button>
                    </div>
                    </td>
                  </tr>
                );
              }.bind(this))
            }
          </tbody>
        </table>

        </div>
      );
    }
    else{
      return(
        <div>
        <button onClick={(event) => this.setState({view: 'addQuestion', msg: null, status: '', question: {content: ''}, questionID: null})} className="btn btn-groups">{i18n.t('add_new_item')}</button><hr/>
        {helperFunctions.renderNoContentInfo()}
        </div>
      )
    }
  }

  renderAddQuestionView(){
    return(
      <form onSubmit={(event) => this.submitQuestionForm(event)}>
        <div className="form-group">
          <label>{i18n.t('set_question_content')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <input type="text" name="content" value={this.state.question.content} onChange={(event) => this.setQuestionContent(event)} required className="form-control" />
        </div>

        <hr/>
        <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
        <button type="button" onClick={(event) => this.setState({view: 'editQuestions', question: {content: ''}}, () => {this.getSurveyQuestions()})} className="btn btn-default">{i18n.t('button_cancel')}</button>
      </form>
    );
  }

  renderDeleteQuestionView(){
    return(
      <form onSubmit={(event) => this.submitQuestionDeleteForm(event)}>
        <p>{i18n.t('confirm_delete_action')}</p>

        <hr/>
        <button type="submit" className="btn btn-danger">{i18n.t('button_delete')}</button>
        <button type="button" onClick={(event) => this.setState({view: 'editQuestions'})} className="btn btn-default">{i18n.t('button_cancel')}</button>
      </form>
    );
  }

  renderResultsView(){
    return(
      <div style={{ width: '100%', height: 500 }}><br/><br/>
      <ResponsiveContainer>
        <BarChart
          data={this.state.answers}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="content" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey={i18n.t('yes')} fill="#00c0ef" />
          <Bar dataKey={i18n.t('no')} fill="#dd4b39" />
        </BarChart>
      </ResponsiveContainer>
      </div>
    )
  }

  renderDeleteView(){
    return(
      <form onSubmit={(event) => this.deleteSurveyForm(event)}>
      <p>{i18n.t('confirm_delete_action')}</p>

      <hr/>
      <button type="submit" className="btn btn-danger">{i18n.t('button_delete')}</button>

      </form>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView();
      }
      if(this.state.view === 'details'){
        view = this.renderDetailsView();
      }
      if(this.state.view === 'create'){
        view = this.renderCreateView();
      }
      if(this.state.view === 'editContent'){
        view = this.renderEditContentView();
      }
      if(this.state.view === 'editQuestions'){
        view = this.renderEditQuestionsView();
      }
      if(this.state.view === 'addQuestion'){
        view = this.renderAddQuestionView();
      }
      if(this.state.view === 'questionEdit'){
        view = this.renderAddQuestionView();
      }
      if(this.state.view === 'questionDelete'){
        view = this.renderDeleteQuestionView();
      }
      if(this.state.view === 'results'){
        view = this.renderResultsView();
      }
      if(this.state.view === 'delete'){
        view = this.renderDeleteView();
      }
    }
    else{
      view = <LoaderComponent />;
    }

    var add = null;
    if(this.state.canCreate === true && this.state.view === 'list'){
      add = this.renderAddButton()
    }

    return(
      <div>
        {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
        {add}
        {view}
      </div>
    );
  }

}
