import API from '../../../../Utils/api';

export function getSurveyList(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('survey/get/list', {params: array, headers: { Authorization: AuthStr }});
}

export function getSurveyDetails(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.get('survey/get/details', {params: array, headers: { Authorization: AuthStr }});
}

export function postSurveyVote(array){
  const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
  return API.post('survey/post/vote', array, {headers: { Authorization: AuthStr }});
}
