import React, {Component} from 'react';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default
class CkEditorComponent extends Component{

  constructor(props){
      super(props);
  }

  render(){
    return(
      <div className="form-group">
        <label>{this.props.label}</label>
          <CKEditor
            editor={ ClassicEditor }
            data={this.props.content}
            onChange={(event, editor) => this.props.onChangeContentHandler(event, editor, editor.getData())}
            config={{
                  removePlugins: [ 'Heading', 'ImageUpload', 'Table', 'Link', 'MediaEmbed' ]
                }} />
      </div>
    )
  }

}
