import React, {Component} from 'react';

import InfoComponent from '../InfoComponent';
import PhotosComponent from '../PhotosComponent';
import DeleteComponent from '../DeleteComponent';

import i18n from "i18next";

export default
class InitialComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      view: 'info'
    }


  }

  render(){
    var view;
    if(this.state.view === 'info'){
      view = <InfoComponent galleryID={this.props.galleryID}/>
    }
    if(this.state.view === 'photos'){
      view = <PhotosComponent galleryID={this.props.galleryID}/>
    }
    if(this.state.view === 'delete'){
      view = <DeleteComponent galleryID={this.props.galleryID} changeView={this.props.changeView}/>
    }
    return(
      <div className="nav-tabs-custom">
        <ul className="nav nav-tabs">
          <li className={this.state.view === 'info' ? "active" : null}><a onClick={(event) => this.setState({view: 'info'})} href="#tab" data-toggle="tab">{i18n.t('main_info')}</a></li>
          <li className={this.state.view === 'photos' ? "active" : null}><a onClick={(event) => this.setState({view: 'photos'})} href="#tab" data-toggle="tab">{i18n.t('photos')}</a></li>
          <li className={this.state.view === 'delete' ? "active" : null}><a onClick={(event) => this.setState({view: 'delete'})} href="#tab" data-toggle="tab">{i18n.t('delete_gallery')}</a></li>
        </ul>
        <div className="tab-content">
          <div className="tab-pane active" id="tab">
            {view}
          </div>

        </div>

      </div>
    )
  }

}
