import React, {Component} from 'react';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';
import * as dashboardRequests from '../../../Requests/DashboardRequests.js';
import * as acl from '../acl.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import i18n from "i18next";

export default
class GroupsListComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      list: [],
      objectGroups: [],
      currentGroupSelected: 0,
      isLoading: false,
      isGroupLoading: false,
      groupDetails: [],
      canExplore: false,
    }

    this.loadGroupDetails = this.loadGroupDetails.bind(this);
  }

  componentDidMount(){
    this.getObjectGroupsList(this.props.objectID);
  }

  componentDidUpdate(prevProps){
    if(prevProps.reload !== this.props.reload){
      this.props.afterReloadHandler(false);
      this.setState({currentGroupSelected: 0});
      this.getObjectGroupsList(this.props.objectID);
      return true;
    }
    return false;
  }

  loadGroupDetails(event, groupID, objectID){
    if(this.state.currentGroupSelected === groupID){
      return null;
    }
    this.setState({currentGroupSelected: groupID, isGroupLoading: true});

    var array = {
      'objectID' : objectID,
      'groupID' : groupID
    }

    let request = requests.getGroupDetails(array);
    request.then(function(response){
      this.setState({groupDetails: response.data.details, canExplore: response.data.canExplore});
    }.bind(this));

    request.finally(function(){
      this.setState({isGroupLoading: false});
    }.bind(this));
  }

  getObjectGroupsList(objectID){
    this.setState({isLoading: true});
    var array = {
      'objectID' : objectID
    }

    let request = dashboardRequests.getObjectGroupsList(array);
    request.then(function(response){
      this.setState({objectGroups: response.data});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderGroupDetails(){
    return(
      <div>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-xs-12">
            <h4>{i18n.t('kids_list')} ({this.state.groupDetails.kidsCount})</h4><hr/>
            <ul className="list-unstyled">
              {
                this.state.groupDetails.kids.map(function(elem){
                  return(
                    <li key={elem.id}>
                      <div className="media">
                        <div className="media-left">
                          <img className="media-object img-circle img-sm" src={elem.avatar} alt="" />
                        </div>
                        <div className="media-body">
                          <h4 className="media-heading">{elem.fullName}<br/><small>{i18n.t('in_group_since')}: <b>{elem.cdate}</b></small></h4>
                        </div>
                      </div>
                      <br/>
                    </li>
                  )
                }.bind(this))
              }
            </ul>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <h4>{i18n.t('teachers_list')}</h4><hr/>
            <ul className="list-unstyled">
              {
                this.state.groupDetails.teachers.map(function(elem){
                  return(
                    <li key={elem.id}>
                      <div className="media">
                        <div className="media-left">
                          <img className="media-object img-circle img-sm" src={elem.avatar} alt="" />
                        </div>
                        <div className="media-body">
                          <h4 className="media-heading">{elem.fullName}<br/><small>{i18n.t(elem.role)}</small></h4>
                        </div>
                      </div>
                      <br/>
                    </li>
                  )
                }.bind(this))
              }
            </ul>

          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">


          </div>
        </div>
        <hr/>
        <button onClick={(event) => this.props.changeMainView(event, 'full_group_profile', this.state.groupDetails)} className="btn btn-groups">{i18n.t('full_group_profile')}</button>
      </div>
    );
  }

  renderAccordion(){
    return(
      <div>
        <div className="box-group" id="accordion">

          {
            this.state.objectGroups.map(function(item){

              var view = null;
              if(this.state.currentGroupSelected === item.id && this.state.isGroupLoading === true){
                view = <LoaderComponent />
              }
              else if (this.state.currentGroupSelected === item.id && this.state.isGroupLoading === false) {
                if(this.state.canExplore === true){
                  view = this.renderGroupDetails();
                }
                else{
                  view = helperFunctions.showBlockadeBlock(i18n.t('access_denied'), i18n.t('access_denied_info'));
                }
              }

              return(
                <div key={item.id} className="panel box box-groups">
                  <div onClick={(event) => this.loadGroupDetails(event, item.id, item.schoolID)} className="box-header with-border">
                    <h4 className="box-title">
                      <a data-toggle="collapse" data-parent="#accordion" href={"#collapse" + item.id}>
                      <div className="pull-left"><img className="img-circle img-md smallAvatar" src={item.avatar} /></div>
                      <div className="pull-left">
                      {item.name}
                      <br/><small>Przedszkole: <b>{item.school}</b><br/>{i18n.t('age_group')}: <b>{item.age}{i18n.t('age_group_suffix')}</b>, {i18n.t('yearbook')}: <b>{item.year_start} - {item.year_end}</b></small>
                      </div>
                      </a>
                    </h4>
                  </div>
                  <div id={"collapse" + item.id} className="panel-collapse collapse">
                    <div className="box-body">
                      {view}

                    </div>
                  </div>
                </div>
              );
            }.bind(this))
          }

        </div>
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderAccordion();
    }
    else{
      view = <LoaderComponent />;
    }
    return(

      <div>{view}</div>

    );
  }

}
