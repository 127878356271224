import React, {Component} from 'react';

import LoaderComponent from '../../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../../acl.js';
import * as requests from '../../../requests.js';
import * as helperFunctions from '../../../../../../Utils/HelperFunctions.js';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';

export default
class PhotosComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      items: [],
      status: '',
      msg: null,
      photo: [],
      view: 'gallery',
      title: '',
      description: '',
      id: 0,
    }

    this.assignFilesHandler = this.assignFilesHandler.bind(this);
    this.getPhotoDetails = this.getPhotoDetails.bind(this);
    this.submitDeleteForm = this.submitDeleteForm.bind(this);
    this.changeState = this.changeState.bind(this);
    this.submitUpdatePhotoCaptions = this.submitUpdatePhotoCaptions.bind(this);
  }

  componentDidMount(){
    this.getGalleryPhotos();
  }

  submitUpdatePhotoCaptions(event){
    event.preventDefault();
    this.setState({isLoading: true});

    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.galleryID,
      'photo_id'  : this.state.photo.id,
      'title' : this.state.title,
      'description' : this.state.description,

    }

    let request = requests.updateGalleryPhotoCaptions(array);
    request.then(function(response){
      this.setState(
        {
          status: 'success',
          msg: i18n.t(response.data.msg),
        });
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  changeState(event, type){
    if(type === 'title'){
      this.setState({title: event.target.value});
    }
    if(type === 'description'){
      this.setState({description: event.target.value});
    }
  }

  getPhotoDetails(event, obj){
    this.setState({photo: obj.photo, title: obj.photo.title, description: obj.photo.description, id: obj.photo.id, view: 'details'})
  }

  assignFilesHandler(event){
    event.preventDefault();
    this.setState({isLoading: true});

    const data = new FormData();
    for (var i = 0; i < event.target.files.files.length; i++) {
      data.append('photos[]', event.target.files.files[i]);
    }
    data.append('school_id', localStorage.getItem('objectID'));
    data.append('id', this.props.galleryID);

    let request = requests.postGalleryPhotosStore(data);
    request.then(function(response){
      this.setState(
        {
          status: 'success',
          msg: i18n.t(response.data.msg),
        },
        () => {this.getGalleryPhotos()}
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));

  }

  submitDeleteForm(event){
    event.preventDefault();
    this.setState({isLoading: true});

    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.galleryID,
      'photo_id'  : this.state.photo.id,
    }

    let request = requests.deleteGalleryPhoto(array);
    request.then(function(response){
      this.setState(
        {
          status: 'success',
          msg: i18n.t(response.data.msg),
          view: 'gallery',
        }, () => {this.getGalleryPhotos()}
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getGalleryPhotos(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.galleryID,
    }

    let request = requests.getGalleryPhotos(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderListView(){
    return(
      <div>
        <form onSubmit={(event) => this.assignFilesHandler(event)}>
          <label>{i18n.t('choose_photos')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <div className="input-group">
            <input name="files" type="file" className="form-control" multiple required/>
            <span className="input-group-btn">
              <button className="btn btn-success" type="submit">{i18n.t('upload_files')}</button>
            </span>
          </div>
        </form><hr/>
      <Gallery photos={this.state.items} onClick={this.getPhotoDetails} />
      </div>
    );
  }

  renderDetailsView(){
    return(
      <div className="media">
        <div className="media-left">
          <img className="media-object" src={this.state.photo.src} alt="..." />
        </div>
        <div className="media-body">
          <form onSubmit={(event) => this.submitUpdatePhotoCaptions(event)}>

            <div className="form-group">
              <label>{i18n.t('set_photo_title')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <input type="text" className="form-control" onChange={(event) => {this.changeState(event, 'title')}} value={this.state.title} required />
            </div>

            <div className="form-group">
              <label>{i18n.t('set_photo_description')}</label>
              <textarea name="description" rows="10" className="form-control" onChange={(event) => {this.changeState(event, 'description')}} value={this.state.description} />
            </div>

            <button className="btn btn-success">{i18n.t('button_save')}</button>
            <button onClick={(event) => this.setState({view: 'gallery'}, () => {this.getGalleryPhotos()})} type="button" className="btn btn-default">{i18n.t('button_cancel')}</button>


          </form>
          <hr/>
          <h4 className="media-heading">{i18n.t('confirm_delete_action')}</h4>
          <form onSubmit={(event) => this.submitDeleteForm(event)}>
            <button type="submit" className="btn btn-danger">{i18n.t('button_delete')}</button>
            <button onClick={(event) => this.setState({view: 'gallery'}, () => {this.getGalleryPhotos()})} type="button" className="btn btn-default">{i18n.t('button_cancel')}</button>
          </form>
        </div>
      </div>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'gallery'){
        view = this.renderListView()
      }
      if(this.state.view === 'details'){
          view = this.renderDetailsView()
      }
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
