import React, {Component} from 'react';

import LoaderComponent from '../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import * as requests from '../requests.js';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

export default
class AdditionalActivityComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      items: [],
      gallery: {items:[]},
      isLoading: false,
      status: '',
      msg: null,
      currentIndex: 0
    }

    this.onSlideChanged = this.onSlideChanged.bind(this);
  }

  componentDidMount(){
    this.getAdditionalActivityList();
  }

  componentDidUpdate(prevProps){
    if(prevProps.reloadList !== this.props.reloadList){
      this.props.finishListReloadHandler(false);
      this.getAdditionalActivityList();
      return true;
    }
    return false;
  }

  getAdditionalActivityList(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
    }

    let request = requests.getProfileAdditionalActivity(array);
    request.then(function(response){
      this.setState({items: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({items: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  onSlideChanged = (e) => this.setState({ currentIndex: e.item })

  renderSlider(gallery){
    if(this.state.gallery.items.length){
      return(
        <div className="jumbotron" key={this.state.gallery.id}>

          <div>
            <h3>{this.state.gallery.title}</h3>
            <p>{this.state.gallery.description}</p>
          </div>

          <div>
            <AliceCarousel
              items={this.state.gallery.items.map((i) => (<img className="img-thumbnail" src={i.file} />))}
              startIndex={this.state.currentIndex}
              onSlideChanged={this.onSlideChanged}
              dotsDisabled={false}
              buttonsDisabled={true}
              autoPlay={true}
              autoPlayInterval={5000}
            />
          </div>

        </div>
      )
    }
    return(<div></div>);
  }

  renderMainView(){
    return (
      <div className="box-group" id="accordion">
        {
          this.state.items.map(function(elem){
            var slider = this.renderSlider(elem.gallery)
            return(
              <div key={elem.id} className="panel box box-success">
                <div className="box-header with-border">
                  <h4 className="box-title">
                    <a data-toggle="collapse" onClick={(event) => this.setState({gallery: elem.gallery, currentIndex: 0})} data-parent="#accordion" href={"#collapse" + elem.id}>
                      {elem.name}
                    </a>
                  </h4>
                </div>
                <div id={"collapse" + elem.id} className="panel-collapse collapse">
                  <div className="box-body">
                    <ul className="list-unstyled">
                      <li>
                        <ul className="list-unstyled">
                            {
                              elem.calendar.map(function(cal){
                                return(
                                  <li key={cal.id}>
                                    <div className="media">
                                      <div className="media-left">
                                        <img className="media-object img-md img-circle" src={cal.teacher.avatar} alt="..." />
                                      </div>
                                      <div className="media-body">
                                        <h4 className="media-heading">{cal.teacher.fullName}</h4>
                                        <p>{cal.day}. {i18n.t('in_hours')}: {cal.time_start} - {cal.time_end}</p>
                                      </div>
                                    </div>
                                    <hr/>
                                  </li>
                                )
                              })
                            }
                        </ul>
                      </li>
                    </ul>

                    <div dangerouslySetInnerHTML={{__html: elem.content}} /><br/><br/>
                    {slider}

                  </div>
                </div>
              </div>

            );

          }.bind(this))
        }
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.items !== undefined && this.state.items.length > 0){
        view = this.renderMainView();
      }
      else{
        view = helperFunctions.renderNoContentInfo();
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
        {view}
      </div>
    );
  }

}
