import React, {Component} from 'react';

import i18n from "i18next";
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';

export default
class AvatarComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      isLoading: false,
      status: '',
      msg: null,
      avatar: '',
      file: [],
      view: 'form',
      validationErrors: [],
    }

    this.changeState = this.changeState.bind(this);
    this.submitAvatarUpload = this.submitAvatarUpload.bind(this);
  }

  componentDidMount(){
    this.getMyAvatar()
  }

  changeState(event, type){
    if(type === 'file'){
      this.setState({file: event.target.files})
    }
  }

  submitAvatarUpload(event){
    event.preventDefault();
    this.setState({isLoading: true, status: '', msg: '', validationErrors: []});

    const data = new FormData();
    for (var i = 0; i < this.state.file.length; i++) {
      data.append('file', this.state.file[i]);
    }

    let request = requests.postMyAvatar(data);
    request.then(function(response){
      this.setState({status: 'success', msg: i18n.t(response.data.msg)}, () => {  this.getMyAvatar()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'validation_error'){
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getMyAvatar(){
    this.setState({isLoading: true});
    let request = requests.getMyAvatar();
    request.then(function(response){
      this.setState({avatar: response.data.image});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderFormView(){
    return(
      <div className="media">
        <div className="media-left">
          <img className="img-circle img-lg media-object" src={this.state.avatar} alt="..." />
        </div>
        <div className="media-body">
          <h4 className="media-heading">{i18n.t('set_avatar')}</h4>

            <form onSubmit={(event) => this.submitAvatarUpload(event)}>
              <div className={'file' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
                <label>{i18n.t('upload_file')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
                <input type="file" name="file" className="form-control" onChange={(event) => this.changeState(event, 'file')} required />
                <span class="help-block">{'file' in this.state.validationErrors ? i18n.t(this.state.validationErrors['file']) : null}</span>
              </div>
              <hr/><button className="btn btn-success" type="submit">{i18n.t('button_save')}</button>
            </form>

        </div>
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'form'){
        view = this.renderFormView()
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
