import React, {Component} from 'react';

import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import i18n from "i18next";

export default
class NotificationsWidget extends Component{

  constructor(props){
    super(props)

    this.state = {
      user: {notifications: { checkSum: 0, count: 0, items: []}}
    }

    this.getDetails = this.getDetails.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.notificationDOMRef = React.createRef();
  }



  getDetails(id){
  
  }

  componentDidUpdate(nextProps, nextState) {
    if(nextProps.user.notifications !== undefined && nextProps.user.notifications.items !== undefined){
      if (nextProps.user.notifications.checkSum !== this.state.user.notifications.checkSum) {
        if(nextProps.user.notifications.items != undefined && nextProps.user.notifications.items.length > 0){
          this.setState({user: nextProps.user});

          for (var i = 0; i < this.state.user.notifications.count; i++) {
            this.notificationDOMRef.current.removeNotification(this.state.user.notifications.items[i].id);
          }

          for (var i = 0; i < nextProps.user.notifications.count; i++) {
            this.addNotification(i18n.t(nextProps.user.notifications.items[i].title), i18n.t(nextProps.user.notifications.items[i].message), nextProps.user.notifications.items[i].class, nextProps.user.notifications.items[i].id);
          }

        }
      }
    }

  }

  addNotification(title, message, type, id) {
    this.notificationDOMRef.current.addNotification({
      id: id,
      title: title,
      message: message,
      type: type,
      insert: "bottom",
      container: "bottom-right",
      animationIn: ['show'],
      animationOut: ['removal'],
      dismiss: { duration: 0 },
      dismissable: { click: true }
    });
  }

  renderOverlay(){
    return(
      <div className="overlay">
        <i className="fas fa-sync-alt fa-spin"></i>
      </div>
    );
  }

  render(){
    var overlay = null;
    if(this.props.loadingUserData){
      overlay = this.renderOverlay();
    }
    return(
      <div>
        <ReactNotification ref={this.notificationDOMRef} />

      </div>


    );
  }

}
