import React, {Component} from 'react';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import i18n from "i18next";
import * as requests from '../../requests.js';
import LoaderComponent from '../../../../../Utils/LoaderComponent';

class ParentsInfoComponent extends Component{

  constructor(props){
    super(props);
    this.state = {
      view: 'list',
      isLoading: false,
      parents: [],
      parentGroups: [],
      parentsList: [],
      caregivers: [],
      can_recieve: [],
      currentParent: [],
      currentParentGroup: null,
      currentParentRecieve: null,
      status: '',
      msg: null,
      invite_parent_view: 'email',
      invite_parent_email: '',
      invite_parent_uid: '',
      invite_parent_name: '',
      invite_parent_surname: '',
      invite_parent_id: 0,
      invite_parent_avatar: '',
      invite_parent_without_account: false,
      validationErrors: [],
    }

    this.changeView = this.changeView.bind(this);
    this.setCurrentParentGroup = this.setCurrentParentGroup.bind(this);
    this.setCurrentParentRecieve = this.setCurrentParentRecieve.bind(this);
    this.submitApplication = this.submitApplication.bind(this);
    this.getKidParentsList = this.getKidParentsList.bind(this);
    this.getKidParentsInfo = this.getKidParentsInfo.bind(this);
    this.submitInviteParentEmail = this.submitInviteParentEmail.bind(this);
    this.submitInviteParentUID = this.submitInviteParentUID.bind(this);
    this.submitInviteParentApplication = this.submitInviteParentApplication.bind(this);
  }

  componentDidMount(){
    this.getKidParentsList();
  }

  changeView(event, view){
    if(view === 'list'){
      this.setState({view: 'list'}, () => {this.getKidParentsList()})
    }
    if(view === 'add_parent'){
      this.setState(
        {
          view: 'add_parent',
          invite_parent_email: '',
          invite_parent_view: 'email',
          invite_parent_name: '',
          invite_parent_surname: '',
          invite_parent_id: 0,
          invite_parent_avatar: '',
          currentParentGroup: 'parents',
          currentParentRecieve: 0,
          status: '',
          msg: null,
        }
      );
    }
    if(view === 'add_parent_without_account'){
      this.setState(
        {
          view: 'add_parent',
          invite_parent_view: 'create_without_email',
          invite_parent_name: '',
          invite_parent_surname: '',
          invite_parent_id: 0,
          invite_parent_avatar: 'https://api.net-kid.com/cache/image?path=app/app/filebase/avatars/logo_bw.jpg&width=100&height=100',
          invite_parent_uid: '',
          invite_parent_email: '',
          invite_parent_without_account: true,
          currentParentGroup: 'parents',
          currentParentRecieve: 0,
          status: '',
          msg: null,
        }
      );
    }
    if(view === 'application'){
      this.setState({view: 'application'}, () => {this.getKidParentsInfo()})
    }
    if(view === 'invite_parent_email'){
      this.setState({invite_parent_email: event.target.value})
    }
    if(view === 'invite_parent_uid'){
      this.setState({invite_parent_uid: event.target.value})
    }
    if(view === 'invite_parent_name'){
      this.setState({invite_parent_name: event.target.value})
    }
    if(view === 'invite_parent_surname'){
      this.setState({invite_parent_surname: event.target.value})
    }
    if(view === 'invite_parent_view_role'){
      this.setState({invite_parent_view: 'role'})
    }
  }


  setCurrentParentGroup(event){
    this.setState({currentParentGroup: event.target.value});
  }

  setCurrentParentRecieve(event){
    this.setState({currentParentRecieve: event.target.value});
  }

  getKidParentsInfo(){
    this.setState({isLoading: true});
    let array = {
      'kidID' : this.props.currentKid.kidID,
      'schoolID' : this.props.currentKid.schoolID,
    }

    let request = requests.getKidsParentsInfo(array);
    request.then(function(response){
      this.setState({parents: response.data.items, parentGroups: response.data.groups});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getKidParentsList(){
    this.setState({isLoading: true, status: '', msg: null});
    let array = {
      'kidID' : this.props.currentKid.kidID,
      'schoolID' : this.props.currentKid.schoolID,
    }

    let request = requests.getKidsParentsList(array);
    request.then(function(response){
      this.setState({parentsList: response.data.parents, caregivers: response.data.caregivers, can_recieve: response.data.can_recieve});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitApplication(event){
    event.preventDefault();
    this.setState({isLoading: true});
    let array = {
      'id' : this.state.currentParent.id,
      'kidID' : this.props.currentKid.kidID,
      'group' : this.state.currentParentGroup,
      'can_recieve' : this.state.currentParentRecieve,
      'schoolID' : this.props.currentKid.schoolID,
    }
    var request;
    if(this.state.view === 'edit'){
      request = requests.postKidsParentEditApplication(array);
    }
    else if (this.state.view === 'delete') {
      request = requests.postKidsParentDeleteApplication(array);
    }
    request.then(function(response){
      this.setState({view: 'application', status: 'success', msg: i18n.t(response.data.msg)}, () => {this.getKidParentsInfo()});
    }.bind(this));

    request.catch(function(error){
      this.setState({view: 'application', status: 'error', msg: i18n.t(error.response.data.error)}, () => {this.getKidParentsInfo()});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitInviteParentApplication(event){
    event.preventDefault();
    this.setState({isLoading: true, status: '', msg: null});
    let array = {
      'kidID' : this.props.currentKid.kidID,
      'schoolID' : this.props.currentKid.schoolID,
      'invite_parent_email' : this.state.invite_parent_email,
      'invite_parent_uid' : this.state.invite_parent_uid,
      'invite_parent_name': this.state.invite_parent_name,
      'invite_parent_surname': this.state.invite_parent_surname,
      'invite_parent_id': this.state.invite_parent_id,
      'invite_parent_role' : this.state.currentParentGroup,
      'invite_parent_can_recieve_kid' : this.state.currentParentRecieve,
      'invite_parent_without_account' : this.state.invite_parent_without_account,
    }
    var request = requests.postInviteParentApplication(array);
    request.then(function(response){
      this.setState({view: 'application', status: 'success', msg: i18n.t(response.data.msg)}, () => {this.getKidParentsInfo()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'validation_error'){
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitInviteParentEmail(event){
    event.preventDefault();
    this.setState({isLoading: true, status: '', msg: null});
    let array = {
      'kidID' : this.props.currentKid.kidID,
      'schoolID' : this.props.currentKid.schoolID,
      'invite_parent_email' : this.state.invite_parent_email,

    }
    var request = requests.postCheckParentEmail(array);

    request.then(function(response){
      if(response.data.accountExists === true){
        this.setState({
          invite_parent_view: 'exists',
          invite_parent_name: response.data.account.name,
          invite_parent_surname: response.data.account.surname,
          invite_parent_id: response.data.account.id,
          invite_parent_avatar: response.data.account.avatar,
          invite_parent_uid: response.data.email.acc_id,
          invite_parent_email: response.data.email.email,
        })
      }
      else{
        this.setState({
          invite_parent_view: 'create',
          invite_parent_name: '',
          invite_parent_surname: '',
          invite_parent_id: 0,
          invite_parent_avatar: 'https://api.net-kid.com/cache/image?path=app/app/filebase/avatars/logo_bw.jpg&width=100&height=100',
          invite_parent_uid: '',
          invite_parent_email: this.state.invite_parent_email,
        })
      }
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'validation_error'){
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitInviteParentUID(event){
    event.preventDefault();
    this.setState({isLoading: true, status: '', msg: null});
    let array = {
      'kidID' : this.props.currentKid.kidID,
      'schoolID' : this.props.currentKid.schoolID,
      'invite_parent_uid' : this.state.invite_parent_uid,

    }
    var request = requests.postCheckParentUID(array);

    request.then(function(response){
      if(response.data.accountExists === true){
        this.setState({
          invite_parent_view: 'exists',
          invite_parent_name: response.data.account.name,
          invite_parent_surname: response.data.account.surname,
          invite_parent_id: response.data.account.id,
          invite_parent_avatar: response.data.account.avatar,
          invite_parent_uid: response.data.email.acc_id,
          invite_parent_email: response.data.email.email,
        })
      }
      else{
        this.setState({
          invite_parent_view: 'create',
          invite_parent_name: '',
          invite_parent_surname: '',
          invite_parent_id: 0,
          invite_parent_avatar: 'https://api.net-kid.com/cache/image?path=app/app/filebase/avatars/logo_bw.jpg&width=100&height=100',
          invite_parent_uid: '',
          invite_parent_email: this.state.invite_parent_email,
        })
      }
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'validation_error'){
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationErrors: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderApplicationButton(){
    return(
      <div>
        <hr/><button onClick={(event) => this.setState({view: 'application'}, () => {this.getKidParentsInfo()})} className="btn btn-default">{i18n.t('button_edit')}</button>
      </div>
    );
  }

  renderApplicationView(){
    if(this.state.isLoading === true){
      return <LoaderComponent />;
    }
    else{
      return(
        <div className="row">
          <div className="col-md-6 col-sm-9 col-xs-12">

              <button className="btn btn-warning" onClick={(event) => this.changeView(event, 'add_parent')}>Złóż wniosek o dodanie konta rodzica / opiekuna</button>&nbsp;&nbsp;
              <button className="btn btn-default" onClick={(event) => this.changeView(event, 'add_parent_without_account')}>Dodaj rodzica / opiekuna bez zakładania mu konta</button>
              <br/><br/>

              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>{i18n.t('name_and_surname')}</th>
                    <th>{i18n.t('group')}</th>
                    <th>{i18n.t('can_recieve_single')}</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.parents.map(function(item){
                      return(
                        <tr key={item.id}>
                          <td>{item.application.status ? <i className="fas fa-hourglass-half"></i> : null}</td>
                          <td>{item.name} {item.surname}</td>
                          <td>
                            {i18n.t(item.group)}
                          </td>
                          <td>
                            {item.can_recive === 1 ? i18n.t('yes') : i18n.t('no')}
                          </td>
                          <td><button disabled={item.application.status ? true : false} onClick={(event) => this.setState({view: 'edit', status: '', msg: null, currentParent: item, currentParentGroup: item.group, currentParentRecieve: item.can_recive})} className="btn btn-xs btn-default">{i18n.t('button_edit')}</button></td>
                          <td><button disabled={item.application.status ? true : false} onClick={(event) => this.setState({view: 'delete', status: '', msg: null, currentParent: item})} className="btn btn-xs btn-danger">{i18n.t('button_delete')}</button></td>
                        </tr>
                      );
                    }.bind(this))
                  }
                </tbody>
              </table>

              <hr/><button type="button" onClick={(event) => this.setState({view: 'list'}, () => {this.getKidParentsList()})} className="btn btn-default">{i18n.t('button_cancel')}</button>

          </div>
        </div>
      );
    }
  }

  renderEditApplicationView(){
    return(
      <div>
        <div className="row">
          <div className="col-md-6 col-sm-9 col-xs-12">

            <form onSubmit={(event) => this.submitApplication(event)}>
              <div className="form-group">
                <label>{i18n.t('group')}</label>
                <select className="form-control" onChange={(event) => this.setCurrentParentGroup(event)} value={this.state.currentParentGroup}>
                  {
                    this.state.parentGroups.map(function(item){
                      return(<option key={item.id} value={item.trans}>{i18n.t(item.trans)}</option>);
                    }.bind(this))
                  }
                </select>
              </div>
              <div className="form-group">
                <label>{i18n.t('can_recieve_single')}</label>
                <select className="form-control" onChange={(event) => this.setCurrentParentRecieve(event)} value={this.state.currentParentRecieve}>
                  <option value="1">{i18n.t('yes')}</option>
                  <option value="0">{i18n.t('no')}</option>
                </select>
              </div>

              <hr/>
              <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
              <button type="button" onClick={(event) => this.setState({view: 'application'}, () => {this.getKidParentsInfo()})} className="btn btn-default">{i18n.t('button_cancel')}</button>

            </form>

          </div>
        </div>

      </div>
    );
  }

  renderDeleteApplicationView(){
    return(
      <div>
        <div className="row">
          <div className="col-md-6 col-sm-9 col-xs-12">

            <form onSubmit={(event) => this.submitApplication(event)}>

              <p>{i18n.t('confirm_parent_delete_application')}</p>

              <hr/>
              <button type="submit" className="btn btn-danger">{i18n.t('button_delete')}</button>
              <button type="button" onClick={(event) => this.setState({view: 'application'}, () => {this.getKidParentsInfo()})} className="btn btn-default">{i18n.t('button_cancel')}</button>

            </form>

          </div>
        </div>

      </div>
    );
  }

  renderDetailsView(){
    var applicationButton = null;
    if(this.props.currentKid.isMyKid === true){
      applicationButton = this.renderApplicationButton();
    }
    return(
      <div>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-xs-12">
            <h4>{i18n.t('parents')}</h4><hr/>
            <ul className="list-unstyled">
              {
                this.state.parentsList.map(function(elem){
                  return(
                    <li key={elem.id}>
                      <div className="media">
                        <div className="media-left">
                          <img className="media-object img-circle img-sm" src={elem.avatar} alt="" />
                        </div>
                        <div className="media-body">
                          <h4 className="media-heading">{elem.name} {elem.surname}</h4>
                          <ul className="list-unstyled">
                            <li>{i18n.t('mobile_app')}: <b>{elem.mobile ? i18n.t('yes') : i18n.t('no')}</b></li>
                          </ul>
                        </div>
                      </div>
                      <br/>
                    </li>
                  )
                }.bind(this))
              }
            </ul>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <h4>{i18n.t('caregivers')}</h4><hr/>
            <ul className="list-unstyled">
                {
                  this.state.caregivers.map(function(elem){
                    return(
                      <li key={elem.id}>
                        <div className="media">
                          <div className="media-left">
                            <img className="media-object img-circle img-sm" src={elem.avatar} alt="" />
                          </div>
                          <div className="media-body">
                            <h4 className="media-heading">{elem.name} {elem.surname}</h4>
                            <ul className="list-unstyled">
                              <li>{i18n.t('mobile_app')}: <b>{elem.mobile ? i18n.t('yes') : i18n.t('no')}</b></li>
                            </ul>
                          </div>
                        </div>
                        <br/>
                      </li>
                    )
                  }.bind(this))
                }
              </ul>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <h4>{i18n.t('can_recieve')}</h4><hr/>
            <ul className="list-unstyled">
                {
                  this.state.can_recieve.map(function(elem){
                    return(
                      <li key={elem.id}>
                        <div className="media">
                          <div className="media-left">
                            <img className="media-object img-circle img-sm" src={elem.avatar} alt="" />
                          </div>
                          <div className="media-body">
                            <h4 className="media-heading">{elem.name} {elem.surname}</h4>
                            <ul className="list-unstyled">
                              <li>{i18n.t('mobile_app')}: <b>{elem.mobile ? i18n.t('yes') : i18n.t('no')}</b></li>
                            </ul>
                          </div>
                        </div>
                        <br/>
                      </li>
                    )
                  }.bind(this))
                }
              </ul>
          </div>
        </div>

        {applicationButton}

      </div>
    )
  }

  renderInviteParentEmailForm(){
    return(
      <div>
        <form onSubmit={(event) => this.submitInviteParentEmail(event)}>

          <div className={'invite_parent_email' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
            <label>{i18n.t('set_email_to_invite_parent')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            <input type="text" name="email" className="form-control" onChange={(event) => this.changeView(event, 'invite_parent_email')} value={this.state.invite_parent_email} required />
            <span class="help-block">{'invite_parent_email' in this.state.validationErrors ? i18n.t(this.state.validationErrors['invite_parent_email']) : null}</span>
          </div>

          <hr/>

          <button type="submit" className="btn btn-warning">{i18n.t('check_email_address')}</button>
          <button type="button" onClick={(event) => this.changeView(event, 'application')} className="btn btn-default">{i18n.t('button_cancel')}</button>

        </form>

        <hr/>
        <p>Możesz też podać numer ID użytkownika którego chcesz zaprosić.</p>

          <form onSubmit={(event) => this.submitInviteParentUID(event)}>

            <div className={'invite_parent_uid' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
              <label>{i18n.t('set_uid_to_invite_parent')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <input type="text" name="text" className="form-control" onChange={(event) => this.changeView(event, 'invite_parent_uid')} value={this.state.invite_parent_uid} required />
              <span class="help-block">{'invite_parent_email' in this.state.validationErrors ? i18n.t(this.state.validationErrors['invite_parent_uid']) : null}</span>
            </div>

            <hr/>

            <button type="submit" className="btn btn-warning">{i18n.t('check_uid')}</button>
            <button type="button" onClick={(event) => this.changeView(event, 'application')} className="btn btn-default">{i18n.t('button_cancel')}</button>

          </form>

      </div>
    );
  }

  renderInviteParentCreateForm(){
    return(
      <div>

        <div className="info-box">
          <span className="info-box-icon bg-aqua">
            <i className="fas fa-info-circle"></i>
          </span>
          <div className="info-box-content">
            <span className="info-box-number">
              <h3>{i18n.t('system_info_alert')}<br/>
              <small>{i18n.t('system_info_alert_2')}</small>
            </h3>
            </span>
          </div>
        </div>

        <br/><br/>

        <div className={'invite_parent_name' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
          <label>{i18n.t('set_name_of_invite_parent')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <input type="text" name="name" className="form-control" onChange={(event) => this.changeView(event, 'invite_parent_name')} value={this.state.invite_parent_name} required />
          <span class="help-block">{'invite_parent_name' in this.state.validationErrors ? i18n.t(this.state.validationErrors['invite_parent_name']) : null}</span>
        </div>

        <div className={'invite_parent_surname' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
          <label>{i18n.t('set_surname_of_invite_parent')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <input type="text" name="surname" className="form-control" onChange={(event) => this.changeView(event, 'invite_parent_surname')} value={this.state.invite_parent_surname} required />
          <span class="help-block">{'invite_parent_surname' in this.state.validationErrors ? i18n.t(this.state.validationErrors['invite_parent_surname']) : null}</span>
        </div>

        <div className={'invite_parent_email' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
          <label>{i18n.t('set_email_of_invite_parent')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <input type="email" name="name" className="form-control" onChange={(event) => this.changeView(event, 'invite_parent_email')} value={this.state.invite_parent_email} required />
          <span class="help-block">{'invite_parent_email' in this.state.validationErrors ? i18n.t(this.state.validationErrors['invite_parent_email']) : null}</span>
        </div>

        <hr/>
        <button type="button" onClick={(event) => this.changeView(event, 'invite_parent_view_role')} className="btn btn-warning">{i18n.t('go_to_kid_relation_setup')}</button>
        <button type="button" onClick={(event) => this.changeView(event, 'application')} className="btn btn-default">{i18n.t('button_cancel')}</button>

      </div>
    )
  }

  renderInviteParentCreateWithoutEmailForm(){
    return(
      <div>

        <div className="info-box">
          <span className="info-box-icon bg-aqua">
            <i className="fas fa-info-circle"></i>
          </span>
          <div className="info-box-content">
            <span className="info-box-number">
              <h3>{i18n.t('system_info_alert')}<br/>
              <small>Utwórz użytkownika bez aktywnego konta w systemie NETkid</small>
            </h3>
            </span>
          </div>
        </div>

        <br/><br/>

        <div className={'invite_parent_name' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
          <label>Podaj imię <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <input type="text" name="name" className="form-control" onChange={(event) => this.changeView(event, 'invite_parent_name')} value={this.state.invite_parent_name} required />
          <span class="help-block">{'invite_parent_name' in this.state.validationErrors ? i18n.t(this.state.validationErrors['invite_parent_name']) : null}</span>
        </div>

        <div className={'invite_parent_surname' in this.state.validationErrors ? "form-group has-error" : "form-group"}>
          <label>Podaj nazwisko <span className="text-danger">({i18n.t('input_is_required')})</span></label>
          <input type="text" name="surname" className="form-control" onChange={(event) => this.changeView(event, 'invite_parent_surname')} value={this.state.invite_parent_surname} required />
          <span class="help-block">{'invite_parent_surname' in this.state.validationErrors ? i18n.t(this.state.validationErrors['invite_parent_surname']) : null}</span>
        </div>

        <hr/>
        <button type="button" onClick={(event) => this.changeView(event, 'invite_parent_view_role')} className="btn btn-warning">{i18n.t('go_to_kid_relation_setup')}</button>
        <button type="button" onClick={(event) => this.changeView(event, 'application')} className="btn btn-default">{i18n.t('button_cancel')}</button>

      </div>
    )
  }

  renderInviteParentExists(){
    return(
      <div>

        <div className="info-box">
          <span className="info-box-icon bg-aqua">
            <i className="fas fa-info-circle"></i>
          </span>
          <div className="info-box-content">
            <span className="info-box-number">
              <h3>{i18n.t('system_info_alert')}<br/>
              <small>
                {i18n.t('account_related_to_email_found')}
              </small>
            </h3>
            </span>
          </div>
        </div>

        <br/><br/>

        <div className="media">
          <div className="media-left">
            <img className="media-object img-circle img-md smallAvatar" src={this.state.invite_parent_avatar} alt="..." />
          </div>
          <div className="media-body">
            <h4 className="media-heading">{this.state.invite_parent_name} {this.state.invite_parent_surname}</h4>
              <p>{i18n.t('assign_account_to_parents_group_click_button')}</p>
              <hr/>
              <button type="button" onClick={(event) => this.changeView(event, 'invite_parent_view_role')} className="btn btn-warning">{i18n.t('go_to_next_step')}</button>
              <button type="button" onClick={(event) => this.changeView(event, 'application')} className="btn btn-default">{i18n.t('button_cancel')}</button>

          </div>
        </div>
      </div>
    )
  }

  renderInviteParentRoleForm(){
    return(
      <form onSubmit={(event) => this.submitInviteParentApplication(event)}>

        <div className="media">
          <div className="media-left">
            <img className="media-object img-circle img-md smallAvatar" src={this.state.invite_parent_avatar} alt="..." />
          </div>
          <div className="media-body">
            <h4 className="media-heading">{this.state.invite_parent_name} {this.state.invite_parent_surname}</h4>
            <p>{i18n.t('select_group_parent_relation_and_can_recieve')}</p>
          </div>
        </div>

        <hr/>

        <div className="form-group">
          <label>{i18n.t('group')}</label>
          <select className="form-control" onChange={(event) => this.setCurrentParentGroup(event)} value={this.state.currentParentGroup}>
            {
              this.state.parentGroups.map(function(item){
                return(<option key={item.id} value={item.trans}>{i18n.t(item.trans)}</option>);
              }.bind(this))
            }
          </select>
        </div>
        <div className="form-group">
          <label>{i18n.t('can_recieve_single')}</label>
          <select className="form-control" onChange={(event) => this.setCurrentParentRecieve(event)} value={this.state.currentParentRecieve}>
            <option value="1">{i18n.t('yes')}</option>
            <option value="0">{i18n.t('no')}</option>
          </select>
        </div>

        <hr/>
        <button type="submit" className="btn btn-warning">{i18n.t('button_save')}</button>
        <button type="button" onClick={(event) => this.setState({view: 'application'}, () => {this.getKidParentsInfo()})} className="btn btn-default">{i18n.t('button_cancel')}</button>

      </form>
    );
  }

  renderAddParentApplicationView(){
    var view = null;
    if(this.state.invite_parent_view === 'email'){
      view = this.renderInviteParentEmailForm();
    }
    if(this.state.invite_parent_view === 'exists'){
      view = this.renderInviteParentExists();
    }
    if(this.state.invite_parent_view === 'create'){
      view = this.renderInviteParentCreateForm();
    }
    if(this.state.invite_parent_view === 'create_without_email'){
      view = this.renderInviteParentCreateWithoutEmailForm();
    }
    if(this.state.invite_parent_view === 'role'){
      view = this.renderInviteParentRoleForm();
    }
    return(
      <div className="row">
        <div className="col-md-6 col-sm-9 col-xs-12">{view}</div>
      </div>
    )
  }

  render(){
    var view = helperFunctions.showBlockadeBlock(i18n.t('access_denied'), i18n.t('access_denied_info'));
    if(this.props.currentKid.canISeeDetails === true){
      if(this.state.isLoading === false){
        if(this.state.view === 'list'){
          view = this.renderDetailsView();
        }
        else if(this.state.view === 'application'){
          view = this.renderApplicationView();
        }
        else if(this.state.view === 'edit'){
          view = this.renderEditApplicationView();
        }
        else if(this.state.view === 'delete'){
          view = this.renderDeleteApplicationView();
        }
        else if(this.state.view === 'add_parent'){
          view = this.renderAddParentApplicationView();
        }
      }
      else{
        view = <LoaderComponent />;
      }
    }

    return(
      <div>
        {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
        {view}
      </div>
    );
  }

}

export default ParentsInfoComponent;
