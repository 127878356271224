import React, {Component} from 'react';

import teacherImage from '../../../Img/teacher.png';

export default class FaqComponent extends Component{

  render(){
    return(
      <div className="mp_faqWrapper">
        <div className="container">

          <div className="row">

            <div className="col-lg-4 col-md-4 col-sm-12"><img src={teacherImage} className="img-responsive"/></div>

              <div className="col-lg-8 col-md-8 col-sm-12">

                <div className="box box-solid">

                  <div className="box-body">
                    <div className="box-group" id="accordion">
                      <div className="panel box box-primary">
                        <div className="box-header with-border">
                          <h4 className="box-title">
                            <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                              Informacje o systemie NETkid
                            </a>
                          </h4>
                        </div>
                        <div id="collapseOne" className="panel-collapse collapse in">
                          <div className="box-body">
                            <p>
                              Netkid to elektroniczna wersja "tablicy korkowej", poszerzonej o liczne dodatkowe funkcje wymiany informacji. Umożliwia dotarcie do każdego rodzica z informacjami związanymi z przebywaniem dziecka - zbiórce 5 zł na przrdstwienie, prośbę o przyniesienie widokówki z wakacji czy informację o planowanym zebraniu. Informacje mogą być adresowane do wszystkich rodziców / opiekunów, ale także ograniczone do poszczególnych grup.
                            </p>
                            <p>
                              Rodzice / opiekunowie mogą wykorzystywać NETkid do zgłaszania nieobecności, odczytywania informacji o przebiegu pobytu dziecka w przedszkolu, zapoznawania się z jadłospisem czy rozkładem dnia zajęć. Mają też realny wpływ na decyzje podejmowane w przedszkolu poprzez uczestnictwo w ankietach.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel box box-primary">
                        <div className="box-header with-border">
                          <h4 className="box-title">
                            <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                              Główne funkcjonalności systemu
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTwo" className="panel-collapse collapse">
                          <div className="box-body">
                            <p>NETkid pozwala między innymi na:</p>
                            <ul>
                              <li>zgłaszanie do przedszkola informacji o nieobecności dziecka</li>
                              <li>edycja danych dziecka</li>
                              <li>dodawanie nowych użytkowników do konta</li>
                              <li>zapoznanie się ze wszystkimi informacjami związanymi z przebywaniem dziecka w Przedszkolu</li>
                              <li>wgląd w kalendarz zajęć</li>
                              <li>możliwość zapoznania się z komunikatami i informacjami przekazywanymi przez Przedszkole</li>
                              <li>przeglądanie galerii</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="panel box box-primary">
                        <div className="box-header with-border">
                          <h4 className="box-title">
                            <a data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                              Co zyskasz dzięki wdrożeniu systemu NETkid w swoim przedszkolu?
                            </a>
                          </h4>
                        </div>
                        <div id="collapseThree" className="panel-collapse collapse">
                          <div className="box-body">
                            <p>Wprowadzając NETkid do przedszkola:</p>
                            <ul>
                              <li>masz pewność, że posługujesz się narzędziem zgodnym z wymaganiami RODO w dziedzinie przetwarzania danych osobowych</li>
                              <li>możesz kontaktować się z wybranymi grupami – rodziców czy opiekunów wszystkich dzieci w przedszkolu</li>
                              <li>masz błyskawiczny wgląd w przebieg zajęć przedszkolnych</li>
                              <li>wiesz, jaka jest frekwencja uczestnictwa w zajęciach</li>
                              <li>pomagasz kadrze nauczycielskiej  w prowadzeniu elektronicznego dziennika zajęć</li>
                              <li>możesz efektywnie zarządzać personelem</li>
                              <li>oszczędzasz czas</li>
                              <li>zwiększasz atrakcyjność przedszkola w oczach rodziców / opiekunów</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

          </div>

        </div>
      </div>
    );
  }

}
