import React, {Component} from 'react';

import i18n from "i18next";
import * as acl from '../../acl.js';
import LoaderComponent from '../../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import * as requests from '../../requests.js';

export default
class KidsListComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'list',
      isLoading: false,
      status: '',
      group: {kids: []},
      msg: null,
      kids: []
    }
  }

  componentWillMount(){
    this.setState({group: this.props.currentGroup});
  }

  getGroupDetails(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : this.props.currentGroup.schoolID,
      'groupID' : this.props.currentGroup.id
    }

    let request = requests.getGroupDetails(array);
    request.then(function(response){
      this.setState({group: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderListView(){
    return(
      <div className="row">
        <div className="col-md-6 col-sm-9 col-xs-12">

          <ul className="list-unstyled">
            {
              this.state.group.kids.map(function(elem){
                return(
                  <li key={elem.id}>
                    <div className="media">
                      <div className="media-left">
                        <img className="media-object img-circle img-sm" src={elem.avatar} alt="" />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">{elem.fullName}<br/><small>{i18n.t('in_group_since')}: <b>{elem.cdate}</b></small></h4>
                      </div>
                    </div>
                    <br/>
                  </li>
                )
              }.bind(this))
            }
          </ul>

        </div>
      </div>
    );
  }

  render(){
    var view
    if(this.state.isLoading === false ){
      if(this.state.group.kids !== undefined && this.state.group.kids.length > 0){
        if(this.state.view === 'list'){
          view = this.renderListView();
        }
      }
      else{
        view = helperFunctions.renderNoContentInfo();
      }

    }
    else{
      view = <LoaderComponent />;
    }
    return(
      <div>
        {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
        {view}
      </div>
    );
  }

}
