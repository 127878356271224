import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import CkEditorComponent from '../../../../../Utils/CkEditorComponent';
import AttachmentsComponent from '../../../../../Utils/AttachmentsComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import Select from 'react-select';

export default
class ObjectGroupsDashboardComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      isLoading: false,
      items: [],
      seletedRange: "1",
      id: null,
      title: '',
      content: '',
      groups: [],
      selectedGroup: [],
      status: '',
      msg: null,
      view: 'list',
      fromDate: null,
      toDate: null,
    }

    this.setDate = this.setDate.bind(this);
    this.onChangeContentHandler = this.onChangeContentHandler.bind(this);
    this.onChangeTitleHandler = this.onChangeTitleHandler.bind(this);
    this.changeSelectedRange = this.changeSelectedRange.bind(this);
    this.handleSelectedGroupChange = this.handleSelectedGroupChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getItemDetails = this.getItemDetails.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  componentDidMount(){
    this.getSchoolGroupDashboard();
  }

  setDate(date, type){
    if(type === 'fromDate'){
      this.setState({fromDate : date}, () => {this.getSchoolGroupDashboard()});
    }
    else{
      this.setState({toDate : date}, () => {this.getSchoolGroupDashboard()});
    }
  }

  changeSelectedRange(event){
    this.setState({seletedRange: event.target.value, groups: []});
    if(event.target.value === "0"){
      this.getAllGroups();
    }
  }

  onChangeContentHandler(event, editor, data){
    this.setState({content: data });
  }

  onChangeTitleHandler(event){
    this.setState({title: event.target.value});
  }

  handleSelectedGroupChange(groups){
    this.setState({selectedGroup: groups});
  }

  getAllGroups(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getSchoolGroupsMultiselect(array);
    request.then(function(response){
      this.setState(
        {
          groups: response.data,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getSchoolGroupDashboard(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'fromDate' : this.state.fromDate,
      'toDate' : this.state.toDate
    }

    let request = requests.getSchoolGroupDashboard(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data.items,
          fromDate : new Date(moment(response.data.fromDate, 'YYYY-MM-DD')),
          toDate : new Date(moment(response.data.toDate, 'YYYY-MM-DD')),
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'title' : this.state.title,
      'content' : this.state.content,
      'for_all_groups' : this.state.seletedRange,
      'groups': this.state.selectedGroup,
      'id'  : this.state.id,
    }

    let request = requests.postSchoolGroupDashboard(array);
    request.then(function(response){
      this.setState({view: 'list', status: 'success', msg: i18n.t(response.data.msg)}, () => {  this.getSchoolGroupDashboard()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  deleteItem(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      school_id: localStorage.getItem('objectID'),
      id: this.state.id,
    }
    let request = requests.deleteSchoolDashboard(array);
    request.then(function(response){
      this.setState({status: 'success', msg: i18n.t(response.data.msg), view: 'list', object: []}, () => {this.getSchoolGroupDashboard()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else if (error.response.data.error === 'validation_error') {
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), validationFields: error.response.data.fields});
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));

  }

  getItemDetails(itemID){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : itemID,
    }

    let request = requests.getSchoolGroupDashboardDetails(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data,
          id: response.data[0].id,
          title: response.data[0].title,
          content: response.data[0].content,
          seletedRange: response.data[0].for_all_groups,
          selectedGroup: response.data[0].groups,
        }, () => {this.getAllGroups()}
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderListView(){
    return(
      <div>
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-12">

            <div className="form-group">
              <label>{i18n.t('select_start_date')}</label>
              <DatePicker
                  selected={this.state.fromDate}
                  onSelect={(value) => this.setDate(value, 'fromDate')}
                  filterDate={this.isWeekday}
                  todayButton={i18n.t('set_current_date')}
                  shouldCloseOnSelect={false}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
              />
            </div>

          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">

            <div className="form-group">
              <label>{i18n.t('select_finish_date')}</label>
              <DatePicker
                  selected={this.state.toDate}
                  onSelect={(value) => this.setDate(value, 'toDate')}
                  todayButton={i18n.t('set_current_date')}
                  filterDate={this.isWeekday}
                  shouldCloseOnSelect={false}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
              />
            </div>

          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">

            <div className="form-group">
              <label>{i18n.t('add_new_item')}</label>
              <button onClick={(event) => this.setState(
                  {
                    view: 'create',
                    status: '',
                    msg: null,
                    title: '',
                    content: '',
                    seletedRange: "1",
                    selectedGroup: [],
                    id: null
                  }
                )} className="btn btn-default"><i className="fas fa-plus-circle"></i> {i18n.t('add_new_item')}</button>
            </div>

          </div>
        </div>

        <hr/>
        <ul className="list-unstyled">
          {
            this.state.items.map(function(elem){
              return(
                <li key={elem.id}>
                  <div onClick={(event) => this.setState({view: 'edit', status: '', msg: null}, () => {this.getItemDetails(elem.id)})} className="media cursor">
                    <div className="media-left">
                      <span className="info-box-icon bg-red"><i class="far fa-calendar-alt"></i></span>
                    </div>
                    <div className="media-body">
                      <h4 className="media-heading">{elem.title}
                        <br/><small>{i18n.t('for_all_groups')}: <b>{elem.for_all_groups ? i18n.t('yes') : i18n.t('no')}</b></small>
                        <br/><small>{i18n.t('cdate')}: <b>{elem.cdate}</b></small>
                      </h4>
                    </div>
                  </div>
                  <hr/>
                </li>
              )
            }.bind(this))
          }
        </ul>
      </div>

    );
  }

  renderGroupsSelect(){
    return(
      <div className="form-group">
        <label>{i18n.t('select_group_this_event_applies')}</label>
        {
          <Select
            value={this.state.selectedGroup}
            onChange={this.handleSelectedGroupChange}
            options={this.state.groups}
            isMulti={true}
            placeholder={i18n.t('select_group_this_event_applies')}
          />
        }
      </div>
    );
  }

  renderFormView(){
    var groups = null;
    if(this.state.seletedRange === "0"){
      groups = this.renderGroupsSelect();
    }

    var view;
    if(this.state.view === 'edit'){
      view = <button onClick={(event) => this.deleteItem(event)} type="button" class="text-right btn btn-danger"><i className="fas fa-trash-alt"></i> {i18n.t('button_delete')}</button>
    }

    return(
      <form onSubmit={(event) => this.submitForm(event)}>
        <div className="form-group">
          <label>{i18n.t('set_item_title')}</label>
          <input type="text" name="title" className="form-control" value={this.state.title} onChange={(event) => this.onChangeTitleHandler(event)} required/>
        </div>


        {<CkEditorComponent label={i18n.t('set_item_content')} content={this.state.content} onChangeContentHandler={this.onChangeContentHandler}/>}

        <div className="form-group">
          <label>{i18n.t('set_event_area')}</label>
          <select className="form-control" name="range" onChange={(event) => this.changeSelectedRange(event)} value={this.state.seletedRange}>
            <option value="1">{i18n.t('all_groups')}</option>
            <option value="0">{i18n.t('select_group_this_event_applies')}</option>
          </select>
        </div>

        {groups}

        <hr/>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
              <button type="button" onClick={(event) => this.setState({view: 'list'}, () => {this.getSchoolGroupDashboard()})} className="btn btn-danger">{i18n.t('button_cancel')}</button>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">{view}</div>
          </div>

      </form>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView()
      }
      else if(this.state.view === 'create' || this.state.view === 'edit'){
        view = this.renderFormView()
      }
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
