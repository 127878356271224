import React, {Component} from 'react';

import loader from '../../../../Img/small_loader.gif';
import i18n from "i18next";

class HeaderBarComponent extends Component{

  constructor(props){
    super(props);
  }

  getAdminIcon(){
    return(
      <li className="settings">
        <a href="#" onClick={(event) => this.props.onClickModuleHandler(event, 'admin')}><i className="fas fa-cogs"></i></a>
      </li>
    );
  }

  getNotifivationsIcon(){
    return(
      <li className="notifications">
        <a href="#" onClick={(event) => this.props.onClickModuleHandler(event, 'applications')}>
            <i className="fas fa-bell"></i>
            {this.props.user.unreadApp > 0 ? <span className="label label-danger">{this.props.user.unreadApp}</span> : null}
        </a>
      </li>
    );
  }

  getTasksIcon(){
    return(
      <li className="tasks">
        <a href="#" onClick={(event) => this.props.onClickModuleHandler(event, 'tasks')}>
            <i className="fas fa-tasks"></i>
            {this.props.user.tasks > 0 ? <span className="label label-danger">{this.props.user.tasks}</span> : null}
        </a>
      </li>
    );
  }

  getSearchIcon(){
    return(
      <li className="search">
        <a href="#" onClick={(event) => this.props.onClickModuleHandler(event, 'search')}>
            <i className="fas fa-search"></i>
        </a>
      </li>
    );
  }

  render(){

    var admin;
    var notifications;
    var tasks;
    var search = this.getSearchIcon();
    if(this.props.user.isAdmin === true){
      admin = this.getAdminIcon();
      notifications = this.getNotifivationsIcon();
      tasks = this.getTasksIcon();
    }



    return(
      <nav className="navbar navbar-static-top" role="navigation">

          <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
              <i className="fas fa-bars"></i>
          </a>



          <div className="navbar-custom-menu">
              <ul className="nav navbar-nav">
                  {search}
                  {admin}

                  <li className="messages">
                      <a href="#" onClick={(event) => this.props.onClickModuleHandler(event, 'msg')}>
                          <i className="far fa-envelope"></i>
                          {this.props.user.unreadMsg > 0 ? <span className="label label-danger">{this.props.user.unreadMsg}</span> : null}
                      </a>
                  </li>
                  {notifications}
                  {tasks}

                  <li className="dropdown notifications-menu">

                      <a href="#" className="dropdown-toggle" data-toggle="dropdown">{localStorage.getItem('lang')}</a>
                      <ul className="dropdown-menu">
                          <li className="header">{i18n.t('select_system_lang')}</li>
                          <li>

                              <ul className="menu">
                                {
                                  this.props.langs.map(function(item){
                                    return(
                                      <li key={item.key} onClick={(event) => this.props.changeLang(item.index)}><a href="#"><i className="fas fa-flag"></i> {i18n.t(item.trans)}</a></li>
                                    );
                                  }.bind(this))
                                }
                              </ul>

                          </li>

                      </ul>
                  </li>

                  <li className="dropdown user user-menu">

                      <a href="#" className="dropdown-toggle" data-toggle="dropdown"><i className="fas fa-user"></i> {this.props.user.name}</a>
                      <ul className="dropdown-menu">

                          <li className="user-header">
                              <img src={this.props.user.userAvatar} className="img-circle" alt="User Image" />

                              <p>
                                  {this.props.user.userName}
                                  <small>{i18n.t('in_system_since')}: <b>{this.props.user.cdate}</b></small>
                              </p>
                          </li>


                          <li className="user-footer">
                              <div className="pull-left">
                                  <a href="#" onClick={(event) => this.props.onClickModuleHandler(event, 'profileEdit')} className="btn btn-default btn-flat">{i18n.t('profile_edit')}</a>
                              </div>
                              <div className="pull-right">
                                  <a href="#" onClick={(event) => this.props.onClickModuleHandler(event, 'logout')} className="btn btn-default btn-flat">{i18n.t('logout')}</a>
                              </div>
                          </li>
                      </ul>
                  </li>



              </ul>
          </div>
      </nav>
    );
  }

}

export default HeaderBarComponent;
