import React, {Component} from 'react';

import LoaderComponent from '../../../../../../Utils/LoaderComponent';
import CkEditorComponent from '../../../../../../Utils/CkEditorComponent';
import AttachmentsComponent from '../../../../../../Utils/AttachmentsComponent';
import i18n from "i18next";
import * as acl from '../../../acl.js';
import * as requests from '../../../requests.js';
import * as helperFunctions from '../../../../../../Utils/HelperFunctions.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";

export default
class MealTimeComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      items: {},
      status: '',
      msg: null,
      isLoading: false,
      view: 'list',
      selectedItemID: 0,
      selectedItemHour: new Date(moment().format("HH:mm")),
      selectedItemName: '',
      action: null,
    }

    this.getItemDetails = this.getItemDetails.bind(this);
    this.handleChangeSelectedItemHour = this.handleChangeSelectedItemHour.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.submitUpdateItem = this.submitUpdateItem.bind(this);
  }

  componentWillMount(){
    this.getMealTimeItems();
  }

  handleChangeSelectedItemHour(date){
    this.setState({selectedItemHour: date});
  }

  handleNameChange(event){
    this.setState({selectedItemName: event.target.value});
  }

  submitUpdateItem(event){
    event.preventDefault();
    this.setState({isLoading: true});


    let request;
    if(this.state.action === 'update'){
      var array = {
        'school_id' : localStorage.getItem('objectID'),
        'id' : this.state.selectedItemID,
        'name' : this.state.selectedItemName,
        'hour' : moment(this.state.selectedItemHour).format("HH:mm"),
      }
      request = requests.postUpdateSchoolFoodMealTimeItem(array);
      request.then(function(response){
        this.setState({status: 'success', msg: i18n.t(response.data.msg)}, () => {this.getItemDetails()});
      }.bind(this));
    }
    if(this.state.action === 'create'){
      var array = {
        'school_id' : localStorage.getItem('objectID'),
        'name' : this.state.selectedItemName,
        'hour' : moment(this.state.selectedItemHour).format("HH:mm"),
      }
      request = requests.postStoreSchoolFoodMealTimeItem(array);
      request.then(function(response){
        this.setState({status: 'success', msg: i18n.t(response.data.msg), view: 'list'}, () => {this.getMealTimeItems()});
      }.bind(this));
    }


    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getMealTimeItems(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getSchoolFoodMealTimes(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getItemDetails(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.state.selectedItemID
    }

    let request = requests.getSchoolFoodMealTimeDetails(array);
    request.then(function(response){
      this.setState(
        {
          selectedItemName: response.data.name,
          selectedItemHour: new Date(moment(response.data.hour, "HH:mm"))
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderMainView(){
    var list = this.renderListView();
    return(
      <div>
        <button onClick={(event) => this.setState({selectedItemID: 0, selectedItemHour: moment().toDate().getTime(), action: 'create', view: 'edit'})} className="btn btn-default"><i className="fas fa-plus-circle"></i> {i18n.t('add_new_item')}</button><hr/>
        {list}
      </div>
    )
  }

  renderListView(){
    var view = helperFunctions.renderNoContentInfo();
    if(this.state.items !== undefined && this.state.items.length){
      view =
        this.state.items.map(function(elem){
          return(

              <div key={elem.id} onClick={(event) => this.setState({selectedItemID: elem.id, action: 'update', selectedItemHour: new Date(moment(elem.hour, "HH:mm")), view: 'edit'}, () => {this.getItemDetails()})} className="media cursor">
                <div className="media-left">
                  <span className="info-box-icon bg-red"><i class="fas fa-utensils"></i></span>
                </div>
                <div className="media-body">
                  <h4 className="media-heading">{elem.name}
                    <br/><small>{i18n.t('scheduled_meal_time')}: <b>{elem.hour}</b></small>
                    <br/><small>{i18n.t('cdate')}: <b>{elem.cdate}</b></small>
                  </h4>
                </div>
              </div>

          )
        }.bind(this))
      }


    return (<div>{view}</div>);
  }

  renderEditView(){
    return(
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12">

          <form onSubmit={(event) => this.submitUpdateItem(event)}>
            <div className="form-group">
              <label>{i18n.t('set_item_title')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <input type="text" name="name" onChange={(event) => this.handleNameChange(event)} value={this.state.selectedItemName} className="form-control" required />
            </div>

            <div className="form-group">
              <label>{i18n.t('scheduled_meal_time')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
                <DatePicker
                  selected={this.state.selectedItemHour}
                  onChange={this.handleChangeSelectedItemHour}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  timeCaption={i18n.t('scheduled_meal_time')}
                  className="form-control"
              />
            </div>

            <hr/>
            <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
            <button type="button" onClick={(event) => this.setState({view: 'list'}, () => {this.getMealTimeItems()})} className="btn btn-default">{i18n.t('button_cancel')}</button>
          </form>

        </div>
      </div>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderMainView()
      }

      if(this.state.view === 'edit'){
        view = this.renderEditView()
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
