import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';
import Moment from 'moment';

export default
class TeacherDetailsViewComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      isLoading: false,
      status: '',
      error: null,
      details: [],
      view: 'details',
      date: new Date(Moment(this.props.date).format("YYYY-MM-DD")),
      create: true,
      action: null,
      editItem: {
        hash: null,
        title_string: '',
        morning_activity: '',
        activities_main_1: '',
        activities_main_2: '',
        afternoon_classes: '',
        outdoor_activities: '',
        card_content: '',
        card_no: null,
        full_date: this.props.date,
      },
      cards: [],
      choosenCard: null,
    }

    this.acceptCard = this.acceptCard.bind(this);
    this.changeView = this.changeView.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.changeState = this.changeState.bind(this);
    this.getUnusedCards = this.getUnusedCards.bind(this);
    this.submitUnunsedCard = this.submitUnunsedCard.bind(this);
  }

  componentDidMount(){
    this.setState({action: this.props.action});

    if(this.props.action === 'create'){
      this.changeView('create', true);
    }
    if(this.props.action === 'edit'){
      this.changeView('edit', false);
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.details !== this.props.details){
      this.setState({action: this.props.action});
      if(this.props.action === 'create'){
        this.changeView('create', true);
      }
      if(this.props.action === 'edit'){
        this.changeView('edit', false);
      }
    }
    if(prevProps.action !== this.props.action){
      this.setState({action: this.props.action});
    }
    return false;
  }

  getUnusedCards(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.groupID,
      'date': this.props.date,
    }
    let request = requests.getGroupActvityLogUnusedCards(array);
    request.then(function(response){
      this.setState(
        {
          cards: response.data,
          choosenCard: null,
        }
      );
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  acceptCard(hash){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.groupID,
      'log_id': hash,
    }
    let request = requests.postGroupDailyLogAccept(array);
    request.then(function(response){
      this.props.changeView('main');
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitUnunsedCard(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.groupID,
      'log_id': this.state.choosenCard,
      'date': this.props.date,
    }
    let request = requests.postGroupDailyLogUnusedCard(array);
    request.then(function(response){
      this.props.changeView('main');
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.groupID,
      'values' : this.state.editItem,
      'create' : this.state.create,
      'date' : this.state.date,
    }

    var request;
    request = requests.postGroupDailyLogStore(array);
    request.then(function(response){
      this.props.changeView('main');
    }.bind(this));

    request.catch(function(error){
      this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  changeState(event, type){
    if(type === 'title_string'){
      const { editItem } = this.state;
      editItem.title_string =  event.target.value;
      this.setState({editItem})
    }
    if(type === 'morning_activity'){
      const { editItem } = this.state;
      editItem.morning_activity =  event.target.value;
      this.setState({editItem})
    }
    if(type === 'activities_main_1'){
      const { editItem } = this.state;
      editItem.activities_main_1 =  event.target.value;
      this.setState({editItem})
    }
    if(type === 'activities_main_2'){
      const { editItem } = this.state;
      editItem.activities_main_2 =  event.target.value;
      this.setState({editItem})
    }
    if(type === 'afternoon_classes'){
      const { editItem } = this.state;
      editItem.afternoon_classes =  event.target.value;
      this.setState({editItem})
    }
    if(type === 'outdoor_activities'){
      const { editItem } = this.state;
      editItem.outdoor_activities =  event.target.value;
      this.setState({editItem})
    }
    if(type === 'card_content'){
      const { editItem } = this.state;
      editItem.card_content =  event.target.value;
      this.setState({editItem})
    }
    if(type === 'card'){
      this.setState({choosenCard: event.target.value})
    }
  }


  changeView(view, action){
    if(view === 'edit'){
      this.setState(
        {
          view: view,
          editItem: {
            hash: this.props.details.hash,
            title_string: this.props.details.title_string,
            card_no: this.props.details.card_no,
            morning_activity: this.props.details.morning_activity,
            activities_main_1: this.props.details.activities_main_1,
            activities_main_2: this.props.details.activities_main_2,
            afternoon_classes: this.props.details.afternoon_classes,
            outdoor_activities: this.props.details.outdoor_activities,
            card_content: this.props.details.card_content,
            full_date: this.props.details.full_date,
          },
        }
      );
    }
    else{
      this.setState(
        {
          editItem: {
            hash: null,
            title_string: '',
            morning_activity: '',
            activities_main_1: '',
            activities_main_2: '',
            afternoon_classes: '',
            outdoor_activities: '',
            card_content: '',
            card_no: null,
            full_date: this.props.date,
          },
        }
      );
    }


    this.setState({create: action});
  }

  renderDetails(){
    return(
      <div>
        <div className="info-box">
          <span className="info-box-icon bg-aqua"><i className="fas fa-info-circle"></i></span>
          <div className="info-box-content">
            <span className="info-box-text">{i18n.t('daily_log_create_title_1')}<br/>{i18n.t('daily_log_create_title_2')}</span><br/>
            <span className="info-box-number">
              <button onClick={(event) => this.acceptCard(this.props.details.hash)} className="btn btn-success">{i18n.t('accept_proposal')}</button>&nbsp;
              <button onClick={(event) => this.setState({action: 'edit'}, () => {this.changeView('edit', true)})} className="btn btn-warning">{i18n.t('edit_proposal')}</button>&nbsp;
              <button onClick={(event) => this.setState({action: 'create'}, () => {this.changeView('create', true)})} className="btn btn-primary">{i18n.t('create_new_daily_log')}</button>
            </span>
          </div>
        </div>

        <h3><small>{i18n.t('daily_log_title')}:</small><br/>{this.props.details.title_string}</h3>

        <br/>
        <h5><b>{i18n.t('daly_log_card_no')}: </b>{this.props.details.card_no}</h5>
        <h5><b>{i18n.t('daily_log_morning_ac')}:</b><br/>{this.props.details.morning_activity}</h5>
        <h5><b>{i18n.t('daily_log_activities_1')}:</b><br/>{this.props.details.activities_main_1}</h5>
        <h5><b>{i18n.t('daily_log_activities_2')}:</b><br/>{this.props.details.activities_main_2}</h5>
        <h5><b>{i18n.t('daily_log_activities_indoor')}:</b><br/>{this.props.details.afternoon_classes}</h5>
        <h5><b>{i18n.t('daily_log_activities_outdoor')}:</b><br/>{this.props.details.outdoor_activities}</h5>
        <h5><b>{i18n.t('daily_log_card_content')}:</b><br/>{this.props.details.card_content}</h5>
      </div>
    );
  }

  renderFormView(){

    return(
      <div className="row">
        <div className="col-sm-12 col-md-9 col-mg-6">

          <form onSubmit={(event) => this.submitForm(event)}>
            <div className="form-group">
              <label>{i18n.t('daily_log_title')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
              <input type="text" name="title_string" className="form-control" onChange={(event) => this.changeState(event, 'title_string')} value={this.state.editItem.title_string} required />
            </div>
            <div className="form-group">
              <label>{i18n.t('daily_log_morning_ac')}</label>
              <textarea name="morning_activity" class="form-control" rows="10" onChange={(event) => this.changeState(event, 'morning_activity')} value={this.state.editItem.morning_activity} />
            </div>
            <div className="form-group">
              <label>{i18n.t('daily_log_activities_1')}</label>
              <textarea name="activities_main_1" class="form-control" rows="10" onChange={(event) => this.changeState(event, 'activities_main_1')} value={this.state.editItem.activities_main_1} />
            </div>
            <div className="form-group">
              <label>{i18n.t('daily_log_activities_2')}</label>
              <textarea name="activities_main_2" class="form-control" rows="10" onChange={(event) => this.changeState(event, 'activities_main_2')} value={this.state.editItem.activities_main_2} />
            </div>
            <div className="form-group">
              <label>{i18n.t('daily_log_activities_indoor')}</label>
              <textarea name="afternoon_classes" class="form-control" rows="10" onChange={(event) => this.changeState(event, 'afternoon_classes')} value={this.state.editItem.afternoon_classes} />
            </div>
            <div className="form-group">
              <label>{i18n.t('daily_log_activities_outdoor')}</label>
              <textarea name="outdoor_activities" class="form-control" rows="10" onChange={(event) => this.changeState(event, 'outdoor_activities')} value={this.state.editItem.outdoor_activities} />
            </div>
            <div className="form-group">
              <label>{i18n.t('daily_log_card_content')}</label>
              <textarea name="card_content" class="form-control" rows="10" onChange={(event) => this.changeState(event, 'card_content')} value={this.state.editItem.card_content} />
            </div>

            <hr/>
            <button className="btn btn-success" type="submit">{i18n.t('button_save')}</button>
          </form>


        </div>
      </div>
    );
  }

  renderChooseCardForm(){
    return (
      <div>
        <form onSubmit={(event) => this.submitUnunsedCard(event)}>

          <div class="input-group">
            <select name="card" className="form-control" onChange={(event) => this.changeState(event, 'card')}>
              <option>{i18n.t('daily_log_choose_card')}</option>
              {
                this.state.cards.map(function(item){
                  return(<option value={item.code}>{i18n.t('card') + " :" + item.card_no}, {item.title_string}</option>)
                }.bind(this))
              }
            </select>
          </div>
          <hr/>
          <button class="btn btn-success" type="submit">{i18n.t('button_save')}</button>


        </form>
      </div>
    )
  }

  renderMainView(){
    var view;
    var chooseCard = null;
    if(this.state.action === 'details'){
      view = this.renderDetails()
    }
    else if(this.state.action === 'chooseCard'){
      chooseCard = <span>&nbsp;<button onClick={(event) => this.setState({action: 'create'})} type="button" className="btn btn-primary">{i18n.t('daily_log_create_card')}</button></span>
      view = this.renderChooseCardForm()
    }
    else {
      if(this.state.create === true && this.state.action !== 'edit'){
        chooseCard = <span>&nbsp;<button onClick={(event) => this.setState({action: 'chooseCard'}, () => {this.getUnusedCards()})} type="button" className="btn btn-primary">{i18n.t('daily_log_choose_other_card')}</button></span>
      }
      view = this.renderFormView()
    }



    return(
      <div>
        <button className="btn btn-default" type="button" onClick={(event) => this.props.changeView('main')}>{i18n.t('go_back_to_calendar')}</button>{chooseCard}<br/><br/>

        {view}

      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderMainView();
    }
    else{
      view = <LoaderComponent />;
    }

    return(
      <div>
        {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
        <div className="nav-tabs-custom"><div className="box-body">{view}</div></div>
      </div>
    );
  }

}
