import React, {Component} from 'react';

import ObjectSelectComponent from '../../../LayoutUtils/ObjectSelectComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import LoaderComponent from '../../../../Utils/LoaderComponent';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';
import * as requests from '../requests.js';
import * as dashboardRequests from '../../../Requests/DashboardRequests.js';
import Moment from 'moment';

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import plLocale from '@fullcalendar/core/locales/pl';

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

import ParentDetails from '../ParentDetailsViewComponent';
import TeacherDetails from '../TeacherDetailsViewComponent';


export default
class CalendarComponent extends Component{

  calendarRef = React.createRef()

  constructor(props){
    super(props)

    this.state = {
      view: 'main',
      status: '',
      error: null,
      isLoading: false,
      groups: [],
      currentGroup: 0,
      calendar: [],
      dates: [],
      currentDate: new Date(Moment().format("YYYY-MM-DD")),
      currentItemID: null,
      role: 'role_parent',
      details: [],
      create: false,
      proposal: false,
      choosenDate: '',
      action: null,
    }

    this.changeGroupSelect = this.changeGroupSelect.bind(this);
    this.changeState = this.changeState.bind(this);
    this.getActivityDetails = this.getActivityDetails.bind(this);
    this.changeView = this.changeView.bind(this);
  }

  componentDidMount(){
    this.getGroups(this.props.objectID);
  }

  componentDidUpdate(prevProps){
    if(prevProps.reload !== this.props.reload){
      this.props.afterReloadHandler(false);
      this.setState({currentGroup: 0, view: 'main'});
      this.getGroupCalendar(this.props.objectID, 0);
      this.getGroups(this.props.objectID);
      return true;
    }
    return false;
  }

  changeGroupSelect(event){
    this.setState({currentGroup: event.target.value});
    this.getGroupCalendar(this.props.objectID, event.target.value);
  }

  changeState(event, type){
    if(type === 'month_select'){
      this.setState({currentDate: event.target.value}, () => {this.getGroupCalendar(this.props.objectID, this.state.currentGroup)})
    }
  }

  changeView(view){
    this.setState({view: view}, () => {this.getGroupCalendar(this.props.objectID, this.state.currentGroup)});
  }

  getActivityDetails(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : this.props.objectID,
      'log_id' : this.state.currentItemID,
      'id' : this.state.currentGroup,
    }

    let request = requests.getGroupsCalendarDetails(array);
    request.then(function(response){
      this.setState(
        {
          role: response.data.role,
          details: response.data.info.details,
          create: response.data.info.create,
          proposal: response.data.info.proposal,
          action: response.data.info.action,
        });
    }.bind(this));

    request.catch(function(error){
      this.setState({groups: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getGroups(objectID){
    this.setState({isLoading: true});
    var array = {
      'objectID' : objectID
    }

    let request = dashboardRequests.getObjectGroupsList(array);
    request.then(function(response){
      this.setState({groups: response.data}, () => {
        this.getGroupCalendarDefault(response.data)
      });
    }.bind(this));

    request.catch(function(error){
      this.setState({groups: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getGroupCalendarDefault(groups){
    if(groups.length > 0){
      this.setState({currentGroup: groups[0].id})
      this.getGroupCalendar(this.props.objectID, groups[0].id);
    }
  }

  getGroupCalendar(objectID, groupID){
    this.setState({isLoading: true});
    var array = {
      'school_id' : objectID,
      'id' : groupID,
      'month' : this.state.currentDate,
    }

    let request = requests.getGroupsCalendar(array);
    request.then(function(response){
      this.setState(
        {
          calendar: response.data.items,
          currentDate: response.data.currentNormal,
          dates: response.data.months
        }
      );

      if(response.data.current !== null){
        let calendarApi = this.calendarRef.current.getApi();
        calendarApi.gotoDate(new Date(response.data.current));
      }


    }.bind(this));

    request.catch(function(error){
      this.setState({calendar: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderCalendarView(){
    return(
      <div>
        <FullCalendar
          defaultView="dayGridMonth"
          locale={plLocale}
          ref={this.calendarRef}
          header={{ left: null, center: null, right: null }}
          showNonCurrentDates={false}
          plugins={[dayGridPlugin]}
          weekends={false}
          events={this.state.calendar}
          eventClick={function(calEvent){
            this.setState({currentItemID: calEvent.event.id, choosenDate: calEvent.event.start, view: 'details'}, () => {this.getActivityDetails()})
          }.bind(this)}
          />

      </div>
    );
  }

  renderMainView(){
    return(
      <div>

        <div className="row">
          <div className="col-md-4 col-sm-6 col-xs-12">

            <div className="info-box">
              <span className="info-box-icon bg-yellow"><i className="fas fa-user-friends"></i></span>

                <div className="info-box-content">
                  <span className="info-box-text">{i18n.t('choose_group')}</span><br/>
                  <span className="info-box-number">
                    <select className="form-control" onChange={(event) => this.changeGroupSelect(event)} value={this.state.currentGroup}>
                      {
                        this.state.groups.map(function(item){
                          return(<option key={item.id} value={item.id}>{item.name}</option>)
                        }.bind(this))
                      }
                    </select>
                  </span>
                </div>

            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12">

            <div className="info-box">
              <span className="info-box-icon bg-violet"><i className="fas fa-calendar-alt"></i></span>

                <div className="info-box-content">
                  <span className="info-box-text">{i18n.t('choose_month')}</span><br/>
                  <span className="info-box-number">
                    <select className="form-control" value={this.state.currentDate} onChange={(event) => this.changeState(event, 'month_select')}>
                      {
                        this.state.dates.map(function(item){
                          return(
                            <option key={item.key} value={item.full_date}>{item.month}</option>
                          );
                        }.bind(this))
                      }
                    </select>
                  </span>
                </div>

            </div>
          </div>

        </div>

        {this.renderCalendarView()}

      </div>
    )
  }

  renderDetailsParentView(){
    return (<ParentDetails changeView={this.changeView} details={this.state.details}/>);
  }

  renderDetailsPrincipalView(){
    return (<TeacherDetails
      changeView={this.changeView}
      details={this.state.details}
      create={this.state.create}
      proposal={this.state.proposal}
      objectID={this.props.objectID}
      groupID={this.state.currentGroup}
      date={this.state.choosenDate}
      action={this.state.action}
      />);
  }

  render(){
    var view;
    if(this.state.groups !== undefined && this.state.groups.length > 0){
      if(this.state.view === 'main'){
        view = this.renderMainView();
      }
      if(this.state.view === 'details' && this.state.role === 'role_parent'){
        view = this.renderDetailsParentView();
      }
      else if (this.state.view === 'details' && (this.state.role === 'role_principal' || this.state.role === 'role_teacher')) {
        view = this.renderDetailsPrincipalView();
      }
    }
    else{
      view = helperFunctions.renderNoContentInfo();
    }

    return(
      <div>
        {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
        <div className="nav-tabs-custom"><div className="box-body">{view}</div></div>
      </div>
    );
  }

}
