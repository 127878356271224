import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import CkEditorComponent from '../../../../../Utils/CkEditorComponent';
import TeacherAssignToActivityComponent from '../../../../../Utils/TeacherAssignToActivityComponent';
import KidsAssignedToActivityComponent from '../../../../../Utils/KidsAssignedToActivityComponent';

import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';


export default
class ObjectAddtionalActivityComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      isLoading: false,
      items: [],
      id: null,
      title: '',
      content: '',
      gallery: [],
      objectGalleryID: 0,
      currentId: 0,
      status: '',
      msg: null,
      view: 'list',

    }


    this.onChangeContentHandler = this.onChangeContentHandler.bind(this);
    this.onChangeTitleHandler = this.onChangeTitleHandler.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getItemDetails = this.getItemDetails.bind(this);
  }

  componentDidMount(){
    this.getSchoolGroupDashboard();
  }

  onChangeContentHandler(event, editor, data){
    this.setState({content: data });
  }

  onChangeTitleHandler(event){
    this.setState({title: event.target.value});
  }

  setGalleryID(event){
    this.setState({objectGalleryID: event.target.value});
  }

  getSchoolGroupDashboard(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getSchoolAdditionalActivityList(array);
    request.then(function(response){
      this.setState({items: response.data[0]});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  submitForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'title' : this.state.title,
      'content' : this.state.content,
      'id'  : this.state.id,
      'gallery_id': this.state.objectGalleryID,
    }

    let request = requests.postSchoolAdditionalActivity(array);
    request.then(function(response){
      this.setState({view: 'edit', status: 'success', msg: response.data.msg}, () => {this.getItemDetails(response.data.id); this.getGallery();})
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getItemDetails(itemID){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : itemID,
    }

    let request = requests.getSchoolAdditionalActivityDetails(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data,
          id: response.data[0].id,
          title: response.data[0].title,
          content: response.data[0].content,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getGallery(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
    }

    let request = requests.getSchoolGallery(array);
    request.then(function(response){
      this.setState({gallery: response.data});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  deleteForm(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id'  : this.state.currentId,
    }

    let request = requests.deleteSchoolAdditionalActivity(array);
    request.then(function(response){
      this.setState({view: 'list', status: 'success', msg: i18n.t(response.data.msg)}, () => {  this.getSchoolGroupDashboard()});
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderCalendarSubtitle(items){
    return (
      <ul className="list-unstyled">
        {
          items.map(function(item){
            return(
              <li key={item.id}><small><b>{item.day}</b> {i18n.t('in_hours')} <b>{item.time_start} - {item.time_end}</b>: {item.teacher.account.name} {item.teacher.account.surname}</small></li>
            );
          }.bind(this))
        }
      </ul>
    );
  }

  renderListView(){
    return(
      <div>
        <button onClick={(event) => this.setState(
            {
              view: 'create',
              id: null,
              title: '',
              content: '',
              gallery: [],
              objectGalleryID: 0,
              status: '',
              msg: null,

            }, () => {this.getGallery()})} className="btn btn-default"><i className="fas fa-plus-circle"></i> {i18n.t('add_new_item')}</button><hr/>
        <ul className="list-unstyled">
          {
            this.state.items.map(function(elem){
              var calendar = null;
              if(elem.calendar.length){
                calendar = this.renderCalendarSubtitle(elem.calendar)
              }
              return(
                <li key={elem.id}>
                  <div onClick={(event) => this.setState({view: 'edit', currentId: elem.id, status: '', msg: null}, () => {this.getItemDetails(elem.id); this.getGallery();})} className="media cursor">
                    <div className="media-left">
                      <span className="info-box-icon bg-red"><i class="far fa-calendar-alt"></i></span>
                    </div>
                    <div className="media-body">
                      <h4 className="media-heading">{elem.title}
                        <br/><small>{i18n.t('cdate')}: <b>{elem.cdate}</b></small><br/><br/>
                        {calendar}
                      </h4>
                    </div>
                  </div>
                  <hr/>
                </li>
              )
            }.bind(this))
          }
        </ul>
      </div>

    );
  }

  renderEditForm(){
    return(
      <form onSubmit={(event) => this.submitForm(event)}>
        <div className="form-group">
          <label>{i18n.t('set_item_title')}</label>
          <input type="text" name="title" className="form-control" value={this.state.title} onChange={(event) => this.onChangeTitleHandler(event)} required/>
        </div>


        {<CkEditorComponent label={i18n.t('set_item_content')} content={this.state.content} onChangeContentHandler={this.onChangeContentHandler}/>}

        <div className="form-group">
          <label>{i18n.t('choose_gallery_you_want_to_add')}</label>
          <select onChange={(event) => this.setGalleryID(event)} value={this.state.objectGalleryID} className="form-control">
            <option value="0">{i18n.t('no_item_select')}</option>
            {
              this.state.gallery.map(function(gallery){
                return(<option key={gallery.id} value={gallery.id}>{gallery.title}</option>);
              }.bind(this))
            }
          </select>
        </div>


        <hr/>
        <button type="submit" className="btn btn-success">{i18n.t('button_save')}</button>
        <button type="button" onClick={(event) => this.setState({view: 'list', status: '', msg: null}, () => {this.getSchoolGroupDashboard()})} className="btn btn-default">{i18n.t('button_cancel')}</button>
      </form>
    )
  }

  renderFormView(){
    var teachers = i18n.t('options_are_available_only_in_edit_mode');
    var kids = i18n.t('options_are_available_only_in_edit_mode');
    var deleteFrom = i18n.t('options_are_available_only_in_edit_mode');
    if(this.state.view === 'edit'){
      teachers = <TeacherAssignToActivityComponent activityID={this.state.id}/>
      kids = <KidsAssignedToActivityComponent activityID={this.state.id}/>
      deleteFrom = this.renderDeleteView();
    }
    return(
      <div>

        <h3>{this.state.title}</h3>

      <div className="nav-tabs-custom">
        <ul className="nav nav-tabs">
          <li><a href="#tab_2" onClick={(event) => this.setState({view: 'list', status: '', msg: null}, () => {this.getSchoolGroupDashboard()})} data-toggle="tab">Wróć do listy</a></li>
          <li className="active"><a href="#tab_1" data-toggle="tab">{i18n.t('main_info')}</a></li>
          <li><a href="#tab_2" data-toggle="tab">{i18n.t('teachers_list')}</a></li>
          <li><a href="#tab_3" data-toggle="tab">{i18n.t('list_of_assigned_kids')}</a></li>
          <li><a href="#tab_4" data-toggle="tab">{i18n.t('button_delete')}</a></li>
        </ul>
        <div className="tab-content">
          <div className="tab-pane active" id="tab_1">
            {this.renderEditForm()}
          </div>

          <div className="tab-pane" id="tab_2">
            {teachers}
          </div>

          <div className="tab-pane" id="tab_3">
            {kids}
          </div>

          <div className="tab-pane" id="tab_4">
            {deleteFrom}
          </div>

        </div>
      </div>
      </div>
    );
  }

  renderDeleteView(){
    return(
      <form onSubmit={(event) => this.deleteForm(event)}>
        <p>{i18n.t('confirm_delete_action')}</p><hr/>
          <ul className="list-inline">
            <li><button type="submit" className="btn btn-danger">{i18n.t('button_delete')}</button></li>
            <li><button type="button" onClick={(event) => this.setState({view: 'edit', status: '', msg: null}, () => {this.getItemDetails(this.state.currentId); this.getGallery();})} className="btn btn-default">{i18n.t('button_cancel')}</button></li>
          </ul>
      </form>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView()
      }
      else if(this.state.view === 'create' || this.state.view === 'edit'){
        view = this.renderFormView()
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
