import React, {Component} from 'react';

import LoaderComponent from '../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import * as requests from '../requests.js';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';

export default
class AboutComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      items: [],
      isLoading: false,
      status: '',
      msg: null,
    }
  }

  componentDidMount(){
    this.getAboutList();
  }

  componentDidUpdate(prevProps){
    if(prevProps.reloadList !== this.props.reloadList){
      this.props.finishListReloadHandler(false);
      this.getAboutList();
      return true;
    }
    return false;
  }

  getAboutList(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
    }

    let request = requests.getProfileAbout(array);
    request.then(function(response){
      this.setState({items: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({items: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderMainView(){
    return (
      <div>
      {
        this.state.items.map(function(elem){
          return(
            <div key={elem.id}>
              <div className="page-header">
                <h2>{elem.title}</h2>
              </div>
              <div dangerouslySetInnerHTML={{__html: elem.content}} />
            </div>
          );
        }.bind(this))
      }
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderMainView();
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
        {view}
      </div>
    );
  }

}
