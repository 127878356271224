import React, {Component} from 'react';

import HeaderBarComponent from '../LayoutUtils/HeaderBarComponent';
import AsideNavigationComponent from '../LayoutUtils/AsideNavigationComponent';
import FooterComponent from '../LayoutUtils/FooterComponent';
import TokenExpiredAlert from '../LayoutUtils/TokenExpiredComponent';
import NotificationsWidget from '../Modules/NotificationsModule/NotificationsWidget';
import i18n from "i18next";
//import * as moment from "moment";
import * as moment from 'moment-timezone';
import LoaderComponent from '../../Utils/LoaderComponent';



import components from '../Requests/ModuleLoader.js';

import * as DashboardRequests from '../Requests/DashboardRequests.js';

class DashboardComponent extends Component{

  constructor(){
    super();

    this.state = {
      tokenExpiredAlert: false,
      loadingUserData: true,
      user: [],
      activeModule: 'dashboard',
      moduleParams: [],
      interval: 0,
      isLoading: true,
      langs: [],
    }

    this.onClickModuleHandler = this.onClickModuleHandler.bind(this);
    this.changeLang = this.changeLang.bind(this);
    this.getSystemLangs = this.getSystemLangs.bind(this);
  }

  changeLang(lang){
    this.setState({isLoading: true});
    var array = {
      'language' : lang,
    }
    let request = DashboardRequests.postChangeSystemLang(array);
    request.then(function(response){
      localStorage.setItem('lang', lang);
      i18n.changeLanguage(lang);
      return this.props.history.push('/panel');
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }



  componentDidMount(){
    moment.locale(localStorage.getItem('lang'));
    moment.tz("Europe/Warsaw")
    this.getSystemLangs();
    this.changeLang(localStorage.getItem('lang'));
    this.checkLocalStorageTokenExists();
    var interval = setInterval(this.checkLocalStorageTokenExists.bind(this), 5000);
    this.setState({interval: interval});
  }

  componentWillUnmount(){
    clearInterval(this.state.interval);
  }

  getSystemLangs(){
    let request = DashboardRequests.getSystemLangs();
    request.then(function(response){
      this.setState({langs: response.data.data});
    }.bind(this));

    request.catch(function(error){}.bind(this));
    request.finally(function(){}.bind(this));
  }

  onClickModuleHandler(event, name, params = []){
    event.preventDefault();
    if(name === 'logout'){
      localStorage.removeItem('token');
      localStorage.removeItem('objectID');
      localStorage.removeItem('lastLoginTime');
      localStorage.removeItem('lang');
      clearInterval(this.state.interval);
      this.props.history.push('/');
    }
    else{
        this.setState({activeModule: name, moduleParams: params})
    }

  }

  checkLocalStorageTokenExists(){
    if(localStorage.getItem('token') === null || localStorage.getItem('token') === '' || localStorage.getItem('token')['error'] === 'expired'){
      this.props.history.push('/');
    }
    else{
      this.checkUserToken();
    }
  }

  checkUserToken(){
    this.setState({loadingUserData: true});

    var array = {
      'objectID' : localStorage.getItem('objectID')
    }

    let request = DashboardRequests.getUser(array);
    request.then(function(response){
      if(response.data.needToAgree === false){
        this.setState({user: response.data})
      }
      else{
        localStorage.removeItem('token');
        localStorage.removeItem('objectID');
        localStorage.removeItem('lastLoginTime');
        localStorage.removeItem('lang');
        this.props.history.push('/');
      }
    }.bind(this));

    request.catch(function(error){
      localStorage.removeItem('token');
      localStorage.removeItem('objectID');
      localStorage.removeItem('lastLoginTime');
      localStorage.removeItem('lang');
      this.props.history.push('/');
    }.bind(this));

    request.finally(function(){
      this.setState({loadingUserData: false})
    }.bind(this));
  }

  renderMainView(){
    const ModuleComponent = components.find(comp => comp.id === this.state.activeModule).component;
    return(
      <div className="wrapper">
      <TokenExpiredAlert status={this.state.tokenExpiredAlert} />
      <header className="main-header">

          <a href="/panel" className="logo">
              <span className="logo-mini"><b>N</b>K</span>
              <span className="logo-lg"><b>NET</b>kid</span>
          </a>

          <HeaderBarComponent
          user={this.state.user}
          loadingUserData={this.state.loadingUserData}
          onClickModuleHandler={this.onClickModuleHandler}
          changeLang = {this.changeLang}
          langs={this.state.langs}
          />

      </header>

      <AsideNavigationComponent user={this.state.user} onClickModuleHandler={this.onClickModuleHandler} lang={localStorage.getItem('lang')} activeModule={this.state.activeModule}/>

      <div className="content-wrapper">

        <section className="content-header">
          <div className="row">

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

              <ModuleComponent
                activeModule={this.state.activeModule}
                currentUser={this.state.user}
                onClickModuleHandler={this.onClickModuleHandler}
                moduleParams={this.state.moduleParams}
                lang={localStorage.getItem('lang')}
              />

            <section className="content container-fluid">

              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-6 col-xs-12">

                  <div className="info-box">
                    <span className="info-box-icon bg-purple"><i className="fas fa-cookie-bite"></i></span>

                    <div className="info-box-content">
                      <span className="info-box-number">
                        <h3>{i18n.t('privacy_policy')}<br/><small>{i18n.t('this_site_uses_cookies')} <a href="/polityka-prywatnosci" target="_blank">{i18n.t('check_privacy_policy')}</a></small></h3>
                      </span>
                    </div>
                  </div>

                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">

                  <div className="info-box cursor" onClick={(event) => this.onClickModuleHandler(event, 'bugsReport')}>
                    <span className="info-box-icon bg-teal"><i className="fas fa-headset"></i></span>

                    <div className="info-box-content">
                      <span className="info-box-number">
                        <h3>{i18n.t('infoline_module')}<br/><small>{i18n.t('infoline_module_subtile')}</small></h3>
                      </span>
                    </div>
                  </div>

                </div>

              </div>

            </section>


            </div>
            <div className="col-lg-3 col-md-0 col-sm-0 col-xs-0">
              <section className="content-header">

                <NotificationsWidget user={this.state.user} onClickModuleHandler={this.onClickModuleHandler} loadingUserData={this.state.loadingUserData}/>

              </section>
            </div>

          </div>


        </section>



      </div>




      <FooterComponent />


    </div>

    );
  }

  render(){
    var view = <LoaderComponent />;
    if(this.state.isLoading === false){
      view = this.renderMainView();
    }

    return(<div>{view}</div>)
  }

}

export default DashboardComponent;
