import React, {Component} from 'react';

import section from '../../../Img/first_section.png';

export default class HomeFirstComponent extends Component{

  render(){
    return(
      <div className="mp_headerWrapper">
        <div className="container">

          <img src={section} className="img-responsive" />

        </div>
      </div>
    );
  }

}
