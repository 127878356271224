import React, {Component} from 'react';

import GroupsListComponent from '../GroupsListComponent';
import CreateGroupComponent from '../CreateGroupComponent';
import GroupProfile from '../GroupProfile/InitialComponent';

import i18n from "i18next";


export default
class InitialComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'list',
      currentGroupID: 0,
    }

    this.changeView = this.changeView.bind(this)
  }

  changeView(view, id){
    this.setState({view: view, currentGroupID: id});
  }

  getSubComponentView(){
    var view = null;
    switch (this.state.view) {
      case 'list':
        view = <GroupsListComponent setAccessDenied={this.props.setAccessDenied} changeView={this.changeView}/>
        break;

      case 'create':
        view = <CreateGroupComponent setAccessDenied={this.props.setAccessDenied}/>
        break;

      case 'edit':
        view = <GroupProfile setAccessDenied={this.props.setAccessDenied} groupID={this.state.currentGroupID}/>
        break;



    }
    return (<div>{view}</div>);
  }

  render(){
    var view = this.getSubComponentView();
    return(
      <div className="row">
        <div className="col-md-3 col-sm-4 col-xs-6">
          <div className="box box-solid">
            <div className="box-body no-padding">
              <ul className="nav nav-pills nav-stacked">
                <li className={this.state.view === 'list' ? "active" : null}><a onClick={(event) => this.setState({view: 'list'})} href="#">{i18n.t('groups_list')}</a></li>
                <li className={this.state.view === 'create' ? "active" : null}><a onClick={(event) => this.setState({view: 'create'})} href="#">{i18n.t('create_new_group')}</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-8 col-xs-12">{view}</div>
      </div>


    );
  }

}
