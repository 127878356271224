import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';

export default
class ListComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      isLoading: false,
      status: '',
      msg: null,
      view: 'list',
      items: [],
    }
  }

  componentDidMount(){
    this.getList()
  }

  getList(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getGalleryList(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderTableView(){
    return(
      <div>
        {
          this.state.items.map(function(item){
            return(
              <div key={item.id}>
                <div className="media cursor" onClick={(event) => this.props.changeView('edit', item.id)}>
                  <div className="media-left">
                    <span className="info-box-icon bg-red"><i className="fas fa-images"></i></span>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">{item.title}</h4>
                    <p>{item.description}</p>
                    <p><small>{i18n.t('photos_count')}: <b>{item.photos}</b>, {i18n.t('cdate')}: <b>{item.cdate}</b></small></p>
                  </div>
                </div>
                <hr/>
              </div>
            )
          }.bind(this))
        }
      </div>
    )
  }

  renderListView(){
    var view = helperFunctions.renderNoContentInfo();
    if(this.state.items !== undefined && this.state.items.length > 0){
      view = this.renderTableView()
    }
    return(
      <div>
        {view}
      </div>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView()
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
