import React, {Component} from 'react';

import LoaderComponent from '../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../acl.js';
import * as requests from '../../requests.js';
import * as helperFunctions from '../../../../../Utils/HelperFunctions.js';

export default class UsersInfoExportComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'form',
      isLoading: false,
      status: '',
      msg: null,
      items: [],
      groups: [],
      selectedGroupsList: [],
      validationErrors: [],
    }

  }

  componentDidMount(){
    this.getInfo();
  }

  generateReport = (event) => {
    this.setState({isLoading: true, status: '', msg: null,});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'groups' : this.state.selectedGroupsList,
    }

    let request = requests.postIOUsersInfoGenerateReport(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data.reports,
          selectedGroupsList: [],
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error), selectedGroupsList: []});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getInfo(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getIOUsersInfoExports(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data.reports,
          groups: response.data.groups
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  changeState(event, type){
    if(type === 'file'){
      this.setState({file: event.target.files})
    }
  }

  downloadReport = (event, hash) => {
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'hash' : hash
    }

    let request = requests.getIOUsersInfoReportDownload(array);
    request.then(function(response){
      const type = response.headers['content-type']
      const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = hash + '.xlsx'
      link.click()
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  manageGroupsList = (event, id) => {
    var index = this.state.selectedGroupsList.indexOf(id);
    if(index > -1){
      var list = this.state.selectedGroupsList;
      delete list[index];
      this.setState({selectedGroupsList: list});
    }
    else{
      var list = this.state.selectedGroupsList;
      list.push(id);
      this.setState({selectedGroupsList: list});
    }
  }

  renderReportsList(){
    return(
      <div className="reportsList">
        <ul className="list-unstyled">
          {
            this.state.items.map(function(item, index){
              return(
                <li key={index}>Raport z dnia: <strong>{item.cdate}</strong> <a href="#" onClick={(event) => this.downloadReport(event, item.hash)}>Pobierz</a></li>
              );
            }.bind(this))
          }
        </ul>
      </div>
    );
  }

  renderGroupsList(){
    return(
      <div>

        <div className="info-box">
            <span className="info-box-icon bg-aqua"><i className="fas fa-info"></i></span>

            <div className="info-box-content">
              <span className="info-box-text">Informacja systemowa</span>
              <span>Aby wygenerować dane użytkowników musisz zaznaczyć conajmniej jedną grupę.</span>
            </div>
        </div>

        <form onSubmit={(event) => this.generateReport(event)}>

          <ul>
            {
              this.state.groups.map(function(item, index){
                return(
                  <li key={index}><input type="checkbox" name="group[]" onClick={(event) => this.manageGroupsList(event, item.id)} value={item.id} /> {item.name}</li>
                );
              }.bind(this))
            }
          </ul>
          <button type="submit" className="btn btn-danger" >Wygeneruj raport</button>

        </form>
      </div>
    )
  }

  renderFormView(){
    var reportsList = null;
    var groupsList = null;
    if(this.state.groups.length > 0){
      groupsList = this.renderGroupsList();
    }
    else{
      groupsList = <p>Brak grup przypisanych do tego przedszkola</p>
    }
    if(this.state.items.length > 0){
      reportsList = this.renderReportsList()
    }
    else{
      reportsList = <p>Aktualnie nie posiadasz żadnych zarchiwizowanych raportów. Aby pobrać, kliknij w button <strong>Wygeneruj raport</strong></p>
    }
    return(
      <div>

        <div className="info-box">
          <span className="info-box-icon bg-red">
            <i className="fas fa-cloud-upload-alt"></i>
          </span>
          <div className="info-box-content">
            <span className="info-box-number">
              <small>
                {i18n.t('users_info_export_title_1')}.<br/>{i18n.t('users_info_export_title_2')}
              </small>
            </span>
          </div>
        </div>

        <br/>

          <div className="panel panel-default">
            <div className="panel-heading">Formularz generowania raportów</div>
            <div className="panel-body">

              {groupsList}

            </div>
          </div>

          <div className="panel panel-default">
            <div className="panel-heading">Lista wygenenerowanych raportów</div>
            <div className="panel-body">
              <p>Funkcja generowania raportów jest buforowana - system każdorazowo zapisuje i przechowywuje wygenerowany raport, możesz go pobrać bez konieczności tworzenia nowego, jednakże jeżeli w Twoim przedszkolu pojawiły się nowe konta to wskazane jest wygenerować i pobrać nowy raport. </p>
              <hr/>{reportsList}
            </div>
          </div>


        <hr/>






        <hr/>

      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'form'){
        view = this.renderFormView()
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
