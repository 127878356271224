import React, {Component} from 'react';

import LoaderComponent from '../../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../../acl.js';
import * as requests from '../../../requests.js';
import * as helperFunctions from '../../../../../../Utils/HelperFunctions.js';

export default
class KidsComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      status: '',
      type: 0,
      msg: null,
      isLoading: false,
      kids: [],
      validationErrors: [],
    }

  }

  componentDidMount(){
    this.getTeacherDetails();
  }

  getTeacherDetails(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.teacherID,
    }

    let request = requests.getTeacherRelatedInfo(array);
    request.then(function(response){
      this.setState(
        {
          kids: response.data.kids,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderListView(){
    return(
      <div>

      <div className="info-box">
        <span className="info-box-icon bg-aqua">
          <i className="fas fa-info-circle"></i>
        </span>
        <div className="info-box-content">
          <span className="info-box-number">
            <h3>{i18n.t('system_info_alert')}<br/><small>{i18n.t('system_info_alert_1')}</small></h3>
          </span>
        </div>
      </div>


        <table className="table table-border table-hover">

          <thead>
            <tr>
              <th>{i18n.t('avatar')}</th>
              <th>{i18n.t('name_and_surname')}</th>
              <th>{i18n.t('group')}</th>
            </tr>
          </thead>

          <tbody>
            {
              this.state.kids.map(function(item){
                return(
                  <tr key={item.id}>
                    <td><img className="img-circle img-md smallAvatar" src={item.image} /></td>
                    <td>{item.name} {item.surname}</td>
                    <td>{item.groupName}</td>
                  </tr>
                )
              }.bind(this))
            }
          </tbody>

        </table>
      </div>

    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderListView()
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
