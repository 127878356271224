import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import MainPageComponent from './Components/MainPageComponent';
import RegisterComponent from './Components/Auth/RegisterComponent';
import DashboardComponent from './Components/Panel/DashboardComponent';
import RemindPassword from './Components/Auth/RemindPassword';
import RestorePassword from './Components/Auth/RestorePassword';
import AssingObjectRelation from './Components/Auth/AssingObjectRelation';
import InvitationsFromParentsComponent from './Components/Auth/AcceptInvitationFromParentComponent';
import Regulations from './Components/Common/RegulationsComponent';
import CookiesInfoComponent from './Components/Common/CookiesInfoComponent';

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import * as moment from "moment";

import 'moment/locale/pl.js';
import lang from 'date-fns/locale/en-GB';
registerLocale('lang', lang);

function App() {
  return (
    <Router>
      <Route path="/" exact component={MainPageComponent} />
      <Route path="/login" exact component={MainPageComponent} action={"login"}/>
      <Route path="/nauczyciel" exact component={MainPageComponent} action={"for_teacher"} />
      <Route path="/rejestracja" component={RegisterComponent} />
      <Route path="/restore-password/:hash" component={RestorePassword} />
      <Route path="/accept-invitation-from-parent/:hash" component={InvitationsFromParentsComponent} />
      <Route path="/assign-account-object-realtion/:hash" component={AssingObjectRelation} />
      <Route path="/panel" component={DashboardComponent} />
      <Route path="/regulamin-korzystania-z-uslugi" component={Regulations} />
      <Route path="/polityka-prywatnosci" component={CookiesInfoComponent} />
    </Router>
  );
}

export default App;
