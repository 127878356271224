import React, {Component} from 'react';

import LoaderComponent from '../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import * as requests from '../requests.js';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';

export default
class EmployeesComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      items: {
        principal: [],
        administration: [],
        teachers: []
      },
      isLoading: false,
      status: '',
      msg: null,
    }
  }

  componentDidMount(){
    this.getEmployeesList();
  }

  componentDidUpdate(prevProps){
    if(prevProps.reloadList !== this.props.reloadList){
      this.props.finishListReloadHandler(false);
      this.getEmployeesList();
      return true;
    }
    return false;
  }

  getEmployeesList(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
    }

    let request = requests.getProfileEmployees(array);
    request.then(function(response){
      this.setState({items: response.data});
    }.bind(this));

    request.catch(function(error){
      this.setState({items: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderEmployeeItem(elem){
    var bio = null;
    var spec = null;
    var func = null;

    if(elem !== null && elem.values.employee_bio.length && elem.values.employee_bio !== undefined){
      bio = elem.values.employee_bio.map(function(bio){
        return(<div dangerouslySetInnerHTML={{__html: "<h5><strong>"+ i18n.t('about_me') + ":</strong></h5>" + bio.content + "<br/><br/>"}} />)
      }.bind(this))
    }
    if(elem !== null && elem.values.employee_spec.length && elem.values.employee_spec !== undefined){
      spec = elem.values.employee_spec.map(function(bio){
        return(<div dangerouslySetInnerHTML={{__html: "<h5><strong>"+ i18n.t('spec') + ":</strong></h5>" + bio.content + "<br/><br/>"}} />)
      }.bind(this))
    }
    if(elem !== null && elem.values.employee_func.length && elem.values.employee_func !== undefined){
      func = elem.values.employee_func.map(function(bio){
        return(<div dangerouslySetInnerHTML={{__html: "<h5><strong>"+ i18n.t('my_functions') +":</strong></h5>" + bio.content + "<br/><br/>"}} />)
      }.bind(this))
    }
    if(elem !== null){
      return(
        <div key={elem.id}>
          <div className="media">
            <div className="media-left">
              <img className="media-object img-lg img-circle" src={elem.avatar} alt="" />
            </div>
            <div className="media-body">
              <h4 className="media-heading">{elem.name}</h4>
              {func}
              {spec}
              {bio}
            </div>
          </div>
          <hr/>
        </div>
      );
    }
    else{
      return (<div></div>)
    }
  }

  renderMainView(){
    return (
      <div className="box-group" id="accordion">
        <div className="panel box box-success">
          <div className="box-header with-border">
            <h4 className="box-title">
              <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                {i18n.t('principal_accounts')}
              </a>
            </h4>
          </div>
          <div id="collapse1" className="panel-collapse collapse in">
            <div className="box-body">
              {
                this.state.items.principal.map(function(elem){
                  return this.renderEmployeeItem(elem);
                }.bind(this))
              }
            </div>
          </div>
        </div>
        <div className="panel box box-success">
          <div className="box-header with-border">
            <h4 className="box-title">
              <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">
                {i18n.t('administration_accounts')}
              </a>
            </h4>
          </div>
          <div id="collapse2" className="panel-collapse collapse">
            <div className="box-body">
            {
              this.state.items.administration.map(function(elem){
                return this.renderEmployeeItem(elem);
              }.bind(this))
            }
            </div>
          </div>
        </div>
        <div className="panel box box-success">
          <div className="box-header with-border">
            <h4 className="box-title">
              <a data-toggle="collapse" data-parent="#accordion" href="#collapse3">
                {i18n.t('teachers_accounts')}
              </a>
            </h4>
          </div>
          <div id="collapse3" className="panel-collapse collapse">
            <div className="box-body">
            {
              this.state.items.teachers.map(function(elem){
                return this.renderEmployeeItem(elem);
              }.bind(this))
            }
            </div>
          </div>
        </div>
      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderMainView();
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
        {view}
      </div>
    );
  }

}
