import React, {Component} from 'react';

import principal from '../../../Img/dyrekcja.png';
import teacher from '../../../Img/nauczycielka.png';
import parent from '../../../Img/ociec.png';


export default class PageComponent extends Component{

  constructor(props){
    super(props)

    this.state = {
      section: 'about'
    }
  }

  componentDidMount(){
    this.setState({section: this.props.section})
  }

  componentWillUpdate(nextProps, nextState){
    if(nextProps.section !== this.props.section){
      this.setState({section: nextProps.section});
    }
  }

  renderForTeacherInfo(){
    return(
      <div className="row">
        <div className="col-xs-12 col-sm-0 col-md-6 col-lg-6 hidden-sm">
          <img src={teacher} className="img-responsive"/>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <h2>Czym jest NETKid?</h2>

          <p>
            NETkid jest systemem, ułatwiającym komunikację z rodzicami. Zapewne w przedszkolu nie raz okazywało się, że planowane zajęcia nie do końca się udają – bo dzieci nie przyniosły np. włóczki czy pocztówki z rodzinnego miasta. „Ojej, zapomniałem powiedzieć tatusiowi…”. „Mama nie dała mi pocztówki bo w domu dziś rano żadnej nie znalazła”.
          </p>

          <p>
            Dzięki NETkidowi można uniknąć podobnych sytuacji. Nasz system to rodzaj elektronicznej „tablicy korkowej”, do której rodzice mają zawsze dostęp. Co więcej, nauczycielki mają pewność, że dany komunikat został odczytany – po prostu widzą w systemie, czy dany rodzic czy opiekun zapoznał się z komunikatem. Dzięki temu ma się pewność, że każdy przyniesie na czwartek kasztany!
          </p>

          <div className="box box-solid">

            <div className="box-body">
              <div className="box-group" id="accordion1">
                <div className="panel box box-primary">
                  <div className="box-header with-border">
                    <h4 className="box-title">
                      <a data-toggle="collapse" data-parent="#accordion1" href="#collapse1_One">
                        Jakie są główne funkcjonalności systemu?
                      </a>
                    </h4>
                  </div>
                  <div id="collapse1_One" className="panel-collapse collapse in">
                    <div className="box-body">
                      <p>Dzięki systemowi, nauczyciel może:</p>
                      <ul>
                        <li>Sprawdzać obecność, przechowywać dane dotyczące uczestnictwa dzieci w zajęciach</li>
                        <li>Mieć w zasięgu ręki ważne informacje o dziecku, np. datę urodzin czy informacje dot. ewentualnych uczuleń.</li>
                        <li>Być na bieżąco informowanym o nieobecności dzieci w grupie wraz terminem powrotu do przedszkola, który deklaruje rodzic czy opiekun.</li>
                        <li>Wysyłać informacje o planowanych wydarzeniach w szkole – np. wyjściu do kina czy zabawie świątecznej.</li>
                        <li>Tworzyć galerię zdjęć grupy, dostępną dla rodziców.</li>
                        <li>Zadawać pytania ankietowe rodzicom, prosząc o wybór jednej z kilku opcji – np. czy wychodzimy w piątek na spacer do parku, na plac zabaw czy odwiedzamy zaprzyjaźnione przedszkole.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="panel box box-primary">
                  <div className="box-header with-border">
                    <h4 className="box-title">
                      <a data-toggle="collapse" data-parent="#accordion1" href="#collapse1_Two">
                        Co jeszcze oferuje NETkid?
                      </a>
                    </h4>
                  </div>
                  <div id="collapse1_Two" className="panel-collapse collapse">
                    <div className="box-body">

                      <p>NETkid daje dodatkowe funkcjonalności, które mogą być wykorzystane dodatkowo w przedszkolu:</p>
                      <ul>
                        <li>Wprowadzenie jadłospisu na dany tydzień.</li>
                        <li>Informacja o godzinach posiłków w przedszkolu.</li>
                        <li>Bazę wiedzy, czyli zbiór ciekawych materiałów, przydatnych w przedszkolach.</li>
                      </ul>

                    </div>
                  </div>
                </div>
                <div className="panel box box-primary">
                  <div className="box-header with-border">
                    <h4 className="box-title">
                      <a data-toggle="collapse" data-parent="#accordion1" href="#collapse1_Three">
                        Zgodność systemu NETKid z programem „Żyrafa Ola i przyjaciele”
                      </a>
                    </h4>
                  </div>
                  <div id="collapse1_Three" className="panel-collapse collapse">
                    <div className="box-body">

                      <p>
                        Do systemu NetKid została dodana elektroniczna wersja propozycji wpisów do dziennika. W ten sposób nauczyciel prowadzący może z łatwością zaakceptować zdefiniowane na dany dzień zajęcia, wybrać inną kartę, zmienić istniejącą – lub stworzyć od początku własny wpis.
                      </p>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>



        </div>
      </div>
    );
  }

  renderForPrincipalInfo(){
    return(
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <img src={principal} className="img-responsive"/>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <h2>Czym jest NETKid?</h2>

          <p>
            NETkid jest systemem zgodnym z wymaganiami RODO, ułatwiającym komunikację z rodzicami oraz przechowywanie informacji o dzieciach, rodzicach i opiekunach. Jest również elektronicznym dziennikiem obecności, a także umożliwia wpisy dotyczące przebiegu codziennych zajęć. Zawiera również zdefiniowane propozycje wpisów do dziennika, opracowanych dla programu „Żyrafa Ola i przyjaciele”.
          </p>

          <div className="box box-solid">

            <div className="box-body">
              <div className="box-group" id="accordion2">
                <div className="panel box box-primary">
                  <div className="box-header with-border">
                    <h4 className="box-title">
                      <a data-toggle="collapse" data-parent="#accordion2" href="#collapse2_One">
                        Co zrobić, by wprowadzić NETKid do przedszkola?
                      </a>
                    </h4>
                  </div>
                  <div id="collapse2_One" className="panel-collapse collapse in">
                    <div className="box-body">
                      <p>Należy skontaktować się z przedstawicielem / ambasadorem wydawnictwa Klett w celu podpisania umowy korzystania oraz powierzenia i przetwarzania danych osobowych, wymaganych przez RODO. Lista przedstawicieli Klett dostępna jest pod tym linkiem: <a href="https://klett.pl/kontakt" target="_blank">https://klett.pl/kontakt</a>.</p>
                    </div>
                  </div>
                </div>
                <div className="panel box box-primary">
                  <div className="box-header with-border">
                    <h4 className="box-title">
                      <a data-toggle="collapse" data-parent="#accordion2" href="#collapse2_Two">
                        Darmowe wdrożenie systemu
                      </a>
                    </h4>
                  </div>
                  <div id="collapse2_Two" className="panel-collapse collapse">
                    <div className="box-body">
                      <p>
                        Obecnie system jest oferowany nieodpłatnie przedszkolom oraz rodzicom tych przedszkolaków, w których wykorzystywany jest program „Żyrafa Ola i przyjaciele”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel box box-primary">
                  <div className="box-header with-border">
                    <h4 className="box-title">
                      <a data-toggle="collapse" data-parent="#accordion2" href="#collapse2_Three">
                        Co zyskuje przedszkole dzięki wdrożeniu systemu NETkid w swoim przedszkolu?
                      </a>
                    </h4>
                  </div>
                  <div id="collapse2_Three" className="panel-collapse collapse">
                    <div className="box-body">
                      <p>Wprowadzając NETkid do przedszkola:</p>
                      <ul>
                        <li>Masz pewność, że posługujesz się narzędziem zgodnym z wymaganiami RODO w dziedzinie przetwarzania danych osobowych</li>
                        <li>Możesz kontaktować się z wybranymi grupami – rodziców czy opiekunów wszystkich dzieci w przedszkolu</li>
                        <li>Masz błyskawiczny wgląd w przebieg zajęć przedszkolnych</li>
                        <li>Wiesz, jaka jest frekwencja uczestnictwa w zajęciach</li>
                        <li>Pomagasz kadrze nauczycielskiej  w prowadzeniu elektronicznego dziennika zajęć</li>
                        <li>Możesz efektywnie zarządzać personelem</li>
                        <li>Oszczędzasz czas</li>
                        <li>Zwiększasz atrakcyjność przedszkola w oczach rodziców / opiekunów</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="panel box box-primary">
                  <div className="box-header with-border">
                    <h4 className="box-title">
                      <a data-toggle="collapse" data-parent="#accordion2" href="#collapse2_Four">
                        Jakie są główne funkcjonalności systemu dla dyrekcji?
                      </a>
                    </h4>
                  </div>
                  <div id="collapse2_Four" className="panel-collapse collapse">
                    <div className="box-body">
                      <p>Dzięki systemowi, dyrekcja może:</p>
                      <ul>
                        <li>Mieć wgląd w obecność dzieci w przedszkolu, przechowywać dane dotyczące uczestnictwa dzieci w zajęciach</li>
                        <li>Mieć w zasięgu ręki ważne informacje o dziecku, np. datę urodzin czy informacje dot. ewentualnych uczuleń.</li>
                        <li>Wysyłać informacje o planowanych wydarzeniach w przedszkolu  – np. wyjściu do kina czy zabawie świątecznej.</li>
                        <li>Przekazywać informacje dla rodziców</li>
                        <li>Informować o akcjach społecznych </li>
                        <li>Prezentować rytm dnia pracy przedszkola</li>
                        <li>Informować o dyżurze wakacyjnym</li>
                        <li>Chwalić się certyfikatami i wyróżnieniami</li>
                        <li>Przedstawiać programy i projekty, statut i regulaminy</li>
                        <li>Jadłospisy</li>
                        <li>Ankiety</li>
                        <li>Wydarzenia</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="panel box box-primary">
                  <div className="box-header with-border">
                    <h4 className="box-title">
                      <a data-toggle="collapse" data-parent="#accordion2" href="#collapse2_Five">
                        Co jeszcze oferuje NETkid?
                      </a>
                    </h4>
                  </div>
                  <div id="collapse2_Five" className="panel-collapse collapse">
                    <div className="box-body">
                      <p>NETkid daje dodatkowe funkcjonalności, które mogą być wykorzystane dodatkowo w przedszkolu:</p>
                      <ul>
                        <li>Wprowadzenie jadłospisu na dany tydzień.</li>
                        <li>Informacja o godzinach posiłków w przedszkolu.</li>
                        <li>Bazę wiedzy, czyli zbiór ciekawych materiałów, przydatnych w przedszkolach.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    );
  }

  renderForParentInfo(){
    return(
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <img src={parent} className="img-responsive"/>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <h2>Czym jest NETKid?</h2>

          <p>
            NETkid jest systemem zgodnym z wymaganiami RODO, ułatwiającym komunikację z przedszkolem. Jest elektroniczną wersją „tablicy korkowej”, dzięki której nie umknie rodzicowi informacja o zbliżającym się zebraniu czy prośbie o przyniesienie na poniedziałek kawałków kolorowej wełny czy pocztówki z rodzinnego miasta.
          </p>
          <p>
            System jest dostępny poprzez stronę internetową oraz jako aplikacja na urządzenia mobline.
          </p>

          <div className="box box-solid">

            <div className="box-body">
              <div className="box-group" id="accordion3">
                <div className="panel box box-primary">
                  <div className="box-header with-border">
                    <h4 className="box-title">
                      <a data-toggle="collapse" data-parent="#accordion3" href="#collapse3_One">
                        W jaki sposób NETkid pomaga rodzicom?
                      </a>
                    </h4>
                  </div>
                  <div id="collapse3_One" className="panel-collapse collapse in">
                    <div className="box-body">
                      <p>Dzięki systemowi rodzic może:</p>
                      <ul>
                        <li>Być w ciągłym kontakcie z wychowawcą – możliwość otrzymywania powiadomień na komórkę dot. dziecka w przedszkolu</li>
                        <li>Zgłaszać nieobecności dzieci.</li>
                        <li>Sprawdzać informacje ogólnoprzedszkolne.</li>
                        <li>Mieć wgląd w prace grupy w przedszkolu (elektroniczny dziennik zajęć)</li>
                        <li>Przeglądać zdjęcia z wydarzeń przedszkolnych</li>
                        <li>Brać udział w ankietach grupowych, mając tym samym wpływ na życie grupy przedszkolnej</li>
                      </ul>

                    </div>
                  </div>
                </div>
                <div className="panel box box-primary">
                  <div className="box-header with-border">
                    <h4 className="box-title">
                      <a data-toggle="collapse" data-parent="#accordion3" href="#collapse3_Two">
                        Co zrobić, by rozpocząć korzystanie z systemu NETkid?
                      </a>
                    </h4>
                  </div>
                  <div id="collapse3_Two" className="panel-collapse collapse">
                    <div className="box-body">
                      <p>
                        Należy przyjąć zaproszenie z przedszkola, które zostanie wysłane na adres emailowy, podany do kontaktu. Następnie wystarczy zaakceptować regulamin systemu – i już można zacząć pracować z systemem.
                      </p>
                      <p>
                        Można również zainstalować aplikację, dostępną w Google Play pod adresem: <a href="https://play.google.com/store/apps/details?id=com.netkid" target="_blank">Link do Google Play</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel box box-primary">
                  <div className="box-header with-border">
                    <h4 className="box-title">
                      <a data-toggle="collapse" data-parent="#accordion3" href="#collapse3_Three">
                        Koszty systemu
                      </a>
                    </h4>
                  </div>
                  <div id="collapse3_Three" className="panel-collapse collapse">
                    <div className="box-body">
                      <p>
                        Obecnie system jest oferowany nieodpłatnie przedszkolom oraz rodzicom tych przedszkolaków, w których wykorzystywany jest program „Żyrafa Ola i przyjaciele”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    );
  }

  renderWhatToDoInfo(){
    return(
      <div>Co zrobić</div>
    );
  }

  render(){
    var view = null;
    if(this.state.section === 'for_teacher'){
      view = this.renderForTeacherInfo();
    }
    else if(this.state.section === 'for_principal'){
      view = this.renderForPrincipalInfo();
    }
    else if(this.state.section === 'for_parent'){
      view = this.renderForParentInfo();
    }
    else if(this.state.section === 'what_to_do'){
      view = this.renderWhatToDoInfo();
    }

    return(
      <div className="mp_pageContent">
        <div className="container">
          {view}
        </div>
      </div>
    );
  }

}
