import React, {Component} from 'react';

import ListComponent from '../ListComponent';
import EditComponent from '../Edit/InitialComponent';
import CreateComponent from '../CreateComponent';

import i18n from "i18next";

export default
class InitialComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      view: 'list',
      currentGalleryID: 0,
    }

    this.changeView = this.changeView.bind(this)
  }

  changeView(view, id){
    this.setState({view: view, currentGalleryID: id});
  }

  getSubComponentView(){
    var view = null;
    switch (this.state.view) {
      case 'list':
        view = <ListComponent setAccessDenied={this.props.setAccessDenied} changeView={this.changeView}/>
        break;

      case 'create':
        view = <CreateComponent setAccessDenied={this.props.setAccessDenied} changeView={this.changeView}/>
        break;

      case 'edit':
        view = <EditComponent setAccessDenied={this.props.setAccessDenied} galleryID={this.state.currentGalleryID} changeView={this.changeView}/>
        break;

    }
    return (<div>{view}</div>);
  }

  render(){
    var view = this.getSubComponentView();
    return(
      <div className="row">
        <div className="col-md-3 col-sm-4 col-xs-6">
          <div className="box box-solid">
            <div className="box-body no-padding">
              <ul className="nav nav-pills nav-stacked">
                <li className={this.state.view === 'list' ? "active" : null}><a onClick={(event) => this.setState({view: 'list'})} href="#">{i18n.t('gallery_list')}</a></li>
                <li className={this.state.view === 'create' ? "active" : null}><a onClick={(event) => this.setState({view: 'create'})} href="#">{i18n.t('create_new_gallery')}</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-8 col-xs-12">{view}</div>
      </div>


    );
  }

}
