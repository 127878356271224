import React, {Component} from 'react';

import LoaderComponent from '../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../acl.js';
import * as requests from '../requests.js';
import * as helperFunctions from '../../../../Utils/HelperFunctions.js';

export default
class ContactComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      main: [],
      other: [],
      isLoading: false,
      status: '',
      msg: null,
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.reloadList !== this.props.reloadList){
      this.props.finishListReloadHandler(false);
      return true;
    }
    return false;
  }

  componentDidMount(){
    this.getObjectContact();
  }

  getObjectContact(){
    this.setState({isLoading: true});
    var array = {
      'objectID' : localStorage.getItem('objectID'),
    }

    let request = requests.getObjectBranches(array);
    request.then(function(response){
      this.setState({main: response.data.main, other: response.data.other});
    }.bind(this));

    request.catch(function(error){
      this.setState({items: []});
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderBranchInfo(item){
    return(
      <div>
        <ul className="listy-unstyled">
          <li>{i18n.t('city')}: <strong>{item.city}</strong></li>
          <li>{i18n.t('address')}: <strong>{item.street}</strong></li>
          <li>{i18n.t('postal_code')}: <strong>{item.postal}</strong></li>
          <li>{i18n.t('phone_number')}: <strong>{item.phone}</strong></li>
          <li>{i18n.t('fax_number')}: <strong>{item.fax}</strong></li>
          <li>{i18n.t('nip')}: <strong>{item.nip}</strong></li>
          <li>{i18n.t('regon')}: <strong>{item.regon}</strong></li>
          <li>{i18n.t('krs')}: <strong>{item.krs}</strong></li>
          <li>{i18n.t('email_adress')}: <strong><a href={"mailto:" + item.contact_email}>{item.contact_email}</a></strong></li>
          <li>{i18n.t('website')}: <strong><a href={item.website} target="_blank">{item.website}</a></strong></li>
          <li>{i18n.t('facebook_profile_url')}: <strong><a href={item.fb_profile} target="_blank">{item.fb_profile}</a></strong></li>
          <li>{i18n.t('bank_acc')}: <strong>{item.bank_acc}</strong></li>
        </ul>
      </div>
    );
  }

  renderMainView(){
    return (
      <div>

        <div className="box box-solid">
            <div className="box-header with-border">
              <h3 className="box-title">{i18n.t('school_and_branches')}</h3>
            </div>

            <div className="box-body">
              <div className="box-group" id="accordion">

                {
                  this.state.main.map(function(item){
                    return(
                      <div className="panel box box-primary">
                        <div className="box-header with-border">
                          <h4 className="box-title">
                            <a data-toggle="collapse" data-parent="#accordion" href={"#" + item.key}>
                              {i18n.t('school_contact_info')}
                            </a>
                          </h4>
                        </div>
                        <div id={item.key} className="panel-collapse collapse in">
                          <div className="box-body">
                            {this.renderBranchInfo(item)}
                          </div>
                        </div>
                      </div>
                    )
                  }.bind(this))
                }

                {
                  this.state.other.map(function(item){
                    return(
                      <div className="panel box box-success">
                        <div className="box-header with-border">
                          <h4 className="box-title">
                            <a data-toggle="collapse" data-parent="#accordion" href={"#" + item.key}>
                              {i18n.t('branch_in_city')}: {item.city}
                            </a>
                          </h4>
                        </div>
                        <div id={item.key} class="panel-collapse collapse">
                          <div className="box-body">
                            {this.renderBranchInfo(item)}
                          </div>
                        </div>
                      </div>
                    );
                  }.bind(this))
                }


              </div>
            </div>

          </div>

      </div>
    );
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderMainView();
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
        {view}
      </div>
    );
  }

}
