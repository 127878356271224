import React, {Component} from 'react';

import LoaderComponent from '../../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../../acl.js';
import * as requests from '../../../requests.js';
import * as helperFunctions from '../../../../../../Utils/HelperFunctions.js';
import Select from 'react-select';
import Gallery from 'react-photo-gallery';

export default
class GalleryComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      items: [],
      allGallerySelect: [],
      selectedGallery: 0,
      status: '',
      msg: null,
      view: 'list',
      id: 0,
      title: '',
      description: '',
      galleryImages: [],
    }

    this.handleSelectedGalleryChange = this.handleSelectedGalleryChange.bind(this);
    this.addGallerySubmit = this.addGallerySubmit.bind(this);
    this.deleteGallery = this.deleteGallery.bind(this);
  }

  componentDidMount(){
    this.getGroupGallery();
  }

  handleSelectedGalleryChange(gallery){
    this.setState({selectedGallery: gallery});
  }

  deleteGallery(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.groupID,
      'galleryID' : this.state.id,
    }

    let request = requests.deleteGroupGallery(array);
    request.then(function(response){
      this.setState({view: 'list', status: 'success', msg: response.data.msg}, () => {this.getGroupGallery()})
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getGalleryDetails(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.groupID,
      'galleryID' : this.state.id,
    }

    let request = requests.getGroupGalleryDetails(array);
    request.then(function(response){
      this.setState(
        {
          title: response.data.title,
          description: response.data.description,
          galleryImages: response.data.photos,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  addGallerySubmit(event){
    event.preventDefault();
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.groupID,
      'gallery' : this.state.selectedGallery,
    }

    let request = requests.postGroupGalleryStore(array);
    request.then(function(response){
      this.setState({status: 'success', msg: response.data.msg})
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getGroupGallery(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.groupID,
    }

    let request = requests.getGroupGallery(array);
    request.then(function(response){
      this.setState(
        {
          items: response.data,

        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  getAllGallerySelect(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
    }

    let request = requests.getGallerySelect(array);
    request.then(function(response){
      this.setState(
        {
          allGallerySelect: response.data,

        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderTableView(){
    return(
      <table className="table table-hover">

        <thead>
          <tr>
            <th>Galeria</th>
            <th>Liczba zdjęć</th>
            <th>Data przypisania</th>
          </tr>
        </thead>

        <tbody>
          {
            this.state.items.map(function(item){
              return(
                <tr key={item.id} className="cursor" onClick={(event) => this.setState({view: 'details', id: item.id}, () => this.getGalleryDetails())}>
                  <td>{item.gallery}</td>
                  <td>{item.photos}</td>
                  <td>{item.cdate}</td>
                </tr>
              )
            }.bind(this))
          }
        </tbody>

      </table>
    )
  }

  renderListView(){
    var view = helperFunctions.renderNoContentInfo();
    if(this.state.items !== undefined && this.state.items.length > 0){
      view = this.renderTableView()
    }
    return(
      <div>
        <button className="btn btn-default" onClick={(event) => this.setState({view: 'create', status: '', msg: null}, () => {this.getAllGallerySelect()})}>{i18n.t('create_new_gallery')}</button>
        <hr/>
        {view}
      </div>
    )
  }

  renderCreateView(){
    return(
      <form onSubmit={(event) => this.addGallerySubmit(event)}>

        <div className="form-group">
          <label>{i18n.t('choose_gallery_you_want_to_add')} <span className="text-danger">({i18n.t('input_is_required')})</span></label>
            {
              <Select
                value={this.state.selectedGallery}
                onChange={this.handleSelectedGalleryChange}
                options={this.state.allGallerySelect}
                isMulti={false}
                placeholder={i18n.t('choose_gallery_you_want_to_add')}
              />
            }
        </div>

        <hr/>
        <button className="btn btn-success" type="submit">{i18n.t('button_save')}</button>
        <button className="btn btn-default" onClick={(event) => this.setState({view: 'list', status: '', msg: null}, () => {this.getGroupGallery()})} type="button">{i18n.t('button_cancel')}</button>

      </form>
    );
  }

  renderDetailsView(){
    return(
      <div>
        <button className="btn btn-default" onClick={(event) => this.setState({view: 'list', status: '', msg: null}, () => {this.getGroupGallery()})}>{i18n.t('go_back_to_list')}</button>
        <hr/>
        <h3>{this.state.title}</h3>
        <p>{this.state.description}</p>
        <hr/>
        <button className="btn btn-danger" onClick={(event) => this.deleteGallery(event)}>{i18n.t('remove_gallery_from_this_group')}</button>
        <hr/>
          <Gallery photos={this.state.galleryImages} />
      </div>
    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      if(this.state.view === 'list'){
        view = this.renderListView()
      }
      if(this.state.view === 'create'){
        view = this.renderCreateView()
      }
      if(this.state.view === 'details'){
        view = this.renderDetailsView()
      }

    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
