import React, {Component} from 'react';

import LoaderComponent from '../../../../../../Utils/LoaderComponent';
import i18n from "i18next";
import * as acl from '../../../acl.js';
import * as requests from '../../../requests.js';
import * as helperFunctions from '../../../../../../Utils/HelperFunctions.js';

export default
class DeleteComponent extends Component{

  constructor(props){
    super(props);

    this.state = {
      status: '',
      type: 0,
      msg: null,
      isLoading: false,
      hasKids: false,
      mainRole: 'role_teacher',
      hasGroups: false,
      validationErrors: [],
    }

    this.deleteTeacher = this.deleteTeacher.bind(this);

  }

  componentDidMount(){
    this.getTeacherDetails();
  }

  getTeacherDetails(){
    this.setState({isLoading: true});
    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.teacherID,
    }

    let request = requests.getTeacherDeleteInfo(array);
    request.then(function(response){
      this.setState(
        {
          hasKids: response.data.hasKids,
          mainRole: response.data.mainRole,
          hasGroups: response.data.hasGroups,
        }
      );
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  deleteTeacher(event){
    event.preventDefault();
    this.setState({isLoading: true});

    var array = {
      'school_id' : localStorage.getItem('objectID'),
      'id' : this.props.teacherID,
    }

    let request = requests.postDeleteTeacher(array);
    request.then(function(response){
      this.props.changeView('principal');
    }.bind(this));

    request.catch(function(error){
      if(error.response.data.error === 'access_denied'){
        this.props.setAccessDenied()
      }
      else{
        this.setState({status: 'error', msg: i18n.t(error.response.data.error)});
      }
    }.bind(this));

    request.finally(function(){
      this.setState({isLoading: false});
    }.bind(this));
  }

  renderKidsInfo(){
    var info;
    if(this.state.hasKids === true){
      info = i18n.t('account_delete_has_kids');
    }
    else{
      info = i18n.t('account_delete_has_no_kids');
    }
    return (<p className="text-danger">{info}</p>);
  }

  renderGroupsInfo(){
    var info;
    if(this.state.hasGroups === true){
      info = i18n.t('account_delete_has_groups');
    }
    else{
      info = i18n.t('account_delete_has_no_groups');
    }
    return (<p className="text-danger">{info}</p>);
  }

  renderRoleInfo(){
    var info;
    switch (this.state.mainRole) {
      case 'role_principal':
        info = i18n.t('account_delete_is_principal');
        break;
      case 'role_administration_worker':
        info = i18n.t('account_delete_is_administration');
        break;
      case 'role_teacher':
        info = i18n.t('account_delete_is_teacher');
        break;
    }
    return (<p className="text-danger">{info}</p>);
  }

  renderListView(){
    return(
      <div>

        <div className="info-box">
          <span className="info-box-icon bg-red">
            <i className="fas fa-info-circle"></i>
          </span>
          <div className="info-box-content">
            <span className="info-box-number">
              <h3>{i18n.t('security_alert')}<br/><small>{i18n.t('security_alert_confirm_action')}</small></h3>
            </span>
          </div>
        </div>

        <h3>{i18n.t('account_delete_warnings')}:</h3>
        <ul>
          <li>{this.renderKidsInfo()}</li>
          <li>{this.renderRoleInfo()}</li>
          <li>{this.renderGroupsInfo()}</li>
        </ul>

        <br/><br/>

        <form onSubmit={(event) => this.deleteTeacher(event)}>
          <div className="callout callout-danger"><h4>{i18n.t('operation_confirm')}</h4>
            <p>{i18n.t('operation_confirm_content')}</p>
            <button className="btn btn-default" type="submit">{i18n.t('button_delete')}</button>
          </div>
        </form>

      </div>

    )
  }

  render(){
    var view;
    if(this.state.isLoading === false){
      view = this.renderListView()
    }
    else{
      view = <LoaderComponent />
    }
    return(
      <div>
      {this.state.status !== '' ? helperFunctions.getResponseAlert(this.state.status, this.state.msg) : null}
      {view}
      </div>
    )
  }

}
